import {Navigate, Outlet, useNavigate,} from 'react-router-dom';
import {useState} from "react";

export const CertificateBtn = ({ data, label, agreementType }) => {

  const navigate = useNavigate();
  let token = localStorage.getItem("token");
  let user_id = localStorage.getItem("id");
  let type = agreementType?.filter((type) => type.id === data.type_id);
  

  return (
    <div className="certeficateBtn_block">
      <button key={data.id} className="agreementBtn">
        <img src={data.photo} alt="" />
      </button>
      <p>{data.certificate_name}</p>
      <button
        className="view_btn"
        onClick={() =>
          token
            ? navigate(`/agreements/${label}/${data.id}`)
            : navigate(`/certificates/${label}/${data.id}`)
        }
      >
        View
      </button>
    </div>
  );
};
