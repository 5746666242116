import "../style.scss";
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import file_upload from "../../../assets/images/icons/file_upload_black_24dp 1.png";
import AgreementToLicense3 from "./AgreemenrToLicense3";
import ScrollTop from "../../../components/scrollTop";



export default function AgreementToLicense2() {
    let navigate = useNavigate()
    let [activedisclosure, setActiveDDisclosure] = useState(false)
    let [activeInvolving, setActiveInvolving] = useState(false)


    let onChangeFileInput = () => {
        document.querySelector("#files").onchange = function () {
            const fileName = this.files[0]?.name;
            const label = document.querySelector("label[for=files]");
            label.innerText = fileName ? fileName : "fileName";
        };
    }

    let _handleCauses = () => {
        setActiveDDisclosure(true)
    }


    return (
        <>
            <ScrollTop/>
            <div className="CertificatesViewApply_header">
                <h2 className="search_text">IP name here</h2>
            </div>
            <div className='agrrement_cont'>
                <div className="agrrement_cont_header">
                    <h2 className="text_center">AGREEMENT TO LICENSE INTELLECTUAL PROPERTY [IP]</h2>
                </div>
                <div className="agrrement_cont_body margin_top_24">
                    <h4 className="margin_top_24">Definitions:.</h4>
                    <table id="definitons">
                        <tr>
                            <td>Grantor:</td>
                            <td>The owner of the [IP]</td>
                        </tr>
                        <tr>
                            <td>License:</td>
                            <td>The permission from the owner to use the [IP]</td>
                        </tr>
                        <tr>
                            <td>Licensee /Borrower:</td>
                            <td>The party seeking permission to use the [IP]</td>
                        </tr>
                        <tr>
                            <td>Intellectual property depository:</td>
                            <td>[IPD]</td>
                        </tr>
                        <tr>
                            <td>Indemnify:</td>
                            <td>Defend and hold harmless from liability against licensor. Clause [9]</td>
                        </tr>
                        <tr>
                            <td>Intellectual Property:</td>
                            <td>[IP]</td>
                        </tr>
                        <tr>
                            <td>Movable Properties Security Rights Act:</td>
                            <td>[MPSR]</td>
                        </tr>
                        <tr>
                            <td>Permitted use:</td>
                            <td>The use scenarios that the Grantor/ Licensor agrees to have IP used for.</td>
                        </tr>
                    </table>
                    <div className="hr_line">
                    </div>
                    <h5 className="">A. Recital:</h5>
                    <div>This agreement shall be an agreement to license the use of the below mentioned intellectual
                        property along the permitted use guidelines hereunder.
                    </div>
                    <div className="margin_top_24">Ownership of the intellectual property <span>does not pass </span>from
                        the Owner /
                        Licensor, to Borrower / Licensee . The license is guided on permitted use of the intellectual
                        property for consideration.
                    </div>
                    <div className="margin_top_24">This is an agreement to license the intellectual property [IP] with
                        the express authority/permission from the owner to use the intellectual property within
                        specified guidelines, within a specified period of time and at a specified cost.
                    </div>
                    <div className="margin_top_24">
                        The Grantor / licensor may at any time if aggrieved by a fundamental breach of the user
                        permitted guidelines, terminate by termination notice within this agreement, with costs and or
                        damages to the licensee.
                    </div>
                    <h5 className="margin_top_24">B. [Recital Parties].</h5>
                    <div>The two parties mentioned in this automated negotiation template contract are the Grantor /
                        Licensor and the Borrower / Licensee. The owner will grant permission of use of their
                        intellectual property [IP] for and within several set scenarios.
                    </div>
                    <h5 className="margin_top_24">C. [Recital Permitted Use]:.</h5>
                    <div>
                        <span style={{float: "right"}}>FLash at Other permitted use below 6.E</span>
                    </div>
                    <div className="margin_top_12">
                        This agreement shall set forth <span>four [4 ]</span> scenarios <span>[clause 6. A-D] </span>that
                        may be used as a
                        guideline for the types of use, that the grantor / licensor shall be able to grant permission
                        too.
                    </div>
                    <div className="margin_top_24">
                        The two parties must agree to the use parameters that they negotiate on. And counter tick and
                        confirm that they agree to be bound by the terms of these parameters set by their own
                        negotiations.
                    </div>
                    <div className="margin_top_24">
                        The <span>fifth</span> scenario named <span>[Other Scenario]: [clause 6 E]</span> is a
                        negotiation that is independent of the above 4 scenarios presented to users.
                    </div>
                    <div className="margin_top_24">
                        Management <a style={{fontWeight: "400"}}>shall not be responsible</a> for any breach of
                        warranty, condition future or present
                        emanating from the results, and contractual intentions within that category of <span>[Other Scenario].</span>
                        We shall however categorically state that the users <span style={{color: "red"}}>must</span> use
                        this category <a style={{fontWeight: "400"}} href="">for legal purposes,</a>and in the event of
                        any illegal activity
                        that has emanated from the use of this system, The said actions of the licensee/ borrower shall
                        be <span>VOID</span> and the
                        relevant authorities, may investigate the purported and attempted procured license actions that
                        are illegal. Ipdepository may
                        deactivate / ban the user's account. On request of the relevant authorities and in contravention
                        of the intellectual property depository community guidelines or terms and conditions. The
                        management also will reserve the right to sue the users within this category for gross
                        misconduct and any illegal activity emanating from negotiation between the parties.
                    </div>
                    <h5 className="margin_top_24">D. [Recital Permitted TIme]:</h5>
                    <div className="margin_top_8">
                        There shall be a timeline for the permitted use, to portray the beginning and the end of the
                        license duration and at the end of that timeline, the licenseagreement with its binding
                        obligations shall be deemed to have lapsed. This agreement shall assist the parties, Grantor /
                        Licensor and the Borrower / Licensee, negotiate and confirm the amount of time the permitted use
                        shall be permitted and upon the time and date stated, and confirmed between the two parties the
                        license shall be deemed to have begun and lapsed.
                    </div>
                    <div className="margin_top_24">
                        The parties are free at any time to renew the terms of the permitted use and duration at, during
                        and at the end of the license duration. By pressing the button <span>[renew]</span>. Or to
                        terminate the
                        license] at any time during the license, with service costs to the parties.
                    </div>
                    <h5 className="margin_top_24">E. User Obligation:</h5>
                    <div className="margin_top_8">
                        The management of ipdepository.com hereby ensures that the obligation, burden, and capacity lies
                        on the user to upload accurate information.
                    </div>
                    <div className="margin_top_24">
                        “It is therefore upon you the user to upload factual information and assist every eventual user
                        of your intellectual property, to receive the right and factual information” IPD administrator,
                        [JEM]
                    </div>
                    <div>
                        The management of ipdepository.com also hereby reserves the non-exclusive right to enter into
                        legal proceedings against any user who is found to be in gross breach of our terms and
                        conditions. We also reserve the right to sue for monetary damages. For breach of the aims of the
                        system to preserve the integrity of [IPD] directory and the Movable Properties Security Rights
                        Act register.
                    </div>
                    <div className="margin_top_12">
                        <span style={{float: "right"}}>[Section 20 of the MPSR Act 2017] integrity of register.</span>
                    </div>
                    <h5 className="margin_top_12">F. IPD Obligation:</h5>
                    <div className="margin_top_8">
                        The management will strive <span>to achieve the aims</span> mentioned above and we are obligated
                        to do so in the following.
                    </div>
                    <div className="margin_top_24">
                        <span>Record transactions:</span> by the use of our system, users will be able to have their
                        [IP] transactions recorded on their profiles and on the relevant movable properties registry as
                        Notices to the registrar.
                    </div>
                    <div className="margin_top_24">
                        The user's transactions will be accurately linked to codes that the system shall generate, to
                        accurately capture the user profile details and transaction details.
                    </div>
                    <div className="margin_top_24">
                        <span> To maintain the intellectual property directory</span>
                        found on our system, to enable users to search
                        for the accurate position of any intellectual property uploaded on to the system.
                    </div>
                    <div className="disclaimer margin_top_24">
                        *******“Disclaimer”*******
                    </div>
                    <div>
                        Management <a href="" style={{fontWeight: "400"}}>shall not be responsible for any user
                        inactivity / activity,</a> during contractual
                        negotiations and the permitted use and activity ongoing during the license period and
                        obligations set forth in the agreement herein. It is for the user to use the automated
                        negotiation template with the purpose and understanding that speed is of the essence in every
                        transaction set forth.
                    </div>
                    <div className="hr_line">

                    </div>
                    <div className="agrrement_cont_header">
                        <h2 className="text_center">SUBJECT MATTER OF THIS AGREEMENT TO LICENSE IS:</h2>
                    </div>
                    <div className="d_flex align_center margin_top_24">
                        <h5>NAME OF INTELLECTUAL PROPERTY:</h5>
                        <div className="filled_data_input" style={{marginLeft: "24px"}}>
                            <input type="text" placeholder="NAME OF CERTIFICATE"/>
                        </div>
                    </div>
                    <div className="margin_top_12">
                        <span style={{float: "right"}}>[S8 of MPSR Act 2017]</span>
                    </div>
                    <div className="margin_top_12">The License agreement and permitted use will be effective by the
                        NOTICE to license being served
                        upon the Movable Properties Rights Registry and in receiving acceptance of the same notice from
                        the registrar of the MPSR.
                    </div>
                    <div className="hr_line">
                    </div>
                    <div className="agrrement_cont_header">
                        <h2 className="text_center">DESCRIPTION OF INTELLECTUAL PROPERTY</h2>
                    </div>
                    <div className="margin_top_12">
                        There is an implied condition that the goods shall correspond with the description.
                    </div>
                    <div className="margin_top_12">
                        <span style={{float: "right"}}>[Section 15 Sale of Goods Act]</span>
                    </div>
                    <div className="margin_top_12">
                        Where the licensee has made it known to the licensor the reason for the license of the [IP]
                        there is an implied condition that the goods in the [IP] shall be fit for the purpose, that was
                        communicated by the grantor/ licensor.
                    </div>
                    <div className="margin_top_12">
                        <span style={{float: "right"}}>[Section 16 (a) Sale of Goods Act]</span>
                    </div>
                    <div className="hr_line">

                    </div>
                    <h5>LAWS:</h5>
                    <div>
                        This contract shall be governed by the Contract Laws of Kenya, The relevant intellectual
                        property laws, governing the mentioned property above, such as the Copyright Act No 12 of 2001,
                        The Trademark Act CAP 506, The Industrial Property Act No 3 of 2001, , The Movable Properties
                        Securities Act 2017 supported by the relevant sections of the Companies Act, the The
                        Registration of Business Names Act, and amendment bill 2021. The Hire purchase Act Kenya Seed
                        and Plant Varieties Act and any other laws that may be required, expressly and implied to
                        achieve the quiet enjoyment of the terms of permitted in a license agreement within the
                        guidelines of permitted use of the intellectual property [IP] from the owner within the
                        specified period of time for value.
                    </div>
                    <div className="margin_top_24">
                        International treaties shall apply to their category of intellectual property governed by each
                        jurisdiction. WCT WIPO copyright treaty, TRIPS trade related aspects of intellectual property,
                        Marrakesh Agreement, The Paris Convention, The Madrid Protocol, Lisbon agreement, Hague
                        agreement. UPOV Convention.
                    </div>
                    <div className="margin_top_24">
                        Practice: Uniform Standards For Intellectual Property Rights Accountability.
                    </div>
                    <div className="hr_line">
                    </div>
                    <div>
                        <span>1. THIS AGREEMENT TO LICENSE</span> is between: [PARTIES]
                    </div>
                    <div className="margin_top_24">
                        <span>PARTY A: 1.1.: GRANTOR / LICENSOR:</span>
                    </div>
                    <div className="apply_certificate_item margin_top_12">
                        <div className="certificate_item">
                            <label htmlFor="">Email</label>
                            <input type="email" placeholder=""/>
                        </div>
                        <div className="certificate_item file_upload" style={{position: "relative"}}>
                            <p>Passport picture</p>
                            <div className="input_container">
                                <img src={file_upload} alt="file_upload" className="file_upload_img"/>
                                <label htmlFor="files" className="labelbtn">Upload here</label>
                                <input type="file" id="files" placeholder="Passport" onChange={onChangeFileInput}/>
                            </div>
                        </div>
                    </div>
                    <div className="apply_certificate_item">
                        <div className="certificate_item">
                            <label htmlFor="">Nationality</label>
                            <input type="text" value="" placeholder=""/>
                        </div>
                        <div className="certificate_item">

                        </div>
                    </div>
                    <div style={{marginTop: "-12px"}}>
                        <span style={{float: "right"}}>[Jurisdiction clause 10]</span>
                    </div>
                  <h5 className="margin_top_32">PARTY 1.2.:BORROWER/ LICENSEE:</h5>
                    <div className="apply_certificate_item margin_top_12">
                        <div className="certificate_item filled_data_input">
                            <input type="text" placeholder="namesurname@gmail.com"/>
                        </div>
                        <div className="certificate_item filled_data_input">
                            <input type="text" placeholder="passport pic.jpeg"/>
                        </div>
                    </div>
                    <div className="apply_certificate_item">
                        <div className="certificate_item filled_data_input">
                            <input type="text" placeholder="Nationality"/>
                        </div>
                        <div className="certificate_item filled_data_input">

                        </div>
                    </div>
                    <div style={{marginTop:"-12px"}}>
                        <span style={{float: "right"}}>[Jurisdiction clause 10]</span>
                    </div>
                    <div className="margin_top_32"><span>1.3 PRE-CONTRACTUAL DOCUMENTATION:</span></div>
                    <div className="margin_top_12">PARTY 1: GRANTOR / LICENSOR:</div>
                    <div className="apply_certificate_item margin_top_12">
                        <div className="certificate_item file_upload" style={{position: "relative"}}>
                            <p>ID and KPA PIN</p>
                            <div className="input_container">
                                <img src={file_upload} alt="file_upload" className="file_upload_img"/>
                                <label htmlFor="files" className="labelbtn">Upload here</label>
                                <input type="file" id="files" placeholder="Passport" onChange={onChangeFileInput}/>
                            </div>
                        </div>
                        <div className="certificate_item file_upload" style={{position: "relative"}}>
                            <p>Resolution to sell</p>
                            <div className="input_container">
                                <img src={file_upload} alt="file_upload" className="file_upload_img"/>
                                <label htmlFor="files" className="labelbtn">Upload here</label>
                                <input type="file" id="files" placeholder="Passport" onChange={onChangeFileInput}/>
                            </div>
                        </div>
                    </div>
                    <div className="apply_certificate_item">
                        <div className="certificate_item file_upload" style={{position: "relative"}}>
                            <p>Passport picture</p>
                            <div className="input_container">
                                <img src={file_upload} alt="file_upload" className="file_upload_img"/>
                                <label htmlFor="files" className="labelbtn">Upload here</label>
                                <input type="file" id="files" placeholder="Passport" onChange={onChangeFileInput}/>
                            </div>
                        </div>
                        <div className="certificate_item file_upload" style={{position: "relative"}}>
                            <p>Front page of national identity card</p>
                            <div className="input_container">
                                <img src={file_upload} alt="file_upload" className="file_upload_img"/>
                                <label htmlFor="files" className="labelbtn">Upload here</label>
                                <input type="file" id="files" placeholder="Passport" onChange={onChangeFileInput}/>
                            </div>
                        </div>
                    </div>
                    <div className="margin_top_12">PARTY 1.2.: BORROWER/ LICENSEE:</div>
                    <div className="apply_certificate_item margin_top_12">
                        <div className="certificate_item filled_data_input">
                            <input type="text" placeholder="ID.pdf | KPA PIN.pdf "/>
                        </div>
                        <div className="certificate_item filled_data_input">
                            <input type="text" placeholder="Resolution to sell.pdf"/>
                        </div>
                    </div>
                    <div className="apply_certificate_item">
                        <div className="certificate_item filled_data_input">
                            <input type="text" placeholder="Passport picture.jpeg"/>
                        </div>
                        <div className="certificate_item filled_data_input">
                            <input type="text" placeholder="Front page of national identity card.jpeg"/>
                        </div>
                    </div>
                    <div className="hr_line">
                    </div>
                    <h4>2. DUE DILIGENCE:</h4>
                    <div className="d_flex align_center form-group margin_top_12">
                        <input type="checkbox" id="checkbox" name="checkbox"/>
                        <label htmlFor="checkbox">Every contractual negotiation shall submit a due diligence search to
                            the registry and across the Intellectual property depository system. These search results
                            shall aid the users in understanding the true position, as to ownership of the [IP]
                            intellectual property in question.</label>
                    </div>
                    <div className="margin_top_16">
                        This is a tri-part search that will enable the parties to know the true position of ownership of
                        the subject matter of this agreement to the license template.
                    </div>
                    <div className="filled_data_input margin_top_16">
                        <input type="text" placeholder="NAME OF CERTIFICATE"/>
                    </div>
                    <div className="margin_top_12">
                        <span style={{float: "right"}}>[Section 8 of the MPSR Act 2017]</span>
                    </div>
                    <h5 className="margin_top_24">2.1. SEARCH ONE:</h5>
                    <div className="margin_top_12">PRE-SEARCH: Both parties hereby consent to conduct an immediate
                        search:
                    </div>
                    <div className="d_flex align_center margin_top_12">
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="No" value="No" name="radio"
                            />
                            No
                        </label>
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="yes" value="yes" name="radio" checked="checked"
                            />
                            Yes
                        </label>
                    </div>
                    <h5 className="margin_top_32">2.2. SEARCH TWO:</h5>
                    <p className="margin_top_12">SEARCH NOW:</p>
                    <div className="margin_top_12">This is the intellectual property depository directory search, to
                        confirm the information of
                        the parties to this contract are correct, and that the position of ownership of the intellectual
                        property named:-
                    </div>
                    <div className="d_flex align_center margin_top_8">
                        <div className="filled_data_input" style={{marginRight: "24px"}}>
                            <input type="text" placeholder="NAME OF INTELLECTUAL PROPERTY"/>
                        </div>
                        <h5>[Section 8 of the MPSR Act 2017]</h5>
                    </div>
                    <p className="margin_top_8">is accurately captured in this agreement.</p>
                    <div className="margin_top_12">
                        <span style={{float: "right"}}>[Section 8 of MPSR Act 2017]</span>
                    </div>
                    <h5 className="margin_top_24">2.3. SEARCH THREE:</h5>
                    <p className="margin_top_12">POST SEARCH:</p>
                    <div className="margin_top_12">This is the search conducted at the Movable Properties Security
                        Registry to ensure that the transfer of the asset
                    </div>
                    <div className="d_flex align_center margin_top_8">
                        <div className="filled_data_input" style={{marginRight: "24px"}}>
                            <input type="text" placeholder="NAME OF INTELLECTUAL PROPERTY"/>
                        </div>
                        <h5>[Section 8 of the MPSR Act 2017]</h5>
                    </div>
                    <div className="margin_top_8">reflects in the register for the specified duration negotiated between
                        the contracting
                        parties.
                    </div>
                    <div className="hr_line">
                    </div>
                    <h5>3. CONFIDENTIALITY:</h5>
                    <div className="margin_top_12">The owner/ licensor and the borrower / licensee shall be deemed to
                        have created a confidential understanding, where the borrower/ licensee shall not reveal the
                        know-how or intricacies, trade secrets or business operation models of the property that may be
                        revealed to them, during the subsistence of this license agreement.
                    </div>
                    <div className="d_flex align_center margin_top_8">
                        <div className="filled_data_input">
                            <input type="text" placeholder="NAME OF INTELLECTUAL PROPERTY"/>
                        </div>
                    </div>
                    <div className="margin_top_12">
                        <span style={{float: "right"}}>[Section 8 of MPSR Act 2017]</span>
                    </div>
                    <div className="margin_top_24">
                        The licensee <span>shall not disclose </span> to anyone the <span>know-how</span> involved in
                        the [IP] to anyone who is not
                        the licensor, should be done by or on strict, written instructions from the owner / licensor.
                    </div>
                    <div className="d_flex align_center margin_top_12">
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="No1" value="No1" name="radio1"
                            />
                            No
                        </label>
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="yes1" value="yes1" name="radio1" checked="checked"
                            />
                            Yes
                        </label>
                    </div>
                    <div className="margin_top_24">There has been a previous disclosure.
                    </div>
                    <div className="d_flex align_center margin_top_12">
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="No1" value="No1" name="radio2"
                            />
                            No
                        </label>
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="yes1" value="yes1" name="radio2" checked="checked"
                            />
                            Yes
                        </label>
                    </div>
                    <div className="margin_top_16">
                        <div className="">
                            <textarea name="" id="" cols="" rows=""
                                      style={{background: "white", width: "100%"}}>Max 200 characters</textarea>
                        </div>
                    </div>
                    <div className="d_flex align_center form-group margin_top_12">
                        <input type="checkbox" id="checkbox1" name="checkbox1"
                               onChange={() => setActiveDDisclosure(!activedisclosure)}/>
                        <label htmlFor="checkbox1">I understand the above disclosure</label>
                    </div>
                    {
                        !activedisclosure ?
                            null
                            :
                            <div>
                                <div className="hr_line">
                                </div>
                                <h5>4. LICENSEE DISCLOSURE ACCEPTANCE:</h5>
                                <div className="margin_top_12">
                                    The borrower / licensee hereby confirms that the Grantor / Licensor has disclosed
                                    the relevant information on a previous disclosure of know-how and that the
                                    disclosure will in no way cause any damage, breach of implied warranty to this
                                    license agreement mentioned herein.
                                </div>
                                <div className="d_flex align_center margin_top_12">
                                    <label className="radio_btn_b d_flex align_center">
                                        <input type="radio" id="No2" value="No2" name="radio3"
                                        />
                                        No
                                    </label>
                                    <label className="radio_btn_b d_flex align_center">
                                        <input type="radio" id="yes2" value="yes2" name="radio3" checked="checked"
                                        />
                                        Yes
                                    </label>
                                </div>
                                <h5 className="margin_top_24">
                                    ANY THIRD PARTY CLAIMS AGAINST THE [IP]
                                </h5>
                                <div className="margin_top_8">Are there any pending lawsuits or claims against the
                                    intellectual property?
                                </div>
                                {
                                    !activeInvolving ?
                                        <div>
                                            <div className="d_flex align_center margin_top_12">
                                                <label className="radio_btn_b d_flex align_center">
                                                    <input type="radio" id="No3" value="No3" name="radio4"
                                                    />
                                                    No
                                                </label>
                                                <label className="radio_btn_b d_flex align_center">
                                                    <input type="radio" id="yes3" value="yes3" name="radio4" checked="checked"
                                                    />
                                                    Yes
                                                </label>
                                            </div>
                                            <div className="margin_top_16">
                                                <div className="">
                            <textarea name="" id="" cols="" rows=""
                                      style={{background: "white", width: "100%"}}>Max 100 characters</textarea>
                                                </div>
                                            </div>
                                            <div className="margin_top_8">
                                                ANY INFRINGEMENT CAUSES IN COURT OR ARBITRATION INVOLVING [IP]
                                            </div>
                                            <div className="d_flex align_center margin_top_12">
                                                <label className="radio_btn_b d_flex align_center">
                                                    <input type="radio" id="No4" value="No4" name="radio5" onChange={()=>setActiveInvolving(true)}
                                                    />
                                                    No
                                                </label>
                                                <label className="radio_btn_b d_flex align_center">
                                                    <input type="radio" id="yes4" value="yes4" name="radio5" checked="checked"
                                                    />
                                                    Yes
                                                </label>
                                            </div>
                                            <div className="margin_top_16">
                                                <div className="">
                            <textarea name="" id="" cols="" rows=""
                                      style={{background: "white", width: "100%"}}>Max 100 characters</textarea>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <AgreementToLicense3/>
                                }



                            </div>
                    }


                </div>
            </div>
        </>
    )
}