import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../../api";
import certificate_pic from "../../../assets/images/certificate_pic.png";
import { Loading } from "../../../components";
import ok_icon_green from "../../../assets/images/ok_icon_green.png";
import Modal from "react-modal";
import cancel_icon from "../../../assets/images/icons/closeBlack.png";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "31px",
    maxWidth: "563px",
    width: "100%",
    zIndex: "234564",
  },
};

export function Certeficate() {
  const inputFile = useRef(null);

  let { id } = useParams();
  const [loading, setLoading] = useState(false);
  let navigation = useNavigate();
  let { pathname } = useLocation();
  let [file, setFile] = useState(null);
  let [photo, setPhoto] = useState("");
  let [agreements, setAgreements] = useState({});
  const [modalIsOpen, setIsOpen] = useState(false);
  let [customerId,setCustomerId] = useState(false)

  let [agreementType, setAgreementType] = useState([]);

  const arrPath = pathname.split("/");
  const path = arrPath[arrPath.length - 2];
  let token = localStorage.getItem("token");
  let user_id = localStorage.getItem("id");

  let [category, setSetCategory] = useState([]);
  let [error, setError] = useState("");
  let [data, setData] = useState({
    type: "sell",
    category_id: 1,
    publish: "private",
  });
  let [pressBtnVerification, setPressBtnVerification] = useState(false);
  let [pressBtnValuation, setPressBtnValuation] = useState(false);
  let [pressBtnInsurance, setPressBtnInsurance] = useState(false);
  let [type, setType] = useState("partner");
  useEffect(() => {
    getAgreement();
    getCategory();
    getAgreementType();
  }, []);

  let getAgreement = async () => {
    try {
      let response = await axiosInstance.get(
        `/api/client/single-agreement/${id}`
      );
      setAgreements(response.data.data);
      setPhoto(response.data.data.photo);
    } catch (e) {
      console.log(e);
      console.log(e.response);
    }
  };

  let getAgreementType = async () => {
    try {
      let response = await axiosInstance.get("api/v1/certificate-types");
      setAgreementType(response.data.data);
    } catch (e) {
      console.log(e);
      console.log(e.response);
    }
  };
  let changeInput = (e, name) => {
    setAgreements({ ...agreements, [name]: e });
  };

  let changeInputCategory = (e, name) => {
    setError("");
    if (name === "type_id") {
      let type_id = agreementType.filter((item) => item.name === e);
      setAgreements({ ...agreements, [name]: type_id[0].id });
      return setData({ ...data, ...{ [name]: type_id[0].id } });
    } else if (name === "category_id") {
      
      let category_id = category.filter((item) => item.id === +e);
      setAgreements({ ...agreements, [name]: category_id[0].id });
      return setData({ ...data, ...{ [name]: category_id[0].id } });
      // setData({ ...data, category_id: category_id[0].id });
    } else {
      setAgreements({ ...agreements, [name]: e });
      setData({ ...data, ...{ [name]: e } });
    }
  };

  let getCategory = async () => {
    try {
      let response = await axiosInstance.get("api/v1/categories");
      setSetCategory(response.data.data);
    } catch (e) {
      console.log(e);
      console.log(e.response);
    }
  };

  let update = async () => {
    try {
      setLoading(true);
      
      let formData = new FormData();
      if (file) {
        formData.append("photo", file);
      }
      
      formData.append("publish", agreements.publish);
      formData.append("type_id", agreements.type_id);
      formData.append("category_id", agreements.category_id);
      formData.append("certificate_name", agreements.certificate_name);
      formData.append("certificate_number", agreements.certificate_number);
      formData.append("proprietor_name", agreements.proprietor_name);
      formData.append("name_of_concept", agreements.name_of_concept);
      formData.append("date_of_issue", agreements.date_of_issue);
      formData.append("country_issued", agreements.country_issued);
      formData.append(
        "certificate_description",
        agreements.certificate_description
      );
      

      // debugger
      await axiosInstance.post(
        `api/client/update-agreement/${agreements.id}`,
        formData
      );
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };
  const onButtonClick = () => {
    inputFile.current.click();
  };

  const handleFileSelected = (e) => {
    const files = Array.from(e.target.files);
    setFile(files[0]);
    const objectUrl = URL.createObjectURL(files[0]);
    setPhoto(objectUrl);
  };

  let creat = () => {
    navigation(`/certificates/${type}/${type}/${id}`);
  };

  useEffect(() => {
    if (agreements?.proposal) {
      const sendId = agreements.proposal.map((item) => item.customer_id);
      setCustomerId(sendId.includes(+user_id));
    }
  }, [agreements]);
  const handleDelete = async (id) => {
    try {
      debugger
      await axiosInstance.get(`api/client/delete-agreement/${id}`);
      closeModal()
        navigation(-1);
    } catch (error) {
      console.log("Something error");
    }
  }
    const  closeModal =  () => {
      setIsOpen(false);
      document.body.style += "overflow: inherit";
    }
  return (
    <div className="certeficate">
      <div className="body">
        <div className="d_flex justify_content_start"></div>
        <div>
          <img
            src={photo ? photo : certificate_pic}
            className={"photoContent"}
            onClick={onButtonClick}
          />
          <input
            type="file"
            id="file"
            ref={inputFile}
            style={{ display: "none" }}
            onChange={handleFileSelected}
          />
          {+user_id === +agreements.user_id ? (
            <>
              <div className="settings">
                <label className="settings_item" htmlFor="private">
                  <input
                    type="radio"
                    id="private"
                    value="private"
                    name="radio"
                    checked={agreements.publish === 0}
                    onChange={(e) => changeInput(0, "publish")}
                  />
                  Private
                </label>
                <label className="settings_item" htmlFor="public">
                  <input
                    type="radio"
                    id="public"
                    value="public"
                    name="radio"
                    checked={agreements.publish === 1}
                    onChange={(e) => changeInput(1, "publish")}
                  />
                  Public
                </label>
              </div>
              <div className="d_flex fd_column send_to_select">
                {pressBtnVerification ? (
                  <p
                    className="d_flex align_center"
                    style={{ color: "#8FC54D" }}
                  >
                    <img
                      src={ok_icon_green}
                      alt=""
                      style={{ marginRight: "5px" }}
                    />
                    Sent to verification
                  </p>
                ) : (
                  <button onClick={() => setPressBtnVerification(true)}>
                    Send to verification
                  </button>
                )}
                {pressBtnValuation ? (
                  <p
                    className="d_flex align_center"
                    style={{ color: "#8FC54D" }}
                  >
                    <img
                      src={ok_icon_green}
                      alt=""
                      style={{ marginRight: "5px" }}
                    />
                    Send to valuation
                  </p>
                ) : (
                  <button onClick={() => setPressBtnValuation(true)}>
                    Send to valuation
                  </button>
                )}
                {pressBtnInsurance ? (
                  <p
                    className="d_flex align_center"
                    style={{ color: "#8FC54D" }}
                  >
                    <img
                      src={ok_icon_green}
                      alt=""
                      style={{ marginRight: "5px" }}
                    />
                    Send to insurance
                  </p>
                ) : (
                  <button onClick={() => setPressBtnInsurance(true)}>
                    Send to insurance
                  </button>
                )}
                <button onClick={() => setIsOpen(true)}>
                  Delete certificate
                </button>
              </div>
            </>
          ) : null}
        </div>
        <div>
          <div className="certificate_items_block">
            <div className="certificate_item">
              <label htmlFor="">Certificate Name</label>
              <input
                disabled={+user_id !== +agreements.user_id}
                type="text"
                value={agreements.certificate_name}
                onChange={(e) =>
                  changeInput(e.target.value, "certificate_name")
                }
              />
            </div>
            <div className="certificate_item">
              <label htmlFor="">Certificate Number</label>
              <input
                disabled={+user_id !== +agreements.user_id}
                type="text"
                value={agreements.certificate_number}
                onChange={(e) =>
                  changeInput(e.target.value, "certificate_number")
                }
              />
              {/*<p>{id}</p>*/}
            </div>
            <div className="certificate_item">
              <label htmlFor="">Proprietor name</label>
              <input
                disabled={+user_id !== +agreements.user_id}
                type="text"
                value={agreements.proprietor_name}
                onChange={(e) => changeInput(e.target.value, "proprietor_name")}
              />
            </div>
            <div className="certificate_item">
              <label htmlFor="">Certificate category</label>
              <select
                disabled={+user_id !== +agreements.user_id}
                onChange={
                  (e) => changeInputCategory(e.target.value, "category_id")
                  // console.log('e.target.value :', e.target.value)
                }
              >
                {category.map((item, index) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="certificate_item">
              <label htmlFor="">Name of concept</label>
              <input
                disabled={+user_id !== +agreements.user_id}
                type="text"
                value={agreements.name_of_concept}
                onChange={(e) => changeInput(e.target.value, "name_of_concept")}
              />
            </div>
            <div className="certificate_item">
              <label htmlFor="">Date of Issue</label>
              <input
                disabled={+user_id !== +agreements.user_id}
                type="text"
                value={agreements.date_of_issue}
                onChange={(e) => changeInput(e.target.value, "date_of_issue")}
              />
            </div>
            <div className="certificate_item">
              <label htmlFor="">Country Issued</label>
              <input
                disabled={+user_id !== +agreements.user_id}
                type="text"
                value={agreements.country_issued}
                onChange={(e) => changeInput(e.target.value, "country_issued")}
              />
            </div>
            <div className="certificate_item">
              <label htmlFor="">Certificate Description #</label>
              <textarea
                disabled={+user_id !== +agreements.user_id}
                name=""
                id=""
                cols="30"
                rows="10"
                value={agreements.certificate_description}
                onChange={(e) =>
                  changeInput(e.target.value, "certificate_description")
                }
              ></textarea>
            </div>
          </div>
          <div className="qrContent">
            <img src={agreements.qr_code} alt="" title="" />
            <p className="text_center">Certificate QR code</p>
          </div>
          {+user_id === +agreements.user_id ? (
            <div className="discard_save_b">
              <button
                className="discard_btn"
                onClick={() => {
                  setPhoto("");
                  getAgreement();
                }}
              >
                Discard
              </button>
              <button className="save_btn" onClick={update}>
                Save changes
              </button>
            </div>
          ) : null}
          {token && +user_id !== +agreements.user_id && !customerId ? (
            <div className="settings certificate_desc_settings">
              {agreementType.map((item) => {
                return (
                  <label className="settings_item" htmlFor={`${item.name}`}>
                    <input
                      type="radio"
                      id={`${item.name.toLowerCase()}`}
                      value={`${item.name}`}
                      name="radio"
                      checked={type === `${item.name.toLowerCase()}`}
                      onClick={(e) => {
                        setType(`${item.name.toLowerCase()}`);
                        changeInput("type", `${item.name.toLowerCase()}`);
                      }}
                    />
                    {item.name}
                  </label>
                );
              })}
            </div>
          ) : null}

          {token && +user_id !== agreements.user_id && !customerId ? (
            <div
              className="discard_save_b"
              style={{ marginLeft: "0px", marginTop: "30px" }}
            >
              <button
                className="save_btn"
                style={{ marginLeft: "0px" }}
                onClick={creat}
              >
                Apply
              </button>
            </div>
          ) : !token && +user_id === agreements.user_id ? (
            <button
              className={"apply_btn"}
              onClick={() => navigation("/sign-in")}
            >
              Sign In
            </button>
          ) : null}
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Modal"
      >
        <div className="modal_inner">
          <div className="modal_inner_header">
            <p>Delete!</p>
            <img src={cancel_icon} alt="" onClick={closeModal} />
          </div>
         
          <div className="text"> Are you sure you want to delete this certificate from your portfolio</div>
          <div className="cancel_btn_b">
            <button onClick={() => handleDelete(id)} style={{marginRight:"5px"}}>Yes</button>
            <button onClick={closeModal}>No</button>
          </div>
        </div>
      </Modal>
      <Loading modalIsOpen={loading} />
    </div>
  );
}
