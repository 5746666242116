import {NavLink, Outlet} from "react-router-dom";
import React, {useEffect, useState} from "react";
import axiosInstance from "../api";


export function AuthorizedHeader() {
    return (
        <div>
            <div className="authorizedHeader">
                <ul>
                    <li className="nav-item">
                        <NavLink
                            to="/agreements"
                            activeClassName="active"
                            className={({isActive}) => isActive ? 'Link_active' : 'link'}
                        >
                            My certificates
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink
                            to="/verified_certificates"
                            className={({isActive}) => isActive ? 'Link_active' : 'link'}
                        >
                            Agreements
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink
                            to="/add-ip"
                            className={({isActive}) => isActive ? 'Link_active' : 'link'}
                        >
                            Add IP
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink
                            // to="/requests"
                            exact to="/requests"
                            className={({isActive}) => isActive ? 'Link_active' : 'link'}
                        >
                            Requests
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink
                            to="/proposals"
                            className={({isActive}) => isActive ? 'Link_active' : 'link'}
                        >
                            Proposals
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink
                            to="/messages"
                            className={({isActive}) => isActive ? 'Link_active' : 'link'}
                        >
                            Messages
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink
                            to="/settings"
                            className={({isActive}) => isActive ? 'Link_active' : 'link'}
                        >
                            Settings
                        </NavLink>
                    </li>
                    {/*<li className="nav-item">*/}
                    {/*    <NavLink*/}
                    {/*        to="/notification"*/}
                    {/*        className={({isActive}) => isActive ? 'Link_active' : 'link'}*/}
                    {/*    >*/}
                    {/*        Notification*/}
                    {/*    </NavLink>*/}
                    {/*</li>*/}
                </ul>
            </div>
            <main>
                <Outlet/>
            </main>
        </div>
    );
}
