import "../style.scss";
import {useNavigate} from "react-router-dom";
import React, {useState} from "react";
import ScrollTop from "../../../components/scrollTop";
import file_upload from "../../../assets/images/icons/file_upload_black_24dp 1.png";

export default function AgreementToCharge2() {
    let navigate = useNavigate()
    let [definitions, setDefinitions] = useState([
            {
                id: 1,
                td1: "Charge:",
                td2: "Secure payment of a debt [borrow using IP]",
            },
            {
                id: 2,
                td1: "Chargee / Creditor:",
                td2: "Party Lending",
            },
            {
                id: 3,
                td1: "Collateral:",
                td2: "The IP",
            },
            {
                id: 4,
                td1: "Default:",
                td2: "Not paid an installment toward the amount borrowed",
            },
            {
                id: 5,
                td1: "Discharge:",
                td2: "After payment of loan amount, the [IP] will be released from the loan obligation",
            },
            {
                id: 6,
                td1: "IP:",
                td2: "Intellectual Property",
            },
            {
                id: 7,
                td1: "MPSR 2017:",
                td2: "The Movable Properties Securities Rights Act of 2017",
            },
            {
                id: 8,
                td1: "Possession:",
                td2: "The right of a creditor to take over the collateral and ensure its use to payment of the borrowed amount",
            },
            {
                id: 9,
                td1: "Secured:",
                td2: "Attached supporting documents Valuation & Insurance policies",
            },
            {
                id: 10,
                td1: "Unsecured:",
                td2: "Supporting documents, valuation & Insurance not attached",
            },
            {
                id: 11,
                td1: "Undated:",
                td2: "The date is not included",
            },
            {
                id: 12,
                td1: "Assignment:",
                td2: "The grantor shall sign an undated assignment to allow The chargee at default to transact as owner to fulfill Remedies available",
            },
            {
                id: 13,
                td1: "Receiver:",
                td2: "Neutral party appointed by the court to manage the Legal interests in the [IP] intellectual property towards payment of creditors and sustaining business",
            },
            {
                id: 14,
                td1: "Redemption:",
                td2: "The right of the chargor to repay the loan/ borrowed amount in installments agreed [7D]",
            },

        ]
    )


    let onChangeFileInput = () => {
        document.querySelector("#files").onchange = function () {
            const fileName = this.files[0]?.name;
            const label = document.querySelector("label[for=files]");
            label.innerText = fileName ? fileName : "fileName";
        };
    }

    return (
        <>
            <ScrollTop/>
            <div className="CertificatesViewApply_header">
                <h2 className="search_text">IP name here</h2>
            </div>
            <div className='agrrement_cont'>
                <div className="agrrement_cont_header">
                    <h2 className="text_center">AGREEMENT TO CHARGE INTELLECTUAL PROPERTY AUTOMATED NEGOTIATION
                        CONTRACT</h2>
                </div>
                <div className="agrrement_cont_body margin_top_24">
                    <h4 className="margin_top_24">Definitions:.</h4>
                    <table id="definitons">
                        {
                            definitions.map((item, index) => {
                                return (
                                    <tr key={item.id}>
                                        <td>{item.td1}</td>
                                        <td>{item.td2}</td>
                                    </tr>
                                )
                            })
                        }
                    </table>
                    <div className="hr_line">
                    </div>
                    <h5 className="">A. Recital:</h5>
                    <div> This agreement shall be an agreement
                        to <span>Charge [secure a security interest] </span> using the
                        intellectual property along the permitted use guidelines hereunder.
                    </div>
                    <div className="margin_top_24">Ownership of the intellectual
                        property <span>does not pass </span> from the Grantor /Chargor [Borrower] to Lender / Chargee .
                    </div>
                    <div className="margin_top_24">
                        Until the chargee exercises the legal rights afforded to them by forfeited payment of the
                        security as explained below herein, and relevant legal applications awarded by Courts of Law.
                    </div>
                    <div className="margin_top_24">
                        This is an agreement to charge the said intellectual property within specified guidelines,
                        within a specified period of time.
                    </div>
                    <div className="margin_top_24">
                        The Chargee may at the default of the chargor to repay issue
                        <span> DEFAULT NOTICE </span> on the Chargor specifying the default amount and interest,
                        payable, and
                        eventually within 0 - 90 day [agreeable by the parties] period of non compliance apply to court
                        to exercise the remedies that may be available to the parties. [Assignment NOTICE] activated.
                    </div>
                    <div className="margin_top_8">
                        <span style={{float: "right"}}>[Section 67 of the MPSR Act 2017]</span>
                    </div>
                    <h5 className="margin_top_24">Recital Parties</h5>
                    <div className="margin_top_8">
                        <span style={{float: "right"}}>[Section 5 of the MPSR Act 2017]</span>
                    </div>
                    <div className="margin_top_8">
                        A person/ user shall exercise the rights and perform the obligations under this Act diligently
                        and in good faith.
                    </div>
                    <div className="margin_top_24">
                        The two parties mentioned in this automated negotiation contract are the
                        Owner / Chargor and the Chargee. The grantor / chargor will intend to create a formal charge by
                        successful execution of this agreement.
                    </div>
                    <div>The two parties mentioned in this automated negotiation template contract are the Grantor /
                        Licensor and the Borrower / Licensee. The owner will grant permission of use of their
                        intellectual property [IP] for and within several set scenarios.
                    </div>
                    <h5 className="margin_top_24">Recital Secured with or without additional documentation</h5>
                    <div className="margin_top_8">
                        This agreement to charge shall set forth the terms and conditions, to enable the owner to secure
                        the payment of a debt using their registered intellectual property. The security may be secured
                        or unsecured. The secured path will enable the owner to attach
                        their <span>valuation report</span> and
                        corresponding <span> insurance policy </span> as supporting documents to be encumbered along
                        with their
                        intellectual property certificate. The unsecured path will not include the valuation and
                        insurance policy.
                    </div>
                    <h5 className="margin_top_24">Recital Notice to charge</h5>
                    <div className="margin_top_8">
                        The automated negotiation template herein will allow the parties involved to create an agreement
                        to charge that will be effective once the charge notice is registered and accepted at The
                        Movable properties securities registry or any other Registry. The owner chargor will intend to
                        secure his intellectual property as security for the payment of a debt from the chargee
                        (lender).
                    </div>
                    <h5 className="margin_top_24">Recital on remedies of the chargee</h5>
                    <div className="margin_top_8">
                        The remedies of the chargee at default of the chargor to repay the outstanding amount and
                        interest calculated herein shall be similar to the remedies available in the <span>[Movable Properties
                        Securities Act Section 67 (3) (a-e)].</span> Which would be, after the initial default notice is
                        issued, the chargee may sue, enter into possession, lease or sell the intellectual property.
                    </div>
                    <h5 className="margin_top_24">Recital Discharge of Charge</h5>
                    <div className="margin_top_8">
                        Upon repayment of the monies owed under this agreement to charge, the chargee at satisfaction of
                        the contractual amount, shall initiate a <span> [Discharge Notice] </span> to the Movable
                        Properties Securities
                        registry, of the effect of full satisfaction of the repayment of monies owed. And shall request
                        the registrar to discharge the security encumbrance on the register.
                    </div>
                    <h5 className="margin_top_24">Recital Undated Assignment</h5>
                    <div className="margin_top_8">
                        There shall be an <span> undated assignment prepared by the system, </span> which shall
                        encompass the details
                        of the agreement to charge. And the assignment shall be in lieu of default [11] below by the
                        chargor. And the chargee shall be able to <a href="" style={{fontWeight: "400"}}>auto date the
                        assignment with the days date at the end
                        expiry of the default notice.</a> To serve as an intention to effect remedies as owner of the
                        intellectual property, no longer as chargee. This assignment notice will be activated with the
                        default notice, ready to be registered at the MPSR or any other Registry.. At the end of the
                        duration in the default notice, the assignment notice shall take effect, allowing the secured
                        creditor to exercise the remedies available to them in the owner's capacity.
                    </div>
                    <div className="hr_line">

                    </div>
                    <div className="agrrement_cont_header">
                        <h2 className="text_center">SUBJECT MATTER OF THIS AGREEMENT TO LICENSE IS:</h2>
                    </div>
                    <div className="d_flex align_center margin_top_24">
                        <h5>NAME OF INTELLECTUAL PROPERTY:</h5>
                        <div className="filled_data_input" style={{marginLeft: "24px"}}>
                            <input type="text" placeholder="NAME OF CERTIFICATE"/>
                        </div>
                    </div>
                    <div className="margin_top_12">
                        <span style={{float: "right"}}>[S8 of MPSR Act 2017]</span>
                    </div>
                    <div className="margin_top_24">The agreement to charge will be effective by the AGREEMENT TO CHARGE
                        NOTICE served upon the Movable Properties Rights Registry and in receiving acceptance of the
                        same notice from the registrar of the Movable Properties Security Rights registry, or any other
                        Registry.
                    </div>
                    <div className="margin_top_24">
                        There is an implied condition that the goods shall correspond with the description.
                    </div>
                    <div className="margin_top_24">
                        <span style={{float: "right"}}>[Section 15 Sale of Goods Act]</span>
                    </div>
                    <div className="hr_line">
                    </div>
                    <h5>LAWS:</h5>
                    <div>
                        This contract shall be governed by the Contract Laws of Kenya, The relevant intellectual
                        property laws, governing the mentioned property above, such as the Copyright Act, The Trademark
                        Act, The Industrial Property Act, The Movable Properties Securities Act. supported by the
                        relevant sections of the Companies Act, the Business Associations Act, Act, the Patents Act, The
                        register Designs Act [UK] The Kenya Seed and Plant Varieties Act, and any other laws that may be
                        required, expressly and implied to achieve the quiet enjoyment of the terms of permitted in a
                        license agreement within the guidelines of permitted use of the intellectual property [IP] from
                        the owner within the specified period of time for value.
                    </div>
                    <div className="margin_top_24">
                        International treaties shall apply to their category of intellectual property governed by each
                        jurisdiction. WCT WIPO copyright treaty, TRIPS trade related aspects of intellectual property,
                        Marrakesh Agreement, The Paris Convention, The Madrid Protocol, Lisbon agreement, Hague
                        agreement. UPOV Convention.
                    </div>
                    <div className="margin_top_24">
                        Practice: Uniform Standards For Intellectual Property Rights Accountability.
                    </div>
                    <div className="hr_line">
                    </div>
                    <div>
                        <span>1. THIS AGREEMENT TO CHARGE</span> is between: [PARTIES]
                    </div>
                    <div className="margin_top_24">
                        <span>PARTY A. 1.1.: GRANTOR / CHARGOR:</span>
                    </div>
                    <div className="apply_certificate_item margin_top_12">
                        <div className="certificate_item">
                            <label htmlFor="">Email</label>
                            <input type="email" placeholder=""/>
                        </div>
                        <div className="certificate_item file_upload" style={{position: "relative"}}>
                            <p>Passport picture</p>
                            <div className="input_container">
                                <img src={file_upload} alt="file_upload" className="file_upload_img"/>
                                <label htmlFor="files" className="labelbtn">Upload here</label>
                                <input type="file" id="files" placeholder="Passport" onChange={onChangeFileInput}/>
                            </div>
                        </div>
                    </div>
                    <div className="apply_certificate_item">
                        <div className="certificate_item">
                            <label htmlFor="">Nationality</label>
                            <input type="text" value="" placeholder=""/>
                        </div>
                        <div className="certificate_item">

                        </div>
                    </div>
                    <div>
                        The party securing their intellectual property for the payment of a debt from party B, who will
                        be obligated to pay the debt to release the security in the intellectual property.
                    </div>
                    <h5 className="margin_top_32">PARTY B: 1.2.: CHARGEE / CREDITOR:</h5>
                    <div className="apply_certificate_item margin_top_12">
                        <div className="certificate_item filled_data_input">
                            <input type="text" placeholder="namesurname@gmail.com"/>
                        </div>
                        <div className="certificate_item filled_data_input">
                            <input type="text" placeholder="passport pic.jpeg"/>
                        </div>
                    </div>
                    <div className="apply_certificate_item">
                        <div className="certificate_item filled_data_input">
                            <input type="text" placeholder="Nationality"/>
                        </div>
                        <div className="certificate_item filled_data_input">

                        </div>
                    </div>
                    <div>
                        The party giving money to party A for the repayment of the money and registering interest on
                        Movable Properties Securities Registry.
                    </div>
                    <div className="margin_top_16">
                        <span style={{float: "right"}}>[Section 6 (3) (b) of the MPSR Act 2017]</span>
                    </div>
                    <div className="hr_line">

                    </div>
                    <div><span>1.3 PRE-CONTRACTUAL DOCUMENTATION:</span></div>
                    <div className="margin_top_12">PARTY 1: GRANTOR / CHARGOR:</div>
                    <div className="apply_certificate_item margin_top_12">
                        <div className="certificate_item file_upload" style={{position: "relative"}}>
                            <p>SELLER ID AND KRA PIN</p>
                            <div className="input_container">
                                <img src={file_upload} alt="file_upload" className="file_upload_img"/>
                                <label htmlFor="files" className="labelbtn">Upload here</label>
                                <input type="file" id="files" placeholder="Passport" onChange={onChangeFileInput}/>
                            </div>
                        </div>
                        <div className="certificate_item file_upload" style={{position: "relative"}}>
                            <p>BUYER ID AND KRA PIN</p>
                            <div className="input_container">
                                <img src={file_upload} alt="file_upload" className="file_upload_img"/>
                                <label htmlFor="files" className="labelbtn">Upload here</label>
                                <input type="file" id="files" placeholder="Passport" onChange={onChangeFileInput}/>
                            </div>
                        </div>
                    </div>
                    <div className="apply_certificate_item">
                        <div className="certificate_item file_upload" style={{position: "relative"}}>
                            <p>RESOLUTION TO CHARGE</p>
                            <div className="input_container">
                                <img src={file_upload} alt="file_upload" className="file_upload_img"/>
                                <label htmlFor="files" className="labelbtn">Upload here</label>
                                <input type="file" id="files" placeholder="Passport" onChange={onChangeFileInput}/>
                            </div>
                        </div>
                        <div className="certificate_item file_upload" style={{position: "relative"}}>
                            <p>PASSPORT PICTURE</p>
                            <div className="input_container">
                                <img src={file_upload} alt="file_upload" className="file_upload_img"/>
                                <label htmlFor="files" className="labelbtn">Upload here</label>
                                <input type="file" id="files" placeholder="Passport" onChange={onChangeFileInput}/>
                            </div>
                        </div>
                    </div>
                    <div className="apply_certificate_item">
                        <div className="certificate_item file_upload" style={{position: "relative"}}>
                            <p>FRONT SIDE OF NATIONAL IDENTIFICATION CARD</p>
                            <div className="input_container">
                                <img src={file_upload} alt="file_upload" className="file_upload_img"/>
                                <label htmlFor="files" className="labelbtn">Upload here</label>
                                <input type="file" id="files" placeholder="Passport" onChange={onChangeFileInput}/>
                            </div>
                        </div>
                        <div className="certificate_item file_upload">
                        </div>
                    </div>
                    <div className="margin_top_12">PARTY 2.: CHARGEE / CREDITOR:</div>
                    <div className="apply_certificate_item margin_top_12">
                        <div className="certificate_item filled_data_input">
                            <input type="text" placeholder="ID.pdf | KPA PIN.pdf "/>
                        </div>
                        <div className="certificate_item filled_data_input">
                            <input type="text" placeholder="BUYER ID AND KRA PIN.pdf"/>
                        </div>
                    </div>
                    <div className="apply_certificate_item">
                        <div className="certificate_item filled_data_input">
                            <input type="text" placeholder="RESOLUTION TO CHARGE.jpeg"/>
                        </div>
                        <div className="certificate_item filled_data_input">
                            <input type="text" placeholder="PASSPORT PICTURE.jpeg"/>
                        </div>
                    </div>
                    <div className="apply_certificate_item">
                        <div className="certificate_item filled_data_input">
                            <input type="text" placeholder="FRONT SIDE OF NATIONAL ID.jpeg"/>
                        </div>
                        <div className="certificate_item filled_data_input">
                        </div>
                    </div>
                    <div className="hr_line">
                    </div>
                    <h4>2. DUE DILIGENCE:</h4>
                    <div className="d_flex align_center form-group margin_top_12">
                        <input type="checkbox" id="checkbox" name="checkbox"/>
                        <label htmlFor="checkbox">This is a tri-part search that will enable the parties to know the
                            true position of ownership of the subject matter of this agreement to Charge template
                            agreement</label>
                    </div>
                    <div className="filled_data_input margin_top_16">
                        <input type="text" placeholder="NAME OF CERTIFICATE"/>
                    </div>
                    <div className="margin_top_12">
                        <span style={{float: "right"}}>[Section 8 of the MPSR Act 2017]</span>
                    </div>
                    <h5 className="margin_top_24">2.1. SEARCH ONE:</h5>
                    <div className="margin_top_12">PRE-SEARCH: Both parties hereby consent to conduct an immediate
                        search:
                    </div>
                    <div className="d_flex align_center margin_top_12">
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="No" value="No" name="radio"
                            />
                            No
                        </label>
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="yes" value="yes" name="radio" checked="checked"
                            />
                            Yes
                        </label>
                    </div>
                    <div className="margin_top_12">
                        To find out if there is an ongoing transaction [ License / Partnership / or Charge] on the said
                        intellectual property.
                    </div>
                    <h5 className="margin_top_32">2.2. SEARCH TWO:</h5>
                    <p className="margin_top_12">SEARCH NOW:</p>
                    <div className="margin_top_12">This is the intellectual property depository directory search, to
                        confirm the information of the parties to this contract are correct, and that the position of
                        ownership of the intellectual property named:-
                    </div>
                    <div className="d_flex align_center margin_top_8">
                        <div className="filled_data_input" style={{marginRight: "24px"}}>
                            <input type="text" placeholder="NAME OF INTELLECTUAL PROPERTY"/>
                        </div>
                        <h5>[Section 8 of the MPSR Act 2017]</h5>
                    </div>
                    <p className="margin_top_8">is accurately captured in this agreement to charge.</p>
                    <div className="margin_top_16">
                        <span style={{float: "right"}}>[Section 34 of MPSR Act 2017]</span>
                    </div>
                    <h5 className="margin_top_32">2.3. SEARCH THREE:</h5>
                    <p className="margin_top_12">POST SEARCH:</p>
                    <div className="margin_top_12">This is the search conducted at the Movable Properties Security
                        Registry to ensure that the agreement to charge, amount and duration of the charged asset is
                        registered against:
                    </div>
                    <div className="d_flex align_center margin_top_8">
                        <div className="filled_data_input" style={{marginRight: "24px"}}>
                            <input type="text" placeholder="NAME OF INTELLECTUAL PROPERTY"/>
                        </div>
                        <h5>[Section 8 of the MPSR Act 2017]</h5>
                    </div>
                    <div className="margin_top_8">reflects in the register.</div>
                    <div className="hr_line">
                    </div>
                    <h5>3. CONFIDENTIALITY:</h5>
                    <div className="margin_top_12">
                        The grantor / chargor and the chargee / creditor shall be deemed to have created a
                        confidentiality arrangement, where the chargor/ chargee shall not reveal the know-how or
                        intricacies, trade secrets or business operation models of the property that may be revealed to
                        them, or revealed during the subsistence of this agreement to charge furthermore the
                        confidential information contained in this agreement to charge.
                    </div>
                    <h5 className="margin_top_24">GRANTOR / CHARGOR</h5>
                    <div className="d_flex align_center margin_top_12">
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="No1" value="No1" name="radio1"
                            />
                            No
                        </label>
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="yes1" value="yes1" name="radio1" checked="checked"
                            />
                            Yes
                        </label>
                    </div>
                    <h5 className="margin_top_24">CHARGEE / CREDITOR
                    </h5>
                    <div className="d_flex align_center margin_top_12">
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="No2" value="No2" name="radio2"
                            />
                            No
                        </label>
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="yes2" value="yes2" name="radio2" checked="checked"
                            />
                            Yes
                        </label>
                    </div>
                    <div className="margin_top_32">If there has been a previous disclosure kindly tick.
                    </div>
                    <div className="d_flex align_center margin_top_12">
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="No" value="No" name="radio3"
                            />
                            No
                        </label>
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="yes" value="yes" name="radio3" checked="checked"
                            />
                            Yes
                        </label>
                    </div>
                    <div className="margin_top_32">
                        Does the above disclosure come into direct conflict with the agreement to charge relating to
                        this
                    </div>
                    <div className="d_flex align_center margin_top_8">
                        <div className="filled_data_input">
                            <input type="text" placeholder="NAME OF INTELLECTUAL PROPERTY"/>
                        </div>
                    </div>
                    <div className="margin_top_8">Property.</div>
                    <div className="margin_top_32">Is the licensee aware of this disclosure?
                    </div>
                    <div className="d_flex align_center margin_top_12">
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="No" value="No" name="radio4"
                            />
                            No
                        </label>
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="yes" value="yes" name="radio4" checked="checked"
                            />
                            Yes
                        </label>
                    </div>
                    <div className="margin_top_32">
                        ANY THIRD PARTY CLAIMS AGAINST THE [IP]
                    </div>
                    <div className="d_flex align_center margin_top_12">
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="No" value="No" name="radio5"
                            />
                            No
                        </label>
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="yes" value="yes" name="radio5" checked="checked"
                            />
                            Yes
                        </label>
                    </div>
                    <div className="margin_top_16">
                        <div className="">
                            <textarea name="" id="" cols="" rows=""
                                      style={{background: "white", width: "100%"}}>Max 100 characters</textarea>
                        </div>
                    </div>
                    <div className="hr_line">

                    </div>
                    <h5>4. PURCHASER DISCLOSURE ACCEPTANCE:</h5>
                    <div>The chargee hereby confirms that the Grantor /Chargor. has disclosed the relevant information
                        on a previous disclosure of know-how and that the disclosure will in no way cause any damage,
                        breach of implied warranty to this agreement to charge mentioned herein.
                    </div>
                    <div className="d_flex align_center margin_top_12">
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="No" value="No" name="radio6"
                            />
                            No
                        </label>
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="yes" value="yes" name="radio6" checked="checked"
                            />
                            Yes
                        </label>
                    </div>
                    <div className="margin_top_16">
                        <span style={{float: "right"}}>[Section 40 of the MPSR Act 2017]</span>
                    </div>
                    <div className="hr_line">
                    </div>
                    <h5>5. OWNERSHIP</h5>
                    <div className="margin_top_8">
                        <span style={{float: "right"}}>[Section 6 (1) of the MPSR Act 2017]</span>
                    </div>
                    <div className="margin_top_16">
                        The Grantor / Chargor warrants that the ownership of the intellectual property, and shown in
                        search results above.
                    </div>
                    <div className="d_flex align_center margin_top_8">
                        <div className="filled_data_input" style={{marginRight: "24px"}}>
                            <input type="text" placeholder="NAME OF INTELLECTUAL PROPERTY"/>
                        </div>
                    </div>
                    <div className="margin_top_8">
                        that the said [IP] is their property and that the Grantor / Chargor has all the relevant
                        capacity to grant to charge the above mentioned intellectual property and to authorize Chargee/
                        Creditor to register their interest as a security against the intellectual property mentioned
                        above:
                    </div>
                    <div className="margin_top_8">
                        <span style={{float: "right"}}>[Section 6 (4) of The MPSR Act 2017]</span>
                    </div>
                    <div className="margin_top_8">
                        This agreement to charge shall be binding while in its duration on the partners agents and
                        personal representatives of the parties involved and mentioned herein.
                    </div>
                    <div className="d_flex align_center margin_top_12">
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="No" value="No" name="radio7"
                            />
                            No
                        </label>
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="yes" value="yes" name="radio7" checked="checked"
                            />
                            Yes
                        </label>
                    </div>
                    <h5 className="margin_top_32">CO- OWNERSHIP</h5>
                    <div className="margin_top_12">ARE YOU THE ONLY OWNER?</div>
                    <div className="d_flex align_center margin_top_12">
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="No" value="No" name="radio8"
                            />
                            No
                        </label>
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="yes" value="yes" name="radio8" checked="checked"
                            />
                            Yes
                        </label>
                    </div>
                    <h5 className="margin_top_32">5.1. CO OWNERSHIP STRUCTURE:</h5>
                    <div className="margin_top_12">The Grantor shall agree to Charge their share in the intellectual
                        property, if they own the intellectual property in co ownership.
                    </div>
                    <div className="margin_top_24">
                        What is
                    </div>
                    <h5 className="margin_top_24">5.1.1.</h5>
                    <div className="apply_certificate_item margin_top_12">
                        <div className="certificate_item">
                            <label htmlFor="">Percentage that you own in the IP</label>
                            <input type="text" value="" placeholder=""/>
                        </div>
                        <div className="certificate_item">

                        </div>
                    </div>
                    <h5 className="margin_top_8">GRANTOR:</h5>
                    <div className="apply_certificate_item margin_top_12">
                        <div className="certificate_item filled_data_input">
                            <input type="text" placeholder="Name Surname"/>
                        </div>
                        <div className="certificate_item filled_data_input">
                        </div>
                    </div>
                    <div style={{marginTop: "-12px"}}>
                        I have informed the other co owner of my intention to CHARGE my share in the intellectual
                        property. Send Notice of intention to sell share to Co-Owner:
                    </div>
                    <div className="apply_certificate_item margin_top_12">
                        <div className="certificate_item">
                            <label htmlFor="">Email of external co owner</label>
                            <input type="text" value="" placeholder=""/>
                        </div>
                        <div className="certificate_item">

                        </div>
                    </div>
                    <h5>NOTICE OF INTENTION TO CHARGE:</h5>
                    <div className="apply_certificate_item margin_top_12">
                        <div className="certificate_item">
                            <label htmlFor="">Username</label>
                            <input type="text" value="" placeholder=""/>
                        </div>
                        <div className="certificate_item">
                            <label htmlFor="">Co-own (IP Name)</label>
                            <input type="text" value="" placeholder=""/>
                        </div>
                    </div>
                    <div className="margin_top_8">
                        AND THIS IS NOTICE OF MY INTENTION TO CHARGE MY SHARE IN THE PROPERTY TO:
                    </div>
                    <div className="apply_certificate_item margin_top_12">
                        <div className="certificate_item">
                            <label htmlFor="">Email of Co-owner</label>
                            <input type="text" value="" placeholder=""/>
                        </div>
                        <div className="certificate_item">
                            <label htmlFor="">IPD address of co owner</label>
                            <input type="text" value="" placeholder=""/>
                        </div>
                    </div>
                    <div className="apply_certificate_item margin_top_12">
                        <div className="certificate_item">
                            <label htmlFor="">Telephone number of co owner</label>
                            <input type="text" value="" placeholder=""/>
                        </div>
                        <div className="certificate_item">

                        </div>
                    </div>
                    <div className="apply_certificate_item margin_top_12">
                        <div className="certificate_item filled_data_input">
                            <input type="text" placeholder="Date"/>
                        </div>
                        <div className="certificate_item filled_data_input">
                            <input type="text" placeholder="Time"/>
                        </div>
                    </div>
                    <div className="d_flex align_center form-group">
                        <input type="checkbox" id="checkbox1" name="checkbox1"/>
                        <label htmlFor="checkbox1">Confirm receipt.</label>
                    </div>
                    <h5 className="margin_top_32">5.2. EXTERNAL CO OWNER RESPONSE FROM EMAIL: </h5>
                    <div className="apply_certificate_item margin_top_12">
                        <div className="certificate_item filled_data_input">
                            <input type="text" placeholder="Email of Co-owner"/>
                        </div>
                        <div className="certificate_item filled_data_input">

                        </div>
                    </div>
                    <h5 className="margin_top_8">CO-OWNER:</h5>
                    <div className="margin_top_12">
                        I understand that the co owner named in the this notice is desirous of CHARGING their share in
                        the intellectual property named
                    </div>
                    <div className="d_flex align_center margin_top_8">
                        <div className="filled_data_input" style={{marginRight: "24px"}}>
                            <input type="text" placeholder="NAME OF INTELLECTUAL PROPERTY"/>
                        </div>
                        <h5>[Section 8 of the MPSR Act 2017]</h5>
                    </div>
                    <div className="margin_top_32">
                        I am a co owner in the above intellectual property?
                    </div>
                    <div className="d_flex align_center margin_top_12">
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="No" value="No" name="radio9"
                            />
                            No
                        </label>
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="yes" value="yes" name="radio9" checked="checked"
                            />
                            Yes
                        </label>
                    </div>
                    <div className="margin_top_32">
                        Would you want to Chrage your share too
                    </div>
                    <div className="d_flex align_center margin_top_12">
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="No" value="No" name="radio10"
                            />
                            No
                        </label>
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="yes" value="yes" name="radio10" checked="checked"
                            />
                            Yes
                        </label>
                    </div>
                    <div className="margin_top_16">
                        <div className="">
                            <textarea name="" id="" cols="" rows=""
                                      style={{background: "white", width: "100%"}}>Max 100 characters</textarea>
                        </div>
                    </div>
                    <div className="hr_line">
                    </div>
                    <h5>6. GRANTOR / CHARGOR GRANTS THE FOLLOWING CHARGE: IN</h5>
                    <div className="d_flex align_center margin_top_12">
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="Whole" value="Whole" name="radio11"
                            />
                            Whole
                        </label>
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="Part" value="Part" name="radio11" checked="checked"
                            />
                            Part
                        </label>
                    </div>
                    <div className="d_flex align_center margin_top_16" style={{width: "62px"}}>
                        <div className="filled_data_input">
                            <input type="text" placeholder="%10"/>
                        </div>
                    </div>
                    <div className="d_flex space_beetween align_center margin_top_16">
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="secured" value="secured" name="radio12"
                            />
                            Secured (with valuation and insurance policies)
                        </label>
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="unsecured" value="unsecured" name="radio12" checked="checked"
                            />
                            Unsecured (without valuation and insurance policies)
                        </label>
                    </div>
                    <div className="hr_line">
                    </div>
                    <h5>7. THE AMOUNT SECURED BY THIS AGREEMENT TO CHARGE IS:</h5>
                    <h5 className="margin_top_16">A. CHARGEE QUOTE:</h5>
                    <div className="apply_certificate_item margin_top_12">
                        <div className="certificate_item filled_data_input">
                            <input type="text" placeholder="Amount (figures)"/>
                        </div>
                        <div className="certificate_item filled_data_input">
                            <input type="text" placeholder="Amount (words)"/>
                        </div>
                    </div>
                    <div>
                        Confirm
                    </div>
                    <div className="d_flex align_center margin_top_12">
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="No" value="No" name="radio12"
                            />
                            No
                        </label>
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="Yes" value="Yes" name="radio12" checked="checked"
                            />
                            Yes
                        </label>
                    </div>
                    <h5 className="margin_top_32">7A. CHARGE AMOUNT:</h5>
                    <div className="apply_certificate_item margin_top_12">
                        <div className="certificate_item">
                            <label htmlFor="">Charge amount</label>
                            <input type="text" value="" placeholder=""/>
                        </div>
                        <div className="certificate_item">
                        </div>
                    </div>
                    <h5 className="margin_top_8">7.1. INTEREST:</h5>
                    <div className="margin_top_8">
                        INTEREST ON AMOUNT
                    </div>
                    <div className="apply_certificate_item margin_top_12">
                        <div className="certificate_item filled_data_input">
                            <input type="text" placeholder="Chargee interest quote - 25%"/>
                        </div>
                        <div className="certificate_item filled_data_input">
                        </div>
                    </div>
                    <div>
                        Confirm
                    </div>
                    <div className="d_flex align_center margin_top_12">
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="No" value="No" name="radio13"
                            />
                            No
                        </label>
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="Yes" value="Yes" name="radio13" checked="checked"
                            />
                            Yes
                        </label>
                    </div>
                    <h5 className="margin_top_32">7.2. GOVERNMENT TAX:</h5>
                    <div className="apply_certificate_item margin_top_16">
                        <div className="certificate_item filled_data_input">
                            <input type="text" placeholder="VALUE ADDED TAX 16 %"/>
                        </div>
                        <div className="certificate_item filled_data_input">
                            <input type="text" placeholder="CAPITAL GAIN"/>
                        </div>
                    </div>
                    <div className="apply_certificate_item">
                        <div className="certificate_item filled_data_input">
                            <input type="text" placeholder="WITHHOLDING TAX"/>
                        </div>
                        <div className="certificate_item filled_data_input">
                            <input type="text" placeholder="CAPITAL GAIN"/>
                        </div>
                    </div>
                    <div className="margin_top_32">
                        Agree with calculated amount
                    </div>
                    <div className="d_flex align_center margin_top_12">
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="No" value="No" name="radio14"
                            />
                            No
                        </label>
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="Yes" value="Yes" name="radio14" checked="checked"
                            />
                            Yes
                        </label>
                    </div>
                    <div>Agree with calculated amount</div>
                    <div className="d_flex align_center margin_top_12">
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="No" value="No" name="radio14"
                            />
                            No
                        </label>
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="Yes" value="Yes" name="radio14" checked="checked"
                            />
                            Yes
                        </label>
                    </div>
                    <h5 className="margin_top_32">7.3. DURATION AND REPAYMENT DATE OF CHARGE:</h5>
                    <div className="apply_certificate_item margin_top_16">
                        <div className="certificate_item filled_data_input">
                            <input type="text" placeholder="Date choosed"/>
                        </div>
                        <div className="certificate_item filled_data_input">
                        </div>
                    </div>
                    <div>
                        Agree with choosen date
                    </div>
                    <div className="d_flex align_center margin_top_12">
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="No" value="No" name="radio15"
                            />
                            No
                        </label>
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="Yes" value="Yes" name="radio15" checked="checked"
                            />
                            Yes
                        </label>
                    </div>
                    <h5 className="margin_top_32">7.4. CHARGEE ACCOUNT DETAILS:</h5>
                    <div className="margin_top_12">The chargor may make weekly payments in satisfaction of the said
                        monthly payment, at their discretion.
                    </div>
                    <div className="margin_top_24">
                        The payment periodic, weekly, daily is to be done to the following account details:
                    </div>
                    <div className="apply_certificate_item margin_top_16">
                        <div className="certificate_item filled_data_input">
                            <input type="text" placeholder="Chargee"/>
                        </div>
                        <div className="certificate_item filled_data_input">
                            <input type="text" placeholder="Name"/>
                        </div>
                    </div>
                    <div className="apply_certificate_item">
                        <div className="certificate_item filled_data_input">
                            <input type="text" placeholder="Account details "/>
                        </div>
                        <div className="certificate_item filled_data_input">
                            <input type="text" placeholder="Bank"/>
                        </div>
                    </div>
                    <div className="apply_certificate_item">
                        <div className="certificate_item filled_data_input">
                            <input type="text" placeholder="Account number"/>
                        </div>
                        <div className="certificate_item filled_data_input">
                            <input type="text" placeholder="MPESA"/>
                        </div>
                    </div>
                    <div className="apply_certificate_item">
                        <div className="certificate_item filled_data_input">
                            <input type="text" placeholder="Till number"/>
                        </div>
                        <div className="certificate_item filled_data_input">
                            <input type="text" placeholder="Phone number"/>
                        </div>
                    </div>
                    <h5 className="margin_top_8">7.6. CHARGE ADJUSTMENT NOTICE:</h5>
                    <h5 className="margin_top_8">This is an adjustment to the:</h5>
                    <div className="margin_top_8">
                        A. AMOUNT payable ([7 & 7A] + [7.2])
                    </div>
                    <div className="apply_certificate_item">
                        <div className="certificate_item filled_data_input">
                            <input type="text" placeholder="Amount"/>
                        </div>
                        <div className="certificate_item filled_data_input">
                        </div>
                    </div>
                    <div>
                        B. INTEREST payable ([7.1])
                    </div>
                    <div className="apply_certificate_item">
                        <div className="certificate_item filled_data_input">
                            <input type="text" placeholder="Amount"/>
                        </div>
                        <div className="certificate_item filled_data_input">
                        </div>
                    </div>
                    <div>
                        C. DATE OF payment ([7.3])
                    </div>
                    <div className="apply_certificate_item">
                        <div className="certificate_item filled_data_input">
                            <input type="text" placeholder="Amount"/>
                        </div>
                        <div className="certificate_item filled_data_input">
                        </div>
                    </div>
                    <div className="margin_top_8">
                        <span style={{float: "right"}}>Total: $250</span>
                    </div>
                    <div className="margin_top_8">
                        Ipd fee: 0.5% of the adjusted amount:
                    </div>
                    <div className="hr_line">
                    </div>
                    <h5>8. RIGHTS OBLIGATIONS AND DUTIES OF CHARGOR AND CHARGEE:</h5>
                    <div className="margin_top_8">
                        hese obligations are binding to the grantor / chargor and are binding on the parties depending
                        on their rights and duties within this automated negotiation template. Upon signing this
                        template and the specific dates and times herein. These obligations and duties become binding on
                        both parties. Breach of which shall be termed as breach of condition or warranty and shall allow
                        the aggrieved party to initiate court proceedings to effect several remedies.
                    </div>
                    <div className="margin_top_24">
                        A. RIGHT TO REGISTER AN AMENDMENT NOTICE: <span>Chargee ONLY</span>
                    </div>
                    <div> [Section 32 of the MPSR 2017</div>
                    <div className="margin_top_24">
                        B. RIGHT TO INSPECT the collateral and related documents [valuation and Insurance] included.
                    </div>
                    <div>[Section 58 of the MPSR Act 2017]</div>
                    <div className="margin_top_24">
                        C. RIGHT TO SUE: for the amount borrowed and interest on the amount.
                    </div>
                    <div>[Section 68 of the MPSR Act 2017]</div>
                    <div className="margin_top_24">
                        D. RIGHT TO TAKE POSSESSION of collateral [ip certificate] and related documents.
                    </div>
                    <div>[Section 71 of the MPSR Act 2017]</div>
                    <div className="margin_top_24">
                        E. RIGHT TO DISPOSE of collateral once taken possession via judicial notice.
                    </div>
                    <div>[Section 72 of the MPSR Act 2017]</div>
                    <div className="margin_top_24">
                        F. OBLIGATION TO RETURN collateral [ip certificate] and related documents
                    </div>
                    <div>[Section 57 of the MPSR Act 2017]</div>
                    <h5 className="margin_top_24">GRANTOR / CHARGOR :</h5>
                    <div className="margin_top_24">
                        G. RIGHT TO REDEMPTION of collateral.
                    </div>
                    <div>[Section 69 of the MPSR Act 2017]</div>
                    <div className="margin_top_24">
                        H. OBLIGATION TO PAY
                    </div>
                    <div className="apply_certificate_item margin_top_12">
                        <div className="certificate_item filled_data_input">
                            <input type="text" placeholder="Amount"/>
                        </div>
                        <div className="certificate_item filled_data_input">
                        </div>
                    </div>
                    <div>
                        I. OBLIGATION TO EXERCISE REASONABLE CARE over the collateral in possession of the grantor, or
                        secured creditor. 17 [Section 56 of the MPSR Act 2017] to preserve the asset.
                    </div>
                    <div className="margin_top_24">
                        J. OBLIGATION TO AUTHORIZE NOTIFICATIONS ON TIME: using the parties profile on ipdepository.com
                        or any other medium. If the medium sought is outside this system, Kindly ensure its registration
                        at the MPSR and ensure you effectively notify the system, your or the other parties dashboard
                        for amendment and search, to adjust the outside notice.
                    </div>
                    <div className="hr_line">
                    </div>
                    <h5>9. CHARGOR ASSIGNMENT IN LIEU OF DEFAULT NOTICE:</h5>
                    <div>
                        The chagor herby signs this undated assignment in lieu of default on payment and the issuance of
                        default notice from the chargee. Here the system places a Charge Notice: which is a custodian
                        lien over the intellectual property certificate in favor of the Chargee. The lien is only to
                        secure the payment of the debt and the chargors right to redeem the intellectual property in the
                        event of default. The chargee is free to use the remedies available to them, within the law.
                    </div>
                    <div className="apply_certificate_item margin_top_16">
                        <div className="certificate_item filled_data_input">
                            <input type="text" placeholder="Date"/>
                        </div>
                        <div className="certificate_item filled_data_input">
                        </div>
                    </div>
                    <div className="hr_line">
                    </div>
                    <h5>ASSIGNMENT OF INTELLECTUAL PROPERTY:</h5>
                    <div className="margin_top_8">
                        This assignment is for intellectual property named:
                    </div>
                    <div className="apply_certificate_item margin_top_16">
                        <div className="certificate_item filled_data_input">
                            <input type="text" placeholder="NAME OF IP"/>
                        </div>
                        <div className="certificate_item filled_data_input">
                        </div>
                    </div>
                    <div className="margin_top_8">
                        That i am the owner, and having secured a charge for:
                    </div>
                    <div className="apply_certificate_item margin_top_16">
                        <div className="certificate_item filled_data_input">
                            <input type="text" placeholder="Amount"/>
                        </div>
                        <div className="certificate_item filled_data_input">
                        </div>
                    </div>
                    <div className="margin_top_8">
                        Having been issued a default notice, attached. I hereby append my signature, prior to the
                        default. To enable the chargee, exercise the remedies available. This assignment is to the
                        transfer of ownership of intellectual property to the management IPD [ IPDEPOSITORY LIMITED ] as
                        custodian of the title during the subsistence of the said loan.
                    </div>
                    <div className="d_flex align_center title_b space_beetween margin_top_32">
                        <span></span>
                        <h5>SIGNED BY</h5>
                        <span></span>
                    </div>
                    <h5 className="margin_top_12">BORROWER/ CHARGOR</h5>
                    <div className="margin_top_16">
                        I Grantor/ Chargor hereby certify that I intend to create a charge over the above mentioned
                        intellectual property, and shall be bound by the periodic payments [7] I shall make to redeem my
                        intellectual property, I also understand that if i fail to redeem the said intellectual
                        property, several court mandated remedies are available to the chargee, such as the right to
                        enter into possession of the intellectual property, and eventual sale, in the eventual default
                        on payment and obligations under this charge agreement.
                    </div>
                    <div className="d_flex align_center margin_top_12">
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="No" value="No" name="radio16"
                            />
                            No
                        </label>
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="Yes" value="Yes" name="radio16" checked="checked"
                            />
                            Yes
                        </label>
                    </div>
                    <div className="apply_certificate_item margin_top_12">
                        <div className="certificate_item filled_data_input">
                            <input type="text" placeholder="namesurname@gmail.com"/>
                        </div>
                        <div className="certificate_item filled_data_input">
                            <input type="text" placeholder="Passport pic.jpeg"/>
                        </div>
                    </div>
                    <div className="apply_certificate_item">
                        <div className="certificate_item filled_data_input">
                            <input type="text" placeholder="Nationality"/>
                        </div>
                        <div className="certificate_item filled_data_input">

                        </div>
                    </div>
                    <div>Digital signature</div>
                    <div>
                        <div className="">
                            <textarea name="" id="" cols="" rows=""
                                      style={{background: "white", width: "100%"}}></textarea>
                        </div>
                    </div>
                    <div className="d_flex align_center title_b space_beetween margin_top_32">
                        <span></span>
                        <h5>SIGNED BY</h5>
                        <span></span>
                    </div>
                    <h5 className="margin_top_12">LENDER/ CHARGEE</h5>
                    <div className="margin_top_16">
                        I creditor / chargee hereby certify that I offer the amount [Money] mentioned herein as security
                        against the Intellectual property mentioned herein. That upon receipt of payment in full of the
                        secured amount and Interest i shall discharge my interest in the said intellectual property and
                        allow the chargor to redeem the Intellectual property. I shall not place any clogs or fetters
                        [obstacles] to the owners right to redeem the [IP] and shall always act in good faith towards
                        the reputation and value of the said intellectual property.
                    </div>
                    <div className="d_flex align_center margin_top_12">
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="No" value="No" name="radio17"
                            />
                            No
                        </label>
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="Yes" value="Yes" name="radio17" checked="checked"
                            />
                            Yes
                        </label>
                    </div>
                    <div className="apply_certificate_item margin_top_12">
                        <div className="certificate_item filled_data_input">
                            <input type="text" placeholder="namesurname@gmail.com"/>
                        </div>
                        <div className="certificate_item filled_data_input">
                            <input type="text" placeholder="Passport pic.jpeg"/>
                        </div>
                    </div>
                    <div className="apply_certificate_item">
                        <div className="certificate_item filled_data_input">
                            <input type="text" placeholder="Nationality"/>
                        </div>
                        <div className="certificate_item filled_data_input">

                        </div>
                    </div>
                    <div>Digital signature</div>
                    <div>
                        <div className="">
                            <textarea name="" id="" cols="" rows=""
                                      style={{background: "white", width: "100%"}}></textarea>
                        </div>
                    </div>
                    <div className="d_flex align_center justify_content_center submit_b margin_top_32">
                        <button className="save_btn" style={{marginLeft: "0px"}}>Continue</button>
                    </div>


                </div>
            </div>
        </>
    )
}