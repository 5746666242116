export const menu = require('./menu.png')
export const close = require('./close.png')
export const search = require('./search.png')

export const oneStopMarket = require('./oneStopMarket.png')
export const agreementReady = require('./agreementReady.png')
export const transactionTrack = require('./transactionTrack.png')

export const transact = require('./transact.png')
export const share = require('./share.png')
export const deposit = require('./deposit.png')

export const phone = require('./phone.png')
export const ipdepositoryLimited = require('./ipdepositoryLimited.png')
export const message = require('./message.png')

export const notification = require('./notification.png')
export const logOut = require('./logOut.png')

export const closeBlack = require('./closeBlack.png')


