import React, {useEffect, useRef, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import axiosInstance from "../../../api";
import certificate_pic from "../../../assets/images/certificate_pic.png";
import {Loading} from "../../../components";
import accepted_icon from "../../../assets/images/icons/accepted_icon.png";

export function VerifiedCertificatesView() {
    const inputFile = useRef(null)

    let {id} = useParams();
    const [loading, setLoading] = useState(false)
    let navigation = useNavigate()
    let {pathname} = useLocation()
    let [file, setFile] = useState(null)
    let [photo, setPhoto] = useState('')
    let [agreements, setAgreements] = useState({})
    const arrPath = pathname.split('/')
    const path = arrPath[arrPath.length - 2]
    let token = localStorage.getItem('token');
    let user_id = localStorage.getItem('id');
    let [category, setSetCategory] = useState([])
    let [error, setError] = useState('')
    let [data, setData] = useState({
        type: 'sell',
        category_id: 1,
        publish: 'private'
    })
    useEffect(() => {
        getAgreement()
        getCategory()
    }, [])

    let getAgreement = async () => {
        try {
            let response = await axiosInstance.get(`/api/client/single-agreement/${id}`)
            setAgreements(response.data.data);
            setPhoto(response.data.data.photo);
        } catch (e) {
            console.log(e);
            console.log(e.response);
        }
    }
    let changeInput = (e, name) => {
        setAgreements({...agreements, [name]: e})
    }

    let changeInputCategory = (e, name) => {
        setError('')
        if (name === 'type') {
            return setData({...data, ...{[name]: e.toLowerCase()}})
        } else if (name === 'category_id') {
            let id = null
            for (let i = 0; i < category.length; i++) {
                if (category[i].name === e) {
                    id = category[i].id
                }
            }
            return setData({...data, ...{[name]: id}})
        } else {
            setData({...data, ...{[name]: e}})
        }
    }

    let getCategory = async () => {
        try {
            let response = await axiosInstance.get('api/v1/categories')
            setSetCategory(response.data.data)
        } catch (e) {
            console.log(e);
            console.log(e.response);
        }
    }

    let update = async () => {
        try {
            setLoading(true)
            let formData = new FormData()
            if (file) {
                formData.append('photo', file)
            }
            formData.append('publish', agreements.publish)
            formData.append('type', path)
            formData.append('category_id', agreements.id)
            formData.append('certificate_name', agreements.certificate_name)
            formData.append('certificate_number', agreements.certificate_number)
            formData.append('proprietor_name', agreements.proprietor_name)
            formData.append('name_of_concept', agreements.name_of_concept)
            formData.append('date_of_issue', agreements.date_of_issue)
            formData.append('country_issued', agreements.country_issued)
            formData.append('certificate_description', agreements.certificate_description)
            await axiosInstance.post(`api/client/update-agreement/${agreements.id}`, formData)
            setLoading(false)
        } catch (e) {
            setLoading(false)
        }
    }
    const onButtonClick = () => {
        inputFile.current.click();
    };

    const handleFileSelected = (e) => {
        const files = Array.from(e.target.files)
        setFile(files[0])
        const objectUrl = URL.createObjectURL(files[0])
        setPhoto(objectUrl)
    }
    console.log(category, "erwrw")

    return (
        <div className='certeficate'>
            {token ?
                +user_id === +agreements.user_id ?
                    null
                    :
                    agreements.user_id ?
                        <button className={'apply_btn'} onClick={() => navigation('/get-proposal', {state: {id: id}})}>
                            Apply
                        </button>
                        :
                        null
                :
                <button className={'apply_btn'} onClick={() => navigation('/sign-in')}>
                    Sign In
                </button>

            }

            <div className="body">
                <div style={{position:"relative"}}>
                    <img
                        src={photo ? photo : certificate_pic}
                        className={'photoContent'}
                        onClick={onButtonClick}/>
                    <input type='file' id='file' ref={inputFile} style={{display: 'none'}}
                           onChange={handleFileSelected}/>
                    <img src={accepted_icon} alt="icon" className="accepted_icon"/>

                    <div className="settings">
                        <label className="settings_item" for="private">
                            <span>Status:</span>
                            Accepted
                        </label>
                        <label className="settings_item" for="public">
                            <span>Type:</span>
                            Charge
                        </label>
                    </div>
                </div>
                <div>
                    <div className="certificate_items_block">
                        <div className="certificate_item">
                            <label htmlFor="">Certificate Name</label>
                            <input type="text" value={agreements.certificate_name}
                                   onChange={e => changeInput(e.target.value, 'certificate_name')}/>
                        </div>
                        <div className="certificate_item">
                            <label htmlFor="">Certificate Number</label>
                            <input type="text" value={agreements.certificate_number}
                                   onChange={e => changeInput(e.target.value, 'certificate_number')}/>
                            {/*<p>{id}</p>*/}
                        </div>
                        <div className="certificate_item">
                            <label htmlFor="">Proprietor name</label>
                            <input type="text" value={agreements.proprietor_name}
                                   onChange={e => changeInput(e.target.value, 'proprietor_name')}/>
                        </div>
                        <div className="certificate_item">
                            <label htmlFor="">Certificate category</label>
                            <select onChange={(e) => changeInputCategory(e.target.value, 'category_id')}>
                                {category.map((item, index) => {
                                    return <option key={index}>{item.name}</option>
                                })}
                            </select>
                        </div>
                        <div className="certificate_item">
                            <label htmlFor="">Name of concept</label>
                            <input type="text" value={agreements.name_of_concept}
                                   onChange={e => changeInput(e.target.value, 'name_of_concept')}/>
                        </div>
                        <div className="certificate_item">
                            <label htmlFor="">Date of Issue</label>
                            <input type="text" value={agreements.date_of_issue}
                                   onChange={e => changeInput(e.target.value, 'date_of_issue')}/>
                        </div>
                        <div className="certificate_item">
                            <label htmlFor="">Country Issued</label>
                            <input type="text" value={agreements.country_issued}
                                   onChange={e => changeInput(e.target.value, 'country_issued')}/>
                        </div>
                        <div className="certificate_item">
                            <label htmlFor="">Certificate Description #</label>
                            <textarea name="" id="" cols="" rows="" value={agreements.certificate_description}
                                      onChange={e => changeInput(e.target.value, 'certificate_description')}></textarea>
                        </div>
                        <p className="title">VERIFICATION INFO</p>
                        <div className="certificate_item">
                            <label htmlFor="">Certificate Name</label>
                            <input type="text" value={agreements.certificate_name}
                                   onChange={e => changeInput(e.target.value, 'certificate_name')}/>
                        </div>
                        <p  className="title">The Certificate is Authentic and Exists in database</p>
                        <div className="settings">
                            <label className="settings_item" htmlFor="No">
                                <input type="radio" id="No" value="No" name="radio"
                                       checked={agreements.publish !== 'No'}
                                       onChange={e => changeInput('No', 'No')}/>
                                No
                            </label>
                            <label className="settings_item" htmlFor="Yes">
                                <input type="radio" id="Yes" value="Yes" name="radio"
                                       checked={agreements.publish !== 'Yes'}
                                       onChange={e => changeInput('Yes', 'Yes')}/>
                                Yes
                            </label>
                        </div>
                        <div className="certificate_item" style={{marginTop: "24px"}}>
                            <label htmlFor="">Discrepancy</label>
                            <textarea name="" id="" cols="" rows="" value={agreements.certificate_description}
                                      onChange={e => changeInput(e.target.value, 'certificate_description')}></textarea>
                        </div>
                        <p  className="title">Attached file/s</p>
                        <span className="file_name">File name.pdf</span>
                        <p className="title margin_top_32">VALUATION OF IP CERTIFICATE & CONCEPT</p>
                        <div className="certificate_item">
                            <label htmlFor="">Name of Value</label>
                            <input type="text" value={agreements.certificate_name}
                                   onChange={e => changeInput(e.target.value, 'certificate_name')}/>
                        </div>
                        <p  className="title">Value Ascertained</p>
                        <div className="settings">
                            <label className="settings_item" htmlFor="No_1">
                                <input type="radio" id="No_1" value="No" name="radio"
                                       checked={agreements.publish !== 'No_1'}
                                       onChange={e => changeInput('No_1', 'No_1')}/>
                                No
                            </label>
                            <label className="settings_item" htmlFor="Yes_1">
                                <input type="radio" id="Yes_1" value="Yes" name="radio"
                                       checked={agreements.publish !== 'Yes_1'}
                                       onChange={e => changeInput('Yes_1', 'Yes_1')}/>
                                Yes
                            </label>
                        </div>
                        <div className="currency_value_block">
                            <div className="certificate_item">
                                <label htmlFor="">Currency</label>
                                <input type="text" value={agreements.certificate_name}
                                       onChange={e => changeInput(e.target.value, 'Currency')}/>
                            </div>
                            <div className="certificate_item">
                                <label htmlFor="">Value</label>
                                <input type="text" value={agreements.certificate_name}
                                       onChange={e => changeInput(e.target.value, 'Value')}/>
                            </div>
                        </div>
                        <div className="certificate_item" style={{marginTop: "24px"}}>
                            <label htmlFor="">Comments</label>
                            <textarea name="" id="" cols="" rows="" value={agreements.certificate_description}
                                      onChange={e => changeInput(e.target.value, 'Comments')}></textarea>
                        </div>
                        <p  className="title">Attached file</p>
                        <span className="file_name">Image Valuation Report or Code.png</span>
                        <p className="title">INSURANCE FORM</p>
                        <div className="certificate_item">
                            <label htmlFor="">Name of Insurance Company</label>
                            <input type="text" value={agreements.certificate_name}
                                   onChange={e => changeInput(e.target.value, 'Name')}/>
                        </div>
                        <div className="certificate_item">
                            <label htmlFor="">Type Of Policy</label>
                            <input type="text" value={agreements.certificate_name}
                                   onChange={e => changeInput(e.target.value, 'Name')}/>
                        </div>
                        <div className="certificate_item">
                            <label htmlFor="">Value Of Policy </label>
                            <input type="text" value={agreements.certificate_name}
                                   onChange={e => changeInput(e.target.value, 'Name')}/>
                        </div>
                        <div className="currency_value_block">
                            <div className="certificate_item">
                                <label htmlFor="">Policy Date</label>
                                <input type="text" value={agreements.certificate_name}
                                       onChange={e => changeInput(e.target.value, 'Currency')}/>
                            </div>
                            <div className="certificate_item">
                                <label htmlFor="">Expiry Date</label>
                                <input type="text" value={agreements.certificate_name}
                                       onChange={e => changeInput(e.target.value, 'Value')}/>
                            </div>
                        </div>
                        <p  className="title">Attached file</p>
                        <span className="file_name">Policy image.png</span>
                    </div>
                </div>

            </div>
            <Loading modalIsOpen={loading}/>
        </div>
    );
}
