import "../style.scss";
import file_upload from "../../../assets/images/icons/file_upload_black_24dp 1.png";
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import ScrollTop from "../../../components/scrollTop";

export default function AgreementToSell() {
    let navigate=useNavigate()
    let [active, setActive]=useState(false)
    return (
        <>
            <ScrollTop/>
            <div className="CertificatesViewApply_header">
                <h2 className="search_text">IP name here</h2>
            </div>
            <div className='agrrement_cont'>
                <div className="agrrement_cont_header">
                    <h2 className="text_center">AGREEMENT TO SELL AND TRANSFER INTELLECTUAL PROPERTY [IP]</h2>
                    <p className="text_center">ipdepository.com </p>
                    <p className="text_center">utomated negotiation template</p>
                    <p className="text_center">Head of terms and agreement generator</p>
                </div>
                <div className="hr_line">

                </div>
                <div className="agrrement_cont_body margin_top_24">
                    <p className="margin_top_24">ipdepository.com terms and conditions.</p>

                    <div className="margin_top_24">ipdepository.com <span style={{color: "#332486"}}>is not</span> a
                        certifying or issuing authority, the system is a mirror image of the
                        information pertained in certification issued to users from governing authorities, on
                        intellectual property rights and is intended to capture this information and assist the owners
                        deposit, search, transact and transfer rights in intellectual property.
                    </div>
                    <span className="margin_top_24"> <span>AIM:</span> the aim of ipdepository.com is to; </span>
                    <div>Create a depository of intellectual property that is factual and honest.
                        To facilitate a register of transactions, dealing with intellectual property and maintain a
                        marketplace for owners / grantors, appreciators,consumers of intellectual property to exercise
                        several moral and contractual rights. Such as the right of distribution, rental and
                        communication of information related to their intellectual property.
                    </div>
                    <div className="margin_top_24"><span
                    >Automated Negotiation Template:</span>This is an automated
                        negotiation template that will ensure that both parties to this
                        sale and transfer agreement are able to negotiate, and upon successful negotiation on the terms
                        and conditions within this template, produce a sale and transfer agreement that is legally
                        binding upon the parties. In which the seller will transfer ownership to the purchaser for value
                        considered. The system shall generate [notices] to activate communication and response to the
                        Movable Properties Securities Rights registry.
                    </div>
                    <div className="margin_top_24"><span>Parties Consent:</span> The parties
                        hereby consent to
                        the use of this contractual template contract generator, and will be bound by the terms and
                        conditions set forth in the negotiations.
                    </div>
                    <div className="margin_top_24">
                        <span>Verification:</span> The system shall verify the contractual
                        terms, by ensuring several clauses, must be consented to by both parties before moving onto the
                        next step. So as to give an equal opportunity to both parties to negotiate, the contents of the
                        intended template agreement.
                    </div>
                    <div className="margin_top_24">
                        <span>Payment:</span>
                        The system shall levy an automated fee on each party to
                        this agreement to sell. Furthermore during negotiation within this agreement the parties hereby
                        agree namely the purchaser to pay the price / consideration and that both purchaser and the
                        seller have had a chance to negotiate within this agreement to sell automated negotiation
                        template, by signing the automated negotiation agreement upon successful negotiation. The seller
                        shall be bound to transfer ownership to the intellectual property as and within the terms of
                        agreement. The terms of the agreement to sell shall be binding. Intellectual Property Depository
                        will charge automated fees, generated within the negotiations as agreed by the parties, and
                        calculate these fees, as a percentage of the above agreed sum, as transaction, or commission
                        fees payable for using this service.
                    </div>
                    <div className="margin_top_24">
                        These fees shall be amenable from time to time and the
                        management of ipdepository.com shall communicate such increment or decrease in service and
                        transaction fees, accordingly.
                    </div>
                    <div className="margin_top_24"><span>Consequence of breach of use of system:</span> The management
                        of
                        ipdepository.com have the right to bar, expel or delete any profiles that are used to defraud,
                        misrepresent, lure, or unduly influence, any transactions that are within this system.
                    </div>
                    <div className="disclaimer margin_top_24">
                        *******“Disclaimer”*******
                    </div>
                    <div><span>[Section 11 (4) (d) of the MPSR Act 2017]</span> The intellectual property depository,
                        ipdepository.com
                        shall not be liable for any false, misrepresented information, uploaded onto the system. To
                        ensure that the above is a matter of concern we have included the verification stage, for value
                        to ensure that the governing bodies that issued the certificates, actually validate the
                        information on the uploaded image of (certificate) known as the verification stage. We strongly
                        urge all users to verify their certificates, and assist with creating an accurate register of
                        intellectual property.
                    </div>
                    <div className="margin_top_24">
                        <span>User Obligation:</span>
                        The management of ipdepository.com hereby ensures that
                        the obligation, burden, and capacity lies on the user to upload accurate information. The
                        purchaser has the right to terminate the agreement to sell at any time during the negotiation
                        process and pay the relevant system fees. [FORM 9 MPSR] and its registration fee. “It is
                        therefore upon you the user to upload factual information and assist every eventual user of your
                        intellectual property, to receive the right and factual information” and that the parties must
                        intend to be bound by the agreement.
                    </div>
                    <div className="margin_top_24">
                        The management of ipdepository.com also hereby reserves the
                        non-exclusive right to enter into legal proceedings against any user who is found to be in gross
                        breach of our terms and conditions. We also reserve the right to sue for monetary damages. For
                        breach of the aims of the system to preserve the integrity of [IPD] directory and the Movable
                        Properties Security Rights Act register. <span> [Section 20 of the MPSR Act 2017] Integrity of
                        register.</span>
                    </div>
                    <div className="margin_top_24">
                        <span> IPD Obligation:</span>
                        The management will strive to achieve the aims mentioned above and we are
                        obligated to do so in the following.
                    </div>
                    <div className="margin_top_24">
                        <a href=""> Record transactions:</a>
                        by the use of our system, users will
                        be able to have their [IP] transactions recorded on their profiles and on the relevant movable
                        properties registry as Notices to the registrar. The user's transactions will be accurately
                        linked to codes that the system shall generate, to accurately capture the user profile details
                        and transaction details.
                    </div>
                    <div className="margin_top_24">
                        <a>To maintain the intellectual property directory</a>
                        found on our system, to
                        enable users to search for the accurate position of any intellectual property uploaded on to the
                        system.
                    </div>
                    <div className="margin_top_24"><a href="">To maintain confidential personal and private
                        information</a> relating to our users, and
                        only reveal to 3rd parties information necessary to achieve valuation and insurance values, and
                        this is at the control of the user, at the valuation and insurance process. IDP will only reveal
                        confidential information if mandated by a court of Law
                    </div>
                    <div className="disclaimer margin_top_24">
                        *******“Disclaimer”*******
                    </div>
                    <div><span>[Section 11 (4) (d) of the MPSR Act 2017]</span> The intellectual property depository,
                        ipdepository.com
                        shall not be liable for any false, misrepresented information, uploaded onto the system. To
                        ensure that the above is a matter of concern we have included the verification stage, for value
                        to ensure that the governing bodies that issued the certificates, actually validate the
                        information on the uploaded image of (certificate) known as the verification stage. We strongly
                        urge all users to verify their certificates, and assist with creating an accurate register of
                        intellectual property.
                    </div>
                    <div className="margin_top_24">
                        <span>User Obligation:</span>
                        The management of ipdepository.com hereby ensures that
                        the obligation, burden, and capacity lies on the user to upload accurate information. The
                        purchaser has the right to terminate the agreement to sell at any time during the negotiation
                        process and pay the relevant system fees. [FORM 9 MPSR] and its registration fee. “It is
                        therefore upon you the user to upload factual information and assist every eventual user of your
                        intellectual property, to receive the right and factual information” and that the parties must
                        intend to be bound by the agreement.
                    </div>
                    <div className="margin_top_24">
                        The management of ipdepository.com also hereby reserves the
                        non-exclusive right to enter into legal proceedings against any user who is found to be in gross
                        breach of our terms and conditions. We also reserve the right to sue for monetary damages. For
                        breach of the aims of the system to preserve the integrity of [IPD] directory and the Movable
                        Properties Security Rights Act register. <span> [Section 20 of the MPSR Act 2017] Integrity of
                        register.</span>
                    </div>
                    <div className="margin_top_24">
                        <span> IPD Obligation:</span>
                        The management will strive to achieve the aims mentioned above and we are
                        obligated to do so in the following.
                    </div>
                    <div className="margin_top_24">
                        <a href=""> Record transactions:</a>
                        by the use of our system, users will
                        be able to have their [IP] transactions recorded on their profiles and on the relevant movable
                        properties registry as Notices to the registrar. The user's transactions will be accurately
                        linked to codes that the system shall generate, to accurately capture the user profile details
                        and transaction details.
                    </div>
                    <div className="margin_top_24">
                        <a>To maintain the intellectual property directory</a>
                        found on our system, to
                        enable users to search for the accurate position of any intellectual property uploaded on to the
                        system.
                    </div>
                    <div className="margin_top_24"><a href="">To maintain confidential personal and private
                        information</a> relating to our users, and
                        only reveal to 3rd parties information necessary to achieve valuation and insurance values, and
                        this is at the control of the user, at the valuation and insurance process. IDP will only reveal
                        confidential information if mandated by a court of Law
                    </div>
                    <div className="disclaimer margin_top_24">
                        *******“Disclaimer”*******
                    </div>
                    <div className="margin_top_24">
                        Management shall not be responsible for any user inactivity, during contractual negotiations and
                        obligations set forth in the agreement herein. It is for the user to use the automated
                        negotiation template with the purpose and understanding that speed is of the essence in every
                        transaction set forth.
                    </div>
                    <div className="margin_top_24"><span>Consent:</span> Both the seller and the purchaser have read and
                        understood the above terms and by clicking on the yes button, agree to be bound by the terms
                        that they both negotiate to in this automated agreement template.
                    </div>
                    <div className="margin_top_24"><span>Note:</span> All contracts created on our platform that are dubbed, commingled, or
                        shrouded in an illegality or are intended to defraud, willfully misrepresent facts are <span>VOID.</span> and
                        parties to the agreement are not able to enforce the obligations and conditions created. The
                        system reserves the right to expel and initiate legal proceedings against any registered user
                        who is in violation of the above.
                    </div>

                    <div className="d_flex align_center form-group margin_top_24">
                        <input type="checkbox" id="checkbox" name="checkbox" onChange={()=>setActive(!active)}/>
                        <label htmlFor="checkbox">I agree lorem Ipsum is simply dummy text of the printing and
                            typesetting industry</label>
                    </div>
                    {
                        active ?
                            <div className="d_flex align_center submit_b margin_top_48" onClick={()=> navigate("/agreement_to_sell_2")}>
                                <button className="save_btn" style={{marginLeft: "0px"}}>Continue</button>
                            </div>
                            :
                            <div className="d_flex align_center submit_b margin_top_48">
                                <button className="continue_btn" style={{marginLeft: "0px"}}>Continue</button>
                            </div>

                    }

                </div>
            </div>
        </>
    )
}