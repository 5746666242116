import "../style.scss";
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import file_upload from "../../../assets/images/icons/file_upload_black_24dp 1.png";
import AgreementToSell3 from "./AgreementToSell3";
import ScrollTop from "../../../components/scrollTop";


export default function AgreementToSell2() {
    let navigate = useNavigate()
    let [activeCauses, setActiveCauses]=useState(false)


    let onChangeFileInput =()=>{
        document.querySelector("#files").onchange = function() {
            const fileName = this.files[0]?.name;
            const label = document.querySelector("label[for=files]");
            label.innerText = fileName ? fileName : "fileName";
        };
    }

    let  _handleCauses = () =>{
        setActiveCauses(true)
    }


    return (
        <>
            <ScrollTop/>
            <div className="CertificatesViewApply_header">
                <h2 className="search_text">IP name here</h2>
            </div>
            <div className='agrrement_cont'>
                <div className="agrrement_cont_header">
                    <h2 className="text_center">AGREEMENT TO SELL AND TRANSFER INTELLECTUAL PROPERTY</h2>
                    <p className="text_center">AUTOMATED NEGOTIATION CONTRACT</p>
                </div>
                <div className="hr_line">

                </div>
                <div className="agrrement_cont_body margin_top_24">
                    <h4 className="margin_top_24">Definitions:.</h4>
                    <table id="definitons">
                        <tr>
                            <td>Grantor:</td>
                            <td>Owner / Seller</td>
                        </tr>
                        <tr>
                            <td>IP:</td>
                            <td>Intellectual Property. Intangible property owned and legally protected</td>
                        </tr>
                        <tr>
                            <td>MPSR 2017:</td>
                            <td>The Movable Properties Securities Rights Act of 2017</td>
                        </tr>
                        <tr>
                            <td>Purchaser:</td>
                            <td>Buyer / Purchaser</td>
                        </tr>
                        <tr>
                            <td>Sell:</td>
                            <td>To pass / give the intellectual property to the buyer for value</td>
                        </tr>
                        <tr>
                            <td>Title:</td>
                            <td>The [IP] certification as proof of ownership</td>
                        </tr>
                        <tr>
                            <td>Transfer:</td>
                            <td>YTransfer of title and ownership to buyer</td>
                        </tr>
                    </table>
                    <h4 className="margin_top_24">A. Recital:</h4>
                    <div>This agreement shall be an agreement to sell, and transfer of absolute ownership of the
                        intellectual property named.
                    </div>
                    <div className="margin_top_24">If the owner is a joint - owner with another party [share ownership
                        with someone else in the form.
                    </div>
                    <div className="margin_top_24">Then this agreement shall be an agreement to sell and transfer of
                        absolute ownership of a defined share in intellectual property [co-ownership share].
                    </div>
                    <div className="margin_top_24">
                        <span>[OWNERS IN COMMON]</span> common defensible share in the property
                    </div>
                    <div>The owner shall agree to sell their share in the intellectual property, if they own the
                        intellectual property in co ownership.
                    </div>
                    <div className="margin_top_24">
                        The owner is guided by co ownership as they may only
                        transfer their share of the intellectual property. This agreement will not apply to
                        joint-ownership as survivorship takes effect]
                    </div>
                    <div className="hr_line">
                    </div>
                    <h3 className="text_center">DESCRIPTION OF INTELLECTUAL PROPERTY</h3>
                    <h4 className="margin_top_12">SUBJECT MATTER OF THIS AGREEMENT TO SELL IS:</h4>
                    <div className="d_flex align_center margin_top_24">
                        <h5>NAME OF INTELLECTUAL PROPERTY:</h5>
                        <div className="filled_data_input" style={{marginLeft: "24px"}}>
                            <input type="text" placeholder="NAME OF INTELLECTUAL PROPERTY"/>
                        </div>
                    </div>
                    <div className="margin_top_24">There is an implied condition that the goods shall correspond with
                        the description.
                    </div>
                    <div>
                        <span>[Section 15 Sale of Goods Act]</span>
                    </div>
                    <div className="margin_top_24"> Where the purchaser has made it known to the seller the reason for
                        the purchase of the [IP] there is an implied condition that the goods shall be fit for the
                        purpose,
                        that was communicated to the buyer from the seller.
                    </div>
                    <div>
                        <span>[Section 16 (a) Sale of Goods Act]</span>
                    </div>
                    <div className="margin_top_24">The transfer of ownership shall be deemed complete, by a transfer
                        notice being lodged and accurately
                        capable of depicting the charge in ownership. From the seller to the purchaser
                    </div>
                    <div className="hr_line">
                    </div>
                    <h4>LAWS:</h4>
                    <div>
                        This contract shall be governed by the Contract Laws of Kenya, The relevant intellectual
                        property laws, governing the mentioned property above, such as the Copyright Act No 12 of 2001,
                        The Trademark Act CAP 506, The Industrial Property Act No 3 of 2001, The Movable Properties
                        Securities Act 2017 and The Sale of Goods Act Cap 31 Laws of Kenya. Supported by the relevant
                        sections of the Companies Act 2015, The Registration of Business Names Act and Amendment Bill
                        2021, Kenya Seed and Plant Varieties Act, and any other laws that may be 3 required, expressly
                        and implied to achieve the absolute transfer of ownership of the above mentioned property.
                    </div>
                    <div className="margin_top_24">
                        International treaties shall apply to their category of intellectual property governed by each
                        jurisdiction. WCT WIPO copyright treaty, TRIPS trade related aspects of intellectual property,
                        Marrakesh Agreement, The Paris Convention, The Madrid Protocol, Lisbon agreement, Hague
                        agreement. UPOV convention.
                    </div>
                    <div className="margin_top_24">
                        Practice: Uniform Standards For Intellectual Property Rights Accountability.
                    </div>
                    <div className="hr_line">
                    </div>
                    <div>
                        <span>1. THIS</span>agreement to sell and transfer is between: [PARTIES]
                    </div>
                    <div className="margin_top_24">
                        <span>PARTY 1.1.: GRANTOR / SELLER:</span>
                    </div>
                    <div className="apply_certificate_item margin_top_12">
                        <div className="certificate_item">
                            <label htmlFor="">Email</label>
                            <input type="email"  placeholder=""/>
                        </div>
                        <div className="certificate_item file_upload" style={{position: "relative"}}>
                            <p>QR code</p>
                                <div className="input_container">
                                    <img src={file_upload} alt="file_upload" className="file_upload_img"/>
                                    <label for="files" className="labelbtn">Passport</label>
                                    <input type="file" id="files" placeholder="Passport" onChange={onChangeFileInput}/>
                                </div>
                        </div>
                    </div>
                    <div className="apply_certificate_item">
                        <div className="certificate_item">
                            <label htmlFor="">Nationality</label>
                            <input type="text" value="" placeholder=""/>
                        </div>
                        <div className="certificate_item">
                            <label htmlFor="">Location</label>
                            <input type="text" value="" placeholder=""/>
                        </div>
                    </div>
                    <div style={{marginTop: "-12px"}}>
                        <span style={{float: "right"}}>seller [Jurisdiction clause 10]</span>
                    </div>
                    <div className="margin_top_24">PARTY 1.2.: BUYER / PURCHASER:</div>
                    <div className="apply_certificate_item margin_top_12">
                        <div className="certificate_item filled_data_input">
                            <input type="text" placeholder="namesurname@gmail.com"/>
                        </div>
                        <div className="certificate_item filled_data_input">
                            <input type="text" placeholder="passport pic.jpeg"/>
                        </div>
                    </div>
                    <div className="apply_certificate_item">
                        <div className="certificate_item filled_data_input">
                            <input type="text" placeholder="Nationality"/>
                        </div>
                        <div className="certificate_item filled_data_input">
                            <input type="text" placeholder="Location"/>
                        </div>
                    </div>
                    <div className="margin_top_12">
                        <span style={{float: "right"}}>buyer [Jurisdiction clause 10]</span>
                    </div>
                    <div className="margin_top_24"><span>1.3 PRE-CONTRACTUAL DOCUMENTATION:</span></div>
                    <div className="margin_top_12">PARTY 1: GRANTOR / SELLER:</div>
                    <div className="apply_certificate_item margin_top_12">
                        <div className="certificate_item file_upload" style={{position: "relative"}}>
                            <p>ID and KPA PIN</p>
                            <div className="input_container">
                                <img src={file_upload} alt="file_upload" className="file_upload_img"/>
                                <label htmlFor="files" className="labelbtn">Upload here</label>
                                <input type="file" id="files" placeholder="Passport" onChange={onChangeFileInput}/>
                            </div>
                        </div>
                        <div className="certificate_item file_upload" style={{position: "relative"}}>
                            <p>Resolution to sell</p>
                            <div className="input_container">
                                <img src={file_upload} alt="file_upload" className="file_upload_img"/>
                                <label htmlFor="files" className="labelbtn">Upload here</label>
                                <input type="file" id="files" placeholder="Passport" onChange={onChangeFileInput}/>
                            </div>
                        </div>
                    </div>
                    <div className="apply_certificate_item">
                        <div className="certificate_item file_upload" style={{position: "relative"}}>
                            <p>Passport picture</p>
                            <div className="input_container">
                                <img src={file_upload} alt="file_upload" className="file_upload_img"/>
                                <label htmlFor="files" className="labelbtn">Upload here</label>
                                <input type="file" id="files" placeholder="Passport" onChange={onChangeFileInput}/>
                            </div>
                        </div>
                        <div className="certificate_item file_upload" style={{position: "relative"}}>
                            <p>Front page of national identity card</p>
                            <div className="input_container">
                                <img src={file_upload} alt="file_upload" className="file_upload_img"/>
                                <label htmlFor="files" className="labelbtn">Upload here</label>
                                <input type="file" id="files" placeholder="Passport" onChange={onChangeFileInput}/>
                            </div>
                        </div>
                    </div>
                    <div className="margin_top_12">PARTY 1.2.: BUYER / PURCHASER:</div>
                    <div className="apply_certificate_item margin_top_12">
                        <div className="certificate_item filled_data_input">
                            <input type="text" placeholder="ID.pdf | KPA PIN.pdf "/>
                        </div>
                        <div className="certificate_item filled_data_input">
                            <input type="text" placeholder="Resolution to sell.pdf"/>
                        </div>
                    </div>
                    <div className="apply_certificate_item">
                        <div className="certificate_item filled_data_input">
                            <input type="text" placeholder="Passport picture.jpeg"/>
                        </div>
                        <div className="certificate_item filled_data_input">
                            <input type="text" placeholder="Front page of national identity card.jpeg"/>
                        </div>
                    </div>
                    <div className="hr_line">
                    </div>
                    <h4>2. DUE DILIGENCE:</h4>
                    <div className="d_flex align_center form-group margin_top_12">
                        <input type="checkbox" id="checkbox" name="checkbox"/>
                        <label htmlFor="checkbox">Every contractual negotiation shall submit a due diligence search to
                            the registry and across the Intellectual property depository system. These search results
                            shall aid the users in understanding the true position, as to ownership of the [IP]
                            intellectual property in question.</label>
                    </div>
                    <div>
                        This is a tri-part search that will enable the parties to know the true position of ownership of
                        the subject matter of this agreement to sell and transfer
                    </div>
                    <div className="filled_data_input margin_top_24">
                        <input type="text" placeholder="NAME OF INTELLECTUAL PROPERTY"/>
                    </div>
                    <div className="margin_top_12">
                        <span style={{float: "right"}}>[Section 8 of the MPSR Act 2017]</span>
                    </div>
                    <h4>2.1. SEARCH ONE:</h4>
                    <div className="margin_top_12">PRE-SEARCH: Both parties hereby consent to conduct an immediate
                        search:
                    </div>
                    <div className="d_flex align_center margin_top_12">
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="No" value="No" name="radio"
                            />
                            No
                        </label>
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="yes" value="yes" name="radio" checked="checked"
                            />
                            Yes
                        </label>
                    </div>
                    <h4 className="margin_top_32">2.2. SEARCH TWO:</h4>
                    <p className="margin_top_12">SEARCH NOW:</p>
                    <div className="margin_top_12">This is the intellectual property depository directory search, to
                        confirm the information of
                        the parties to this contract are correct, and that the position of ownership of the intellectual
                        property named
                    </div>
                    <div className="d_flex align_center margin_top_8">
                        <div className="filled_data_input" style={{marginRight: "24px"}}>
                            <input type="text" placeholder="NAME OF INTELLECTUAL PROPERTY"/>
                        </div>
                        <h5>[Section 8 of the MPSR Act 2017]</h5>
                    </div>
                    <p className="margin_top_8">reflects the new ownership and transfer from seller to purchaser.</p>
                    <div className="hr_line">
                    </div>
                    <h4>3. CONFIDENTIALITY:</h4>
                    <div className="margin_top_12">The seller and the buyer shall be deemed to have created a
                        confidentiality arrangement, where
                        the seller shall not reveal the know-how or intricacies, trade secrets or business operation
                        models of the property mentioned.
                    </div>
                    <div className="d_flex align_center margin_top_8">
                        <div className="filled_data_input">
                            <input type="text" placeholder="NAME OF INTELLECTUAL PROPERTY"/>
                        </div>
                    </div>
                    <div className="margin_top_8">to anyone who is not the purchaser, or on strict, written instructions
                        from the purchaser, to
                        reveal any information to agents, employees, distributors etc.
                    </div>
                    <div className="d_flex align_center margin_top_12">
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="No1" value="No1" name="radio1"
                            />
                            No
                        </label>
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="yes1" value="yes1" name="radio1" checked="checked"
                            />
                            Yes
                        </label>
                    </div>
                    <div className="margin_top_24">Is your IP in dispute?
                    </div>
                    <div className="d_flex align_center margin_top_12">
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="No1" value="No1" name="radio2"
                            />
                            No
                        </label>
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="yes1" value="yes1" name="radio2" checked="checked"
                            />
                            Yes
                        </label>
                    </div>
                    <div className="margin_top_24">Any third party claims against the IP
                    </div>
                    <div className="d_flex align_center margin_top_12">
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="No1" value="No1" name="radio3"
                            />
                            No
                        </label>
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="yes1" value="yes1" name="radio3" checked="checked"
                            />
                            Yes
                        </label>
                    </div>
                    <div className="margin_top_16">
                        <div className="">
                            <textarea name="" id="" cols="" rows=""
                                      style={{background: "white", width: "100%"}}>Max 100 characters</textarea>
                        </div>
                    </div>
                    <h4 className="margin_top_24">3.1. ANY INFRINGEMENT CAUSES IN COURT OR ARBITRATION I INVOLVING [IP]
                    </h4>
                    {
                        !activeCauses ?
                            <>
                                <div className="margin_top_12">Is there a claim?</div>
                                <div className="d_flex align_center margin_top_12">
                                    <label className="radio_btn_b d_flex align_center">
                                        <input type="radio" id="No1" value="No1" name="radio4"
                                        />
                                        No
                                    </label>
                                    <label className="radio_btn_b d_flex align_center">
                                        <input type="radio" id="yes1" value="yes1" name="radio4" checked="checked"
                                        />
                                        Yes
                                    </label>
                                </div>
                                <div className="margin_top_24">The seller agrees to indemnify, bear the cost of any third party
                                    claim of ownership claim against the purchaser. As breach of implied warranty on transfer of
                                    quiet possession of property.
                                </div>
                                <div className="d_flex align_center margin_top_12">
                                    <label className="radio_btn_b d_flex align_center">
                                        <input type="radio" id="No1" value="No1" name="radio5" onChange={ _handleCauses}
                                        />
                                        No
                                    </label>
                                    <label className="radio_btn_b d_flex align_center">
                                        <input type="radio" id="yes1" value="yes1" name="radio5"  onChange={()=>setActiveCauses(false)}
                                        />
                                        Yes
                                    </label>
                                </div>
                                <div className="margin_top_16">
                                    <div className="">
                            <textarea name="" id="" cols="" rows=""
                                      style={{background: "white", width: "100%"}}>Max 100 characters</textarea>
                                    </div>
                                </div>
                                <div className="disclaimer margin_top_24" style={{fontSize:"16px"}}>
                                    ******Message about why the contract can’t be sign, fe there is an open claim******
                                </div>
                            </>
                            :
                            <AgreementToSell3/>
                    }


                </div>
            </div>
        </>
    )
}