import {contactUs} from "./home";

export const logo = require('./logo.png')
export const qr = require('./qr.png')

export const user = require('./user.png')
export const profileUser = require('./profileUser.png')

export const visa = require('./visa.png')
export const masterCard = require('./masterCard.png')
export const americanExpress = require('./americanExpress.png')

export {
    slide1BC,
    slide2BC,
    slide3BC,
    whatWeDo,
    contactUs,
} from './home'

export {
    menu,
    close,
    search,

    oneStopMarket,
    agreementReady,
    transactionTrack,

    transact,
    share,
    deposit,

    phone,
    ipdepositoryLimited,
    message,
    notification,
    logOut,
    closeBlack,

} from './icons'
