import { Loading } from "../../components";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import file_upload from "../../assets/images/icons/file_upload_black_24dp 1.png";
import calendar_icon from "../../assets/images/icons/calendar.png";
import Calendar from "../../components/calendar";
import { zodResolver } from "@hookform/resolvers/zod";
import { change_schema } from "./schema";
import { Controller, useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import axiosInstance from "../../api";

export function CertificatesViewApplyCharge() {
  let navigate = useNavigate();
  const { path, id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [agreementType, setAgreementType] = useState([]);
  const [startDate, setStartDate] = useState("");

   let getAgreementType = async () => {
     try {
       let response = await axiosInstance.get("api/v1/certificate-types");
       setAgreementType(response.data.data);
     } catch (e) {
       console.log(e);
       console.log(e.response);
     }
   };
     useEffect(() => {
       getAgreementType();
     }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    clearErrors,
    setValue,
  } = useForm({
    resolver: zodResolver(change_schema),
  });
  
  const handleChangeStart = (dateChange) => {
    setValue("duration_date", dateChange.toString(), {
      shouldDirty: true,
    });
    clearErrors("duration_date");
    setStartDate(dateChange);
  };
  const submitData = async (data) => {
    const type_id = agreementType.filter(
      (item) => item.name.toLowerCase() === path
    )[0];
    const newData = {
      agreement_id: +id,
      type_id: type_id.id,
      certificate: {
        ...data,
      },
    };
    
    setErrorMessage("");
    setIsLoading(true);

    try {
      await axiosInstance.post("api/client/create-proposal", newData);

      reset();
      setIsLoading(false);
      navigate(-1);
    } catch (e) {
      console.log(e);
      setErrorMessage(e.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <Loading modalIsOpen={isLoading} />
      <div className="CertificatesViewApply_header">
        <h5 className="search_text">Apply for certificate</h5>
      </div>
      <form onSubmit={handleSubmit(submitData)} className="view_certeficate">
        <div className="body">
          <h5>PARTIES TO THE AGREEMENT</h5>
          <div className="apply_certificate_item margin_top_24">
            <div className="certificate_item">
              {errors?.part_1 ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.part_1.message}
                </p>
              ) : null}
              <label htmlFor="">Part 1</label>
              <input type="text" {...register("part_1")} />
            </div>
            <div className="certificate_item">
              {errors?.part_2 ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.part_2.message}
                </p>
              ) : null}
              <label htmlFor="">Part 2</label>
              <input type="text" {...register("part_2")} />
            </div>
          </div>
          <h5 className="margin_top_24">DUE DILIGENCE</h5>
          {errors?.due_diligence ? (
            <p style={{ color: "red", fontSize: "16px" }}>
              {errors?.due_diligence.message}
            </p>
          ) : null}
          <div className="d_flex align_center form-group">
            <input
              type="checkbox"
              id="checkbox"
              name="checkbox"
              {...register("due_diligence")}
            />
            <label htmlFor="checkbox">
              Parties to this chat template acknowledge the importance of due
              diligence and hereby undertake an inquiry into the status of the
              certificate from the issuing authority. The Authority will
              authenticate the existence of the certificate to the said
              intellectual property.
            </label>
          </div>
          <h5 className="margin_top_48">CONFIDENTIALITY</h5>
          {errors?.confidentiality ? (
            <p style={{ color: "red", fontSize: "16px" }}>
              {errors?.confidentiality.message}
            </p>
          ) : null}
          <div className="d_flex align_center form-group">
            <input
              type="checkbox"
              id="checkbox1"
              name="checkbox"
              className="checkbox"
              {...register("confidentiality")}
            />
            <label htmlFor="checkbox1">
              Parties to this proposal agree to keep the details within this
              proposal confidential.
            </label>
          </div>
          <h5 className="margin_top_48">CHARGEE DISCLOSURE ACCEPTANCE</h5>
          {errors?.chargee_disclosure_acceptance ? (
            <p style={{ color: "red", fontSize: "16px" }}>
              {errors?.chargee_disclosure_acceptance.message}
            </p>
          ) : null}
          <div className="d_flex align_center form-group">
            <input
              type="checkbox"
              id="checkbox2"
              name="checkbox"
              className="checkbox"
              {...register("chargee_disclosure_acceptance")}
            />
            <label htmlFor="checkbox2">
              Chargee has accepted the disclosed information relevant to the
              intellectual property.
            </label>
          </div>
          <h5 className="margin_top_48">OWNERSHIP AND CO OWNERSHIP</h5>
          <div className="apply_certificate_item margin_top_24">
            <div className="certificate_item">
              <label htmlFor="">Ownership</label>
              {errors?.ownership ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.ownership.message}
                </p>
              ) : null}
              <input
                type="text"
                placeholder="Name Surname"
                {...register("ownership")}
              />
            </div>
            <div className="certificate_item">
              <label htmlFor="">Co ownership</label>
              {errors?.co_ownership ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.co_ownership.message}
                </p>
              ) : null}
              <input
                type="text"
                placeholder="Name Surname"
                {...register("co_ownership")}
              />
            </div>
          </div>
          <h5 className="margin_top_24">
            GRANTOR / CHARGOR GRANTS THE FOLLOWING CHARGE
          </h5>
          {errors?.whole_or_part ? (
            <p style={{ color: "red", fontSize: "16px" }}>
              {errors?.whole_or_part.message}
            </p>
          ) : null}
          <div className="d_flex radio_btns_b margin_top_24">
            <label className="radio_btn_b d_flex align_center">
              <input
                type="radio"
                id="private"
                value="whole"
                name="radio"
                {...register("whole_or_part", {
                  validate: (value) => {
                    const parsedValue = change_schema.parse({
                      whole_or_part: value,
                    });
                    return parsedValue.whole_or_part === value;
                  },
                })}
              />
              Whole
            </label>
            <label className="radio_btn_b d_flex align_center">
              <input
                type="radio"
                id="public"
                value="part"
                name="radio"
                {...register("whole_or_part", {
                  validate: (value) => {
                    const parsedValue = change_schema.parse({
                      whole_or_part: value,
                    });
                    return parsedValue.whole_or_part === value;
                  },
                })}
              />
              Part
            </label>
          </div>
          <div className="apply_certificate_item margin_top_24">
            <div className="certificate_item">
              <label htmlFor="">Specify which part</label>
              {errors?.specify_which_part ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.specify_which_part.message}
                </p>
              ) : null}
              <input
                type="text"
                placeholder=""
                {...register("specify_which_part")}
              />
            </div>
            <div className="certificate_item"></div>
          </div>
          {errors?.secured_or_unsecured ? (
            <p style={{ color: "red", fontSize: "16px" }}>
              {errors?.secured_or_unsecured.message}
            </p>
          ) : null}
          <div className="d_flex align_center">
            <label className="ddIpContent_b1_item">
              <input
                type="radio"
                id="secured"
                name="radio"
                value="secured"
                {...register("secured_or_unsecured", {
                  validate: (value) => {
                    const parsedValue = change_schema.parse({
                      secured_or_unsecured: value,
                    });
                    return parsedValue.secured_or_unsecured === value;
                  },
                })}
              />
              Secured (with valuation and insurance policies)
            </label>
            <label className="ddIpContent_b1_item">
              <input
                type="radio"
                id="unsecured"
                name="radio"
                value="unsecured"
                {...register("secured_or_unsecured", {
                  validate: (value) => {
                    const parsedValue = change_schema.parse({
                      secured_or_unsecured: value,
                    });
                    return parsedValue.secured_or_unsecured === value;
                  },
                })}
              />
              Unsecured (without valuation and insurance policies)
            </label>
          </div>
          <h5 className="margin_top_48">
            THE AMOUNT SECURED BY THIS AGREEMENT TO CHARGE
          </h5>
          <h2 className="fs_18 f_700">SECURED AMOUNT</h2>
          <div className="apply_certificate_item">
            <div className="certificate_item">
              <label htmlFor="">Amount (figures)</label>
              {errors?.secured_amount_figures ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.secured_amount_figures.message}
                </p>
              ) : null}
              <input
                type="text"
                placeholder=""
                {...register("secured_amount_figures", {
                  valueAsNumber: true,
                })}
              />
            </div>
            <div className="certificate_item">
              <label htmlFor="">Amount (words)</label>
              {errors?.secured_amount_words ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.secured_amount_words.message}
                </p>
              ) : null}
              <input
                type="text"
                placeholder=""
                {...register("secured_amount_words", {
                  valueAsNumber: true,
                })}
              />
            </div>
          </div>
          <h2 className="fs_18 f_700 margin_top_24">REPAYMENT AMOUNT</h2>
          <div className="apply_certificate_item">
            <div className="certificate_item">
              <label htmlFor="">Amount (figures)</label>
              {errors?.repayment_amount_figures ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.repayment_amount_figures.message}
                </p>
              ) : null}
              <input
                type="text"
                placeholder=""
                {...register("repayment_amount_figures", {
                  valueAsNumber: true,
                })}
              />
            </div>
            <div className="certificate_item">
              <label htmlFor="">Amount (words)</label>
              {errors?.repayment_amount_words ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.repayment_amount_words.message}
                </p>
              ) : null}
              <input
                type="text"
                placeholder=""
                {...register("repayment_amount_words", {
                  valueAsNumber: true,
                })}
              />
            </div>
          </div>
          <h2 className="fs_18 f_700 margin_top_24">
            INTEREST ON AMOUNT (SHALL BE 3-10 %PERCENT OF THE AMOUNT ABOVE)
          </h2>
          <div className="apply_certificate_item">
            <div className="certificate_item">
              <label htmlFor="">Amount (figures)</label>
              {errors?.interest_on_amount_figures ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.interest_on_amount_figures.message}
                </p>
              ) : null}
              <input
                type="text"
                placeholder=""
                {...register("interest_on_amount_figures", {
                  valueAsNumber: true,
                })}
              />
            </div>
            <div className="certificate_item">
              <label htmlFor="">Amount (words)</label>
              {errors?.interest_on_amount_words ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.interest_on_amount_words.message}
                </p>
              ) : null}
              <input
                type="text"
                placeholder=""
                {...register("interest_on_amount_words", {
                  valueAsNumber: true,
                })}
              />
            </div>
          </div>
          <h2 className="fs_18 f_700 margin_top_24">
            DURATION (SHALL BE 1-9 years)
          </h2>
          <div className="apply_certificate_item margin_top_16">
            {errors?.duration_date ? (
              <p style={{ color: "red", fontSize: "16px" }}>
                {errors?.duration_date.message}
              </p>
            ) : null}
            <label className="calendar_block">
              Date
              <img
                src={calendar_icon}
                alt="calendar_icon"
                className="calendar_icon"
              />
              <Controller
                name="duration_start_date"
                control={control}
                defaultValue={startDate}
                render={() => (
                  <DatePicker
                    selected={startDate}
                    onChange={handleChangeStart}
                  />
                )}
              />
            </label>
            <div className="certificate_item"></div>
          </div>
          <h5 className="margin_top_48">CHARGEE ACCOUNT DETAILS</h5>
          <div className="apply_certificate_item margin_top_24">
            <div className="certificate_item">
              <label htmlFor="">Chargee</label>
              {errors?.chargee_chargee ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.chargee_chargee.message}
                </p>
              ) : null}
              <input
                type="text"
                placeholder=""
                {...register("chargee_chargee")}
              />
            </div>
            <div className="certificate_item">
              <label htmlFor="">Name</label>
              {errors?.chargee_name ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.chargee_name.message}
                </p>
              ) : null}
              <input type="text" placeholder="" {...register("chargee_name")} />
            </div>
          </div>
          <div className="apply_certificate_item">
            <div className="certificate_item">
              <label htmlFor="">Account details</label>
              {errors?.chargee_details ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.chargee_details.message}
                </p>
              ) : null}
              <input
                type="text"
                placeholder=""
                {...register("chargee_details")}
              />
            </div>
            <div className="certificate_item">
              <label htmlFor="">Bank</label>
              {errors?.chargee_bank ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.chargee_bank.message}
                </p>
              ) : null}
              <input type="text" placeholder="" {...register("chargee_bank")} />
            </div>
          </div>
          <div className="apply_certificate_item">
            <div className="certificate_item">
              <label htmlFor="">Account number</label>
              {errors?.chargee_number ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.chargee_number.message}
                </p>
              ) : null}
              <input
                type="text"
                placeholder=""
                {...register("chargee_number", {
                  valueAsNumber: true,
                })}
              />
            </div>
            <div className="certificate_item">
              <label htmlFor="">MPESA</label>
              {errors?.chargee_mpesa ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.chargee_mpesa.message}
                </p>
              ) : null}
              <input
                type="text"
                placeholder=""
                {...register("chargee_mpesa")}
              />
            </div>
          </div>
          <div className="apply_certificate_item">
            <div className="certificate_item">
              <label htmlFor="">Till number</label>
              {errors?.chargee_till_number ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.chargee_till_number.message}
                </p>
              ) : null}
              <input
                type="text"
                placeholder=""
                {...register("chargee_till_number", {
                  valueAsNumber: true,
                })}
              />
            </div>
            <div className="certificate_item">
              <label htmlFor="">Phone number</label>
              {errors?.charge_phone_number ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.charge_phone_number.message}
                </p>
              ) : null}
              <input
                type="text"
                placeholder=""
                {...register("charge_phone_number", {
                  valueAsNumber: true,
                })}
              />
            </div>
          </div>
          <h5 className="margin_top_24">FURTHER CHARGE</h5>
          <p className="" style={{ fontSize: "16px" }}>
            The Chargor [borrower] requests further funds from the Chargee
            [lender]. The chargor hereby applies for a further loan amount of:
          </p>
          <div className="apply_certificate_item margin_top_16">
            <div className="certificate_item">
              <label htmlFor="">Amount (figures)</label>
              {errors?.further_charge_amount_figure ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.further_charge_amount_figure.message}
                </p>
              ) : null}
              <input
                type="text"
                placeholder=""
                {...register("further_charge_amount_figure", {
                  valueAsNumber: true,
                })}
              />
            </div>
            <div className="certificate_item">
              <label htmlFor="">Amount (words)</label>
              {errors?.further_charge_amount_words ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.further_charge_amount_words.message}
                </p>
              ) : null}
              <input
                type="text"
                placeholder=""
                {...register("further_charge_amount_words", {
                  valueAsNumber: true,
                })}
              />
            </div>
          </div>
          <h5 className="margin_top_24">
            RIGHTS OBLIGATIONS AND DUTIES OF CHARGOR AND CHARGEE
          </h5>
          <p>
            These obligations are binding to the grantor / chargor and are
            binding on the parties depending on their rights and duties within
            this automated negotiation template. Upon signing this template and
            the specific dates and times herein. These obligations and duties
            become binding on both parties. Breach of which shall be termed as
            breach of condition or warranty and shall allow the aggrieved party
            to initiate court proceedings to effect several remedies.
          </p>
          <p>
            {" "}
            A. RIGHT TO REGISTER AN AMENDMENT NOTICE:{" "}
            <span style={{ fontWeight: "700" }}>Chargee ONLY</span>
          </p>
          <p>[Section 32 of the MPSR 2017]</p>
          <p className="margin_top_24">
            {" "}
            B. RIGHT TO INSPECT the collateral and related documents [valuation
            and Insurance] included.
          </p>
          <p>[Section 58 of the MPSR Act 2017]</p>
          <p className="margin_top_24">
            {" "}
            C. RIGHT TO SUE: for the amount borrowed and interest on the amount.
          </p>
          <p className=""> [Section 68 of the MPSR Act 2017]</p>
          <p className="margin_top_24">
            {" "}
            D. RIGHT TO TAKE POSSESSION of collateral [ip certificate] and
            related documents.
          </p>
          <p> [Section 71 of the MPSR Act 2017]</p>
          <p className="margin_top_24">
            {" "}
            E. RIGHT TO DISPOSE of collateral once taken possession via judicial
            notice.
          </p>
          <p> [Section 72 of the MPSR Act 2017]</p>
          <p className="margin_top_24">
            F. OBLIGATION TO RETURN collateral [ip certificate] and related
            documents
          </p>
          <p> [Section 57 of the MPSR Act 2017]</p>
          <p className="margin_top_24">
            {" "}
            G. RIGHT TO REDEMPTION of collateral.
          </p>
          <p> [Section 69 of the MPSR Act 2017]</p>
          <p className="margin_top_24">
            {" "}
            H. OBLIGATION TO PAY the agreed amount[7D] on the agreed date[7.2]
            as per this agreement to charge at Part
          </p>
          <p> [7 ] LOAN AMOUNT Plus VAT 16 %</p>
          <p> [7A] REPAYMENT AMOUNT</p>
          <p>[7.1] INTEREST</p>
          <p>[7.3] CALENDAR</p>
          <p>and [7.4] TO ACCOUNT DETAILS</p>
          <p className="margin_top_24">
            H.1. Calculate the above amounts [7 + 7.1]
          </p>
          <div className="apply_certificate_item margin_top_16">
            <div className="certificate_item">
              <label htmlFor="">Amount</label>
              {errors?.rights_obligations_amount ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.rights_obligations_amount.message}
                </p>
              ) : null}
              <input
                type="text"
                placeholder=""
                {...register("rights_obligations_amount", {
                  valueAsNumber: true,
                })}
              />
            </div>
            <div className="certificate_item"></div>
          </div>
          <p className="margin_top_24">
            I. OBLIGATION TO EXERCISE REASONABLE CARE over the collateral in
            possession of the grantor, or secured creditor.
          </p>
          <p>[Section 56 of the MPSR Act 2017] to preserve the asset.</p>
          <p className="margin_top_24">
            {" "}
            J. OBLIGATION TO AUTHORIZE NOTIFICATIONS ON TIME: using the parties
            profile on ipdepository.com or any other medium. If the medium
            sought is outside this system, Kindly ensure its registration at the
            MPSR and ensure you effectively notify the system, your or the other
            parties dashboard for amendment and search, to adjust the outside
            notice.
          </p>
          <h5 className="margin_top_48">
            CHARGOR ASSIGNMENT IN LIEU OF DEFAULT NOTICE
          </h5>
          {errors?.chargor_assignment ? (
            <p style={{ color: "red", fontSize: "16px" }}>
              {errors?.chargor_assignment.message}
            </p>
          ) : null}
          <div className="d_flex align_center form-group">
            <input
              type="checkbox"
              id="checkbox10"
              name="checkbox"
              className="checkbox"
              {...register("chargor_assignment")}
            />
            <label htmlFor="checkbox10">
              The chargor user 1.1 known as borrower hereby agrees that the
              management of IPD [IPdepository Limited ] the mandate to effect
              any judicial order that may assist in the chargee 1.2 hereby known
              as the lender achieve their rights to the said intellectual
              property by virtue of the agreement to charge hereby entered into.
            </label>
          </div>
          <h5 className="margin_top_48">ASSIGNMENT OF INTELLECTUAL PROPERTY</h5>
          <div className="apply_certificate_item margin_top_24">
            <div className="certificate_item">
              <label htmlFor="">
                This assignment is for intellectual property named
              </label>
              {errors?.assignment_of_intellectual_namepfip ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.assignment_of_intellectual_namepfip.message}
                </p>
              ) : null}
              <input
                type="text"
                placeholder="NAME PF IP"
                {...register("assignment_of_intellectual_namepfip")}
              />
            </div>
            <div className="certificate_item">
              <label htmlFor="">
                That i am the owner, and having secured a charge for
              </label>
              {errors?.assignment_of_intellectual_amount ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.assignment_of_intellectual_amount.message}
                </p>
              ) : null}
              <input
                type="text"
                placeholder="Amount"
                {...register("assignment_of_intellectual_amount")}
              />
            </div>
          </div>
          {/* <h5 className="margin_top_24">SIGNATURE</h5>
            <div className="d_flex align_center form-group">
              <input
                type="checkbox"
                id="checkbox12"
                name="checkbox"
                className="checkbox"
              />
              <label htmlFor="checkbox12">
                I Grantor/ Chargor hereby certify that i intend to create a
                charge over the above mentioned intellectual property, and shall
                be bound by the periodic payments [7] I shall make to redeem my
                intellectual property, I also understand that if i fail to
                redeem the said intellectual property, several court mandated
                remedies are available to the chargee, such as the right to
                enter into possession of the intellectual property, and eventual
                sale, in the eventual default on payment and obligations under
                this charge agreement.
              </label>
            </div>
            <div className="margin_top_48">
              <div className="line_signature margin_top_24"></div>
              <p style={{ fontSize: "16px" }}>
                GRANTOR /CHARGOR SIGNATURE (PARTY A)
              </p>
            </div>
            <div className="d_flex align_center form-group margin_top_48">
              <input
                type="checkbox"
                id="checkbox13"
                name="checkbox"
                className="checkbox"
              />
              <label htmlFor="checkbox13">
                I creditor / chargee hereby certify that i offer the amount
                [Money] mentioned herein as security against the Intellectual
                property mentioned herein. That upon receipt of payment in full
                of the secured amount and Interest i shall discharge my interest
                in the said intellectual property and allow the chargor to
                redeem the Intellectual property. I shall not place any clogs or
                fetters [obstacles] to the owners right to redeem the [IP] and
                shall always act in good faith towards the reputation and value
                of the said intellectual property.
              </label>
            </div>
            <div className="margin_top_48">
              <div className="line_signature margin_top_24"></div>
              <p style={{ fontSize: "16px" }}>CREDITOR / CHARGEE (PARTY B)</p>
            </div> */}
          <div className="d_flex space_beetween margin_top_48 checkboxes_group align_center">
            <div className="d_flex align_center form-group">
              <input
                type="checkbox"
                id="checkbox34"
                name="checkbox"
                checked
                className="checkbox"
                {...register("verify_certificate")}
              />
              <label htmlFor="checkbox34">Verify Certificate</label>
            </div>
            <div className="d_flex align_center form-group">
              <input
                type="checkbox"
                id="checkbox35"
                name="checkbox"
                className="checkbox"
                {...register("value")}
              />
              <label htmlFor="checkbox35">Value</label>
            </div>
            <div className="d_flex align_center form-group">
              <input
                type="checkbox"
                id="checkbox36"
                name="checkbox"
                // className="checkbox"
                {...register("insure")}
              />
              <label htmlFor="checkbox36">Insure</label>
            </div>
          </div>
          <div className="d_flex space_beetween  checkboxes_group align_center">
            <div className="d_flex align_center form-group">
              {errors?.verify_certificate ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.verify_certificate.message}
                </p>
              ) : null}
            </div>
            <div className="d_flex align_center form-group">
              {errors?.value ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.value.message}
                </p>
              ) : null}
            </div>
            <div className="d_flex align_center form-group">
              {errors?.insure ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.insure.message}
                </p>
              ) : null}
            </div>
          </div>
          <div className="d_flex align_center submit_b margin_top_48">
            <button
              className="save_btn"
              // onClick={() => navigate("/sign-in")}
              type="submit"
              style={{ marginLeft: "0px" }}
            >
              Submit
            </button>
          </div>
          {errorMessage && (
            <p style={{ color: "red", textAlign: "center", marginTop: "10px" }}>
              {errorMessage}
            </p>
          )}
        </div>
      </form>
    </>
  );
}
