import React from "react";

import {
    slide1BC,
    slide2BC,
    slide3BC,
    whatWeDo,
    oneStopMarket,
    agreementReady,
    transactionTrack,
    transact,
    share,
    deposit,
    contactUs
} from "../../assets/images";

import {Pagination, A11y, Autoplay} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import {useNavigate} from "react-router-dom";
import {GetStartedBanner} from "../../components/getStartedBanner";


export function Home() {
    let navigation = useNavigate()
    let navigate = useNavigate()
    return (
        <div className="home">
            <GetStartedBanner/>

            <div className="whatWeDo" id={'test'}>

                <h3>WHAT <span>WE</span> DO</h3>
                <div className='content'>
                    <img src={whatWeDo} alt="" title=''/>
                    <div className="aside">
                        <div>
                            <h4 style={{marginBottom: "0px"}}>You Are In Control Of Your <span>[IP]</span></h4>
                            <p className="control_text">
                                We are an intellectual property certificate storage and transfer community, enabling verification, valuation and insurance of certificates and concepts.
                                We highly recommend the registration of ideas, via your local intellectual property
                                authority.
                                As policy the uploading of false certificate information is forbidden and at
                                verification
                                stage during any transaction, any certificate found to be in violation of the above will
                                be
                                removed from the network, at users cost to system or transaction.
                            </p>
                            <button className='learnMore' onClick={() => navigation('/about')}>Learn More</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="whyChooseIPD">
                <h3>WHY CHOOSE <span>IPD</span></h3>
                <div className="content ">
                    <div className="item">
                        <img src={oneStopMarket} alt="" title=''/>
                        <h5>Deposit Services</h5>
                        <p>
                            We provide a secure platform for depositing copies of your intellectual property certificates.
                            Our system ensures the confidentiality and integrity of your certificates.
                            By depositing your certificates with us, you can easily prove ownership and protect your intellectual property rights.
                        </p>
                    </div>
                    <div className="item">
                        <img src={agreementReady} alt="" title=''/>
                        <h5>Sharing Services</h5>
                        <p>
                            We offer a convenient way to share your intellectual property certificates with others.
                            Our platform allows you to control who has access to your certificates.
                            You can share your certificates with potential buyers, licensees, or partners to explore business opportunities.
                        </p>
                    </div>
                    <div className="item">
                        <img src={transactionTrack} alt="" title=''/>
                        <h5>Transaction Services</h5>
                        <p>
                            We facilitate various types of transactions related to your intellectual property certificates.
                            You can sell, licence, charge or partner your certificates to interested parties through our platform.
                        </p>
                    </div>
                </div>
            </div>

            <div className="howItWorks">
                <h3>HOW IT <span>WORKS</span></h3>
                <div className="content">
                    <div className="item">
                        <div className="body">
                            <div>
                                <img src={deposit} alt="" title=''/>
                            </div>
                            <p>deposit</p>
                        </div>
                    </div>
                    <div className="item">
                        <div className="body">
                            <div>
                                <img src={share} alt="" title=''/>
                            </div>
                            <p>share</p>
                        </div>
                    </div>
                    <div className="item">
                        <div className="body">
                            <div>
                                <img src={transact} alt="" title=''/>
                            </div>
                            <p>transact</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className='advertiseYourConcept'>
                <h2>
                    Advertise your concept within the network
                </h2>
                <div className="content">
                    <div className="item">
                        <h5>AGREEMENT TO SELL</h5>
                        <p>
                            Users agree to sell and transfer the intellectual property mentioned and identified on the
                            system.
                        </p>
                        <button onClick={() => navigation('/list/certificates', {state: {type: 'sell'}})}>Learn More</button>
                    </div>
                    <div className="item">
                        <h5>AGREEMENT TO LICENSE</h5>
                        <p>
                            Users agree among themselves on the permitted use of the intellectual property for a
                            specified purpose and time.
                        </p>
                        <button onClick={() => navigation('/list/certificates',  {state: {type: 'license'}})}>Learn More</button>
                    </div>
                    <div className="item">
                        <h5>AGREEMENT TO CHARGE</h5>
                        <p>
                            Users agree to use the intellectual property as security for the repayment of a loan
                            obligation.
                        </p>
                        <button onClick={() => navigation('/list/certificates',  {state: {type: 'charge'}})}>Learn More</button>
                    </div>
                    <div className="item">
                        <h5>AGREEMENT TO PARTNER</h5>
                        <p>
                            Users agree to come together and combine Intellectual property for the achievement of profit
                            and an aim or period of time.
                        </p>
                        <button onClick={() => navigation('/list/certificates',  {state: {type: 'partner'}})}>Learn More</button>
                    </div>
                </div>
            </div>

            <div className="contactUs">
                <img src={contactUs} alt="" title=''/>
                <div className='content'>
                    <div>
                        <h3>Contact<span> Us</span></h3>
                        <p>
                            We're always excited to assist you achieve more from your intellectual property.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}
