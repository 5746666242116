import React, {useEffect, useState} from "react";
import axiosInstance from "../../../api";

export function Notification() {
    let [data, setData] = useState([])

    useEffect(() => {
        getNotification()
    }, [])

    let getNotification = async () => {
        try {
            let response = await axiosInstance.get('api/client/notify-agreement')
            setData([...response.data.data])
        }catch (e){
            console.log(e, 'eeee');
        }
    }

    let _renderNot = () => {
        return data.map((item, index) => {
            return(
                <tr key={index}>
                    <td data-column="Certificate Number">{item.certificate_number}</td>
                    <td data-column="Certificate Name">{item.certificate_name}</td>
                    <td data-column="Status" className='status statusRegistered'>
                        <span>{item.agreement_status}</span>
                    </td>
                </tr>
            )
        })
    }

    return (
        <div className='notification'>
            <table>
                <thead>
                <tr>
                    <th>Certificate Number</th>
                    <th>Certificate Name</th>
                    <th>Status</th>
                </tr>
                </thead>
                <tbody>
                {_renderNot()}
              {/*  <tr>
                    <td data-column="Certificate Number">text</td>
                    <td data-column="Certificate Name">text</td>
                    <td data-column="Status" className='status statusProcessing'>
                        <span>Processing</span>
                    </td>
                </tr>
                <tr>
                    <td data-column="Certificate Number">text</td>
                    <td data-column="Certificate Name">text</td>
                    <td data-column="Status" className='status statusPending'>
                        <span>Pending</span>
                    </td>
                </tr>*/}

                </tbody>
            </table>
        </div>
    );
}
