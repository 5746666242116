import React, {useEffect, useState} from "react";
import ReactPaginate from 'react-paginate';


import {CertificateBtn} from "../../components";
import axiosInstance from "../../api";
import Pagination from "./pagination/Pagination";
import {GetStartedBanner} from "../../components/getStartedBanner";
import {useLocation} from "react-router-dom";

export function AgreementsList() {
    const {state} = useLocation()
    const [agreements, setAgreements] = useState([])
    let [page, setPage]=useState(0)

    let onChangePage = (page) => {
            setPage(page.nextSelectedPage)

    }
    console.log(state);
    useEffect(() => {
        getAgreements()
    }, [])

    let getAgreements = async () => {
        try {
            let response = await axiosInstance.get('api/client/show/agreements')
            console.log('response :', response);

            setAgreements(response.data.data[state.type])
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <>
            <GetStartedBanner/>
            <div className='agreementsContainer'>
                <div>
                    <div className="banner" style={agreements.length < 4 ? {justifyContent: 'start'} : null}>

                        {agreements.map((item, index) => {
                            if(page <= index && index < page + 24){
                                return (
                                    <CertificateBtn data={item} label={'sell'} key={index}/>
                                )
                            }
                        })}

                    </div>
                </div>
                <Pagination agrements={agreements} onChangePage={onChangePage}/>
            </div>
        </>

    );
}
