import "./style.css";
import React, {useState} from "react";

export default function RejectedRequest(){
    const [requestInputFields1, setRequestInputFields1] = useState([
        {
            label: 'Category',
            placeholder: 'Category',
            key: 'category'
        },
        {
            label: 'Acceptance',
            placeholder: 'Acceptance',
            key: 'acceptance'
        },
        {
            label: 'Breach of terms',
            placeholder: 'Name',
            key: 'brand_terms'
        },
        {
            label: 'Capacity',
            placeholder: 'Name',
            key: 'capacity'
        },
        {
            label: 'Confidentiality',
            placeholder: 'Confidentiality',
            key: 'confidentiality'
        },
        {
            label: 'Consideration',
            placeholder: 'Name',
            key: 'Consideration'
        },
        {
            label: 'Due diligence',
            placeholder: 'Name',
            key: 'due_diligence'
        },
        {
            label: 'Duration',
            placeholder: 'Name',
            key: 'duration'
        },
        {
            label: 'Intention',
            placeholder: 'Name',
            key: 'intention'
        },
    ])
    const [requestInputFields2, setRequestInputFields2] = useState([
        {
            label: 'Indemnity',
            placeholder: 'Indemnity',
            key: 'Indemnity'
        },
        {
            label: 'Injury',
            placeholder: 'Injury ',
            key: 'injury '
        },
        {
            label: 'Negotiation',
            placeholder: 'Name',
            key: 'negotiation'
        },
        {
            label: 'Non-compete',
            placeholder: 'Non-compete',
            key: 'non_compete'
        },
        {
            label: 'Notices',
            placeholder: 'Notices',
            key: 'notices'
        },
        {
            label: 'Offer',
            placeholder: 'Name',
            key: 'offer'
        },
        {
            label: 'Price/ Cost',
            placeholder: '$250',
            key: 'due_diligence'
        },
        {
            label: 'Terms and Conditions',
            placeholder: 'Name',
            key: 'terms'
        },
        {
            label: 'Termination',
            placeholder: 'Name',
            key: 'termination'
        },
    ])
    return (
        <div className="cancel_request_cont">
            <div className="cancel_request_cont_fields registered_request_cont_fields">
                <div className="formGroup">
                    {
                        requestInputFields1.map((item, index) => {
                            return (
                                <div className="formGroup_item">
                                    <label key={index}>
                                        {item.label}
                                        <input type="text" placeholder={item.placeholder}
                                               onChange={(e) => (e.target.value, item.key)
                                               }/>
                                    </label>
                                </div>
                            )
                        })
                    }
                </div>
                <div className="formGroup">
                    {
                        requestInputFields2.map((item, index) => {
                            return (
                                <div className="formGroup_item">
                                    <label key={index}>
                                        {item.label}
                                        <input type="text" placeholder={item.placeholder}
                                               onChange={(e) => (e.target.value, item.key)
                                               }/>
                                    </label>
                                </div>

                            )
                        })
                    }
                </div>
            </div>
            <div className="radio_btns_b">
                <label className="ddIpContent_b1_item">
                    <input type="radio" id="verify" value="verify"  name ="radio" />
                    Verify Certificate
                </label>
                <label className="ddIpContent_b1_item">
                    <input type="radio" id="value" value="value"  name ="radio"/>
                    Value
                </label>
                <label className="ddIpContent_b1_item">
                    <input type="radio"  id="insure" value="insure"  name ="radio"/>
                    Insure
                </label>
            </div>
        </div>
    )
}