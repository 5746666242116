import React, {useEffect, useState} from "react";
import {NavLink, useNavigate} from 'react-router-dom'
import {
    logo,
    menu,
    close,
    search,
    notification,
    logOut,
} from '../../assets/images'
import Avatar from '../../assets/images/user_unknown.png'
import axiosInstance from "../../api";
import {useLocation} from "react-router-dom";
import search_icon from "../../assets/images/icons/search_icon_black.png";
import notification_icon from "../../assets/images/icons/notification_white.png";


export function Header({handleLogout, token, setSearchText}) {
    let locatinn = useLocation()
    const [click, setClick] = React.useState(false);
    let [types, setTypes] = useState([])
    let [user, setUser] = useState({})
    let [image, setImage] = useState('')
    const [navigation] = useState(['coprights', 'trademarks', 'industrial-designs', 'patents', 'traditional-knowledge', 'geographical-indices', 'plant-breeders-rights'])
    let navigate = useNavigate()

    const handleClick = () => setClick(!click);
    const Close = () => setClick(false);


    useEffect(() => {
        responseType()
        if (token) {
            getUser()
        }


    }, [])

    let responseType = async () => {
        try {
            let response = await axiosInstance.get('api/v1/categories')
            setTypes(response.data.data);
        } catch (e) {
            console.log(e);
            console.log(e.response);
        }
    }


    let getUser = async () => {
        try {
            console.log('ertg');
            let response = await axiosInstance.get('api/client/show-profile')
            setUser(response.data)
            setImage(response.data.image)
        } catch (e) {
            console.log(e);
        }
    }
    let _renderType = () => {
        return types.map((item, index) => {
            return (
                <li className="nav-item" key={item.id}>
                    <NavLink
                        to={`/${navigation[index]}`}
                        activeClassName="active"
                        className="link"
                        state={{category_id: item.id}}
                        onClick={click ? handleClick : null}
                    >
                        {item.name}
                    </NavLink>
                </li>
            )
        })
    }


    return (
        <header>
            <div className={click ? "" : ""} onClick={() => Close()}/>
            <nav className="navbar" onClick={e => e.stopPropagation()}>
                {
                    locatinn.pathname === "/search"
                        ?
                        <div className="nav-container_search">
                            <div className="nav-container_search_b">
                                <div>
                                    <NavLink
                                        to="/"
                                        className="nav-logo nav_logo_web"
                                    >
                                        <img src={logo} alt=""/>
                                    </NavLink>
                                </div>
                                <div className="header_search_b">
                                    <img src={search_icon} alt=""/>
                                    <input type="text" placeholder="Search"
                                           onChange={(e) => setSearchText(e.target.value)}/>
                                </div>
                                <div className="">
                                    <ul className={click ? "nav-menu active" : "nav-menu"}>
                                        <div className="nav-container_search_b_mobile">
                                            <div className="nav_menu_header_search_b">
                                                <img src={search_icon} alt=""/>
                                                <input type="text" placeholder="Search"
                                                       onChange={(e) => setSearchText(e.target.value)}/>
                                            </div>
                                            <li>
                                                {
                                                    token ?
                                                        <div className="authContent">
                                                            <button onClick={() => navigate('/notification-proposal')}>
                                                                <img src={notification} alt=""/>
                                                            </button>
                                                            <p>{user.name}</p>
                                                            <button onClick={() => navigate('/requests')}
                                                                    className='btn'>
                                                                {image ?
                                                                    <img src={image} alt=""/>
                                                                    :
                                                                    <img src={Avatar} alt=""/>
                                                                }
                                                            </button>
                                                            <button onClick={() => handleLogout()} className='btn'>
                                                                <img src={logOut} alt=""/>
                                                            </button>
                                                        </div>
                                                        :
                                                        <button onClick={() => navigate("/before-sign-in")} className='btn'>
                                                            Get Started
                                                        </button>
                                                }
                                            </li>
                                        </div>
                                    </ul>
                                    <div className="nav-icon nav-icon_search" onClick={handleClick}>
                                        <img src={click ? close : menu} alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <div className="nav-container">
                            <NavLink
                                to="/"
                                className="nav-logo"
                            >
                                <img src={logo} alt=""/>
                            </NavLink>
                            <ul className={click ? "nav-menu active" : "nav-menu"}>
                                <div>
                                    {_renderType()}
                                    <li className='searchContent' onClick={() => navigate('/search')}>
                                        <button className='searchBtn'><img src={search} alt=""/></button>
                                    </li>
                                    <li>
                                        {
                                            token ?
                                                <div className="authContent">
                                                    <button onClick={() => navigate('/notification-proposal')}>
                                                        <img src={click ? notification_icon : notification} alt=""/>
                                                    </button>
                                                    <p>{user.name}</p>
                                                    <button onClick={() => navigate('/requests')} className='btn'>
                                                        {image ?
                                                            <img src={image} alt=""/>
                                                            :
                                                            <img src={Avatar} alt=""/>
                                                        }
                                                    </button>
                                                    <button onClick={() => handleLogout()} className='btn'>
                                                        <img src={logOut} alt=""/>
                                                    </button>
                                                </div>
                                                :
                                                <button onClick={() => navigate("/before-sign-in")} className='btn'>
                                                    Get Started
                                                </button>
                                        }
                                    </li>
                                </div>
                            </ul>
                            <div className="nav-icon" onClick={handleClick}>
                                <img src={click ? close : menu} alt=""/>
                            </div>
                        </div>

                }

            </nav>
        </ header>
    )
}
