import React, {useState} from "react";
import {logo} from "../../../assets/images";
import {useLocation, useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {Loading} from "../../../components";
import * as _ from "lodash";
import axiosInstance from "../../../api";

export function SignIn({handleLogin}) {
    let dispatch = useDispatch()
    let location = useLocation()
    let navigate = useNavigate()
    let [error, setError] = useState('')
    let [loading, setLoading] = useState(false)
    let [data, setData] = useState({})
    let changeInput = async (e, name) => {
        setData({..._.assignIn(data, {[name]: e})})
        setError('')
    }

    const EmailValidation = () => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        re.test(String(data.email).toLowerCase());
        return re.test(String(data.email).toLowerCase());
    }
    const signIn = async (e) => {
        try {
            if (data.email && data.password) {
                if (EmailValidation()) {
                    if (data.password.length > 7) {
                        setLoading(true)
                        let response = await axiosInstance.post('api/client/login', data)
                        dispatch({
                            type: "SET_CUSTOMER",
                            payload: response.data.user,
                        });
                        
                        localStorage.setItem('token', response.data._token);
                        localStorage.setItem('id', response.data.user.id);
                        handleLogin({
                            token: response.data.token
                        })
                        
                        setLoading(false)
                        if (location.state?.type === 'charge') {
                            navigate(`/certificates/charge/${location.state.path}/${location.state.id}`)
                        } else if (location.state?.type === 'license') {
                            navigate(`/certificates/license/${location.state.path}/${location.state.id}`)
                        } else if (location.state?.type === 'sell') {
                            navigate(`/certificates/sell/${location.state.path}/${location.state.id}`)
                        } else if (location.state?.type === 'partner') {
                            navigate(`/certificates/partner/${location.state.path}/${location.state.id}`)
                        } else {
                            navigate('/agreements')
                        }
                    } else {
                        setError('Length must be at least 8 characters')
                    }
                } else {
                    setError('Please enter valid email address')
                }
            } else {
                setError('Please fill in all required fields')
            }
        } catch (e) {
            setLoading(false)
            if (e.response?.data) {
                setError(e.response?.data.message)
            }
        }
    }

    return (
        <div className='signBanner'>
            <img src={logo} alt="" title="" className='logo'/>
            <div className={'div'}>
                <h1>Sign in to account</h1>
                <label htmlFor="email">
                    Username or email address
                    <input type="email" id='email' onChange={(e) => changeInput(e.target.value, 'email')}/>
                </label>
                <label htmlFor="pass" style={{marginBottom: 0}}>
                    Password
                    <input type="password" id='pass' onChange={(e) => changeInput(e.target.value, 'password')}/>
                </label>
                <div style={{display: "flex", justifyContent: 'space-between', marginTop: '1.1rem'}}>
                    <label htmlFor="rememberMe">
                        <input type="radio"/>
                        Remember Me
                    </label>
                    <button className="forget_pass" onClick={() => navigate('/forgot')}>Forgot password?</button>
                </div>
                {error ?
                    <p style={{color: 'red', marginTop: 10}}>{error}</p>
                    :
                    null
                }
                <button className='btn' onClick={signIn}>Sign In</button>
                <div style={{justifyContent: "center", display: "flex"}}>
                    <p style={{marginRight: '.3rem'}}>Don’t have account?</p>
                    <button onClick={() => navigate('/sign-up')} style={{color: "#332486"}}>Create Account</button>
                </div>
            </div>
            <Loading modalIsOpen={loading}/>
        </div>
    );
}
