import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import search_icon from "../../../assets/images/icons/search_icon_black.png";
import message_author_pic from "../../../assets/images/message_author_pic.png";
import message_sent_pic from "../../../assets/images/meesage_sent_pic.png";
import incognito_user_pic from "../../../assets/images/icons/Incognito_user_pic.png";
import axiosInstance from "../../../api";
import ChatItem from "../../../components/chatItem";
import Pusher from "pusher-js";

export default function Messages() {
  let [messageActive, setMessageActive] = useState(null);
  let [messageRoomId, setMessageRoomId] = useState(null);
  let myId = localStorage.getItem("id");
  let [inputValue, setInputValue] = useState("");
  let [myInfo,setMyInfo] = useState(null)
  console.log('myInfo :', myInfo);
  const [messageRoom, setMessageRoom] = useState([]);
  console.log('messageRoom :', messageRoom);
  const [messageData, setMessageDate] = useState([]);
  console.log('messageData :', messageData);
  const pusher = new Pusher("9b5feaa395acb8ad196b", {
    cluster: "ap2",
  });

  useEffect(() => {
    if (messageRoomId !== null) {
      const userId = messageRoom[messageRoomId].user.id;
      console.log(`message-chanel-${myId}-${userId}`);
      console.log(`message-event-${myId}-${userId}`);
      const channel = pusher.subscribe(`message-chanel-${myId}-${userId}`);
      console.log("channel :", channel);
      channel.bind(`message-event-${myId}-${userId}`, function (data) {
        console.log("data :", data);
        const created_at = formDate(new Date());
        debugger;
        const otherMessage = {
          created_at,
          from_id: { ...messageRoom[messageRoomId].user },
          message: data.message.message,
        };
        if(messageData.length) {
          setMessageDate([...messageData, otherMessage]);
        }
        
      });
      return () => {
        console.log("unmount");
        pusher.unsubscribe(messageRoom[messageRoomId].name);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageRoomId, messageData]);
  const getRooms = async () => {
    try {
      
      const { data } = await axiosInstance.get("api/client/message/get/rooms");
      console.log("data :", data);
      console.log('data.roomMessage :', data.data.roomMessage);
      setMessageRoom(data.data.roomMessage);
      setMyInfo(data.data.user);
    } catch (error) {
      console.log("Error Message", error);
    }
  };
  const getMessages = async (roomId) => {
    try {
      const { data } = await axiosInstance.get(
        `api/client/message/messages/${roomId}`
      );

      setMessageDate(data);
    } catch (error) {
      console.log("error :", error);
    }
  };
  useEffect(() => {
    getRooms();
  }, []);
  let activeMessage = (index) => {
    setMessageActive(index);
  };
  let handleChangeMessage = (e) => {
    setInputValue(e.target.value);
  };
  let _sendMessage = async () => {
    
    const created_at = formDate(new Date())
    const myMessage = {created_at,from_id: {...myInfo},message:inputValue}
    setMessageDate([...messageData, myMessage]);
    setInputValue(" ");
    try {
      const { data } = await axiosInstance.post(
        "api/client/message/chat/send-message",
        { room_id: messageActive, message: inputValue }
      );
    } catch (error) {
      console.log("error :", error);
    }
    
  };
  const handleKeyDown = async (event) => {
    if (event.key === "Enter") {
      _sendMessage();
    }
  };
  const formDate = (dateTime) => {
    const date = new Date(Date.parse(dateTime));
    const year = date.getUTCFullYear();
    const month = date.getUTCMonth() + 1; // Month is 0-based, so add 1
    const day = date.getUTCDate();
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")} ${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;

    return formattedDate;
  };
  return (
    <div className="messages_container">
      <div className="messages_cont1">
        <div className="search_block">
          <input type="text" placeholder="Search" />
          <img src={search_icon} alt="" />
        </div>
        <div className="messages_cont1_b2">
          {messageRoom?.map((item, index) => {
            return (
              <div
                className={
                  messageRoomId === index
                    ? "messages_cont1_b2_item messages_cont1_b2_item_active"
                    : "messages_cont1_b2_item"
                }
                key={index}
                onClick={() => {
                  getMessages(item?.id);

                  activeMessage(item?.id);
                  setMessageRoomId(index);
                }}
              >
                <img
                  src={item?.img || incognito_user_pic}
                  alt="pic"
                  className="author_pic"
                />
                <div className="name_b">
                  <label htmlFor="">{item?.user.email}</label>
                  <div>
                    {item?.first_message &&
                    item.first_message.message.length > 40
                      ? item?.first_message.message.slice(0, 40) + "..."
                      : item?.first_message.message}
                  </div>
                </div>
                <span className="day_time_b">
                  {item?.first_message?.created_at &&
                    formDate(item?.first_message?.created_at)}
                </span>
              </div>
            );
          })}
        </div>
      </div>
      {!messageActive && messageActive !== 0 ? (
        <div className="messages_cont2">
          <p className="start_t">Choose a contact to start.</p>
        </div>
      ) : (
        <div className="messages_cont2">
          <div className="messages_cont2_inner">
            <div className="day_b">
              <div className="line"></div>
              <p>Today</p>
              <div className="line"></div>
            </div>
            {messageData.length > 0 && (
              <ChatItem messages={messageData} formDate={formDate} />
            )}

            <div className="messages_sent_block">
              <input
                type="text"
                placeholder="Type your message here"
                onChange={handleChangeMessage}
                value={inputValue}
                onKeyDown={handleKeyDown}
              />
              <button className="send_message_btn" onClick={_sendMessage}>
                <img src={message_sent_pic} alt="pic" />
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
