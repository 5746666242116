import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import * as _ from "lodash";
import axiosInstance from "../../../api";


export default function User(props){
    let navigate = useNavigate()
    let [error, setError] = useState('')
    let [data, setData] = useState({
        type: 'user',
    })

    let changeInput = async (e, name) => {
        setData({..._.assignIn(data, {[name]: e})})
        setError('')
    }

    const EmailValidation = () => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        re.test(String(data.email).toLowerCase());
        return re.test(String(data.email).toLowerCase());
    }

    let signUp = async () => {
        try {
           
            if (data.email && data.name && data.phone_number  && data.password) {
                if (EmailValidation()) {
                    if (data.password.length > 7) {
                        props.setLoading(true)
                        data.password_confirmation = data.password;
                        let response = await axiosInstance.post('api/client/register', data)
                        console.log(response,"dsds");
                        
                        
                        localStorage.setItem('token', response.data._token);
                        localStorage.setItem("id", response.data.user.id);
                        props.handleLogin({
                            token: response.data.token
                        })
                        props.setLoading(false)
                        navigate('/agreements')
                    } else {
                        setError('Length must be at least 8 characters')
                    }
                } else {
                    setError('Please enter valid email address')
                }
            } else {
                setError('Please fill in all required fields')
            }
        } catch (e) {
            console.log(e);
            debugger
            
            if (e.response?.data?.data) {
                
                    const errorMsg = Object.keys(e.response.data.data).map(
                      (item) => e.response.data.data[`${item}`]
                    );
                    setError(errorMsg);
            }
                else {
                    setError(e.response?.data.message)

                }
            
            props.setLoading(false);
        }
    }
    return (
        <div>
            <label htmlFor="userName">
                Full name
                <input type="text" id='userName' onChange={(e) => changeInput(e.target.value, 'name')}/>
            </label>
            <label htmlFor="email">
                Email
                <input type="email" id='email' onChange={(e) => changeInput(e.target.value, 'email')}/>
            </label>
            <label htmlFor="phone">
                Phone Number
                <input type="text" id='phone' onChange={(e) => changeInput(e.target.value, 'phone_number')}/>
            </label>
            <label htmlFor="pass">
                Password
                <input type="password" id='pass' onChange={(e) => changeInput(e.target.value, 'password')}/>
            </label>
            {error ?
                <p style={{color: 'red', marginTop: 10}}>{error}</p>
                :
                null
            }
            <button className='btn' onClick={signUp}>Sign Up</button>
            <div style={{display: "flex", justifyContent: "center"}}>
                <p style={{marginRight: '.3rem'}}>Don’t have account?</p>
                <button onClick={() => navigate('/sign-in')} style={{color: "#332486"}}>Sign in</button>
            </div>
        </div>
    )
}
