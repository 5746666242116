import {Navigate,  Outlet,} from 'react-router-dom';

export const ProtectedRoute = ({
                                   isAllowed,
                                   redirectPath = '/home',
                                   children,

                               }) => {
    if (!isAllowed) {
        return <Navigate to={redirectPath} replace />;
    }

    return children ? children : <Outlet />;
};