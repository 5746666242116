import React, {useEffect, useState} from "react";
import axiosInstance from "../../api";
import {useLocation, useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";

export function AcceptReject() {
    let customer_id = localStorage.getItem('id')
    let location = useLocation()
    let [messages, setMessages] = useState([])
    let [message, setMessage] = useState('')
    const [proposalInput, setProposalInput] = useState([{
        label: 'Acceptance', placeholder: 'Name', key: 'acceptance', type: 'string', value: ''
    }, {
        label: 'Indemnity', placeholder: 'Name', key: 'indemnity', type: 'number', value: ''
    }, {
        label: 'Injury ', placeholder: 'Name', key: 'injury', value: '', type: 'number'
    }, {
        label: 'Negotiation', placeholder: 'Name', key: 'negotiation', value: '', type: 'string'
    }, {
        label: 'Capacity', placeholder: 'Name', key: 'capacity', value: '', type: 'number'
    }, {
        label: 'Non-compete ', placeholder: 'Name', key: 'non_compete', value: '', type: 'string'
    }, {
        label: 'Confidentiality', placeholder: 'Name', key: 'confidentiality', value: '', type: 'number'
    }, {
        label: 'Notices', placeholder: 'Name', key: 'notices', value: '', type: 'string'
    }, {
        label: 'Offer', placeholder: 'Name', key: 'offer', value: '', type: 'string'
    }, {
        label: 'Due diligence', placeholder: 'Name', key: 'due_diligence', value: '', type: 'string'
    }, {
        label: 'Duration', placeholder: 'Name', key: 'duration', value: '', type: 'string'
    }, {
        label: 'Intention', placeholder: 'Name', key: 'intention', value: '', type: 'string'
    }, {
        label: 'Termination', placeholder: 'Name', key: 'termination', value: '', type: 'string'
    },])
    const [proposalSelect, setProposalSelect] = useState([{
        label: 'Breach of terms',
        value1: 'value1',
        value2: 'value2',
        value3: 'value3',
        value: '',
        key: 'breach_of_terms'
    }, {
        label: 'Consideration', value1: 'value1', value2: 'value2', value3: 'value3', value: '', key: 'consideration'
    }, {
        label: 'Price/ Cost', value1: 1, value2: 2, value3: 3, value: '', key: 'price_cost'
    }, {
        label: 'Terms and Conditions', value1: 1, value2: 2, value3: 3, value: '', key: 'terms_conditions'
    },])
    const [proposalCheckBox] = useState([{
        name: "verifyCertificate", label: 'Insurance', key: 'valuation_type', value: 'verify_certificate'
    }, {
        name: "value", label: 'Valuation', key: 'valuation_type', value: 'verify_certificate'
    }, {
        name: "insure", label: 'Insure', key: 'valuation_type', value: 'verify_certificate'
    }])

    useEffect(() => {
        getProposal()
        messageUpdate()
    }, [])

    let getProposal = async () => {
        try {
            let response = await axiosInstance.get(`api/client/single-proposal/${location.state.item.proposal_id}`)
            setMessages(response.data.data.messages)
            for (let i = 0; i < proposalInput.length; i++) {
                proposalInput[i].value = response.data.data[proposalInput[i].key]
            }
            for (let i = 0; i < proposalSelect.length; i++) {
                proposalSelect[i].value = response.data.data[proposalSelect[i].key]
            }
            setProposalSelect([...proposalSelect])
            setProposalInput([...proposalInput])
        } catch (e) {
            console.log(e);
            getProposalMe()
        }
    }

    let messageUpdate = async () => {
        try {
            await axiosInstance.put(`api/client/update-messages`, {proposal_id: location.state.item.proposal_id, user_id: location.state.item.user_id})
        } catch (e) {
            console.log(e);
            console.log(e.response, 'ergtregh');
        }
    }

    let getProposalMe = async () => {
        try {
            let response = await axiosInstance.get(`api/client/my-proposal/${location.state.item.proposal_id}`)
            setMessages(response.data.data.messages)
             for (let i = 0; i < proposalInput.length; i++) {
                proposalInput[i].value = response.data.data[proposalInput[i].key]
            }
            for (let i = 0; i < proposalSelect.length; i++) {
                proposalSelect[i].value = response.data.data[proposalSelect[i].key]
            }
            setProposalSelect([...proposalSelect])
            setProposalInput([...proposalInput])
        } catch (e) {
            console.log(e);
            console.log(e.response);
        }
    }



    let sendMessage = async () => {
        try {
            if(message.length){
                let data = {
                    proposal_id: location.state.item.proposal_id,
                    message
                }
                await axiosInstance.post('api/client/send-message', data)
                messages.unshift({
                    user_id: customer_id,
                    message: message,
                })
                setMessages([...messages])
                setMessage('')
            }
        }catch (e){
            console.log(e);
        }
    }

    return (<div className='proposal'>
        <h1>Get a Proposal</h1>
        <div className="body">
            {proposalInput.map((item, index) => {
                return (<label key={index}>
                    {item.label}
                    <input type={item.type} disabled={true} placeholder={item.placeholder}
                           value={item.value}/>
                </label>)
            })}
            {proposalSelect.map((item, index) => {
                return (<label key={index}>
                    {item.label}
                    <select name={item.label} value={item.value}>
                        <option disabled selected>{item.label}</option>
                        <option placeholder={item.placeholder}>{item.value1}</option>
                        <option placeholder={item.placeholder}>{item.value2}</option>
                        <option placeholder={item.placeholder}>{item.value3}</option>
                    </select>
                </label>)
            })}
            <div className="checkboxContent">
                {proposalCheckBox.map((item, index) => {
                    return (<label key={index}>
                        <input type="checkbox" name={item.name}/>
                        {item.label}
                    </label>)
                })}
            </div>
            <div className="checkboxContent">
                <label>
                       <textarea id="w3review" name="w3review" rows="4" cols="50" value={message} onChange={e => setMessage(e.target.value)}>
                       </textarea>
                </label>
            </div>
            <div className='btnContent'>
                <button className='btn'>Reject</button>
                <button className='btn'>Accept</button>
                <button className='btn' onClick={sendMessage}>Submit</button>
            </div>
            <div>
                {
                    messages.map((item, index) => {
                        return(
                            <div key={index} className={+customer_id !== +item.user_id ? 'chat_item' : 'chat_item_left' }>
                                <p className={`chat_text`}>{item.message}</p>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    </div>);
}
