import "./style.css";
import React, {useState} from "react";

export default function AcceptedRequest(){
    const [requestInputFields1, setRequestInputFields1] = useState([
        {
            label: 'Due diligence',
            placeholder: 'Name',
            key: 'due_diligence'
        },
        {
            label: 'Duration',
            placeholder: 'Name',
            key: 'duration'
        },
        {
            label: 'Intention',
            placeholder: 'Name',
            key: 'intention'
        },
    ])
    const [requestInputFields2, setRequestInputFields2] = useState([
        {
            label: 'Indemnity',
            placeholder: 'Indemnity',
            key: 'Indemnity'
        },
        {
            label: 'Injury',
            placeholder: 'Injury ',
            key: 'injury '
        },
        {
            label: 'Negotiation',
            placeholder: 'Name',
            key: 'negotiation'
        },
        {
            label: 'Non-compete',
            placeholder: 'Non-compete',
            key: 'non_compete'
        },
        {
            label: 'Notices',
            placeholder: 'Notices',
            key: 'notices'
        },
        {
            label: 'Offer',
            placeholder: 'Name',
            key: 'offer'
        },
        {
            label: 'Price/ Cost',
            placeholder: '$250',
            key: 'due_diligence'
        },
    ])
    return (
        <div className="cancel_request_cont">
            <div className="cancel_request_cont_fields accepted_request_cont_fields">
                <div className="formGroup">
                    <div className="formGroup_item">
                        <label>
                            Category
                            <select name="" id="">
                                <option value="">Category</option>
                            </select>
                        </label>
                    </div>
                    <div className="formGroup_item">
                        <label>
                            Acceptance
                            <input type="text" placeholder="Acceptance"/>
                        </label>
                    </div>
                    <div className="formGroup_item">
                        <label>
                            Breach of terms
                            <select name="" id="">
                                <option value="">Name</option>
                            </select>
                        </label>
                    </div>
                    <div className="formGroup_item">
                        <label>
                            Capacity
                            <input type="text" placeholder="Name"/>
                        </label>
                    </div>
                    <div className="formGroup_item">
                        <label>
                            Confidentiality
                            <input type="text" placeholder="Confidentiality	"/>
                        </label>
                    </div>
                    <div className="formGroup_item">
                        <label>
                            Consideration
                            <select name="" id="">
                                <option value="">Name</option>
                            </select>
                        </label>
                    </div>

                    {
                        requestInputFields1.map((item, index) => {
                            return (
                                <div className="formGroup_item">
                                    <label key={index}>
                                        {item.label}
                                        <input type="text" placeholder={item.placeholder}
                                               onChange={(e) => (e.target.value, item.key)
                                               }/>
                                    </label>
                                </div>
                            )
                        })
                    }
                </div>
                <div className="formGroup">
                    {
                        requestInputFields2.map((item, index) => {
                            return (
                                <div className="formGroup_item">
                                    <label key={index}>
                                        {item.label}
                                        <input type="text" placeholder={item.placeholder}
                                               onChange={(e) => (e.target.value, item.key)
                                               }/>
                                    </label>
                                </div>

                            )
                        })
                    }
                    <div className="formGroup_item">
                        <label>
                            Terms and Conditions
                            <select name="" id="">
                                <option value="">Name</option>
                            </select>
                        </label>
                    </div>
                    <div className="formGroup_item">
                        <label>
                            Termination
                            <input type="text" placeholder="Name"/>
                        </label>
                    </div>
                </div>
            </div>
            <div className="radio_btns_b">
                <label className="ddIpContent_b1_item">
                    <input type="radio" id="verify" value="verify"  name ="radio" />
                    Verify Certificate
                </label>
                <label className="ddIpContent_b1_item">
                    <input type="radio" id="value" value="value"  name ="radio"/>
                    Value
                </label>
                <label className="ddIpContent_b1_item">
                    <input type="radio"  id="insure" value="insure"  name ="radio"/>
                    Insure
                </label>
            </div>
            <div className="cancel_request_btn_b">
                <button className="cancel_request_btn">Reject</button>
                <button className="approve_request_btn">Approve</button>
            </div>
        </div>
    )
}