import { z } from "zod";
import { convertImageFilesToBase64 } from "../../helper/helper";
const ACCEPTED_IMAGE_TYPES = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "image/webp",
];
export const change_schema = z
  .object({
    part_1: z.string().nonempty("Required"),
    part_2: z.string().nonempty("Required"),
    due_diligence: z
      .boolean()
      .refine(
        (val) => {
          return val === true;
        },
        { message: "You must accept the terms" }
      )
      .optional(),
    confidentiality: z
      .boolean()
      .refine(
        (val) => {
          return val === true;
        },
        { message: "You must accept the terms" }
      )
      .optional(),
    chargee_disclosure_acceptance: z
      .boolean()
      .refine(
        (val) => {
          return val === true;
        },
        { message: "You must accept the terms" }
      )
      .optional(),
    ownership: z.string().nonempty("Required"),
    co_ownership: z.string().nonempty("Required"),
    whole_or_part: z.enum(["whole", "part"]),
    // whole_or_part: z.string().nonempty("Required").nullish(),
    specify_which_part: z.string().nonempty("Required"),
    secured_or_unsecured: z.enum(["secured", "unsecured"]),
    secured_amount_figures: z.number(),
    secured_amount_words: z.number(),
    repayment_amount_figures: z.number(),
    repayment_amount_words: z.number(),
    interest_on_amount_figures: z.number(),
    interest_on_amount_words: z.number(),
    chargee_chargee: z.string().nonempty("Required"),
    chargee_name: z.string().nonempty("Required"),
    chargee_details: z.string().nonempty("Required"),
    chargee_bank: z.string().nonempty("Required"),
    chargee_number: z.number(),
    chargee_mpesa: z.string().nonempty("Required"),
    chargee_till_number: z.number(),
    charge_phone_number: z.number(),
    further_charge_amount_figure: z.number(),
    further_charge_amount_words: z.number(),
    rights_obligations_amount: z.number(),
    chargor_assignment: z.boolean(),
    assignment_of_intellectual_namepfip: z.string().nonempty("Required"),
    assignment_of_intellectual_amount: z.string().nonempty("Required"),
    verify_certificate: z.boolean(),
    value: z.boolean(),
    insure: z.boolean(),
  })
  .partial("Required");