const readFileAsBinaryString = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = () => {
      reject(reader.error);
    };
    reader.readAsBinaryString(file);
  });
};

export const readFileAsDataURL = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};
export const convertImageFilesToBase64 = async (files) => {
  const base64Objects = [];
  for (const file of files) {
    const dataURL = await readFileAsDataURL(file);
    // const base64 = dataURL.split(",")[1];
    return dataURL;
    // base64Objects.push({ name: file.name, image: base64 });
  }

  return base64Objects;
};

export const base64ToFile = (base64String, fileName, fileType = "image/png") => {
  const byteString = atob(base64String.split(",")[1]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  const blob = new Blob([ab], { type: fileType });
  return new File([blob], fileName, { type: fileType });
};
