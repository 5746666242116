import "./style.css";

export function Policy() {
    return (
        <div className="policy_cont">
            <div className="policy_cont_b1">
                <div><span>IPD:</span> intellectual property depository</div>
                <span>PRIVACY POLICY</span>
                <a href="//www.ipdepository.com" target="_blank" >www.ipdepository.com</a>
            </div>
            <div className="policy_cont_b2">
                <p>Welcome to the Intellectual Property Depository.
                    Known as IPD
                </p>
            </div>
            <h2>Trademark and Copyright registered:</h2>
            <div className="policy_cont_b3">
                <div className="policy_cont_b3_t1"><span>A.</span>Registered and non registered users who use the
                    services offered by intellectual
                    property depository shall be subject the the following:
                </div>
                <div className="policy_cont_b3_t2"><span>The aim</span> The aim of the intellectual property depository
                    is to maintain transactions
                    pertaining to registered intellectual property that has been uploaded into the system by the
                    grantors/owners of the same. Intellectual property that has not been registered and not uploaded
                    onto the [IP] depository system shall not be subject to these conditions mentioned within our
                    policy.
                    Intellectual property depository shall at all time respect the right to privacy as enshrined in the
                    Constitution 2010 of the Republic of Kenya and buttressed by principles of The Data Protection Act
                    2019 Section 25 A. and other international treaties relating to protection of data.
                </div>

                <ol className="policy_cont_b3_t3">
                    <li>
                        <span> All intellectual property rights</span>
                        pertaining to the registered intellectual property shall at all times
                        remain the property of the registered users.
                    </li>
                    <li>
                        The system <span> shall only be used</span> for storage of certificates, search and
                        verification, commercial transactions regarding [IP] certificates and transactional records. The
                        IPD
                        system is not an is suing authority.
                    </li>
                    <li>
                        The system <span>shall be a mirror image</span> of the intellectual property pertaining to
                        registered
                        intellectual property transactions and ownership.
                    </li>
                </ol>
                <div className="policy_cont_b3_t4">
                    With the above 3 points: It is imperative that users upload factual information pertaining to their
                    certificate, to create an
                    accurate log for future users. This privacy policy describes how and when we collect data and the
                    use of the data and its
                    applicability to achieving the aims of the system named IPD.
                </div>
                <div className="policy_cont_b3_t5">
                    <span>B. Where we collect data:</span>
                    <p>Collection of Data:</p>
                    <p>Registration process:</p>
                    <p>Uploading of certificate:</p>
                    <p>Valuation process:</p>
                    <p>Transaction process:</p>
                    <p>Notices:</p>
                </div>
                <div className="policy_cont_b3_t6">
                    When this policy mentions “we” or “us” it refers to the controller of your information. Irrespective
                    of
                    the country you live in, the registered user shall be able to authorize the system to share
                    information
                    with the relevant 3rd parties listed in this policy.
                </div>
                <div className="policy_cont_b3_t7">
                    How do we collect information:
                    <ol>
                        <li> IPD will only collect information that is relevant to account opening. That is an email
                            address, phone
                            number, p.o box and other relevant information regarding the owner of the account.
                        </li>
                        <li> IPD will only collect information that has been captured on intellectual property
                            certificates.
                            [scan image] for storage.
                        </li>
                        <li> IPD will only collect information regarding the intellectual property that is relevant to
                            the user
                            to create an accurate valuation of the intellectual property at the current time, while the
                            user is
                            filling in the [valuation form].
                        </li>
                        <li> IPD will only at the users request [for valuation] send the valuation form to an insurer
                            for the
                            intention of policy creation.
                        </li>
                    </ol>
                </div>
                <div className="policy_cont_b3_t8">
                    Any personal information that is not necessary to prove ownership of intellectual property shall be
                    deemed to be outside the scope of data needed by the management .
                </div>
                <div className="policy_cont_b3_t8">
                    Note: IPD shall not ask for any sensitive data to be uploaded onto our system.
                    The system is only concerned with matters;
                </div>
                <div className="policy_cont_b3_t9">
                    <p> Brief explanation of system aim:_ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _</p>
                    <p> Ownership, do you own the certificate image the user has uploaded, if so prove it?</p>
                    <div> Add a value record to the intellectual property by requesting a registered valuation from a
                        3rd
                        party specialized in the valuation process. Request for an insurance policy against the
                        intellectual property
                        from a registered insurance agency that is capable of creating the above mentioned insurance
                        policy.
                        Commercialize your Intellectual property by our agreements to Sell&Transfer, License, Charge or
                        Partner agreements that are a core service to our registered users.
                    </div>
                </div>
                <div className="policy_cont_b3_t10">
                    <p>_ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _</p>
                    To achieve the above in a safe and non intrusive manner, intellectual property depository has
                    several
                    processes of verification.
                </div>
                <div className="policy_cont_b3_t11">
                    <span>C. Note:</span> intellectual property depository is not an issuing authority and shall have
                    the issuing
                    authorities verify the information certificate image uploaded by users. For this to happen the users
                    shall consent to the process of verification, by a request for valuation and a request for
                    insurance.
                </div>


                <h2>Requests:</h2>

                <div className="policy_cont_b3_t12">
                    <span>3rd Party Requests:</span>
                    <div> IPD shall partner with the relevant stakeholders to ensure the aims of the system are
                        achieved. For the
                        above to be possible, users automatically consent to the achievement of these aims, and the
                        following
                        relationship with the stated 3rd parties. The system shall allow the 3rd parties to only:
                    </div>
                </div>
                <div className="policy_cont_b3_t13">
                    <ul>
                        <li> Create values from the information sent to them, from the users certificate information and
                            the
                            Valuation 3rd Party shall be able to post, attach a PDF, of other format back into the IPD
                            user file, to
                            signify a valuation report. That shall be binding on the 3rd party system, agents,
                            representatives and
                            employers, be it Limited Liability companies, Corporations, LCC, Partnerships, Business
                            Enterprises and
                            the like.
                        </li>
                        <li> Create insurance policies for the activities or concepts described in and found within the
                            users
                            Intellectual Property. The 3rd party insurance entity shall receive the above stated
                            valuation and
                            report, in order to create a policy for either the activities, concepts purposes, either pre
                            or post
                            incident or injury as regards the intellectual property. The insurance entity will be able
                            to post back
                            a PDF or other format back into the IPD user file to signify and represent an insurance
                            policy that is
                            binding on the representatives, agents and employers of the insurance entity.
                        </li>
                    </ul>
                </div>

                <div className="policy_cont_b3_t14">
                    The request is in the form of a valuation form. The registered owner will appreciate the value of
                    the
                    intellectual property, and this is done to create a clearer picture of the actual value that was put
                    into the creation of the intellectual property, and not its actual current financial value. Hence if
                    able to separate the two, will give an accurate estimate of the value of the [IP].
                </div>
                <div className="policy_cont_b3_t14">
                    <span>  The users shall have the right to request for a further valuation from an external valuer.</span>
                    <div>
                        The system shall package the valuation form in PDF. and external shall be able to log into the
                        IP D
                        system. Valuation portal [ONLY] and create a valuation critique, structure, or accurate value of
                        the
                        said information.The user will now have the option to request for an insurance policy on the
                        said value received from the
                        enhanced external valuation. [ External fees may be payable on the valuation and insurance
                        services, as their third party services,
                        not offered by IPD.]
                    </div>
                </div>

                <div className="policy_cont_b3_t14">
                    <span>List of Third parties.</span>
                </div>
                <div className="policy_cont_b3_t15">
                    <span>  Valuation assessors:</span>
                    will only receive information on the valuation form and proof of ownership from log
                    in details on the subject matter.
                </div>
                <div className="policy_cont_b3_t15">
                    <span> Insurance brokers:</span>
                    will only receive the information from the valuation process, and this will assist in
                    an insurance policy for the subject matter.
                </div>
                <div className="policy_cont_b3_t15">
                    <span> Copyright Board::</span>
                    will verify the certificate information that has been provided on the certificate to
                    ascertain if the information provided by the owner tallys accurately with the information on the
                    copyright database.
                </div>
                <div className="policy_cont_b3_t15">
                    <span> Kenya Industrial Property Institute:</span>
                    will verify the information provided on the certificate, to
                    ascertain if the information on the certificate tallys accurately with the information on the
                    Industrial institute database.
                </div>
                <div className="policy_cont_b3_t14">
                    <span> Note:</span>
                    any other issuing authority that deals with certification shall be included in this list of third
                    parties who will be called upon to verify certificate information.[As the system expands outside the
                    territory of The Republic of Kenya]
                    .The stakeholders involved in the various Jurisdictions, shall be aligned towards achieving the aims
                    of
                    the system. IPD shall be bound by whichever Jurisdictional authorities, and their legal frameworks
                    relating to intellectual property]
                </div>
                <div className="policy_cont_b3_t5">
                    <span>  D. Automated Negotiation Templates:</span>
                </div>
                <div className="policy_cont_b3_t14">
                    Currently four <span>[4]</span> automated Negotiation Templates exist. These are agreement to sell
                    and transfer,
                    agreement to license, agreement to charge and agreement to enter into partnership with the subject
                    matter being the identifiable intellectual property. These agreements outline the structure, the
                    terms
                    and conditions to be followed, culminating, during and after the contractual period between
                    registered
                    users. [pre, during and post] contractual objectives. The information collected by these agreements
                    are
                    privy, between the parties and the administration of IPD.
                </div>
                <div className="policy_cont_b3_t14">
                    The agreements are capable of being amended between parties, to meet their contractual obligations
                    between them, and IPD shall not reveal these contracts, unless the following occurs.
                </div>
                <div className="policy_cont_b3_t13">
                    <ul>
                        <li> An illegality is about to be committed using our templates.</li>
                        <li> An illegality has been committed by the parties to the contract.</li>
                        <li> A court order has been issued to reveal the information in the contract.</li>
                        <li>The contract is evidence in an on-going investigation or court process.</li>
                    </ul>
                </div>

                <div>
                    The information in the agreements if used for legal purposes and do not infringe other intellectual
                    property rights, or other legal rights of individuals privy to the agreement shall always remain
                    information held in confidence between the contracting parties.
                </div>
                <div className="policy_cont_b3_t13">
                    <div className="policy_cont_b3_t15">
                        <span> Note: </span>
                        the above privy/confidentiality can be waived, if illegal activities are commissioned by users
                        on
                        the platform.
                    </div>
                    <div className="policy_cont_b3_t15">
                        <span> Note: </span>
                        the management of IPD has the authority to delete, report to the authorities, any members,
                        profiles, activities and certificates, that are intended to mislead, defraud, or engage in
                        illegal
                        activities.
                    </div>
                    <div className="policy_cont_b3_t15">
                        <span> Note: </span>
                        All contracts created on our platform that are dubbed, commingled, or shrouded in an illegality
                        or
                        are intended to defraud, willfully misrepresent facts are VOID. and parties to the agreement are
                        not
                        able to enforce the obligations and conditions created. And the management of IPD may institute
                        legal
                        proceedings against any registered member who violates the above herein.
                    </div>
                    <div className="policy_cont_b3_t15">
                        <span> Note: </span>
                        Performance of obligations negotiated between parties shall be at no risk to the management and
                        the management shall not be responsible for any negligent , harmful, injurious activity or non
                        performance of contractual obligations.
                    </div>
                </div>

                <div className="policy_cont_b3_t5">
                    <span> B. Expiry of data:</span>
                    <div>IPD shall keep user profiles active until the users themselves deactivate the said accounts
                        holding
                        their intellectual property information.
                        The public ledger shall be available for administrative purposes and deregistered or deactivated
                        accounts shall not be able to access this information, but may be able to search history of
                        transactions
                        relating to intellectual property once deactivated. They shall not be able to delete the
                        transaction
                        record of the intellectual property.
                    </div>
                </div>
                <div className="policy_cont_b3_t5">
                    <span> C. Intellectual property held in trust.</span>
                    <div> There may exist situations where a minor is the creator of the intellectual property and a
                        guardian has
                        uploaded the intellectual property onto the intellectual property depository. The guardian /
                        trustee
                        should alway be aware of the fiduciary duty of care to the intellectual property and its owner
                        [minor]
                        and act in good faith and be able to account for profits. IPD shall not be involved in trustee
                        relationships created by the parties described in this paragraph, IPD shall be limited to the
                        scope of
                        its business operations in its aims.
                        [Unless otherwise provided for by our customers and clients. Intellectual Property Trusts may be
                        created
                        by our inhouse practitioners, and agreements towards the management and use of profiles agreed
                        to].
                    </div>
                </div>
                <div className="policy_cont_b3_t5">
                    <span> D. Notice.</span>
                    <div> The registered users are hereby notified that the data collected to be able to satisfy the
                        services
                        rendered by IPD. If any further information is needed , users shall be notified of the request
                        for
                        further information.
                    </div>
                </div>
                <div className="policy_cont_b3_t5">
                    <span>Transactional notices:</span>
                    <div> Shall be made public to the registered users and shall be used as criteria for search results,
                        by
                        registered and non registered users.
                        A notice that a Sale, License, Charge or Partnership shall be made public to users to inform
                        them of the
                        intellectual property status.
                    </div>
                </div>
                <div className="policy_cont_b3_t5">
                    <span> E. Purpose.</span>
                    <div> The data collected shall only be used for the purpose stated in the aims of the system, which
                        is to
                        create an intellectual property marketplace, and accurately record transactions that involve
                        registered
                        intellectual property, between users.
                        As technological advancements are made, and laws amended, if needed we shall advise if further
                        information may be tenable.
                    </div>
                </div>
                <div className="policy_cont_b3_t5">
                    <span> F. Consent.</span>
                    <div> Your data shall not be disclosed without user consent, as shown by the request to value and
                        request to
                        insure the intellectual property, that has to be prompted by the registered user and not the
                        system
                        administrators.
                        [You registered users, shall always prompt the sharing of your information for the purposes of
                        fulfilling the aims of the system]. Such as the request to value, verify and insure the
                        Intellectual
                        Property certificates and related concepts entailed in the use, commercialization and purpose of
                        the
                        system.
                    </div>
                </div>
                <div className="policy_cont_b3_t5">
                    <span> G. Disclosure.</span>
                    <div> Your data shall, with your consent above, be disclosed to the authorities that issued the
                        certificates ,
                        and to the valuation and insurance entities, applicable to ensure an accurate valuation and
                        insurance
                        policy are issued.
                    </div>
                </div>
                <div className="policy_cont_b3_t5">
                    <span> H. Access.</span>
                    <div> Registered users shall at all times have access to their data, by logging into their accounts
                        and using
                        the relevant links, their data is there for the user to amend accordingly.
                    </div>
                </div>
                <div className="policy_cont_b3_t5">
                    Users are free to disengage from the services of IPD at their will and if in violation of the rules,
                    regulations, laws and policies put together to create the intellectual property depository
                    community.
                    The user must remember that transactional records are important not only to users online, but the
                    general public. The register must not mislead users, hence we urge users to be honest and have a
                    duty of
                    care to current and future users, when they upload information or use these services.
                </div>
                <div className="policy_cont_b3_t5">
                    <p><span> I. QR code: </span> QUICK RESPONSE CODES:</p>
                    <div> Registered users, who have paid administrative fees levied by the system, shall have use of
                        IPD QRcodes
                        that are central to the achievement of the system's aims. These QR codes shall be system
                        designed and
                        tagged, branded and originated from our system. We the management of IPD have the right to
                        deactivate QR
                        codes that are in violation of our terms and conditions and community guidelines.
                    </div>
                </div>
                <div className="policy_cont_b3_t16">
                    <span>IPD: </span>
                    management has the right to amend these policy guidelines that are aimed at keeping an accurate
                    record at any time without notice to the users.
                    I hereby state that I have read the above user policy and understand the information pertained in
                    it.
                </div>

                <div>_ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ </div>

                <div className="policy_cont_b3_t5">
                    <div style={{fontWeight: "600"}}>I agree:</div>
                    <div> Y/</div>
                    <div> N [return to main page of website]</div>
                    <div>_ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _</div>
                </div>

                <div>
                    <p> Privacy & Policy Guidelines for:</p>
                    <p> www.ipdepository.com</p>
                    <span>_ _ _ _ _</span>
                </div>

                <div className="policy_cont_b3_t17">
                    <span>IPDEPOSITORY LIMITED:</span>
                    <span> P.O Box 45707-00100</span>
                    <span>  Nairobi</span>
                    <span>  Kenya.</span>
                    <span>  *data handling certification registration and other relevant certifications shall be added into this
                documentation as needed by relevant authorities.</span>
                </div>

            </div>
        </div>
    )
}