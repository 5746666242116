import React, {useState} from "react";
import {logo} from "../../../assets/images";
import {useNavigate} from "react-router-dom";
import {Loading} from "../../../components";
import User from "./User";
import ThirdParty from "./Third party";

export function SignUp(props) {
    let navigate = useNavigate()
    let [loading, setLoading] = useState(false)
    let [showUser, setShowUser] = useState(true);
    let [showThirty, setShowThirty] = useState(false);

    let tabToggler = (active) => {
        if (active === "user") {
            setShowUser(true);
            setShowThirty(false);
        }
        if (active === "Third Party") {
            setShowThirty(true);
            if (active === "Third Party") {
                setShowThirty(true);
                setShowUser(false);
            }
        }
    }

    return (
        <div className='signBanner'>
            <img src={logo} alt="" title="" className='logo'/>
            <div className={'div'}>
                <div className="d_flex tabs_block">
                    <button  className={showUser ? "tab_active": "tab"}
                            onClick={()=>tabToggler("user")}
                            >User
                    </button>
                    <button  className={showThirty ? "tab_active": "tab"}
                            onClick={()=>tabToggler("Third Party")}>
                        Third Party
                    </button>
                </div>
                <h1>Create Account</h1>
                <div id="user" className={showUser ? "showTab": "hideTab"}>
                    <User loading={loading} setLoading={setLoading} handleLogin={props.handleLogin}/>
                </div>
                <div id="Third Party" className={showThirty ? "dhowTab" : "hideTab"}>
                    <ThirdParty loading={loading} setLoading={setLoading}/>
                </div>
            </div>
            <Loading modalIsOpen={loading} setLoading={setLoading}/>
        </div>
    );
}
