import {Swiper, SwiperSlide} from "swiper/react";
import {A11y, Autoplay, Pagination} from "swiper";
import {slide1BC, slide2BC, slide3BC} from "../../assets/images";
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import "../../../src/scss/pages/search/index.scss.css"
import {GetStartedBanner} from "../../components/getStartedBanner";

export const Search = (props) => {
    let navigate = useNavigate()
    let [onChange, setOnChange] = useState(false)
    return (
        <>
            {props.searchText ?
                <div className="search_result_cont">
                    <div className="search_result_cont_header">
                        <p className="search_text">Search Result for {props.searchText}</p>
                    </div>
                    <div className="search_result_block">
                        {/*{*/}
                        {/*    props.searchText ?*/}
                        {/*        <div className="no_result">Result</div>*/}
                        {/*        :*/}
                                <p className="no_result">No result found for “{props.searchText}”. </p>

                        {/*}*/}

                    </div>
                </div>
                :
                <GetStartedBanner/>
            }
        </>

    )
}