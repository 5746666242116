import React, {useState} from "react";
import * as _ from "lodash";
import axiosInstance from "../../api";
import {useLocation, useNavigate} from "react-router-dom";

export function GetProposal() {
    let location = useLocation()
    let navigation = useNavigate()
    let [data, setData] = useState({agreement_id: location.state.id})
    let [error, setError] = useState('')
    const [proposalInput] = useState([
        {
            label: 'Acceptance',
            placeholder: 'Name',
            key: 'acceptance',
            type: 'string'
        },
        {
            label: 'Indemnity',
            placeholder: 'Name',
            key: 'indemnity',
            type: 'number'
        },
        {
            label: 'Injury ',
            placeholder: 'Name',
            key: 'injury',
            type: 'number'
        },
        {
            label: 'Negotiation',
            placeholder: 'Name',
            key: 'negotiation',
            type: 'string'
        },
        {
            label: 'Capacity',
            placeholder: 'Name',
            key: 'capacity',
            type: 'number'
        },
        {
            label: 'Non-compete ',
            placeholder: 'Name',
            key: 'non_compete',
            type: 'string'
        },
        {
            label: 'Confidentiality',
            placeholder: 'Name',
            key: 'confidentiality',
            type: 'number'
        },
        {
            label: 'Notices',
            placeholder: 'Name',
            key: 'notices',
            type: 'string'
        },
        {
            label: 'Offer',
            placeholder: 'Name',
            key: 'offer',
            type: 'string'
        },
        {
            label: 'Due diligence',
            placeholder: 'Name',
            key: 'due_diligence',
            type: 'string'
        },
        {
            label: 'Duration',
            placeholder: 'Name',
            key: 'duration',
            type: 'string'
        },
        {
            label: 'Intention',
            placeholder: 'Name',
            key: 'intention',
            type: 'string'
        },
        {
            label: 'Termination',
            placeholder: 'Name',
            key: 'termination',
            type: 'string'
        },
    ])
    const [proposalSelect] = useState([
        {
            label: 'Breach of terms',
            value1: 'value1',
            value2: 'value2',
            value3: 'value3',
            key: 'breach_of_terms'
        },
        {
            label: 'Consideration',
            value1: 'value1',
            value2: 'value2',
            value3: 'value3',
            key: 'consideration'
        },
        {
            label: 'Price/ Cost',
            value1: 1,
            value2: 2,
            value3: 3,
            key: 'price_cost'
        },
        {
            label: 'Terms and Conditions',
            value1: 1,
            value2: 2,
            value3: 3,
            key: 'terms_conditions'
        },
    ])
    const [proposalCheckBox] = useState([
        {
            name: "verifyCertificate",
            label: 'Insurance Certificate',
            key: 'valuation_type',
            value: 'verify_certificate'
        },
        {
            name: "value",
            label: 'Value',
            key: 'valuation_type',
            value: 'verify_certificate'
        },
        {
            name: "insure",
            label: 'Insure',
            key: 'valuation_type',
            value: 'verify_certificate'
        }
    ])

    let changeInput = async (e, name) => {
        setData({..._.assignIn(data, {[name]: e})})
        setError('')
    }

    let submit = async () => {
        try {
            if(Object.keys(data).length >= 17){
                let formData = new FormData()
                formData.append('agreement_id', location.state.id)
                formData.append('acceptance', data.acceptance)
                formData.append('breach_of_terms', data.breach_of_terms)
                formData.append('capacity', data.capacity)
                formData.append('confidentiality', data.confidentiality)
                formData.append('indemnity', data.indemnity)
                formData.append('consideration', data.consideration)
                formData.append('due_diligence', data.due_diligence)
                formData.append('duration', data.duration)
                formData.append('injury', data.injury)
                formData.append('intention', data.intention)
                formData.append('negotiation', data.negotiation)
                formData.append('non_compete', data.non_compete)
                formData.append('notices', data.notices)
                formData.append('offer', data.offer)
                formData.append('price_cost', data.price_cost)
                formData.append('termination', data.termination)
                formData.append('terms_conditions', data.terms_conditions)
                formData.append('valuation_type', data.valuation_type)

                let response = await axiosInstance.post('api/client/create-proposal', formData)
                navigation('/')
            }else{
                setError('Please fill in all required fields')
            }
        }catch (e){
            console.log(e, 'e');
            console.log(e.response, 'e.response');
        }
    }

    return (
        <div className='proposal'>
            <h1>Get a Proposal</h1>
            <div className="body">
                {
                    proposalInput.map((item, index) => {
                        return (
                            <label key={index}>
                                {item.label}
                                <input type={item.type} placeholder={item.placeholder} onChange={e => changeInput(e.target.value, item.key)}/>
                            </label>
                        )
                    })
                }
                {
                    proposalSelect.map((item, index) => {
                        return (
                            <label key={index}>
                                {item.label}
                                <select name={item.label}  onChange={(e) => changeInput(e.target.value, item.key)}>
                                    <option disabled selected>{item.label}</option>
                                    <option  placeholder={item.placeholder}>{item.value1}</option>
                                    <option  placeholder={item.placeholder}>{item.value2}</option>
                                    <option  placeholder={item.placeholder}>{item.value3}</option>
                                </select>
                            </label>
                        )
                    })
                }
                <div className="checkboxContent">
                    {
                        proposalCheckBox.map((item, index) => {
                            return (
                                <label key={index}>
                                    <input type="checkbox" name={item.name} onChange={(e) => changeInput(item.value, item.key)}/>
                                    {item.label}
                                </label>
                            )
                        })
                    }
                </div>
                <div className={'error_div'}>
                    {error ? <p className={'error_text'}>{error}</p> : null}
                </div>
                <div className='btnContent'>
                    <button className='btn' onClick={submit}>Submit</button>
                </div>
            </div>
        </div>
    );
}
