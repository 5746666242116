import certificate_pic from "../../assets/images/certificate_pic.png";
import {Loading} from "../../components";
import React, {useEffect, useRef, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import axiosInstance from "../../api";



export function CertificatesView(props){
    let navigate=useNavigate()
    const inputFile = useRef(null)
    let {id} = useParams();
    const[loading, setLoading] = useState(false)
    let {pathname} = useLocation()
    let [file, setFile] = useState(null)
    let [photo, setPhoto] = useState('')
    let [agreements, setAgreements] = useState({})
   
    let [type, setType] = useState('')
   
    const arrPath = pathname.split('/')
    const path = arrPath[arrPath.length - 2]

    useEffect(() => {
        getAgreement()
    }, [])

    let getAgreement = async () => {
        try {

            let response = await axiosInstance.get(`/api/client/single-agreement/${id}`)
            setAgreements(response.data.data);
            setPhoto(response.data.data.photo);
        } catch (e) {
            console.log(e, 'ertfg');
            console.log(e.response, 'erg');
        }
    }
    let changeInput = (e, name) => {
        setAgreements({...agreements, [name]: e})
    }
   
    let update = async () => {
        try {
            setLoading(true)
            let formData = new FormData()
            if(file){
                formData.append('photo', file)
            }
            formData.append('publish', agreements.publish)
            formData.append('type', path)
            formData.append('category_id', agreements.id)
            formData.append('certificate_name', agreements.certificate_name)
            formData.append('certificate_number', agreements.certificate_number)
            formData.append('proprietor_name', agreements.proprietor_name)
            formData.append('name_of_concept', agreements.name_of_concept)
            formData.append('date_of_issue', agreements.date_of_issue)
            formData.append('country_issued', agreements.country_issued)
            formData.append('certificate_description', agreements.certificate_description)
            await axiosInstance.post( `api/client/update-agreement/${agreements.id}`, formData)
            setLoading(false)
            navigate("/login")
            console.log("fdfdf")
        }catch (e){
            setLoading(false)
        }
    }
    const onButtonClick = () => {
        inputFile.current.click();
    };

    const handleFileSelected = (e) => {
        const files = Array.from(e.target.files)
        setFile(files[0])
        const objectUrl = URL.createObjectURL(files[0])
        setPhoto(objectUrl)
    }

 

    return (
      <div className="certeficate">
        <div className="body">
          <div>
            <img
              src={photo ? photo : certificate_pic}
              className={"photoContent"}
              onClick={onButtonClick}
              alt="certificate"
            />
            <input
              type="file"
              id="file"
              ref={inputFile}
              style={{ display: "none" }}
              onChange={handleFileSelected}
            />
          </div>
          <div>
            <div className="certificate_items_block certificate_view_item_block">
              <div className="certificate_item">
                <label htmlFor="">Certificate Name</label>

                <input value={agreements.certificate_name} disabled />
              </div>
              <div className="certificate_item">
                <label htmlFor="">Certificate Number</label>
                <input value={agreements.certificate_number} disabled />
                <p></p>
              </div>
              <div className="certificate_item">
                <label htmlFor="">Proprietor name</label>
                <input value={agreements.proprietor_name} disabled />
              </div>
              <div className="certificate_item">
                <label htmlFor="">Name of concept</label>
                <input value={agreements.name_of_concept} disabled />
              </div>
              <div className="certificate_item">
                <label htmlFor="">Date of Issue</label>
                <input value={agreements.date_of_issue} disabled />
              </div>
              <div className="certificate_item">
                <label htmlFor="">Country Issued</label>
                <input value={agreements.country_issued} disabled />
              </div>
              <div className="certificate_item certificate_item_desc">
                <label htmlFor="" className="" style={{ fontWeight: "700" }}>
                  Certificate Description #
                </label>
              </div>
              <div className="settings certificate_desc_settings">
                <label className="settings_item" htmlFor="sell">
                  <input
                    type="radio"
                    id="sell"
                    value="Sell"
                    name="radio"
                    checked={type=== "sell"}
                    onClick={(e) => {
                      setType("sell");
                      changeInput("type", "sell");
                    }}
                  />
                  Sell
                </label>
                <label className="settings_item" htmlFor="license">
                  <input
                    type="radio"
                    id="license"
                    value="License"
                    name="radio"
                    checked={type === "license"}
                    onClick={(e) => {
                      setType("license");
                      changeInput("type", "license");
                    }}
                  />
                  License
                </label>
                <label className="settings_item" htmlFor="charge">
                  <input
                    type="radio"
                    id="charge"
                    value="charge"
                    name="radio"
                    checked={type === "charge"}
                    onClick={(e) => {
                        
                      setType("charge");
                      changeInput("type", "charge");
                    }}
                  />
                  Charge
                </label>
                <label className="settings_item" htmlFor="partner">
                  <input
                    type="radio"
                    id="partner"
                    value="Partner"
                    name="radio"
                    checked={type === "partner"}
                    onClick={(e) => {
                      setType("partner");
                      changeInput("type", "partner");
                    }}
                  />
                  Partner
                </label>
              </div>
              <div className="apply_b">
                <button
                  className="save_btn"
                  onClick={() => {
                    let token = localStorage.getItem("token");
                    if (!token) {
                      navigate("/sign-in", {
                        state: { id, type, path: arrPath[arrPath.length - 2] },
                      });
                    }
                  }}
                  style={{ marginLeft: "0px" }}
                >
                  Apply
                </button>
              </div>
              <div className="qrContent qr_content">
                <img
                  src={agreements.photo}
                  alt=""
                  title=""
                  className="QR_code_img"
                />
                <p className="text_center">Certificate QR code</p>
              </div>
            </div>
          </div>
        </div>
        <Loading modalIsOpen={loading} />
      </div>
    );
}
