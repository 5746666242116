import {agreementReady, deposit, oneStopMarket, share, transact, transactionTrack, whatWeDo} from "../../assets/images";
import React from "react";
import {useNavigate} from "react-router-dom";

export function About() {
    let navigation = useNavigate()
    return (
        <div className="home">
            <div className="whatWeDo" id={'test'}>

                <h3>WHAT <span>WE</span> DO</h3>
                <div className='content'>
                    <img src={whatWeDo} alt="" title=''/>
                    <div className="aside">
                        <div>
                            <h4 style={{marginBottom: "0px"}}>You Are In Control Of Your <span>[IP]</span></h4>
                            <p>
                                We are an intellectual property certificate storage and transfer community, enabling
                                verification, valuation and insurance of certificates and concepts.
                            </p>
                            <p className="margin_top_24"> We highly recommend the registration of ideas, via your local
                                intellectual property
                                authority.</p>
                            <p className="margin_top_24">As policy the uploading of false certificate information is forbidden and at
                                verification
                                stage during any transaction, any certificate found to be in violation of the above will
                                be
                                removed from the network, at users cost to system or transaction.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="whyChooseIPD">
                <h3>WHY CHOOSE <span>IPD</span></h3>
                <div className="content ">
                    <div className="item">
                        <img src={oneStopMarket} alt="" title=''/>
                        <h5>Deposit Services</h5>
                        <p>
                            We provide a secure platform for depositing copies of your intellectual property certificates.
                            Our system ensures the confidentiality and integrity of your certificates.
                            By depositing your certificates with us, you can easily prove ownership and protect your intellectual property rights.
                        </p>
                    </div>
                    <div className="item">
                        <img src={agreementReady} alt="" title=''/>
                        <h5>Sharing Services</h5>
                        <p>
                            We offer a convenient way to share your intellectual property certificates with others.
                            Our platform allows you to control who has access to your certificates.
                            You can share your certificates with potential buyers, licensees, or partners to explore business opportunities.
                        </p>
                    </div>
                    <div className="item">
                        <img src={transactionTrack} alt="" title=''/>
                        <h5>Transaction Services</h5>
                        <p>
                            We facilitate various types of transactions related to your intellectual property certificates.
                            You can sell, licence, charge or partner your certificates to interested parties through our platform.
                        </p>
                    </div>
                </div>
            </div>

            <div className="howItWorks">
                <h3>HOW IT <span>WORKS</span></h3>
                <div className="content">
                    <div className="item">
                        <div className="body">
                            <div>
                                <img src={deposit} alt="" title=''/>
                            </div>
                            <p>deposit</p>
                        </div>
                    </div>
                    <div className="item">
                        <div className="body">
                            <div>
                                <img src={share} alt="" title=''/>
                            </div>
                            <p>share</p>
                        </div>
                    </div>
                    <div className="item">
                        <div className="body">
                            <div>
                                <img src={transact} alt="" title=''/>
                            </div>
                            <p>transact</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}