import React, {useState} from "react";

export function AgreementProposal(props) {
    const [proposalInput, setProposalInput] = useState([
        {
            label: 'Acceptance',
            placeholder: 'Name',
        },
        {
            label: 'Injury ',
            placeholder: 'Name',
        },
        {
            label: 'Breach of terms',
            placeholder: 'Name',
        },
        {
            label: 'Negotiation',
            placeholder: 'Name',
        },
        {
            label: 'Capacity',
            placeholder: 'Name',
        },
        {
            label: 'Non-compete ',
            placeholder: 'Name',
        },
        {
            label: 'Confidentiality',
            placeholder: 'Name',
        },
        {
            label: 'Notices',
            placeholder: 'Name',
        },
        {
            label: 'Consideration',
            placeholder: 'Name',
        },
        {
            label: 'Offer',
            placeholder: 'Name',
        },
        {
            label: 'Due diligence',
            placeholder: 'Name',
        },
        {
            label: 'Price/ Cost',
            placeholder: 'Name',
        },
        {
            label: 'Duration',
            placeholder: 'Name',
        },
        {
            label: 'Terms and Conditions',
            placeholder: 'Name',
        },
        {
            label: 'Intention',
            placeholder: 'Name',
        },
        {
            label: 'Termination',
            placeholder: 'Name',
        },
        {
            label: 'Indemnity',
            placeholder: 'Name',
        },
    ])
    const [proposalCheckBox, setProposalCheckBox] = useState([
        {
            name: "verifyCertificate",
            label: 'Insurance Certificate'
        },
        {
            name: "value",
            label: 'Value'
        },
        {
            name: "insure",
            label: 'Insure'
        }
    ])

    return (
        <div className='proposal'>
            <h1>Agreement Proposal</h1>
            <div className="body">
                {
                    proposalInput.map((item, index) => {
                        return (
                            <label key={index}>
                                {item.label}
                                <input type="text" placeholder={item.placeholder}/>
                            </label>
                        )
                    })
                }
                <div className="checkboxContent">
                    {
                        proposalCheckBox.map((item, index) => {
                            return (
                                <label key={index}>
                                    <input type="checkbox" name={item.name}/>
                                    {item.label}
                                </label>
                            )
                        })
                    }
                </div>
                <div className='btnContent'>
                    <button className='btn light'>Reject</button>
                    <button className='btn'>Accept</button>
                </div>
            </div>
        </div>
    );
}
