import {logo} from "../../../assets/images";
import {Loading} from "../../../components";
import React from "react";
import {useNavigate} from "react-router-dom";

export default function BeforeGetStarted() {
    let navigate = useNavigate()
    return (
        <div className='beforeSigncont'>
            <img src={logo} alt="" title="" className='logo'/>
            <div className={'div'}>
                <h1>Welcome to IPD </h1>
                <h2>[Intellectual Property Depository]</h2>
                <div>
                    Remember to register your concepts at your local intellectual property authority, The system should
                    only accept deposits of certificates to registered intellectual property. This protects yourself and
                    eventual users of your concepts from infringement and liability in the future.
                </div>
                <div>
                    We also encourage you to use our platform to Verify your certificates, from your local intellectual
                    property authority. Value your concepts from a reputable valuation organization and Insure your
                    concepts or agreements entered into within the IPD network
                </div>
                <div>
                    Thank you for being part of this journey towards controlled commercialisation of your intellectual property.
                </div>
                <div>
                    “deposit that idea”
                </div>
                <button className='btn' onClick={() => navigate('/sign-in')}>OK</button>
            </div>
        </div>
    )
}