import {Swiper, SwiperSlide} from "swiper/react";
import {A11y, Autoplay, Pagination} from "swiper";
import {slide1BC, slide2BC, slide3BC} from "../assets/images";
import React from "react";
import {useNavigate} from "react-router-dom";

export function GetStartedBanner() {
    let navigation = useNavigate()
    let navigate = useNavigate()
    let token = localStorage.getItem('token');
    return (
        <div className="getStartedBanner">
            <Swiper
                modules={[Pagination, A11y, Autoplay]}
                slidesPerView={1}
                pagination={{clickable: true}}
                onSwiper={(swiper) => console.log(swiper)}
                onSlideChange={() => console.log('slide change')}

                // autoplay={{
                //     delay: 2500,
                //     disableOnInteraction: false,
                // }}
            >
                <SwiperSlide>
                    <img src={slide1BC} alt="" title=''/>
                    <div className='body'>
                        <h2>
                            Assisting you commercialize your intellectual property.
                        </h2>
                        { token ?
                            null
                            :
                            <button onClick={()=>navigate("/sign-in")}>Get Started
                            </button>
                        }

                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <img src={slide2BC} alt="" title=''/>
                    <div className='body'>
                        <h2>
                            Search for real time certificate information.
                        </h2>
                        { token ?
                            null
                            :
                            <button onClick={()=>navigate("/sign-in")}>Get Started
                            </button>
                        }
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <img src={slide3BC} alt="" title=''/>
                    <div className='body'>
                        <h2>
                            Real time negotiation, notification & termination of contractual obligations.
                        </h2>
                        { token ?
                            null
                            :
                            <button onClick={()=>navigate("/sign-in")}>Get Started
                            </button>
                        }
                    </div>
                </SwiperSlide>
            </Swiper>
        </div>
    )
}