import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import {
  Home,
  Coprights,
  SignIn,
  SignUp,
  GetProposal,
  Agreements,
  Certeficate,
  Requests,
  AddIp,
  Notification,
  Subscription,
  Billing,
  Profile,
  Password,
  AgreementProposal,
  AcceptReject,
  NotificationProposal,
  ForgotPass,
  ChangePass,
  AgreementsList,
} from "../pages";
import {
  ProtectedRoute,
  SettingsHeader,
  AuthorizedHeader,
} from "../components";
import { Header, Footer } from "../layouts";
import Messages from "../pages/authorized/messages";
import Proposals from "../pages/authorized/proposals";
import Pending from "../pages/authorized/proposals/contents/Pending";
import Accepted from "../pages/authorized/proposals/contents/Accepted";
import Registered from "../pages/authorized/proposals/contents/Registered";
import Rejected from "../pages/authorized/proposals/contents/Rejected";
import CancelRequest from "../pages/authorized/cancel_request/CancelRequest";
import AcceptedRequest from "../pages/authorized/accepted_request/AcceptedRequest";
import RegisteredRequest from "../pages/authorized/registered_request/RegisteredRequest";
import RejectedRequest from "../pages/authorized/rejected_request/RejectedRequest";
import New from "../pages/authorized/proposals/contents/Pending";
import { Search } from "../pages/search/search";
import { Policy } from "../pages/policy/policy";
import { About } from "../pages/about/about";
import { VerifiedCertificates } from "../pages/authorized/verified_certificates";
import { CertificatesView } from "../pages/certificatesView";
import { VerifiedCertificatesView } from "../pages/authorized/verified_certificates_view";
import { CertificatesViewApplyCharge } from "../pages/certificated_view_apply_charge";
import { CertificatesViewApplyLicense } from "../pages/certificated_view_apply_license";
import { CertificatesViewApplyPartner } from "../pages/certificated_view_apply_partner";
import { CertificatesViewApplySell } from "../pages/certificated_view_apply_sell";
import AgreementToSell2 from "../pages/webPagesAfterFillingInfo/agreement_to_sell_intelectual_property/AgreementToSell2";
import AgreementToSell from "../pages/webPagesAfterFillingInfo/agreement_to_sell_intelectual_property/AgrementToSell";
import AgreementToSell3 from "../pages/webPagesAfterFillingInfo/agreement_to_sell_intelectual_property/AgreementToSell3";
import SelectSubscription from "../pages/auth/signUp/SelectSubscription";
import AgreementToLicense from "../pages/webPagesAfterFillingInfo/agreement_to_license_intellectual_property/AgrementToLicense";
import AgreementToLicense2 from "../pages/webPagesAfterFillingInfo/agreement_to_license_intellectual_property/AgrementToLicense2";
import AgreementToCharge from "../pages/webPagesAfterFillingInfo/agreement_to_charge_intelectual_property/AgreementToCharge";
import AgreementToCharge2 from "../pages/webPagesAfterFillingInfo/agreement_to_charge_intelectual_property/AgreementToCharge2";
import AgreementToPartner from "../pages/webPagesAfterFillingInfo/agreement_to_partner_intelectual_property/AgreementToPartner";
import AgreementToPartner2 from "../pages/webPagesAfterFillingInfo/agreement_to_partner_intelectual_property/AgreementToPartner2";
import BeforeGetStarted from "../pages/auth/beforeGetStarted/BeforeGetStarted";

export function RoutePage(props) {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [searchText, setSearchText] = useState("");
  const [hide, setHide] = useState(false);
  const location = useLocation();
  console.log(location, "location");

  useEffect(() => {
    hideHeaderFooter();
  }, [location]);

  const handleLogin = (data) => setToken(data);
  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("id");
    setToken(null);
  };

  let hideHeaderFooter = () => {
    switch (location.pathname) {
      case "/agreement_to_sell":
        setHide(true);
        break;
      case "/agreement_to_sell_2":
        setHide(true);
        break;
      case "/agreement_to_license":
        setHide(true);
        break;
      case "/agreement_to_license_2":
        setHide(true);
        break;
      case "/agreement_to_charge":
        setHide(true);
        break;
      case "/agreement_to_charge_2":
        setHide(true);
        break;
      case "/agreement_to_partner":
        setHide(true);
        break;
      case "/agreement_to_partner_2":
        setHide(true);
        break;
      default:
        setHide(false);
    }
  };

  return (
    <>
      {hide ? null : (
        <Header
          handleLogout={handleLogout}
          token={token}
          setSearchText={setSearchText}
        />
      )}
      <section>
        <Routes>
          <Route path="/agreement_to_sell" element={<AgreementToSell />} />
          <Route path="/agreement_to_sell_2" element={<AgreementToSell2 />} />
          <Route
            path="/agreement_to_license"
            element={<AgreementToLicense />}
          />
          <Route
            path="/agreement_to_license_2"
            element={<AgreementToLicense2 />}
          />
          <Route path="/agreement_to_charge" element={<AgreementToCharge />} />
          <Route
            path="/agreement_to_charge_2"
            element={<AgreementToCharge2 />}
          />
          <Route
            path="/agreement_to_partner"
            element={<AgreementToPartner />}
          />
          <Route
            path="/agreement_to_partner_2"
            element={<AgreementToPartner2 />}
          />
          <Route path="/search" element={<Search searchText={searchText} />} />
          <Route index element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route
            path="/certificates/:path/:id"
            element={<CertificatesView />}
          />
          <Route
            path="/certificates/sell/:path/:id"
            element={<CertificatesViewApplySell />}
          />
          <Route
            path="/certificates/license/:path/:id"
            element={<CertificatesViewApplyLicense />}
          />
          <Route
            path="/certificates/charge/:path/:id"
            element={<CertificatesViewApplyCharge />}
          />
          <Route
            path="/certificates/partner/:path/:id"
            element={<CertificatesViewApplyPartner />}
          />
          <Route
            path="/notification-proposal"
            element={<NotificationProposal />}
          />
          <Route path="/" element={<AuthorizedHeader />}>
            <Route element={<ProtectedRoute isAllowed={token} />}>
              <Route path="/agreements/" element={<Agreements />} />
              <Route path="/agreements/:path/:id" element={<Certeficate />} />
              <Route
                path="/verified_certificates"
                element={<VerifiedCertificates />}
              />
              <Route
                path="/verified_certificates/:path/:id"
                element={<VerifiedCertificatesView />}
              />
              <Route path="/requests" element={<Requests />}>
                <Route path="new" element={<New />} />
                <Route index path="accepted" element={<Accepted />} />
                <Route path="registered" element={<Registered />} />
                <Route path="rejected" element={<Rejected />} />
              </Route>
              <Route path="/add-ip" element={<AddIp />} />
              <Route path="/settings" element={<SettingsHeader />}>
                <Route path="subscription" element={<Subscription />} />
                <Route index path="Billing" element={<Billing />} />
                <Route path="profile" element={<Profile />} />
                <Route path="password" element={<Password />} />
              </Route>
              <Route path="/proposals" element={<Proposals />}>
                <Route index path="pending" element={<Pending />} />
                <Route  path="accepted" element={<Accepted />} />
                <Route path="registered" element={<Registered />} />
                <Route path="rejected" element={<Rejected />} />
              </Route>
              <Route path="/cancel_request" element={<CancelRequest />} />
              <Route path="/accepted_request" element={<AcceptedRequest />} />
              <Route
                path="/registered_request"
                element={<RegisteredRequest />}
              />
              <Route path="/rejected_request" element={<RejectedRequest />} />
              <Route path="/messages" element={<Messages />} />
              <Route path="/notification" element={<Notification />} />
            </Route>
          </Route>
          <Route path="/agreements/:path/:id" element={<Certeficate />} />
          <Route path="/before-sign-in" element={<BeforeGetStarted />} />
          <Route
            path="/sign-in"
            element={<SignIn handleLogin={handleLogin} />}
          />
          <Route
            path="/sign-up"
            element={<SignUp handleLogin={handleLogin} />}
          />
          <Route
            path="/select-subscription"
            element={<SelectSubscription handleLogin={handleLogin} />}
          />
          <Route path="/forgot" element={<ForgotPass />} />
          <Route path="/forgot/change" element={<ChangePass />} />
          <Route path="/coprights" element={<Coprights />} />
          <Route path="/list/certificates" element={<AgreementsList />} />
          <Route path="/trademarks" element={<Coprights />} />
          <Route path="/industrial-designs" element={<Coprights />} />
          <Route path="/patents" element={<Coprights />} />
          <Route path="/traditional-knowledge" element={<Coprights />} />
          <Route path="/geographical-indices" element={<Coprights />} />
          <Route path="/plant-breeders-rights" element={<Coprights />} />

          <Route path="/get-proposal" element={<GetProposal />} />
          <Route path="/agreement-proposal" element={<AgreementProposal />} />
          <Route path="/accept-reject" element={<AcceptReject />} />
          <Route path="/policy" element={<Policy />} />
          <Route path="/about" element={<About />} />

          <Route path="*" element={<p>There's nothing here: 404!</p>} />
        </Routes>
      </section>
      {hide ? null : <Footer />}
    </>
  );
}
