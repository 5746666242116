import {Navigate, Outlet, useNavigate,} from 'react-router-dom';
import {useState} from "react";

export const VerifiedCertificateBtn = ({data,label}) => {
    const navigate = useNavigate()
    let token = localStorage.getItem('token');
    let user_id = localStorage.getItem('id');

    return (
        <div className="certeficateBtn_block">
            <button
                key={data.id}
                className='agreementBtn'
            >
                <img src={data.photo} alt=""/>
            </button>
            <p>{data.certificate_name}</p>
            <button className="view_btn"  onClick={ () => navigate(`/verified_certificates/${data.type}/${data.id}`)}>View</button>
        </div>

    )
};