import { Loading } from "../../components";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./style.scss";
import file_upload from "../../assets/images/icons/file_upload_black_24dp 1.png";
import { sell_schema } from "./schema";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import axiosInstance from "../../api";
import TooltipItem from "../../components/tooltipItem";

export function CertificatesViewApplySell() {
  let navigate = useNavigate();
  const { path, id } = useParams();
  let loaction = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  let [agreementType, setAgreementType] = useState([]);
  const [certificate,setCertificate] = useState(null)
  let getAgreementType = async () => {
    try {
      let response = await axiosInstance.get("api/v1/certificate-types");
      setAgreementType(response.data.data);
    } catch (e) {
      console.log(e);
      console.log(e.response);
    }
  };
  useEffect(() => {
    getAgreementType();
  }, []);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    clearErrors,
    setValue,
  } = useForm({
    resolver: zodResolver(sell_schema),
    defaultValues: loaction.state?.certificate || {},
  });

  useEffect(()=> {
    if(loaction.state?.certificate) {
        setCertificate(loaction.state.certificate);
    }
  },[loaction])
  const submitData = async (data) => {
    const type_id = agreementType.filter(
      (item) => item.name.toLowerCase() === path
    )[0];
    const newData = {
      agreement_id: +id,
      type_id: type_id.id,
      certificate: {
        ...data,
      },
    };
    setErrorMessage("");
    setIsLoading(true);

    try {
      await axiosInstance.post("api/client/create-proposal", newData);

      reset();
      setIsLoading(false);
      navigate(-1);
    } catch (e) {
      console.log(e);
      setErrorMessage(e.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Loading modalIsOpen={isLoading} />
      <div className="CertificatesViewApply_header">
        <h5 className="search_text">Apply for certificate</h5>
      </div>
      <div className="view_certeficate">
        <form onSubmit={handleSubmit(submitData)} className="body">
          <h5>PARTIES TO THE AGREEMENT</h5>
          <div className="apply_certificate_item margin_top_24">
            <div className="certificate_item">
              {errors?.part_1 ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.part_1.message}
                </p>
              ) : null}
              <label htmlFor="">Part 1</label>
              <input
                type="text"
                {...register("part_1")}
                placeholder="Owner of IP Name"
              />
            </div>
            <div className="certificate_item">
              {errors?.part_2 ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.part_2.message}
                </p>
              ) : null}
              <label htmlFor="">Part 2</label>
              <input
                type="text"
                {...register("part_2")}
                placeholder="Purchaser Name"
              />
            </div>
          </div>
          <h5 className="margin_top_24">DUE DILIGENCE</h5>
          {errors?.due_diligence ? (
            <p style={{ color: "red", fontSize: "16px" }}>
              {errors?.due_diligence.message}
            </p>
          ) : null}
          <div className="d_flex align_center form-group">
            <input
              type="checkbox"
              id="checkbox"
              name="checkbox"
              {...register("due_diligence")}
            />
            <label htmlFor="checkbox">
              Parties to this chat template acknowledge the importance of due
              diligence and hereby undertake an inquiry into the status of the
              certificate from the issuing authority. The Authority will
              authenticate the existence of the certificate to the said
              intellectual property.
            </label>
          </div>
          <h5 className="margin_top_48">CONFIDENTIALITY</h5>
          {errors?.confidentiality ? (
            <p style={{ color: "red", fontSize: "16px" }}>
              {errors?.confidentiality.message}
            </p>
          ) : null}
          <div className="d_flex align_center form-group">
            <input
              type="checkbox"
              id="checkbox1"
              name="checkbox"
              className="checkbox"
              {...register("confidentiality")}
            />
            <label htmlFor="checkbox1">
              Parties to this proposal agree to keep the details within this
              proposal confidential.
            </label>
          </div>
          <h5 className="margin_top_48">PURCHASER DISCLOSURE </h5>
          <div className="apply_certificate_item margin_top_24">
            <div className="certificate_item">
              {errors?.purchase_disclosure ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.purchase_disclosure.message}
                </p>
              ) : null}
              <label htmlFor="" className="tooltip-wrapper">
                Purchaser disclosure
                <TooltipItem
                  desc={
                    "Seller to disclose material facts about the certificate and intellectual property."
                  }
                />
              </label>
              <input
                type="text"
                placeholder="Name Surname"
                {...register("purchase_disclosure")}
              />
            </div>
            <div className="certificate_item"></div>
          </div>
          <h5 className="margin_top_24">OWNERSHIP AND CO OWNERSHIP</h5>
          <div className="apply_certificate_item margin_top_24">
            <div className="certificate_item">
              {errors?.ownership ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.ownership.message}
                </p>
              ) : null}
              <label htmlFor="" className="tooltip-wrapper">
                Ownership
                <TooltipItem
                  desc={
                    "Seller to confirm if they are the only owner of the certificate and intellectual property? If not fill out co-ownership section."
                  }
                />
              </label>
              <input
                type="text"
                placeholder="Name Surname"
                {...register("ownership")}
              />
            </div>
            <div className="certificate_item">
              {errors?.co_ownership ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.co_ownership.message}
                </p>
              ) : null}
              <label htmlFor="" className="tooltip-wrapper">
                Co ownership
                <TooltipItem
                  desc={
                    "Seller to explain the shareholding, equity or profit share the co-owner has in this certificate & intellectual property."
                  }
                />
              </label>
              <input
                type="text"
                placeholder="Name Surname"
                {...register("co_ownership")}
              />
            </div>
          </div>
          <h5 className="margin_top_24">TRANSFER OF OWNERSHIP</h5>
          <div className="apply_certificate_item margin_top_24">
            <div className="certificate_item">
              {errors?.transfer_of_ownership ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.transfer_of_ownership.message}
                </p>
              ) : null}
              <label htmlFor="" className="tooltip-wrapper">
                Transfer of ownership
                <TooltipItem desc="Seller is to explain any additional items in the transfer of ownership with the certificate and when this transfer will occur." />
              </label>
              <input
                type="text"
                placeholder="Name Surname"
                {...register("transfer_of_ownership")}
              />
            </div>
            <div className="certificate_item"></div>
          </div>
          <h5 className="margin_top_24">INDEMNIFICATION</h5>
          <div className="d_flex align_center form-group">
            {errors?.indemnification ? (
              <p style={{ color: "red", fontSize: "16px" }}>
                {errors?.indemnification.message}
              </p>
            ) : null}
            <input
              type="checkbox"
              id="checkbox3"
              name="scales"
              {...register("indemnification")}
            />
            <label htmlFor="checkbox3">
              The seller warrants and represents that they have the right to
              sell and transfer this intellectual property certificate & its
              registered relevant [know-how] Furthermore to hold the purchaser
              harmless to all damages, costs & expenses arising from a third
              party claim to this intellectual property sale and transfer.
            </label>
          </div>
          <div className="apply_certificate_item margin_top_24">
            <div className="certificate_item">
              {errors?.indemnification_amount ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.indemnification_amount.message}
                </p>
              ) : null}
              <label htmlFor="" className="tooltip-wrapper">
                Amount
                <TooltipItem desc="Seller to state the amount that they are willing to indemnify the seller in case of an infringement claim by a thirdi  party. Either in terms of legal fees and damages incurred by the purchaser." />
              </label>
              <input
                type="text"
                placeholder=""
                {...register("indemnification_amount", {
                  valueAsNumber: true,
                })}
              />
            </div>
            <div className="certificate_item"></div>
          </div>

          <h5 className="margin_top_24">
            COST OF SALE AND TRANSFER CURRENCY TAB: KENYA SHILLING / US DOLLAR /
            EURO / POUND STERLING.
          </h5>
          <div className="margin_top_12">
            AMOUNTS MUST TALLY. THE AMOUNT IN NUMBERS MUST BE THE SAME AMOUNT IN
            FIGURES . JUST LIKE A PAY ORDER / CHEQUE
          </div>
          <div className="apply_certificate_item margin_top_12">
            <div className="certificate_item">
              {errors?.cost_of_sale_number ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.cost_of_sale_number.message}
                </p>
              ) : null}
              <label htmlFor="">Amount in numbers</label>
              <input
                type="text"
                placeholder=""
                {...register("cost_of_sale_number", {
                  valueAsNumber: true,
                })}
              />
            </div>
            <div className="certificate_item">
              {errors?.cost_of_sale_figures ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.cost_of_sale_figures.message}
                </p>
              ) : null}
              <label htmlFor="">Amount in figures</label>
              <input
                type="text"
                placeholder=""
                {...register("cost_of_sale_figures", {
                  valueAsNumber: true,
                })}
              />
            </div>
          </div>
          <h5 className="margin_top_24">TRANSFER OF ACCESS TO [IP]</h5>
          <div className="apply_certificate_item margin_top_24">
            <div className="certificate_item">
              {errors?.transfer_of_access_website ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.transfer_of_access_website.message}
                </p>
              ) : null}
              <label htmlFor="" className="tooltip-wrapper">
                Website
                <TooltipItem desc="Seller to fill in." />
              </label>
              <input
                type="text"
                placeholder=""
                {...register("transfer_of_access_website")}
              />
            </div>
            <div className="certificate_item">
              {errors?.transfer_of_access_url ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.transfer_of_access_url.message}
                </p>
              ) : null}
              <label htmlFor="" className="tooltip-wrapper">
                URL
                <TooltipItem desc="Seller to fill in." />
              </label>
              <input
                type="text"
                placeholder=""
                {...register("transfer_of_access_url")}
              />
            </div>
          </div>
          <div className="apply_certificate_item">
            <div className="certificate_item">
              {errors?.transfer_of_access_literal ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.transfer_of_access_literal.message}
                </p>
              ) : null}
              <label htmlFor="" className="tooltip-wrapper">
                Literal material / Posters etc
                <TooltipItem desc="Seller to fill in." />
              </label>
              <input
                type="text"
                placeholder=""
                {...register("transfer_of_access_literal")}
              />
            </div>
            <div className="certificate_item" style={{ position: "relative" }}>
              <label htmlFor="" className="tooltip-wrapper">
                QR code
                <TooltipItem desc="Choice of Nil/ if no Qr code exists." />
              </label>
              {certificate?.transfer_of_access_qr && (
                <a download="qr.png" href={certificate.transfer_of_access_qr}>
                  Download
                </a>
              )}
              {errors?.transfer_of_access_qr ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.transfer_of_access_qr.message}
                </p>
              ) : null}
              <input
                type="file"
                placeholder="Upload QR code"
                {...register("transfer_of_access_qr")}
              />
              <img
                src={file_upload}
                alt="file_upload"
                style={{ bottom: "9%" }}
                className="file_upload_img"
              />
            </div>
          </div>
          <div className="apply_certificate_item">
            <div className="certificate_item">
              {errors?.transfer_of_access_number ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.transfer_of_access_number.message}
                </p>
              ) : null}
              <label htmlFor="" className="tooltip-wrapper">
                File Number
                <TooltipItem desc="Seller to fill in." />
              </label>
              <input
                type="text"
                placeholder=""
                {...register("transfer_of_access_number", {
                  valueAsNumber: true,
                })}
              />
            </div>
            <div className="certificate_item">
              {errors?.transfer_of_access_other ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.transfer_of_access_other.message}
                </p>
              ) : null}
              <label htmlFor="" className="tooltip-wrapper">
                Other
                <TooltipItem desc="Seller to explain what else will be involved in the transfer other than the certificate. Either Know-how or Trade secrets or other intellectual property of importance to this certificate." />
              </label>
              <input
                type="text"
                placeholder=""
                {...register("transfer_of_access_other")}
              />
            </div>
          </div>
          <div className="d_flex space_beetween margin_top_48 checkboxes_group align_center">
            <div className="d_flex align_center form-group">
              <input
                type="checkbox"
                id="checkbox34"
                name="checkbox"
                checked
                className="checkbox"
                {...register("verify_certificate")}
              />
              <label htmlFor="checkbox34">Verify Certificate</label>
            </div>
            <div className="d_flex align_center form-group">
              <input
                type="checkbox"
                id="checkbox35"
                name="checkbox"
                className="checkbox"
                {...register("value")}
              />
              <label htmlFor="checkbox35">Value</label>
            </div>
            <div className="d_flex align_center form-group">
              <input
                type="checkbox"
                id="checkbox36"
                name="checkbox"
                // className="checkbox"
                {...register("insure")}
              />
              <label htmlFor="checkbox36">Insure</label>
            </div>
          </div>
          <div className="d_flex space_beetween  checkboxes_group align_center">
            <div className="d_flex align_center form-group">
              {errors?.verify_certificate ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.verify_certificate.message}
                </p>
              ) : null}
            </div>
            <div className="d_flex align_center form-group">
              {errors?.value ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.value.message}
                </p>
              ) : null}
            </div>
            <div className="d_flex align_center form-group">
              {errors?.insure ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.insure.message}
                </p>
              ) : null}
            </div>
          </div>
          {!certificate && (
            <div className="d_flex align_center submit_b margin_top_48">
              <button
                className="save_btn"
                // onClick={() => navigate("/sign-in")}
                type="submit"
                style={{ marginLeft: "0px" }}
              >
                Submit
              </button>
            </div>
          )}

          {errorMessage && (
            <p style={{ color: "red", textAlign: "center", marginTop: "10px" }}>
              {errorMessage}
            </p>
          )}
        </form>
      </div>
    </>
  );
}
