import { Loading } from "../../components";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import file_upload from "../../assets/images/icons/file_upload_black_24dp 1.png";
import calendar_icon from "../../assets/images/icons/calendar.png";
import Calendar from "../../components/calendar";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, Controller } from "react-hook-form";
import { license_schema } from "./schema";
import DatePicker from "react-datepicker";
import axiosInstance from "../../api";
export function CertificatesViewApplyLicense() {
  let navigate = useNavigate();

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const { path, id } = useParams();
  const [isLoading,setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("");
  let [agreementType, setAgreementType] = useState([]);
  console.log('agreementType :', agreementType);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    clearErrors,
    setValue,
  } = useForm({
    resolver: zodResolver(license_schema),
  });
  
    console.log('errors :', errors);

  let getAgreementType = async () => {
    try {
      let response = await axiosInstance.get("api/v1/certificate-types");
      setAgreementType(response.data.data);
    } catch (e) {
      console.log(e);
      console.log(e.response);
    }
  };

  useEffect(() => {
    getAgreementType()
  },[])
  const handleChangeStart = (dateChange) => {
  
    setValue("duration_start_date", dateChange.toString(), {
      shouldDirty: true,
    });
    clearErrors("duration_start_date");
    setStartDate(dateChange);
  };
  const handleChangeEnd = (dateChange) => {
    setValue("duration_end_date", dateChange.toString(), {
      shouldDirty: true,
    });
    clearErrors("duration_end_date");
    setEndDate(dateChange);
  };

  const submitData = async (data) => {
    const type_id = agreementType.filter(item => (item.name.toLowerCase() === path))[0]

    const newData = {
      agreement_id: +id,
      type_id: type_id.id,
      certificate: {
        ...data,
      },
    };
    
    setIsLoading(true)
  
    try {
      await axiosInstance.post("api/client/create-proposal", newData);

      reset();
      setIsLoading(false);
      navigate(-1);
    } catch (e) {
      console.log(e);
      setErrorMessage(e.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <Loading modalIsOpen={isLoading} />
      <div className="CertificatesViewApply_header">
        <h5 className="search_text">Apply for certificate</h5>
      </div>
      <div className="view_certeficate">
        <form onSubmit={handleSubmit(submitData)} className="body">
          <h5>PARTIES TO THE AGREEMENT</h5>
          <div className="apply_certificate_item margin_top_24">
            <div className="certificate_item">
              {errors?.part_1 ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.part_1.message}
                </p>
              ) : null}
              <label htmlFor="">Part 1</label>
              <input type="text" name="part_1" {...register("part_1")} />
            </div>
            <div className="certificate_item">
              {errors?.part_2 ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.part_2.message}
                </p>
              ) : null}
              <label htmlFor="">Part 2</label>
              <input type="text" name="part_2" {...register("part_2")} />
            </div>
          </div>
          <h5 className="margin_top_24">DUE DILIGENCE</h5>
          {errors?.due_diligence ? (
            <p style={{ color: "red", fontSize: "16px" }}>
              {errors?.due_diligence.message}
            </p>
          ) : null}
          <div className="d_flex align_center form-group">
            <input
              type="checkbox"
              id="checkbox"
              name="due_diligence"
              {...register("due_diligence")}
            />
            <label htmlFor="checkbox">
              Parties to this chat template acknowledge the importance of due
              diligence and hereby undertake an inquiry into the status of the
              certificate from the issuing authority. The Authority will
              authenticate the existence of the certificate to the said
              intellectual property.
            </label>
          </div>
          <h5 className="margin_top_48">CONFIDENTIALITY</h5>
          {errors?.confidentiality ? (
            <p style={{ color: "red", fontSize: "16px" }}>
              {errors?.confidentiality.message}
            </p>
          ) : null}
          <div className="d_flex align_center form-group">
            <input
              type="checkbox"
              id="checkbox1"
              name="confidentiality"
              className="checkbox"
              {...register("confidentiality")}
            />
            <label htmlFor="checkbox1">
              Parties to this proposal agree to keep the details within this
              proposal confidential.
            </label>
          </div>
          <h5 className="margin_top_48">LICENSEE DISCLOSURE ACCEPTANCE</h5>
          {errors?.licensee_disclosure_acceptance ? (
            <p style={{ color: "red", fontSize: "16px" }}>
              {errors?.licensee_disclosure_acceptance.message}
            </p>
          ) : null}
          <div className="d_flex align_center form-group">
            <input
              type="checkbox"
              id="checkbox2"
              name="checkbox"
              className="checkbox"
              {...register("licensee_disclosure_acceptance")}
            />
            <label htmlFor="checkbox2">
              Licensee has accepted the disclosed information relevant to the
              intellectual property.
            </label>
          </div>
          <h5 className="margin_top_48">OWNERSHIP AND CO OWNERSHIP</h5>
          <div className="apply_certificate_item margin_top_24">
            <div className="certificate_item">
              {errors?.ownership ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.ownership.message}
                </p>
              ) : null}
              <label htmlFor="">Ownership</label>
              <input
                type="text"
                placeholder="Name Surname"
                {...register("ownership")}
              />
            </div>
            <div className="certificate_item">
              {errors?.co_ownership ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.co_ownership.message}
                </p>
              ) : null}
              <label htmlFor="">Co ownership</label>
              <input
                type="text"
                placeholder="Name Surname"
                {...register("co_ownership")}
              />
            </div>
          </div>
          <h5 className="margin_top_24">PERMITTED USE SCENARIOS</h5>

          <p className="margin_top_16" style={{ fontWeight: "700" }}>
            ADVERTISING SCENARIO
          </p>
          {errors?.advertising_scenarioe ? (
            <p style={{ color: "red", fontSize: "16px" }}>
              {errors?.advertising_scenarioe.message}
            </p>
          ) : null}
          <div className="d_flex align_center form-group">
            <input
              type="checkbox"
              id="checkbox3"
              name="checkbox"
              className="checkbox"
              {...register("advertising_scenarioe")}
            />
            <label htmlFor="checkbox3">
              I/We hereby submit the following advertising scenario in which the
              intellectual property may be used for:
            </label>
          </div>
          <div className="margin_top_24">
            <div className="">
              {errors?.define ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.define.message}
                </p>
              ) : null}
              <label htmlFor="">Define</label>
              <textarea
                name=""
                id=""
                cols=""
                rows=""
                style={{ background: "white", width: "100%" }}
                {...register("define")}
              >
                {" "}
              </textarea>
            </div>
          </div>
          <h5 className="margin_top_48">RESEARCH SCENARIO</h5>
          {errors?.research_scenario_1 ? (
            <p style={{ color: "red", fontSize: "16px" }}>
              {errors?.research_scenario_1.message}
            </p>
          ) : null}
          {errors?.research_scenario_2 ? (
            <p style={{ color: "red", fontSize: "16px" }}>
              {errors?.research_scenario_2.message}
            </p>
          ) : null}
          <div className="d_flex align_center form-group">
            <input
              type="checkbox"
              id="checkbox4"
              name="checkbox"
              className="checkbox"
              {...register("research_scenario_1")}
            />
            <label htmlFor="checkbox4">
              I/We hereby submit the following research scenario in which the
              intellectual property may be used for:
            </label>
          </div>
          <div className="d_flex align_center form-group margin_top_16">
            <input
              type="checkbox"
              id="checkbox5"
              name="checkbox"
              className="checkbox"
              {...register("research_scenario_2")}
            />
            <label htmlFor="checkbox5">
              During research scenario the borrower shall at all times give
              credit to the Grantor / Licensor / Borrower:-
            </label>
          </div>
          <div className="margin_top_24">
            <div className="">
              <label htmlFor="">Define the aim of research</label>
              {errors?.define_the_aim_of_research ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.define_the_aim_of_research.message}
                </p>
              ) : null}
              <textarea
                name=""
                id=""
                cols=""
                rows=""
                style={{ background: "white", width: "100%" }}
                {...register("define_the_aim_of_research")}
              >
                {" "}
              </textarea>
            </div>
          </div>
          <h5 className="margin_top_24">ENTERTAINMENT SHOW</h5>
          {errors?.entertainment_show ? (
            <p style={{ color: "red", fontSize: "16px" }}>
              {errors?.entertainment_show.message}
            </p>
          ) : null}
          <div className="d_flex align_center form-group">
            <input
              type="checkbox"
              id="checkbox6"
              name="scales"
              {...register("entertainment_show")}
            />
            <label htmlFor="checkbox6">
              I/We hereby submit the following entertainment or show scenario in
              which the intellectual property may be used for:
            </label>
          </div>
          <div className="apply_certificate_item margin_top_24">
            <div className="certificate_item">
              {errors?.entertainment_name ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.entertainment_name.message}
                </p>
              ) : null}
              <label htmlFor="">Name of show</label>
              <input
                type="text"
                placeholder=""
                {...register("entertainment_name")}
              />
            </div>
            <div className="certificate_item">
              {errors?.entertainment_location ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.entertainment_location.message}
                </p>
              ) : null}
              <label htmlFor="">Location</label>
              <input
                type="text"
                placeholder=""
                {...register("entertainment_location")}
              />
            </div>
          </div>
          <div className="apply_certificate_item">
            <div className="certificate_item">
              {errors?.entertainment_venue ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.entertainment_venue.message}
                </p>
              ) : null}
              <label htmlFor="">Venue</label>
              <input
                type="text"
                placeholder=""
                {...register("entertainment_venue")}
              />
            </div>
            <div className="certificate_item">
              {errors?.entertainment_date ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.entertainment_date.message}
                </p>
              ) : null}
              <label htmlFor="">Date</label>
              <input
                type="text"
                placeholder=""
                {...register("entertainment_date")}
              />
            </div>
          </div>
          <div className="apply_certificate_item">
            <div className="certificate_item">
              {errors?.entertainment_host ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.entertainment_host.message}
                </p>
              ) : null}
              <label htmlFor="">Host (s)</label>
              <input
                type="text"
                placeholder=""
                {...register("entertainment_host")}
              />
            </div>
            <div className="certificate_item">
              {errors?.entertainment_define_further ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.entertainment_define_further.message}
                </p>
              ) : null}
              <label htmlFor="">Define further</label>
              <input
                type="text"
                placeholder=""
                {...register("entertainment_define_further")}
              />
            </div>
          </div>
          <h5 className="margin_top_24">CEREMONY USE</h5>
          {errors?.ceremony_use ? (
            <p style={{ color: "red", fontSize: "16px" }}>
              {errors?.ceremony_use.message}
            </p>
          ) : null}
          <div className="d_flex align_center form-group">
            <input
              type="checkbox"
              id="checkbox7"
              name="scales"
              {...register("ceremony_use")}
            />
            <label htmlFor="checkbox7">
              I/We hereby submit the following ceremonial scenario in which the
              intellectual property may be used for:
            </label>
          </div>
          <div className="apply_certificate_item margin_top_24">
            <div className="certificate_item">
              {errors?.ceremony_name ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.ceremony_name.message}
                </p>
              ) : null}
              <label htmlFor="">Name of ceremony</label>
              <input
                type="text"
                placeholder=""
                {...register("ceremony_name")}
              />
            </div>
            <div className="certificate_item">
              {errors?.ceremony_date ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.ceremony_date.message}
                </p>
              ) : null}
              <label htmlFor="">Date of ceremony</label>
              <input
                type="text"
                placeholder=""
                {...register("ceremony_date")}
              />
            </div>
          </div>
          <div className="apply_certificate_item">
            <div className="certificate_item">
              {errors?.ceremony_location ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.ceremony_location.message}
                </p>
              ) : null}
              <label htmlFor="">Location of ceremony</label>
              <input
                type="text"
                placeholder=""
                {...register("ceremony_location")}
              />
            </div>
            <div className="certificate_item">
              {errors?.ceremony_venue ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.ceremony_venue.message}
                </p>
              ) : null}
              <label htmlFor="">Venue of ceremony</label>
              <input
                type="text"
                placeholder=""
                {...register("ceremony_venue")}
              />
            </div>
          </div>
          <div className="apply_certificate_item">
            <div className="certificate_item">
              {errors?.ceremony_host ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.ceremony_host.message}
                </p>
              ) : null}
              <label htmlFor="">Host (s)</label>
              <input
                type="text"
                placeholder=""
                {...register("ceremony_host")}
              />
            </div>
            <div className="certificate_item">
              {errors?.ceremony_license ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.ceremony_license.message}
                </p>
              ) : null}
              <label htmlFor="">Licensee define further</label>
              <input
                type="text"
                placeholder=""
                {...register("ceremony_license")}
              />
            </div>
          </div>
          <h5 className="margin_top_24">OTHER PERMITTED USE</h5>
          {errors?.other_permitted_use ? (
            <p style={{ color: "red", fontSize: "16px" }}>
              {errors?.other_permitted_use.message}
            </p>
          ) : null}
          <div className="d_flex align_center form-group">
            <input
              type="checkbox"
              id="checkbox8"
              name="checkbox"
              className="checkbox"
              {...register("other_permitted_use")}
            />
            <label htmlFor="checkbox8">
              I/We hereby submit the following [Other] scenario in which we
              intend to use the intellectual property for:
            </label>
          </div>
          <div className="margin_top_24">
            <div className="">
              {errors?.other_permitted_accurately ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.other_permitted_accurately.message}
                </p>
              ) : null}
              <label htmlFor="">
                Accurately define what use the Intellectual property is to be
                used for
              </label>
              <textarea
                name=""
                id=""
                cols=""
                rows=""
                style={{ background: "white", width: "100%" }}
                {...register("other_permitted_accurately")}
              >
                {" "}
              </textarea>
            </div>
          </div>
          <h5 className="margin_top_24">RIGHT TO SUB LICENSE</h5>
          {errors?.right_to_sub_license ? (
            <p style={{ color: "red", fontSize: "16px" }}>
              {errors?.right_to_sub_license.message}
            </p>
          ) : null}

          <div className="d_flex align_center margin_top_12">
            <label className="radio_btn_b d_flex align_center">
              <input type="radio" id="No" value="No" name="radio" />
              No
            </label>
            <label className="radio_btn_b d_flex align_center">
              <input
                type="radio"
                id="yes"
                value="yes"
                name="radio"
                checked="checked"
              />
              Yes
            </label>
          </div>
          <h5 className="margin_top_48">TRANSFER OF ACCESS TO [IP]</h5>
          <div className="apply_certificate_item margin_top_24">
            <div className="certificate_item">
              {errors?.transfer_of_access_website ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.transfer_of_access_website.message}
                </p>
              ) : null}
              <label htmlFor="">Website</label>
              <input
                type="text"
                placeholder=""
                {...register("transfer_of_access_website")}
              />
            </div>
            <div className="certificate_item">
              {errors?.transfer_of_access_url ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.transfer_of_access_url.message}
                </p>
              ) : null}
              <label htmlFor="">URL</label>
              <input
                type="text"
                placeholder=""
                {...register("transfer_of_access_url")}
              />
            </div>
          </div>
          <div className="apply_certificate_item">
            <div className="certificate_item">
              {errors?.transfer_of_access_literal ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.transfer_of_access_literal.message}
                </p>
              ) : null}
              <label htmlFor="">Literal material / Posters etc</label>
              <input
                type="text"
                placeholder=""
                {...register("transfer_of_access_literal")}
              />
            </div>
            <div
              className="certificate_item file_upload"
              style={{ position: "relative" }}
            >
              <p>QR code</p>
              {errors?.transfer_of_access_qr ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.transfer_of_access_qr.message}
                </p>
              ) : null}
              <label htmlFor="upload">Upload QR code</label>
              <input
                type="file"
                placeholder="Upload QR code"
                id="upload"
                {...register("transfer_of_access_qr")}
              />
              <img
                src={file_upload}
                alt="file_upload"
                className="file_upload_img_"
              />
              <span className="input-file-text"></span>
            </div>
          </div>
          <div className="apply_certificate_item">
            <div className="certificate_item">
              {errors?.transfer_of_access_number ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.transfer_of_access_number.message}
                </p>
              ) : null}
              <label htmlFor="">File Number</label>
              <input
                type="number"
                placeholder=""
                {...register("transfer_of_access_number", {
                  valueAsNumber: true,
                })}
              />
            </div>
            <div className="certificate_item">
              {errors?.transfer_of_access_other ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.transfer_of_access_other.message}
                </p>
              ) : null}
              <label htmlFor="">Other</label>
              <input
                type="text"
                placeholder=""
                {...register("transfer_of_access_other")}
              />
            </div>
          </div>
          <h5 className="margin_top_24">DURATION</h5>
          {errors?.duration ? (
            <p style={{ color: "red", fontSize: "16px" }}>
              {errors?.duration.message}
            </p>
          ) : null}
          <div className="d_flex align_center form-group">
            <input
              type="checkbox"
              id="checkbox10"
              name="checkbox"
              className="checkbox"
              {...register("duration")}
            />
            <label htmlFor="checkbox10">
              The two parties the Owner / Licensor and the Borrower / Licensee,
              hereby agree to be bound by the terms of this license agreement
              for the following duration of time.
            </label>
          </div>
          <div className="apply_certificate_item margin_top_24 calendar_cont">
            <label className="calendar_block">
              {errors?.duration_start_date ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.duration_start_date.message}
                </p>
              ) : null}
              Start date
              <img
                src={calendar_icon}
                alt="calendar_icon"
                className="calendar_icon"
              />
              <Controller
                name="duration_start_date"
                control={control}
                defaultValue={startDate}
                render={() => (
                  <DatePicker
                    selected={startDate}
                    onChange={handleChangeStart}
                  />
                )}
              />
            </label>
            <label className="calendar_block">
              End date
              {errors?.duration_end_date ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.duration_end_date.message}
                </p>
              ) : null}
              <img
                src={calendar_icon}
                alt="calendar_icon"
                className="calendar_icon"
              />
              <Controller
                name="duration_end_date"
                control={control}
                defaultValue={endDate}
                render={() => (
                  <DatePicker selected={endDate} onChange={handleChangeEnd} />
                )}
              />
            </label>
          </div>
          <h5 className="margin_top_48">COST OF LICENSE / PRICE USD</h5>
          <div className="margin_top_12">
            AMOUNTS TO TALLY THE NUMBERS MUST BE THE SAME AS THE FIGURES. JUST
            AS A PAY ORDER OR
            <p>CHEQUE:-</p>
          </div>
          <div className="apply_certificate_item margin_top_12">
            <div className="certificate_item">
              {errors?.cost_of_license_number ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.cost_of_license_number.message}
                </p>
              ) : null}
              <label htmlFor="">Amount in numbers</label>
              <input
                type="number"
                placeholder=""
                {...register("cost_of_license_number", { valueAsNumber: true })}
              />
            </div>
            <div className="certificate_item">
              {errors?.cost_of_license_figures ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.cost_of_license_figures.message}
                </p>
              ) : null}
              <label htmlFor="">Amount in figures</label>
              <input
                type="number"
                placeholder=""
                {...register("cost_of_license_figures", {
                  valueAsNumber: true,
                })}
              />
            </div>
          </div>
          <h5 className="margin_top_24">BREACH OF TERMS</h5>
          {errors?.breach_of_terms ? (
            <p style={{ color: "red", fontSize: "16px" }}>
              {errors?.breach_of_terms.message}
            </p>
          ) : null}
          <div className="d_flex align_center form-group">
            <input
              type="checkbox"
              id="checkbox11"
              name="checkbox"
              className="checkbox"
              {...register("breach_of_terms")}
            />
            <label htmlFor="checkbox11">
              The Borrower / Licensee hereby admits that they understand that
              they are only permitted to use the intellectual property owned by
              the Owner/ Licensor for the permitted use negotiated and agreed
              upon in Clause 6 A-D or E any unjustified use that is not
              incidental or related to the use mentioned in this clauses, will
              amount to a breach of conditions of this agreement, and will
              enable the Owner / Licensor to terminate the license by issuing a
              termination notice, [below] and sue for any damages caused by the
              breach of the permitted use, as they see fit.
            </label>
          </div>
          <h5 className="margin_top_48">INDEMNIFICATION</h5>
          {errors?.indemnification ? (
            <p style={{ color: "red", fontSize: "16px" }}>
              {errors?.indemnification.message}
            </p>
          ) : null}
          <div className="d_flex align_center form-group">
            <input
              type="checkbox"
              id="checkbox12"
              name="checkbox"
              className="checkbox"
              {...register("indemnification")}
            />
            <label htmlFor="checkbox12">
              Compensation for harm, loss or damage caused by any false
              representation by the borrower to any third 3rd parties, during
              the course and subsistence of this license period. The licensee
              MAY be liable for the following: if improper use of intellectual
              property during or after license if causation is found to emanate
              from this license period. ACTS THIRD PARTY ACTIVITIES AND DAMAGES:
              The licensee/ borrower shall be liable for any loss, or damage
              occurring to third parties by the improper use of the intellectual
              property licensed to them. The permitted use guidelines must be
              adhered to at all times during the subsistence of this license.
              [Torts Law and liability] Occupiers liability & risk may shift to
              the licensee if the intellectual property is premises based,
              during the subsistence of the license. [Fitness for purpose] the
              intellectual property must be used per the guidelines stipulated
              in the registration process, and the licensee/ borrower may be
              liable for any use that is outside the aims of the intellectual
              property. OUTSIDE RESEARCH parameters that cause harm. [RESEARCH
              PARAMETERS] the licensee / borrower shall seek the relevant
              County, Administrative or Government authorities clearance and
              permission in your region. Be it Educational, Medical, Sanitation,
              Construction or other related fields. [Negligence] The borrower
              may be liable for any negligent acts or omissions occurring from
              the licensed use of the intellectual property. [Contractual] The
              licensee/ borrower may be liable for any contractual obligations
              arising from the licensed use of the intellectual property during
              the tenure of the license agreement. The use of the term may be
              determined on the facts and through due process by court or
              abitral body.
            </label>
          </div>
          <h5 className="margin_top_48">TERMINATION OF LICENSE</h5>
          {errors?.termination_of_license ? (
            <p style={{ color: "red", fontSize: "16px" }}>
              {errors?.termination_of_license.message}
            </p>
          ) : null}
          <div className="d_flex align_center form-group">
            <input
              type="checkbox"
              id="checkbox13"
              name="checkbox"
              className="checkbox"
              {...register("termination_of_license")}
            />
            <label htmlFor="checkbox13">
              The license agreement can be terminated by both parties at any
              time during the subsistence of the license. Either party wishing
              to terminate the license shall issue a termination notice to the
              other party, stating the grounds for termination. And by the
              termination notice There shall not be a refund of monies paid as
              service fee or costs to the ipdepository.com system. The contract
              herein may also be terminated by the breach mentioned above in
              clause 9 or incidental matters.
            </label>
          </div>
          <div className="d_flex space_beetween margin_top_48 checkboxes_group align_center">
            <div className="d_flex align_center form-group">
              <input
                type="checkbox"
                id="checkbox34"
                name="checkbox"
                checked
                className="checkbox"
                {...register("verify_certificate")}
              />
              <label htmlFor="checkbox34">Verify Certificate</label>
            </div>
            <div className="d_flex align_center form-group">
              <input
                type="checkbox"
                id="checkbox35"
                name="checkbox"
                className="checkbox"
                {...register("value")}
              />
              <label htmlFor="checkbox35">Value</label>
            </div>
            <div className="d_flex align_center form-group">
              <input
                type="checkbox"
                id="checkbox36"
                name="checkbox"
                // className="checkbox"
                {...register("insure")}
              />
              <label htmlFor="checkbox36">Insure</label>
            </div>
          </div>
          <div className="d_flex space_beetween  checkboxes_group align_center">
            <div className="d_flex align_center form-group">
              {errors?.verify_certificate ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.verify_certificate.message}
                </p>
              ) : null}
            </div>
            <div className="d_flex align_center form-group">
              {errors?.value ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.value.message}
                </p>
              ) : null}
            </div>
            <div className="d_flex align_center form-group">
              {errors?.insure ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.insure.message}
                </p>
              ) : null}
            </div>
          </div>
          <div className="d_flex align_center submit_b margin_top_48">
            <button
              className="save_btn"
              // onClick={() => navigate("/sign-in")}
              type="submit"
              style={{ marginLeft: "0px" }}
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
