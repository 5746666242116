import React, {useState} from "react";
import axiosInstance from "../../../../api";
import {Loading} from "../../../../components";

export function Password() {
    const [loading, setLoading] = useState(false)
    const [user, setUser] = useState({
        old_password: '',
        password: '',
        password_repeat: '',
    })
    const [error, setError] = useState('')
    let handleChange = (value, name) => {
        setUser({...user, ...{[name]: value}})
        setError('')
    }

    let change = async () => {
        try {
            setLoading(true)
            if (user.password && user.old_password && user.password_repeat) {
                if (user.password.length) {
                    let response = await axiosInstance.post('api/client/settings/reset-password', {old_password: user.old_password, password: user.password})
                    setUser({
                        old_password: '',
                        password: '',
                        password_repeat: '',
                    })
                    setLoading(false)
                } else {
                    setError('Length must be at least 8 characters')
                }
            } else {
                setError('Please fill in all required fields')
            }
        } catch (e) {
            setLoading(false)
            if(e?.response?.data){
                setError(e.response.data)
            }
        }
    }

    return (
        <>
            <div className='passwordContent'>
                <div className="passwordContent_inner">
                    <div className="passwordContent_inner_block1">
                        <div>
                            <label>
                                Old password
                                <input type="password" placeholder='********' value={user.old_password}
                                       onChange={(e) => handleChange(e.target.value, 'old_password')}/>
                            </label>
                            <label>
                                New password
                                <input type="password" placeholder='********' value={user.password}
                                       onChange={(e) => handleChange(e.target.value, 'password')}/>
                            </label>
                        </div>
                        <label>
                            Repeat new password
                            <input type="password" placeholder='********' value={user.password_repeat}
                                   onChange={(e) => handleChange(e.target.value, 'password_repeat')}/>
                        </label>
                    </div>
                    {error ?
                        <p style={{color: 'red', marginTop: 10}}>{error}</p>
                        :
                        null
                    }
                    <div className="password_btns_b">
                        <button className="discardBtn" onClick={() => setUser({
                            old_password: '',
                            password: '',
                            password_repeat: '',
                        })}>Discard</button>
                        <button className='saveBtn' onClick={change}>Save</button>
                    </div>
                </div>
            </div>
            <Loading modalIsOpen={loading}/>
        </>
    )
}
