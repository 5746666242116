import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as _ from "lodash";
import axiosInstance from "../../../api";

export default function ThirdParty(props) {
  let navigate = useNavigate();
  let [error, setError] = useState("");
  let [data, setData] = useState({});

  let changeInput = async (e, name) => {
    setData({ ..._.assignIn(data, { [name]: e }) });
    setError("");
  };

  const EmailValidation = () => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    re.test(String(data.email).toLowerCase());
    return re.test(String(data.email).toLowerCase());
  };

  let signUp = async () => {
    try {
      if (
        data.email &&
        data.name &&
        data.password &&
        data.business_name &&
        data.phone_number
      ) {
        if (EmailValidation()) {
          if (data.password.length > 7) {
            /*    let response = await axiosInstance.post('api/client/register', data)
                            localStorage.setItem('token', response.data._token);
                            props.handleLogin({
                                token: response.data.token
                            })*/
            navigate("/select-subscription", { state: data });
          } else {
            setError("Length must be at least 8 characters");
          }
        } else {
          setError("Please enter valid email address");
        }
      } else {
        setError("Please fill in all required fields");
      }
    } catch (e) {
      if (e.response?.data) {
        if (e.response?.data?.errors?.email) {
          setError(e.response?.data?.errors?.email);
        } else {
          setError(e.response?.data.message);
        }
      }
    }
  };

  return (
    <div>
      {/* <label htmlFor="userName">
        Username
        <input
          type="text"
          id="userName"
          onChange={(e) => changeInput(e.target.value, "name")}
        />
      </label>
      <label htmlFor="email">
        Email
        <input
          type="email"
          id="email"
          onChange={(e) => changeInput(e.target.value, "email")}
        />
      </label>
      <label htmlFor="businessName">
        Business Name
        <input
          type="text"
          id="businessName"
          onChange={(e) => changeInput(e.target.value, "business_name")}
        />
      </label>
      <label htmlFor="phone">
        Phone Number
        <input
          type="text"
          id="phone"
          onChange={(e) => changeInput(e.target.value, "phone_number")}
        />
      </label>
      <label htmlFor="pass" style={{ marginBottom: 0 }}>
        Password
        <input
          type="password"
          id="pass"
          onChange={(e) => changeInput(e.target.value, "password")}
        />
      </label> */}
      {error ? <p style={{ color: "red", marginTop: 10 }}>{error}</p> : null}
      <a
        target="_blank"
        className="btn"
        rel="noReferrer"
        style={{ textAlign: "center" }}
        href="http://65.109.11.93:3010/signup"
        // onClick={() => navigate("")}
      >
        Go to Third Party Dashboard
      </a>
      {/* <div style={{ display: "flex", justifyContent: "center" }}>
        <p style={{ marginRight: ".3rem" }}>Don’t have account?</p>
        <button
          onClick={() => navigate("/sign-in")}
          style={{ color: "#332486" }}
        >
          Sign in
        </button>
      </div> */}
    </div>
  );
}
