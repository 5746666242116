import React from 'react'
import incognito_user_pic from "../assets/images/icons/Incognito_user_pic.png";





const ChatItem = ({ messages, formDate }) => {
  let id = localStorage.getItem("id");
  return (
    <>
      {messages.map((item,index) => {
      
        if (parseInt(id) === item.from_id.id) {
          return (
            <div className="send_message_block" key={index}>
              <div></div>
              <div className="send_message_block_2">
                <div className="send_message_block_2_b1">
                  <div>
                    <label htmlFor="">{item.from_id.name}</label>
                    <span>{formDate(item.created_at)}</span>
                  </div>
                  <img src={incognito_user_pic} alt="pic" />
                </div>
                <div className="messages_block">{item.message}</div>
              </div>
            </div>
          );
        }
        return (
          <>
            <div className="send_receive_messages_blocks" key={index}>
              <div className="receive_message_block">
                <div className="receive_message_block_1">
                  <div className="receive_message_block_1_b1">
                    <img src={incognito_user_pic} alt="pic" />
                    <div>
                      <label htmlFor="">{item.from_id.name}</label>
                      <span>{formDate(item.created_at)}</span>
                    </div>
                  </div>
                  <div className="messages_block">{item.message}</div>
                </div>
              </div>
              {/* {messageSent.map((item, index) => {
            return (
              <div className="send_message_block" key={index}>
                <div></div>
                <div className="send_message_block_2">
                  <div className="send_message_block_2_b1">
                    <div>
                      <label htmlFor="">Name Surname</label>
                      <span>10:40 AM</span>
                    </div>
                    <img src={message_author_pic} alt="pic" />
                  </div>
                  <div className="messages_block">{item.text}</div>
                </div>
              </div>
            );
          })} */}
            </div>
          </>
        );
      })}
    </>
  );
};

export default ChatItem