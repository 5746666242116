import {NavLink, Outlet} from "react-router-dom";
import React from "react";

export  function SettingsHeader() {
    return (
        <div className="settingsContainer">
            <div>
                <ul className='header'>
                    <li className="nav-item">
                        <NavLink
                            to="subscription"
                            activeClassName="active"
                            className="link"
                        >
                            Subscription
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink
                            to="billing"
                            className="link"
                        >
                            Billing
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink
                            to="profile"
                            className="link"
                        >
                            Profile
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink
                            to="password"
                            className="link"
                        >
                            Password
                        </NavLink>
                    </li>

                </ul>
            </div>
            <main>
                <Outlet/>
            </main>
        </div>
    );
}
