import { z } from "zod";
import { convertImageFilesToBase64 } from "../../helper/helper";
const ACCEPTED_IMAGE_TYPES = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "image/webp",
];

export const partner_schema = z
  .object({
    part_1: z.string().nonempty("Required"),
    part_2: z.string().nonempty("Required"),
    due_diligence: z
      .boolean()
      .refine(
        (val) => {
          return val === true;
        },
        { message: "You must accept the terms" }
      )
      .optional(),
    confidentiality: z
      .boolean()
      .refine(
        (val) => {
          return val === true;
        },
        { message: "You must accept the terms" }
      )
      .optional(),
    partnership_parties: z
      .boolean()
      .refine(
        (val) => {
          return val === true;
        },
        { message: "You must accept the terms" }
      )
      .optional(),
    ownership: z
      .boolean()
      .refine(
        (val) => {
          return val === true;
        },
        { message: "You must accept the terms" }
      )
      .optional(),
    co_ownership_intellectual_property: z.enum(["no", "yes"]),
    ipd_details: z.string().nonempty("Required"),
    ipd_name: z.string().nonempty("Required"),
    email_of_co_owner: z.string().email().nonempty("Required"),
    ipd_address_of_co_owner: z.string().nonempty("Required"),
    phone_number_of_co_owner: z.number(),
    combination_of_ip_ownership: z
      .boolean()
      .refine(
        (val) => {
          return val === true;
        },
        { message: "You must accept the terms" }
      )
      .optional(),
    partner_a_name: z.string().nonempty("Required"),
    partner_a_offer_amount: z.number(),
    partner_a_offer_define: z.number(),
    partner_a_further_info: z.string().nonempty("Required"),
    brochures_posters_promotional_material: z
      .boolean()
      .refine(
        (val) => {
          return val === true;
        },
        { message: "You must accept the terms" }
      )
      .optional(),
    brochures_posters_file: z
      .any()
      .refine(
        (files) => ACCEPTED_IMAGE_TYPES.includes(files?.[0]?.type),
        "Only .jpg, .jpeg, .png and .webp formats are supported."
      )
      .transform((files) => convertImageFilesToBase64(files)),
    valuation_report_insurance_policy: z
      .boolean()
      .refine(
        (val) => {
          return val === true;
        },
        { message: "You must accept the terms" }
      )
      .optional(),
    valuation_report_file: z
      .any()
      .refine(
        (files) => ACCEPTED_IMAGE_TYPES.includes(files?.[0]?.type),
        "Only .jpg, .jpeg, .png and .webp formats are supported."
      )
      .transform((files) => convertImageFilesToBase64(files)),
    domain_name_access: z
      .boolean()
      .refine(
        (val) => {
          return val === true;
        },
        { message: "You must accept the terms" }
      )
      .optional(),
    relevant_know_how: z
      .boolean()
      .refine(
        (val) => {
          return val === true;
        },
        { message: "You must accept the terms" }
      )
      .optional(),
    partnership_duration_grantor_partner: z.string().nonempty("Required"),
    partnership_duration_partner_a: z.string().nonempty("Required"),
    partnership_duration_partner_b: z.string().nonempty("Required"),
    aim_goal: z
      .boolean()
      .refine(
        (val) => {
          return val === true;
        },
        { message: "You must accept the terms" }
      )
      .optional(),
    eg_end_of_event: z.string().nonempty("Required"),
    aim_goal_issue: z.string().nonempty("Required"),
    aim_goal_suggestion: z.string().nonempty("Required"),
    verify_certificate: z.boolean(),
    value: z.boolean(),
    insure: z.boolean(),
  })
  .partial("Required");
