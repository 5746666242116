import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";

import {
    slide1BC,
} from "../../assets/images";
import {Pagination, A11y, Autoplay} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import axiosInstance from "../../api";
import {GetStartedBanner} from "../../components/getStartedBanner";

export function Coprights(props) {
    let location = useLocation()
    let navigate = useNavigate()
    let token = localStorage.getItem('token');
    const [item, setItem] = useState([])

    useEffect(() => {
        responseAgreements()
    }, [location])

    let responseAgreements = async () => {
        try {
            let response = await axiosInstance.get(`api/v1/agreements/${location.state.category_id}`)
            setItem(response.data.data)
        }catch (e){

        }
    }

    let apply = async (type, id) => {
        try {
            if(token){
                navigate(`/agreements/${type}/${id}`)
            }else{
                navigate('/sign-in')
            }
        }catch (e){
            console.log(e);
        }
    }

    return (
        <div className="coprights">
            <GetStartedBanner/>
            <div className="itemsBanner">
                {
                    item.map((data, index)=>{
                        return(
                            <div className="item" key={data.id}>
                                <div className="photo" >
                                   <img src={data.photo} alt={'photo'}/>
                                    <button className="view_btn"  onClick={ () => token ? navigate(`/agreements/${data.type}/${data.id}`): navigate(`/certificates/${data.type}/${data.id}`)}>View</button>
                                </div>
                                <p className='name'>{data.certificate_name}</p>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}
