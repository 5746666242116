import React from "react";
import {RoutePage} from "./routs/route";
import { Provider } from "react-redux";
import "react-tooltip/dist/react-tooltip.css";
import store from './store'

export default function App() {
    console.log('rteg');
    return (
      <Provider store={store}>
        <RoutePage/>

      </Provider>

);
};
