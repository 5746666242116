import React from 'react';
import ReactLoading from 'react-loading';
import Modal from 'react-modal';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'rgba(255, 255, 255, 0)',
        borderWidth: 0,
    },

};

export const Loading = ({modalIsOpen, closeModal}) => (
    <Modal
        ariaHideApp={false}
        isOpen={modalIsOpen}
        style={customStyles}
    >
        <div style={{
            width: 200,
            height: 200,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
            <ReactLoading type={'spin'} color={'#332486'} height={'50%'} width={'50%'}/>
        </div>

    </Modal>
);

