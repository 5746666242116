import React, {useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {Loading} from "../../../components";
import axiosInstance from "../../../api";
import logo from "../../../assets/images/logo.png";

export default function SelectSubscription(props) {
    let {state} = useLocation()
    let navigate = useNavigate()
    let [loading, setLoading] = useState(false)
    let [subscription] = useState([
            {
                title: "Verification",
                desc1: "Authorizing body/ Authority will validate the information on user certificate",
                desc2: "3rd party costs may apply.",
                price: "10",
                type: 'verification'
            },
            {
                title: "Valuation",
                desc1: "Registered valuers are able to create valuation reports on intellectual property concepts.",
                desc2: "",
                price: "10",
                type: 'valuation'
            },
            {
                title: "Insurance",
                desc1: "Insurance firms are able to insure the intellectual property concepts.",
                desc2: "",
                price: "10",
                type: 'insurance'
            },
        ]
    )
    
    let signUp = async (type) => {
        try {
            setLoading(true)
            let data = state
            data.type = type
             let response = await axiosInstance.post('api/client/register', data)
            localStorage.setItem('token', response.data._token);
             props.handleLogin({
                 token: response.data.token
             })
            navigate('/agreements')
            setLoading(true)
        } catch (e) {
            console.log(e);
            setLoading(false)
        }
    }

    return (
        <div className="select_subscription_cont">
            <img src={logo} alt=""/>
            <h2>Choose subscription to continue</h2>
            <div className="subscriptions_blocks">
                {
                    subscription.map((item, index) => {
                        return (
                            <div className="subscription_block" key={index}>
                                <p>{item.title}</p>
                                <div className="desc1">{item.desc1}</div>
                                <div className="desc2">{item.desc2}</div>
                                <div className="price"><span className="currency">$</span>{item.price} <span
                                    className="span_month">month</span></div>
                                <button onClick={() => signUp(item.type)}>Subscribe Now</button>
                            </div>
                        )
                    })
                }
            </div>
            <Loading modalIsOpen={loading} setLoading={setLoading}/>
        </div>
    )
}
