import "../style.scss";
import {useNavigate} from "react-router-dom";
import React, {useState} from "react";
import ScrollTop from "../../../components/scrollTop";


export default function AgreementToCharge() {
    let navigate = useNavigate()
    let [active, setActive] = useState(false)
    return (
        <>
            <ScrollTop/>
            <div className="CertificatesViewApply_header">
                <h2 className="search_text">IP name here</h2>
            </div>
            <div className='agrrement_cont'>
                <div className="agrrement_cont_header">
                    <h2 className="text_center">AGREEMENT TO CHARGE INTELLECTUAL PROPERTY AGREEMENT TO CHARGE [IP]</h2>
                </div>
                <div className="hr_line">
                </div>
                <div className="agrrement_cont_body margin_top_24">
                    <p className="margin_top_24">ipdepository.com terms and conditions.</p>
                    <div className="margin_top_24">ipdepository.com <span style={{color: "#332486"}}>is not</span> a
                        certifying or issuing authority, the system is a mirror image of the
                        information pertained in certification issued to users from governing authorities, on
                        intellectual property rights and is intended to capture this information and assist the owners
                        deposit, search, transact and transfer rights in intellectual property.
                    </div>
                    <span className="margin_top_24"> <span>AIM:</span> the aim of ipdepository.com is to; </span>
                    <div>Create a depository of intellectual property that is factual and honest.
                        To facilitate a register of transactions, dealing with intellectual property and maintain a
                        marketplace for owners / grantors, appreciators,consumers of intellectual property to exercise
                        several moral and contractual rights. Such as the right of distribution, rental and
                        communication of information related to their intellectual property.
                    </div>
                    <div className="margin_top_24"><span
                    >Automated Negotiation Template:</span>This is an automated negotiation template that will ensure
                        that both parties to this agreement to charge are able to negotiate, and upon successful
                        negotiation on the terms and conditions within this template, produce an agreement to charge
                        that is legally binding upon the parties. This agreement to charge is based on the intention of
                        the owner, pledging the intellectual property [IP] as security for the payment of a debt. There
                        will be no transfer of legal or beneficial ownership, however the registration of security
                        encumbrance on the title of intellectual property via registration on the relevant registers
                        such as the Movable Properties Register as the named intellectual property, its category and the
                        amount secured, will be necessary. Upon default on redemption / repayment of security, is when
                        the remedies to the chargee may auction, or take possession of the intellectual property and
                        eventually sell the property to recover debt and interest payable.
                    </div>
                    <div className="margin_top_24"><span>Parties Consent:</span> The parties
                        hereby consent to
                        the use of this contractual template contract generator, and will be bound by the terms and
                        conditions set forth in the negotiations.
                    </div>
                    <div className="margin_top_24">
                        The parties will be identified as. <span>GRANTOR/ CHARGOR</span> [party who intends to secure
                        financing] and
                        <span>CHARGEE/ CREDITOR</span> [the party that offers the credit [money/value consideration]
                        facility against
                        the intellectual property and has their interest registered on the movable properties security
                        registry or other registry. That shall also be tasted at satisfaction of repayment of amount
                        owed, to discharge the charge against the intellectual property.
                    </div>
                    <div className="margin_top_24">
                        <span>Verification:</span> The system shall verify the contractual
                        terms, by ensuring several clauses, must be consented to by both parties before moving onto the
                        next step. So as to give an equal opportunity to both parties to negotiate, the contents of the
                        intended template agreement.
                    </div>
                    <div className="margin_top_24">
                        <span>Payment:</span>
                        The parties hereby agree to pay the automated fees, generated within the negotiations as agreed
                        by the parties, and the system shall generate its own fees, as a percentage of the above agreed
                        sum, as transaction, or commission fees payable for using this service. These fees shall be
                        amenable from time to time and the management of ipdepository.com shall communicate such
                        increment or decrease in service and transaction fees, accordingly.
                    </div>
                    <div className="margin_top_24"><span>Consequence of breach: </span>
                        The management of ipdepository.com have the right to bar, expel, suspend or delete any profiles
                        that are used to defraud, misrepresent, lure, or unduly influence, any transactions that are
                        within this system. The aims of this service can only be achieved by honest disclosure and the
                        uploading of correct information.
                    </div>
                    <div className="disclaimer margin_top_24">
                        *******“Disclaimer”*******
                    </div>
                    <h5 className="margin_top_8">ILLEGALITY:</h5>
                    <div className="margin_top_24">
                        Any agreement that is entered into with the purpose to defraud or commit an illegal act shall be
                        termed VOID. The conditions that have been negotiated within the negotiation shall not be
                        binding.
                    </div>
                    <div className="margin_top_24">
                        The intellectual property depository, ipdepository.com shall not be liable for any
                        false, misrepresented information, uploaded onto the system. To ensure that the above is a
                        matter of concern we have included the verification stage, for value to ensure that the
                        governing bodies that issued the certificates, actually validate the information on the uploaded
                        image of (certificate) known as the verification stage.
                    </div>
                    <div className="margin_top_24">We strongly urge all users to verify
                        their certificates, and assist with creating an accurate register of intellectual property.
                    </div>
                    <div className="disclaimer margin_top_24">
                        *******“Disclaimer”*******
                    </div>
                    <div className="margin_top_8">
                        <span style={{float: "right"}}>[Section 11 (4) (d) MPSR Act 2017]</span>
                    </div>
                    <div className="margin_top_8">
                        he intellectual property depository, ipdepository.com, shall not be liable for any acts or
                        omissions carried out by the Owner/ borrower/ chargor ,or the chargee / creditor on and during
                        the duration of the charge agreement between the parties involved. This system is only to be
                        used as reference and proof of contractual dealing and capacity, in exercising the rights
                        afforded to intellectual property owners. The charging parameters are to be negotiated between
                        the parties, and the system has in place the negotiation structure for both parties to meet at a
                        conclusion.
                    </div>
                    <div className="margin_top_24">
                        <span>User Obligation:</span>
                        The management of ipdepository.com hereby ensures that
                        the obligation, burden, and capacity lies on the user to upload accurate information.
                    </div>
                    <div className="margin_top_24"> “It is therefore upon you the user to upload factual information and
                        assist every eventual
                        user of your intellectual property, to receive the right and factual information” IPD
                        Adminiistrator [JEM] as a good root of title, and ensure the directory service on this platform
                        delivers accurate information for future agreements to contract
                    </div>
                    <div className="margin_top_8 d_flex" style={{float: "right", justifyContent:"end"}}>
                        <span  style={{marginRight:"5px"}}>[Section 20 MPSR Act]  </span>  integrity of register
                    </div>
                    <div className="margin_top_8">
                        The management of ipdepository.com also hereby reserves the non-exclusive right to enter into
                        legal proceedings against any user who is found to be in gross breach of our terms and
                        conditions. We also reserve the right to sue for monetary damages. For breach of the aims of the
                        system.
                    </div>
                    <div className="margin_top_24">
                        <span>User Obligation:</span>  The Owner / Chargor is under strict obligation to remain within the terms and conditions
                        negotiated between the parties. And shall adhere to the contractual obligation to pay the agreed
                        amount negotiated herein and discharge the security placed on the intellectual property.
                        Likewise the Chargee / Creditor is also within the same strict obligation to remain within the
                        terms and conditions negotiated herein. Breach of any of the terms and conditions negotiated
                        between the two parties shall be deemed as breach of a condition warranting the chargor /
                        chargee to be afforded the legal rights to determine legal issues. In the relevant Courts of
                        Law. The chargee may be afforded several legal remedies such as, enter into possession,appoint a
                        receiver to the management of the intellectual property and among others eventually exercise its
                        statutory power of sale of the same intellectual property. Upon determination of any legal
                        process.
                    </div>
                    <div className="margin_top_24">
                        <span>Variation of terms concerning payment:</span>
                        The system shall enable the parties to vary the terms as
                        to payment, date and interest payable. This variation must be consented to by the parties, and
                        within the duration of the charge. [7.5].this variation shall be registered as an
                    </div>
                    <div className="d_flex align_center margin_top_8">
                        <div className="filled_data_input" style={{width: "max-content"}}>
                            <input type="text" placeholder="ADJUSTMENT NOTICE"/>
                        </div>
                    </div>
                    <div className="margin_top_8">
                        <span> IPD Obligation:</span>
                        The management will strive to achieve the aims mentioned above and we are
                        obligated to do so in the following.
                    </div>
                    <div className="margin_top_24">
                        <span> Record transactions:</span> by the use of our system, users will
                        be able to have their [IP] transactions recorded on their profiles and on the relevant movable
                        properties registry as Notices to the registrar.
                    </div>

                    <div className="margin_top_24">The user's transactions will be accurately
                        linked to codes that the system shall generate, to accurately capture the user profile details
                        and transaction details.
                    </div>
                    <div className="margin_top_24">
                        <span>To maintain the intellectual property directory</span>
                        found on our system, to
                        enable users to search for the accurate position of any intellectual property uploaded on to the
                        system.
                    </div>
                    <div className="margin_top_24"><span>To maintain confidential personal and private
                        information</span> relating to our users, and
                        only reveal to 3rd parties information necessary to achieve valuation and insurance values, and
                        this is at the control of the user, at the valuation and insurance process.
                    </div>
                    <div className="margin_top_24">IDP will only reveal
                        confidential information if mandated by a court of Law
                    </div>
                    <div className="margin_top_24"><span>Consent:</span> Both the Grantor / Chargor / and the Chargee /
                        Creditor [secured / unsecured] have read and understood the above terms and by clicking on the
                        yes button, agree to be bound by the terms that they both negotiate to in this automated
                        agreement to charge template.
                    </div>
                    <div className="margin_top_24"><span>Note:</span> All contracts created on our platform that are
                        dubbed, commingled, or
                        shrouded in an illegality or are intended to defraud, willfully misrepresent facts
                        are VOID. and
                        parties to the agreement are not able to enforce the obligations and conditions created. The
                        system reserves the right to expel and initiate legal proceedings against any registered user
                        who is in violation of the above.
                    </div>

                    <div className="d_flex align_center form-group margin_top_24">
                        <input type="checkbox" id="checkbox" name="checkbox" onChange={() => setActive(!active)}/>
                        <label htmlFor="checkbox">I agree lorem Ipsum is simply dummy text of the printing and
                            typesetting industry</label>
                    </div>
                    {
                        active ?
                            <div className="d_flex align_center submit_b margin_top_48"
                                 onClick={() => navigate("/agreement_to_charge_2")}>
                                <button className="save_btn" style={{marginLeft: "0px"}}>Continue</button>
                            </div>
                            :
                            <div className="d_flex align_center submit_b margin_top_48">
                                <button className="continue_btn" style={{marginLeft: "0px"}}>Continue</button>
                            </div>

                    }
                </div>
            </div>
        </>
    )
}