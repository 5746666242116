import React from "react";
import {ipdepositoryLimited, phone, message} from "../../assets/images";
import {useNavigate} from "react-router-dom";

export function Footer() {
    let navigate=useNavigate()
    return (
        <footer>
            <div className="foot">
                <div className="content">
                    <ul>
                        <li className='head'>About Us</li>
                        <li onClick={()=>navigate("/policy")} style={{cursor:"pointer"}}>What</li>
                        <li onClick={()=>navigate("/policy")} style={{cursor:"pointer"}}>Why</li>
                        <li onClick={()=>navigate("/policy")} style={{cursor:"pointer"}}>How</li>
                    </ul>
                    <ul>
                        <li className='head'>Contact Us</li>
                        <li className="tel_li"><a href="tel:+254 733 99 38 41"> <img src={phone} alt=""/> +254 733 99 38 41</a>
                        </li>
                        <li>
                            <img src={ipdepositoryLimited} alt=""/>
                            IPDEPOSITORY LIMITED
                        </li>
                        <li>45707-00100</li>
                        <li>Nairobi</li>
                        <li>Kenya</li>
                    </ul>
                    <ul>
                        <li className='head'>Pre-Registration Consultation</li>
                        <div>
                            <li className='small'>If you have  registered your intellectual property kindly use the following email
                            </li>
                            <li className="tel_li"><a href="mailto:management@ipdepository.com"> <img src={message} alt=""/>
                                management@ipdepository.com</a>
                            </li>
                        </div>
                        <div>
                            <li className='small'>For consultations concerning IPD network</li>
                            <li  className="tel_li">
                                <a href="mailto:info@ipdepository.com"> <img src={message} alt=""/>
                                    info@ipdepository.com</a>

                            </li>
                        </div>
                    </ul>
                </div>
                <div className="copyRight">
                    <p>Copyright @2022 IPD,<a onClick={()=>navigate("/policy")} style={{cursor:"pointer"}}>Privacy Policy</a> </p>
                </div>
            </div>
        </footer>

    )
}
