import {NavLink, Outlet, useNavigate} from "react-router-dom";
import React, {useState} from "react";
import axiosInstance from "../../../api";

const statusType = {
  pending: 0,
  accepted: 1,
  registered: 2,
  rejected: 3,
  new: 0,
};

export default function Proposals(){
    let navigation = useNavigate()
    let [active, setActive] = useState('')
    let requestProposals = async (type) => {
        
        try {
            let {data} = await axiosInstance.get(
              `api/client/all-proposals`
            );
            const status = statusType[type]
        
            const filterDataStatus = data.data.filter((item)=>item.status=== status)
            setActive(type)
            navigation(type, { state: { proposals: filterDataStatus } });
            
        } catch (r) {

        }
    }

    return (
        <div className="settingsContainer">
            <div>
                <ul className='header'>
                    <li className="nav-item">
                        <button
                            className="link"
                            style={active === 'pending' ? {border: '1px solid #332486'} : null}
                            onClick={() => requestProposals('pending')}
                        >
                            Pending
                        </button>
                    </li>
                    <li className="nav-item">
                        <button
                            className="link"
                            style={active === 'accepted' ? {border: '1px solid #332486'} : null}
                            onClick={() => requestProposals('accepted')}
                        >
                            Accepted
                        </button>
                    </li>
                    <li className="nav-item">
                        <button
                            className="link"
                            style={active === 'registered' ? {border: '1px solid #332486'} : null}
                            onClick={() => requestProposals('registered')}
                        >
                            Registered
                        </button>
                    </li>
                    <li className="nav-item">
                        <button
                            className="link"
                            style={active === 'rejected' ? {border: '1px solid #332486'} : null}
                            onClick={() => requestProposals('rejected')}
                        >
                            Rejected
                        </button>
                    </li>

                </ul>
            </div>
            <main>
                <Outlet/>
            </main>
        </div>
    );
}
