import React, {useEffect, useState} from "react";

import {CertificateBtn} from "../../../components";
import axiosInstance from "../../../api";
import {VerifiedCertificateBtn} from "../../../components/verifiedCertificatedBtn";

export function VerifiedCertificates() {
    const [sell, setSell] = useState([])
    const [license, setLicense] = useState([])
    const [charge, setCharge] = useState([])
    const [partner, setPartner] = useState([])
    const [totalCount, setTotalCount] = useState(0)


    useEffect(() => {
        getAgreements()
    }, [])

    let getAgreements =async () => {
        try {
            let {data} = await axiosInstance.get('api/client/all-agreements')
            
            // setTotalCount(response.data.data.total_count)
            setSell(data.data.sell)
            setLicense(data.data.license)
            setCharge(data.data.charge)
            setPartner(data.data.partner)
        }catch (e){
            console.log(e);
        }
    }

    return (
        <div className='agreementsContainer'>
            <div>
                {/*<div className={'CountDiv'}><h2>Total agreements count {totalCount}</h2></div>*/}
                <div><h2>Verified</h2></div>
                <div className="banner" style={sell.length < 4 ? {justifyContent: 'start'} : null}>
                    {sell.map((item, index) => {
                        return (
                            <VerifiedCertificateBtn data={item} label={'sell'} key={index}/>
                        )
                    })}
                </div>
                <div>
                    <button className='seeMore'>See more</button>
                </div>
            </div>
            <div>
                <div><h2>Valued</h2></div>
                <div className="banner" style={license.length < 4 ? {justifyContent: 'start'} : null}>
                    {license.map((item, index) => {
                        return (
                            <VerifiedCertificateBtn data={item} label={'sell'} key={index}/>
                        )
                    })}
                </div>
                <div>
                    <button className='seeMore'>See more</button>
                </div>
            </div>
            <div>
                <div><h2>Insured</h2></div>
                <div className="banner" style={charge.length < 4 ? {justifyContent: 'start'} : null}>

                    {charge.map((item, index) => {
                        return (
                            <VerifiedCertificateBtn data={item} label={'sell'} key={index}/>
                        )
                    })}
                </div>
                <div>
                    <button className='seeMore'>See more</button>
                </div>
            </div>
        </div>
    );
}
