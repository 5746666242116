import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import axiosInstance from "../../api";
import {AuthorizedHeader} from "../../components";

export function NotificationProposal() {
    let navigate = useNavigate()
    let [data, setData] = useState([])
    let [type, setType] = useState('')

    let requestProposals = async () => {
        try {
            let response = await axiosInstance.get(`api/client/notify-messages`)
            if (response.data.length > 1) {
                let arr = []
                for (let i = 0; i < response.data.length - 1; i++) {
                    for (let j = i + 1; j < response.data.length; j++) {
                        if (response.data[i].id === response.data[j].id) {
                            if (!response.data[i].is_seen) {
                                arr.push(response.data[i])
                            } else {
                                arr.push(response.data[j])
                            }
                        } else {
                            arr.push(response.data[j])
                        }
                    }
                }
                setData([...arr]);
            } else {
                setData([...response.data])
            }

        } catch (r) {

        }
    }

    useEffect(() => {
        requestProposals()
    }, [type])

    let _renderProposals = () => {
        return data.map((item, index) => {

            return (
                <div className="item" key={index}>
                    <div className="imgContent">
                        <p>{item.proposals.acceptance}</p>
                        {!item.is_seen ?
                            <div className={'dot_red'}/>
                            :
                            null
                        }
                    </div>
                    <h4>Agreement Proposal</h4>
                    <button
                        onClick={() => navigate(`/accept-reject`, {
                            state: {
                                item: {
                                    proposal_id: item.proposal_id,
                                    user_id: item.user_id
                                }
                            }
                        })}>View
                    </button>
                </div>
            )
        })
    }

    return (
        <>
            {data ?
                <>
                    <AuthorizedHeader/>
                <div className='requestsContainer'>
                    <div className="body">
                        {_renderProposals()}
                    </div>
                </div>
                </>
                :
                <div className="search_result_cont">
                        <AuthorizedHeader/>
                    <div className="search_result_block">
                        <p className="text_center" style={{fontSize:"24px"}}>No Notifications</p>
                    </div>
                </div>
            }
        </>
    );
}
