import React, {useEffect, useState} from "react";
import {profileUser} from "../../../../assets/images";
import unknownUser from  "../../../../assets/images/user_unknown.png"
import axiosInstance from "../../../../api";
import Upload from "rc-upload";
import {Loading} from "../../../../components";

export function Profile() {
    let [user, setUser] = useState({})
    let [loading, setLoading] = useState(false)
    let [imgFile, setImgFile] = useState('')
    let [image, setImage] = useState('')
    let [error, setError] = useState('')

    useEffect(() => {
        getUser()
    }, [])

    let getUser = async () => {
        try {
            let response = await axiosInstance.get('api/client/show-profile')
            setUser(response.data)
            setImage(response.data.image)
        } catch (e) {
            console.log(e);
        }
    }
    let changeUser = async () => {
        try {
            if (user.phone_number && user.business_name && user.email && user.name) {
                setLoading(true)
                let formData = new FormData()
                formData.append('phone_number', user.phone_number)
                formData.append('business_name', user.business_name)
                formData.append('email', user.email)
                formData.append('name', user.name)
                if(imgFile){
                    formData.append('image', imgFile)
                }
                await axiosInstance.post('api/client/update-profile', formData)
                setLoading(false)

            } else {
                setError('Please enter all fild')
            }
        } catch (e) {
            setLoading(false)

            console.log(e);
        }
    }

    let handleChange = (value, name) => {
        setUser({...user, ...{[name]: value}})
    }

    return (
        <div className='profileContent'>
            <div className="body">
                <div className="headContent">
                    <div type={'file'}>
                        {image ?
                        <img src={image ? image : profileUser} alt="img" title=''/>
                            :
                            <img src={unknownUser} alt=""/>
                        }
                    </div>
                    <button
                        id="upload-button"
                        className="btn-file upload_img_btn c_white fs_16 f_500 change_pic upload_pic"
                    >
                        <input type="file" onChange={e => {
                            setImage(URL.createObjectURL(e.target.files[0]));
                            setImgFile(e.target.files[0])
                        }}/>
                        Upload profile image
                    </button>
                {/*    {imageUplading ?
                        <div>
                            <button style={{border: `none`}} type={'file'} className="change_pic">Change profile
                                picture
                            </button>
                            <button className="remove_pic">Remove profile picture</button>
                        </div>
                        :
                        false
                    }*/}

                </div>
                <div className="profile_formGroupBlock">
                    <div className="formGroup">
                        <label>
                            Username
                            <input type="text" placeholder='Username' value={user.name}
                                   onChange={(e) => handleChange(e.target.value, 'name')}/>
                        </label>
                        <label>
                            Business Name
                            <input type="text" placeholder='Business Name' value={user.business_name}
                                   onChange={(e) => handleChange(e.target.value, 'business_name')}/>
                        </label>
                    </div>
                    <div className="formGroup">
                        <label>
                            Email
                            <input type="email" placeholder='Email' value={user.email}
                                   onChange={(e) => handleChange(e.target.value, 'email')}
                                   style={{background: "#E4E4E4"}}/>
                        </label>
                        <label>
                            Phone Number
                            <input type="text" placeholder='+1 000 000 0000' value={user.phone_number}
                                   onChange={(e) => handleChange(e.target.value, 'phone_number')}/>

                        </label>

                    </div>
                </div>
                {error ?
                    <p style={{color: 'red'}}>{error}</p>
                    :
                    null
                }
                <div className="profile_btns_b">
                    <button className="discardBtn" onClick={() => {
                        setImage('')
                        setImgFile('')
                        getUser()
                    }}>Discard
                    </button>
                    <button className='saveBtn' onClick={() => changeUser()}>Save changes</button>
                </div>
                <Loading  modalIsOpen={loading}/>
            </div>
        </div>
    );
}
