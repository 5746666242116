import "./style.css";
import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';


export default function  Pagination({  agrements, onChangePage }){
    const pageCount = Math.ceil(agrements.length/ 6);

    const handlePageClick = (event) => {

    };

    return(
        <div>
            <div className="d_flex justify_content_center align_items_center pagination_cont">
                <ReactPaginate
                    breakLabel="..."
                    nextLabel=">"
                    onClick={(e) => {
                        onChangePage(e)
                        console.log(e, "page")
                    }}
                    onPageChange={(e) => {
                        if( 0 < e.selected || e.selected <= pageCount-1){
                            onChangePage({nextSelectedPage: e.selected})
                        }
                    }}
                    pageRangeDisplayed={0}
                    pageCount={pageCount}
                    agrements={agrements}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                    className="pagination"
                />
            </div>
        </div>
    )
}