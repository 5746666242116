import {useEffect, useState} from "react";
import certificate_photo from "../../../../assets/images/certificate_pic.png";
import {useLocation, useNavigate} from "react-router-dom";


export default function Rejected(){
    let navigate=useNavigate()
    let location = useLocation()
    let [agreementProposal, setAgreementProposal] = useState([])

    useEffect(() => {
        if (location.state.proposals) {
            
            setAgreementProposal(location.state.proposals);

        }
    }, [location])

    return (
        <div className="agreement_proposal_cont">
            {
                agreementProposal.map((item, index) => {
                    return (
                      <div className="agreement_proposal_item">
                        <div className="agreement_proposal_item_b1">
                          <div className="agreement_photo_b">
                            <img src={item?.agreement.qr_code} alt="" />
                          </div>
                          <span>{item?.agreement?.certificate_name}</span>
                        </div>
                        <div className="agreement_proposal_btns_b">
                          <button onClick={() => navigate("/rejected_request")}>
                            View
                          </button>
                        </div>
                      </div>
                    );
                })
            }

        </div>
    )
}
