import {useEffect, useRef, useState} from "react"
import {
    visa,
    closeBlack,
    masterCard,
    americanExpress,
} from "../../../../assets/images";
import {useNavigate} from "react-router-dom";
import axiosInstance from "../../../../api";
import * as _ from "lodash";

export function Billing() {
    let navigate=useNavigate();
    const ref = useRef()
    const [on, setOnState] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const [cards, setCards] = useState({})
    const [card, setCard] = useState({})
    const [error, setError] = useState('')

    useEffect(() => {
        const checkIfClickedOutside = e => {
            if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
                setIsMenuOpen(false)
                document.body.classList.remove('showModal')
            }
        }
        document.addEventListener("mousedown", checkIfClickedOutside)

        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }

    }, [isMenuOpen])

    const showModal = () => {
        setIsMenuOpen(oldState => !oldState)
        document.body.classList.add('showModal')

    }
    const closeModal = () => {
        setIsMenuOpen(false)
        document.body.classList.remove('showModal')

    }
    const toggle = () => setOnState(o => !o);

    useEffect(() => {
        getCards()
    }, [])

    let getCards = async () => {
        try {
            let response = await axiosInstance.get('api/client/get-cards')
            setCards(response.data.data)
        }catch (e){
            console.log(e);
        }
    }

    let deleteCards = async (id) => {
        try {
            let response = await axiosInstance.get(`api/client/delete-pm-method/${id}`)
            setCards(response.data.data)
        }catch (e){
            console.log(e);
        }
    }

    let changeInput = async (e, name) => {
        setCard({..._.assignIn(card, {[name]: e})})
        setError('')
    }


    return (
        <>
            <div className='billingContent'>
                <h5>My Cards</h5>

                <div>
                    <h6>Name Lastname</h6>
                    <div className="cardBody">
                    <span>
                        <img src={visa} alt=""/>
                        <div>
                            <h6>Visa ****1546</h6>
                            <p>Card expires at 09/25</p>
                        </div>
                    </span>
                        <span>
                        <button>Delete</button>
                        <button>Edit</button>
                    </span>
                    </div>
                </div>
                <div style={{borderColor: '#DEDFE0'}}>
                    <h6 style={{fontWeight:"bold"}}>Important Note!</h6>
                    <p>Please carefully read <span onClick={()=>navigate("/policy")} style={{color:"#332486", cursor:"pointer"}}>Privacy policy</span> adding your new payment card</p>
                </div>
                <button
                    onClick={showModal}
                >Add Card
                </button>
            </div>
            <div className="modal addCardModal">
                <div className="modalBc"></div>
                {isMenuOpen && (<div className="modalContent" ref={ref}>

                    <div className="modalBody">
                        <button onClick={closeModal} className='closeBtn'>
                            <img src={closeBlack} alt="" title=''/>
                        </button>

                        <h5>Add New Card</h5>
                        <div style={{display: "block"}}>
                            <label>
                                Name On Card
                                <input type="text" placeholder=''/>
                            </label>
                        </div>
                        <div className='cardNumberContent'>
                            <label>
                                Card Number
                                <input type="text" placeholder=''/>
                            </label>
                            <div className='cardsContent'>
                                <img src={visa} alt=""/>
                                <img src={masterCard} alt=""/>
                                <img src={americanExpress} alt=""/>
                            </div>
                        </div>
                        <div className='expirationContent'>
                            <label>
                                Expiration Month
                                <select placeholder="Name">
                                    <option placeholder='1'>1</option>
                                    <option placeholder='2'>2</option>
                                    <option placeholder='3'>3</option>
                                    <option placeholder='4'>4</option>
                                    <option placeholder='5'>5</option>
                                    <option placeholder='6'>6</option>
                                    <option placeholder='7'>7</option>
                                    <option placeholder='8'>8</option>
                                    <option placeholder='9'>9</option>
                                    <option placeholder='10'>10</option>
                                    <option placeholder='11'>11</option>
                                    <option placeholder='12'>12</option>
                                </select>
                            </label>
                            <label>
                                Expiration Year
                                <select placeholder="Name">
                                    <option placeholder='2016'>2016</option>
                                    <option placeholder='2016'>2017</option>
                                    <option placeholder='2016'>2018</option>
                                    <option placeholder='2016'>2019</option>
                                    <option placeholder='2016'>2020</option>
                                    <option placeholder='2016'>2021</option>
                                    <option placeholder='2016'>2022</option>
                                    <option placeholder='2016'>2023</option>
                                </select>
                            </label>
                            <label>
                                CVV
                                <input type="text" placeholder='CVV'/>
                            </label>
                        </div>
                        <div>
                            <p>Save Card for further billing?</p>
                            <div className="saveCard">
                                <button className={on ? 'on' : 'off'} on={on} onClick={toggle}>
                                    <span className="pin"/>
                                </button>
                                Save Card
                            </div>
                        </div>
                        <div className="btnsContent">
                            <button className='light'>Discard</button>
                            <button>Add Card</button>
                        </div>
                    </div>
                </div>)}
            </div>
        </>
    );
}
