import "../style.scss";
import React, {useState} from "react";
import ScrollTop from "../../../components/scrollTop";
import file_upload from "../../../assets/images/icons/file_upload_black_24dp 1.png";
import calendar_icon from "../../../assets/images/icons/calendar.png";
import Calendar from "../../../components/calendar";

export default function AgreementToPartner2() {
    let [definitions, setDefinitions] = useState([
            {
                id: 1,
                td1: "Application:",
                td2: "To court by a partner / creditor / interested party to wind up the partnership.",
            },
            {
                id: 2,
                td1: "Combination:",
                td2: "Bringing together of either IP or resources schedule [1A]",
            },
            {
                id: 3,
                td1: "Grantor / Partner:",
                td2: "The initial partner who grants their IP to be partnered with other users.",
            },
            {
                id: 4,
                td1: "IP:",
                td2: "Intellectual property.",
            },
            {
                id: 5,
                td1: "MPSR 2017:",
                td2: "The Movable Properties Securities Rights Act of 2017.",
            },
            {
                id: 6,
                td1: "Partnership:",
                td2: "The coming together of two or more users to combine intellectual property to achieve a goal.[7.1.1] & or for a period of time. [7.1]",
            },
            {
                id: 7,
                td1: "Extinction:",
                td2: "The bringing to an end of the partnership. [7.2.2]",
            }
        ]
    )
    let [addDescription, setAddDescription] = useState([
        {
            id: 1,
            partnerName: "GRANTOR / PARTNER",
            propertyName: "NAME OF INTELLECTUAL PROPERTY",
        },
        {
            id: 2,
            partnerName: "PARTNER A",
            propertyName: "NAME OF INTELLECTUAL PROPERTY",
        },
        {
            id: 3,
            partnerName: "PARTNER B",
            propertyName: "NAME OF INTELLECTUAL PROPERTY",
        },
    ])
    let [partner, setpartner] = useState([
        {
            id: 1,
            partnerName: "PARTNER A [1.2]",
            nameOfProperty: "NAME OF INTELLECTUAL PROPERTY",
            amount: "",
            contribution: "Define (max 100 characters)",
        },
        {
            id: 2,
            partnerName: "PARTNER B [1.3]",
            nameOfProperty: "NAME OF INTELLECTUAL PROPERTY",
            amount: "",
            contribution: "Define (max 100 characters)",
        },
        {
            id: 3,
            partnerName: "PARTNER C [1.4]",
            nameOfProperty: "NAME OF INTELLECTUAL PROPERTY",
            amount: "",
            contribution: "Define (max 100 characters)",
        },
        {
            id: 4,
            partnerName: "PARTNER D [1.5]",
            nameOfProperty: "NAME OF INTELLECTUAL PROPERTY",
            amount: "",
            contribution: "Define (max 100 characters)",
        }
    ])

    let onChangeFileInput = () => {
        document.querySelector("#files").onchange = function () {
            const fileName = this.files[0]?.name;
            const label = document.querySelector("label[for=files]");
            label.innerText = fileName ? fileName : "fileName";
        };
    }

    let AddPertnershipDetails = () => {
        addDescription.push({
            id: 5,
            partnerName: 'bvrefg',
            propertyName: 'wregf',
        })
        setAddDescription([...addDescription])
    }
    let handleClickAddPertner = () => {
        partner.push(
            {
                id: 8,
                partnerName: "PARTNER E [1.6]",
                nameOfProperty: "NAME OF INTELLECTUAL PROPERTY",
                amount: "",
                contribution: "Define (max 100 characters)",
            }
        )
        setpartner([...partner])
    }
    return (
        <>
            <ScrollTop/>
            <div className="CertificatesViewApply_header">
                <h2 className="search_text">IP name here</h2>
            </div>
            <div className='agrrement_cont'>
                <div className="agrrement_cont_header">
                    <h2 className="text_center">AGREEMENT TO ENTER INTO PARTNERSHIP ON, WITH & OR USING INTELLECTUAL
                        PROPERTY [IP] AUTOMATED NEGOTIATION CONTRACT</h2>
                </div>
                <div className="agrrement_cont_body margin_top_24">
                    <h4 className="margin_top_24">Definitions:.</h4>
                    <table id="definitons">
                        {
                            definitions.map((item, index) => {
                                return (
                                    <tr key={item.id}>
                                        <td>{item.td1}</td>
                                        <td>{item.td2}</td>
                                    </tr>
                                )
                            })
                        }
                    </table>
                    <div className="hr_line">
                    </div>
                    <h5 className="">A. Recital:</h5>
                    <div>
                        This agreement shall be an agreement to enter into partnership and combine intellectual property
                        mentioned in order to achieve a certain goal or for a defined period of time.
                    </div>
                    <div className="margin_top_24">
                        The grantor / partner shall grant the other partners the right to combine intellectual property,
                        capital, expertise etc. into a partnership agreement to fulfill a certain goal or for a defined
                        duration.
                    </div>
                    <div className="margin_top_24">
                        If the Grantor / Partner is a joint - owner with another party [share ownership with someone
                        else in the form of :
                    </div>
                    <div className="margin_top_24">
                        <span>[OWNERS IN COMMON] </span> common defensible share in the property. The Grantor / Partner
                        shall agree to
                        use their share in the agreement to partner on intellectual property, if they own the
                        intellectual property in co ownership.
                    </div>
                    <div className="margin_top_24">
                        The Grantor / Partner is guided by co ownership as they may only partner with their share of the
                        intellectual property.
                    </div>
                    <div className="margin_top_24">
                        [This agreement will not apply to joint-ownership as survivorship takes effect]
                    </div>
                    <h5 className="margin_top_24">A. Recital:</h5>
                    <h5>Every party to this agreement shall be issued a vote this vote shall be used to resolve issues
                        that may arise as per.</h5>
                    <div className="margin_top_24">
                        The partners shall be able to vote during the partnership.
                    </div>
                    <div className="margin_top_16">
                        <div className="">
                            <textarea name="" id="" cols="" rows=""
                                      style={{
                                          background: "white",
                                          width: "100%"
                                      }}>Decision (max 100 characters)</textarea>
                        </div>
                    </div>
                    <div className="margin_top_16">
                        <div className="">
                            <textarea name="" id="" cols="" rows=""
                                      style={{
                                          background: "white",
                                          width: "100%"
                                      }}>Suggestion (max 100 characters)</textarea>
                        </div>
                    </div>
                    <div className="margin_top_16">
                        <span style={{float: "right"}}>[Section 15 of the Partnership Act 15 (2) 0rdinary matter]</span>
                    </div>
                    <h5 className="margin_top_16">This vote shall be active during the subsistence of this partnership
                        aims and duration.</h5>
                    <div className="margin_top_24">
                        The vote mechanism between the parties to this agreement shall be a mechanism of dispute
                        resolution between the parties.
                    </div>
                    <h5 className="margin_top_24">
                        Each partner shall be issued one vote [1] to vote and place on issues that may arise during the
                        management of the partnership.
                    </h5>
                    <div className="hr_line">
                    </div>
                    <div className="agrrement_cont_header">
                        <h2 className="text_center">DESCRIPTION OF INTELLECTUAL PROPERTY</h2>
                    </div>
                    <h5>THE SUBJECT MATTER [S] of this agreement to enter into partnership shall be:</h5>
                    {
                        addDescription.map((item, indec) => {
                            return (
                                <div key={item.id}>
                                    <div className="d_flex align_center margin_top_16">
                                        <h5 style={{marginRight: "24px"}}>{item.partnerName}</h5>
                                        <div className="filled_data_input">
                                            <input type="text" placeholder="NAME OF INTELLECTUAL PROPERTY"
                                                   value={item.propertyName}/>
                                        </div>
                                    </div>
                                    <div className="margin_top_12">
                                        <span style={{float: "right"}}>[Section 8 of the MPSR Act]</span>
                                    </div>
                                </div>
                            )
                        })
                    }

                    <div className="d_flex justify_content_start" onClick={AddPertnershipDetails}>
                        <button className="save_btn" style={{marginLeft: "0px"}}>Add partner</button>
                    </div>
                    <div className="margin_top_12">
                        <span style={{float: "right"}}>[Section 8 of the MPSR Act]</span>
                    </div>
                    <div className="margin_top_24">
                        Where the partners herein have made it known to each other the reason for the partnership of
                        [IP] there is an implied condition that the goods shall be fit for the purpose, that was
                        communicated to all partners.
                    </div>
                    <div className="margin_top_12">
                        <span style={{float: "right"}}>[Section 16 (a) Sale of Goods Act]</span>
                    </div>
                    <div className="hr_line">
                    </div>
                    <div>
                        <span>The combining of ownership in this agreement to partner</span>
                        shall be guided by the relevant sections
                        of the Partnership Act. and shall be deemed binding once the same agreement has been registered
                        at both The Partnership registry or at the Movable property Security rights registry. by 6 a
                        Partnership notice being lodged and accurately capable of depicting the Combination in ownership
                        within this agreement to partner for a specific <span>goal or period of time.</span>
                    </div>
                    <div className="hr_line">
                    </div>
                    <h5>LAWS:</h5>
                    <div>
                        This agreement shall be governed by the Contract Laws of Kenya, The relevant intellectual
                        property laws, governing the mentioned property above, such as the Copyright Act No 12 of 2001,
                        The Trademark Act CAP 506, The Industrial Property Act No 3 of 2001, The Movable Properties
                        Securities Act 2017. Supported by the relevant sections of the Companies Act 2015, The
                        Registration of Business Names Act and Amendment Bill 2021, the Partnership Act 2012 The Kenya
                        Seed and Plant Varieties Act and any other laws that may be required, expressly and implied to
                        the aims of this agreement which are to bring users of the system together to partner with their
                        Intellectual property to achieve a goal or for a set period of time.
                    </div>
                    <div className="margin_top_24">
                        International treaties shall apply to their category of intellectual property governed by each
                        jurisdiction. WCT WIPO copyright treaty, TRIPS trade related aspects of intellectual property,
                        Marrakesh Agreement, The Paris Convention, The Madrid Protocol, Lisbon agreement, Hague
                        agreement. UPOV Convention.
                    </div>
                    <div className="margin_top_24">
                        Practice: Uniform Standards For Intellectual Property Rights Accountability.
                    </div>
                    <div>
                        <span>1. THIS </span> agreement to enter into partnership is between: <span>[PARTIES]</span>
                    </div>
                    <div className="margin_top_24">
                        <span>PARTY 1.1.: GRANTOR / PARTNER:</span>
                    </div>
                    <div className="apply_certificate_item margin_top_12">
                        <div className="certificate_item">
                            <label htmlFor="">Email</label>
                            <input type="email" placeholder=""/>
                        </div>
                        <div className="certificate_item file_upload" style={{position: "relative"}}>
                            <p>Passport picture</p>
                            <div className="input_container">
                                <img src={file_upload} alt="file_upload" className="file_upload_img"/>
                                <label htmlFor="files" className="labelbtn">Upload here</label>
                                <input type="file" id="files" placeholder="Passport" onChange={onChangeFileInput}/>
                            </div>
                        </div>
                    </div>
                    <div className="apply_certificate_item">
                        <div className="certificate_item">
                            <label htmlFor="">Nationality</label>
                            <input type="text" value="" placeholder=""/>
                        </div>
                        <div className="certificate_item">
                            <label htmlFor="">Location</label>
                            <input type="text" value="" placeholder=""/>
                        </div>
                    </div>
                    <div>
                        <span style={{float: "right"}}>[Jurisdiction clause 10]</span>
                    </div>
                    <h5 className="margin_top_32">PARTY 1.2.: INTENDED PARTNER A:</h5>
                    <div className="apply_certificate_item margin_top_12">
                        <div className="certificate_item filled_data_input">
                            <input type="text" placeholder="namesurname@gmail.com"/>
                        </div>
                        <div className="certificate_item filled_data_input">
                            <input type="text" placeholder="passport pic.jpeg"/>
                        </div>
                    </div>
                    <div className="apply_certificate_item">
                        <div className="certificate_item filled_data_input">
                            <input type="text" placeholder="Nationality"/>
                        </div>
                        <div className="certificate_item filled_data_input">
                            <input type="text" placeholder="Location"/>
                        </div>
                    </div>
                    <div>
                        <span style={{float: "right"}}>[Jurisdiction clause 10]</span>
                    </div>
                    <div className="hr_line">
                    </div>
                    <h5>SCHEDULE 1.A</h5>
                    <div className="margin_top_24">This table is the contents that the partners will contribute into
                        this agreement to partner.
                    </div>
                    <h5 className="margin_top_24">PARTNERSHIP DETAILS: </h5>
                    <h5 className="margin_top_24">GRANTOR / PARTNER [1.1]</h5>
                    <div className="margin_top_24">
                        Is the partner that has offered their intellectual property to enter or to be used as and part
                        of the agreement to enter into partnership with
                    </div>
                    <div className="d_flex align_center margin_top_16">
                        <div className="filled_data_input">
                            <input type="text" placeholder="NAME OF INTELLECTUAL PROPERTY"/>
                        </div>
                    </div>
                    <div className="margin_top_12">
                        <span style={{float: "right"}}>[Section 6 (3) (b) of the MPSR Act 2017]</span>
                    </div>
                    <div className="margin_top_16">Or NiL [IP]</div>
                    <div className="margin_top_16">Offer contribution [Cash]</div>
                    <div className="apply_certificate_item">
                        <div className="certificate_item">
                            <label htmlFor="">Amount</label>
                            <input type="text" value="" placeholder=""/>
                        </div>
                        <div className="certificate_item">
                        </div>
                    </div>
                    <div>
                        <div className="">
                            Offer contribution [Expertise]
                            <textarea name="" id="" cols="" rows=""
                                      style={{
                                          background: "white",
                                          width: "100%"
                                      }}>Define (max 100 characters)</textarea>
                        </div>
                    </div>
                    <div>
                        {
                            partner.map((item, index) => {
                                return (
                                    <div key={item.id}>
                                        <div className="hr_line">
                                        </div>
                                        <h5 className="margin_top_8">{item.partnerName}</h5>
                                        <div className="margin_top_24">
                                            Hereby grants intellectual property named
                                        </div>
                                        <div className="d_flex align_center margin_top_16">
                                            <div className="filled_data_input">
                                                <input type="text" placeholder="NAME OF INTELLECTUAL PROPERTY"
                                                       value={item.nameOfProperty}/>
                                            </div>
                                        </div>
                                        <div className="margin_top_12">
                                            to be included into this agreement to partner.
                                        </div>
                                        <div className="margin_top_12">
                                            <span style={{float: "right"}}>[Section 8 of the MPSR 2017]</span>
                                        </div>
                                        <div className="margin_top_16">Or NiL [IP]</div>
                                        <div className="margin_top_16">Offer contribution [Cash]</div>
                                        <div className="apply_certificate_item">
                                            <div className="certificate_item">
                                                <label htmlFor="">Amount</label>
                                                <input type="text" value={item.amount} placeholder=""/>
                                            </div>
                                            <div className="certificate_item">
                                            </div>
                                        </div>
                                        <div>
                                            <div className="">
                                                Offer contribution [Expertise]
                                                <textarea name="" id="" cols="" rows=""
                                                          style={{
                                                              background: "white",
                                                              width: "100%"
                                                          }}>{item.contribution}</textarea>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="d_flex justify_content_start margin_top_24" onClick={handleClickAddPertner}>
                        <button className="save_btn" style={{marginLeft: "0px"}}>Add partner</button>
                    </div>
                    <div className="hr_line">
                    </div>
                    <h5>Extinction of partnership</h5>
                    <ul style={{listStyle: "square"}} className="partnership_list">
                        <li>if partners numbers fall below 2</li>
                        <li>Fix term expires [7.1]</li>
                        <li>Court order</li>
                        <li>[application of Partner / Minister / Court]</li>
                    </ul>
                    <div className="margin_top_12">
                        <span style={{float: "right"}}>[Section 35 of the Partnership Act 2012]</span>
                    </div>
                    <div className="hr_line">
                    </div>
                    <h5>1.3. PRE-CONTRACTUAL DOCUMENTATION:</h5>
                    <div className="margin_top_12">
                        PARTY 1: GRANTOR / PARTNER:
                    </div>
                    <div className="apply_certificate_item margin_top_12">
                        <div className="certificate_item file_upload" style={{position: "relative"}}>
                            <p>ID and KPA PIN</p>
                            <div className="input_container">
                                <img src={file_upload} alt="file_upload" className="file_upload_img"/>
                                <label htmlFor="files" className="labelbtn">Upload here</label>
                                <input type="file" id="files" placeholder="Passport" onChange={onChangeFileInput}/>
                            </div>
                        </div>
                        <div className="certificate_item file_upload" style={{position: "relative"}}>
                            <p>Resolution to sell</p>
                            <div className="input_container">
                                <img src={file_upload} alt="file_upload" className="file_upload_img"/>
                                <label htmlFor="files" className="labelbtn">Upload here</label>
                                <input type="file" id="files" placeholder="Passport" onChange={onChangeFileInput}/>
                            </div>
                        </div>
                    </div>
                    <div className="apply_certificate_item">
                        <div className="certificate_item file_upload" style={{position: "relative"}}>
                            <p>Passport picture</p>
                            <div className="input_container">
                                <img src={file_upload} alt="file_upload" className="file_upload_img"/>
                                <label htmlFor="files" className="labelbtn">Upload here</label>
                                <input type="file" id="files" placeholder="Passport" onChange={onChangeFileInput}/>
                            </div>
                        </div>
                        <div className="certificate_item file_upload" style={{position: "relative"}}>
                            <p>Front page of national identity card</p>
                            <div className="input_container">
                                <img src={file_upload} alt="file_upload" className="file_upload_img"/>
                                <label htmlFor="files" className="labelbtn">Upload here</label>
                                <input type="file" id="files" placeholder="Passport" onChange={onChangeFileInput}/>
                            </div>
                        </div>
                    </div>
                    <div className="hr_line">
                    </div>
                    <h5>2. DUE DILIGENCE:</h5>
                    <div className="margin_top_12">
                    <span>Every contractual negotiation shall submit a due diligence search to the registry
                        and across the Intellectual property depository system. These search results shall aid
                        the users in understanding the true position, as to ownership of the [IP] intellectual property in question.</span>
                    </div>
                    <div className="margin_top_24">
                        <span> Every partner shall pay for the search fee of their own [IP]</span>
                    </div>
                    <h5 className="margin_top_24">DUE DILIGENCE:</h5>
                    <div className="margin_top_12">
                        This is a tri-part 3part search that will enable the parties to know the true position of
                        ownership of the subject matter of this agreement to partner.
                    </div>
                    <div className="filled_data_input margin_top_8">
                        <input type="text" placeholder="NAME OF INTELLECTUAL PROPERTY"/>
                    </div>
                    <div className="margin_top_12">
                        <span style={{float: "right"}}>[Section 8 of the MPSR Act 2017]</span>
                    </div>
                    <h5 className="margin_top_32">2.1. SEARCH ONE:</h5>
                    <div className="margin_top_12">PRE-SEARCH: Both parties hereby consent to conduct an immediate
                        search:
                    </div>
                    <div className="d_flex align_center margin_top_12">
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="No" value="No" name="radio"
                            />
                            No
                        </label>
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="yes" value="yes" name="radio" checked="checked"
                            />
                            Yes
                        </label>
                    </div>
                    <h5 className="margin_top_32">2.2. SEARCH TWO:</h5>
                    <p className="margin_top_12">SEARCH NOW:</p>
                    <div className="margin_top_12">This is the intellectual property depository directory search, to
                        confirm the information of the parties to this contract are correct, and that the position
                        of ownership of the intellectual property named.
                    </div>
                    <div className="d_flex align_center margin_top_8">
                        <div className="filled_data_input" style={{marginRight: "24px"}}>
                            <input type="text" placeholder="NAME OF INTELLECTUAL PROPERTY"/>
                        </div>
                        <h5>[Section 8 of the MPSR Act 2017]</h5>
                    </div>
                    <p className="margin_top_8">is accurately captured in this agreement to partner.</p>
                    <div className="margin_top_16">
                        <span style={{float: "right"}}>[Section 34 of MPSR Act 2017]</span>
                    </div>
                    <h5 className="margin_top_32">2.3. SEARCH THREE:</h5>
                    <p className="margin_top_12">POST SEARCH:</p>
                    <div className="margin_top_12">This is the search conducted at the Movable Properties Security
                        Registry to ensure that this combination of the asset.
                    </div>
                    <div className="d_flex align_center margin_top_8">
                        <div className="filled_data_input" style={{marginRight: "24px"}}>
                            <input type="text" placeholder="NAME OF INTELLECTUAL PROPERTY"/>
                        </div>
                    </div>
                    <div className="margin_top_8">in this agreement to partner reflects the
                        <span>new partnership and combination of ownership for a specified goal and of time.</span>
                    </div>
                    <div className="hr_line">
                    </div>
                    <h5>3. CONFIDENTIALITY:</h5>
                    <div className="margin_top_12">
                        The grantor /partner and the intended partners <span>[A-X]</span> shall be deemed to have
                        created a
                        confidentiality arrangement, where the grantor/ partner and the partners [A-X] shall not
                        reveal
                        the <span>know-how</span> or intricacies, trade secrets or business operation models of the
                        property
                        mentioned:-
                    </div>
                    <div className="d_flex align_center margin_top_8">
                        <div className="filled_data_input" style={{marginRight: "24px"}}>
                            <input type="text" placeholder="NAME OF INTELLECTUAL PROPERTY"/>
                        </div>
                    </div>
                    <div className="margin_top_8">
                        to anyone who is not the <span>Partner [A-_X]</span> or Partner / grantor or on strict,
                        written
                        instructions
                        from the partner [s], to reveal any information to agents, employees, distributors etc.
                    </div>
                    <div className="d_flex align_center margin_top_24">
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="No" value="No" name="radio1"
                            />
                            No
                        </label>
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="yes" value="yes" name="radio1" checked="checked"
                            />
                            Yes
                        </label>
                    </div>
                    <div className="margin_top_24">
                        ANY THIRD PARTY CLAIMS AGAINST THE [IP]
                    </div>
                    <div className="d_flex align_center margin_top_12">
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="No" value="No" name="radio2"
                            />
                            No
                        </label>
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="yes" value="yes" name="radio2" checked="checked"
                            />
                            Yes
                        </label>
                    </div>
                    <div className="margin_top_16">
                        <div className="">
                            <textarea name="" id="" cols="" rows=""
                                      style={{background: "white", width: "100%"}}>Max 100 characters</textarea>
                        </div>
                    </div>
                    <div className="d_flex align_center form-group margin_top_12">
                        <input type="checkbox" id="checkbox" name="checkbox"/>
                        <label htmlFor="checkbox">I understand the above disclosure</label>
                    </div>
                    <div className="margin_top_32">
                        ANY THIRD PARTY CLAIMS AGAINST THE [IP]
                    </div>
                    <div className="d_flex align_center margin_top_12">
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="No" value="No" name="radio3"
                            />
                            No
                        </label>
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="yes" value="yes" name="radio3" checked="checked"
                            />
                            Yes
                        </label>
                    </div>
                    <h5 className="margin_top_32">
                        3.1. ANY INFRINGEMENT CAUSES IN COURT OR ARBITRATION I INVOLVING [IP]
                    </h5>
                    <div className="d_flex align_center margin_top_12">
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="No" value="No" name="radio4"
                            />
                            No
                        </label>
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="yes" value="yes" name="radio4" checked="checked"
                            />
                            Yes
                        </label>
                    </div>
                    <div className="margin_top_16">
                        <div className="">
                            <textarea name="" id="" cols="" rows=""
                                      style={{background: "white", width: "100%"}}>Max 100 characters</textarea>
                        </div>
                    </div>
                    <div className="hr_line">
                    </div>
                    <h5>EACH PARTNER</h5>
                    <div>If there has been a previous disclosure kindly tick</div>
                    <div className="d_flex align_center margin_top_12">
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="No" value="No" name="radio5"
                            />
                            No
                        </label>
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="yes" value="yes" name="radio5" checked="checked"
                            />
                            Yes
                        </label>
                    </div>
                    <div className="margin_top_32">
                        Does the above disclosure come into direct conflict with the Partnership of this
                    </div>
                    <div className="d_flex align_center margin_top_8">
                        <div className="filled_data_input" style={{marginRight: "24px"}}>
                            <input type="text" placeholder="NAME OF INTELLECTUAL PROPERTY"/>
                        </div>
                    </div>
                    <div className="margin_top_8">
                        <span style={{float: "right"}}>[Section 8 Of MPSR Act 2017]</span>
                    </div>
                    <div className="margin_top_16">
                        Is the <span>[PARTNER]</span> aware of this disclosure?
                    </div>
                    <div className="hr_line">

                    </div>
                    <h5>4. PARTNERSHIP PARTIES DISCLOSURE ACCEPTANCE:</h5>
                    <div className="margin_top_12">
                        The partner grantor and partner <span> [d] </span>hereby confirms that the grantor has
                        disclosed
                        the relevant
                        information on a previous disclosure of know-how and that the disclosure will in no way
                        cause
                        any damage, breach of implied warranty to the agreement to enter into partnership mentioned
                        herein.
                    </div>
                    <div className="d_flex align_center margin_top_12">
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="No" value="No" name="radio6"
                            />
                            No
                        </label>
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="yes" value="yes" name="radio6" checked="checked"
                            />
                            Yes
                        </label>
                    </div>
                    <div className="hr_line">
                    </div>
                    <h5>5. OWNERSHIP</h5>
                    <div className="margin_top_8">
                        <span style={{float: "right"}}>[Section 6 (1) of the MPSR Act]</span>
                    </div>
                    <div className="margin_top_12">
                        Every partner warrants that the ownership of the intellectual property
                    </div>
                    <div className="d_flex align_center margin_top_12">
                        <div className="filled_data_input" style={{marginRight: "24px"}}>
                            <input type="text" placeholder="NAME OF INTELLECTUAL PROPERTY"/>
                        </div>
                    </div>
                    <div className="margin_top_8">
                        <span style={{float: "right"}}>[Section 8 of the MPSR Act 2017]</span>
                    </div>
                    <div className="margin_top_24">
                        Is their property and has the relevant capacity to enter into partnership and combine
                        ownership
                        of the [IP] to that of the Partner [S]. Any transaction entered into after formation of this
                        agreement to partner will be for the general good of all the partners involved. As
                        presumption
                        of partnership has been activated and executed by signing this agreement, and will be
                        subsisting
                        until the goals or duration of partnership are met. This agreement to partner shall be
                        binding
                        while in its duration and goals on the partners agents and personal representatives.
                    </div>
                    <div className="d_flex align_center margin_top_12">
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="No" value="No" name="radio7"
                            />
                            No
                        </label>
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="yes" value="yes" name="radio7" checked="checked"
                            />
                            Yes
                        </label>
                    </div>
                    <div className="margin_top_24">
                        Are you a co owner in the intellectual property [IP]?
                    </div>
                    <div className="d_flex align_center margin_top_16">
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="No" value="No" name="radio8"
                            />
                            No
                        </label>
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="yes" value="yes" name="radio8" checked="checked"
                            />
                            Yes
                        </label>
                    </div>
                    <h5 className="margin_top_32">5.1. CO OWNERSHIP STRUCTURE:</h5>
                    <div className="margin_top_12">
                        The co-owner shall agree to sell their share in the intellectual property, if they own the
                        intellectual property in co ownership. What is the.
                    </div>
                    <h5 className="margin_top_24">5.1.1.</h5>
                    <div className="apply_certificate_item margin_top_12">
                        <div className="certificate_item">
                            <label htmlFor="">Percentage that you own in the IP</label>
                            <input type="text" value="" placeholder=""/>
                        </div>
                        <div className="certificate_item">

                        </div>
                    </div>
                    <h5 className="margin_top_8">GRANTOR PARTNER:</h5>
                    <div className="margin_top_12">I have informed the other co-owner of my intention to Partner my
                        share in the intellectual
                        property. Send Notice of intention to sell share to Co-Owner:
                    </div>
                    <div className="apply_certificate_item margin_top_12">
                        <div className="certificate_item">
                            <label htmlFor="">Email of external co owner</label>
                            <input type="text" value="" placeholder=""/>
                        </div>
                        <div className="certificate_item">

                        </div>
                    </div>
                    <h5 className="margin_top_8">NOTICE OF INTENTION TO PARTNER:</h5>
                    <div className="apply_certificate_item margin_top_12">
                        <div className="certificate_item">
                            <label htmlFor="">Username</label>
                            <input type="text" value="" placeholder=""/>
                        </div>
                        <div className="certificate_item">
                            <label htmlFor="">Co-own (IP Name)</label>
                            <input type="text" value="" placeholder=""/>
                        </div>
                    </div>
                    <div className="margin_top_8">
                        AND THIS IS NOTICE OF MY INTENTION TO PARTNER MY SHARE IN THE PROPERTY TO:
                    </div>
                    <div className="apply_certificate_item margin_top_12">
                        <div className="certificate_item">
                            <label htmlFor="">Intention to Partner</label>
                            <input type="text" value="" placeholder=""/>
                        </div>
                        <div className="certificate_item">
                            <label htmlFor="">IPD address of co owner</label>
                            <input type="text" value="" placeholder=""/>
                        </div>
                    </div>
                    <div className="apply_certificate_item margin_top_12">
                        <div className="certificate_item">
                            <label htmlFor="">Telephone number of partner</label>
                            <input type="text" value="" placeholder=""/>
                        </div>
                        <div className="certificate_item">

                        </div>
                    </div>
                    <div className="apply_certificate_item margin_top_12">
                        <div className="certificate_item filled_data_input">
                            <input type="text" placeholder="Date"/>
                        </div>
                        <div className="certificate_item filled_data_input">
                            <input type="text" placeholder="Time"/>
                        </div>
                    </div>
                    <div className="d_flex align_center form-group">
                        <input type="checkbox" id="checkbox1" name="checkbox1"/>
                        <label htmlFor="checkbox1">Confirm receipt.</label>
                    </div>
                    <h5 className="margin_top_32">5.2. EXTERNAL CO OWNER RESPONSE FROM EMAIL: </h5>
                    <div className="apply_certificate_item margin_top_12">
                        <div className="certificate_item filled_data_input">
                            <input type="text" placeholder="Email of Co-owner"/>
                        </div>
                        <div className="certificate_item filled_data_input">
                        </div>
                    </div>
                    <h5 className="margin_top_8">CO-OWNER:</h5>
                    <div className="margin_top_12">
                        I understand that the co owner named in the this notice is desirous of CHARGING their share
                        in
                        the intellectual property named.
                    </div>
                    <div className="d_flex align_center margin_top_8">
                        <div className="filled_data_input" style={{marginRight: "24px"}}>
                            <input type="text" placeholder="NAME OF INTELLECTUAL PROPERTY"/>
                        </div>
                        <h5>[Section 8 of the MPSR Act 2017]</h5>
                    </div>
                    <div className="margin_top_32">
                        I am a co owner in the above intellectual property?
                    </div>
                    <div className="d_flex align_center margin_top_12">
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="No" value="No" name="radio9"
                            />
                            No
                        </label>
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="yes" value="yes" name="radio9" checked="checked"
                            />
                            Yes
                        </label>
                    </div>
                    <div className="margin_top_32">
                        Would you want to enter into partnership with.
                    </div>
                    <div className="d_flex align_center margin_top_12">
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="No" value="No" name="radio10"
                            />
                            No
                        </label>
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="yes" value="yes" name="radio10" checked="checked"
                            />
                            Yes
                        </label>
                    </div>
                    <div className="margin_top_16">
                        <div className="">
                            <textarea name="" id="" cols="" rows=""
                                      style={{background: "white", width: "100%"}}>Max 100 characters</textarea>
                        </div>
                    </div>
                    <div className="hr_line">
                    </div>
                    <h5>CO -OWNERSHIP INITIAL NOTICE</h5>
                    <div className="apply_certificate_item margin_top_12">
                        <div className="certificate_item filled_data_input">
                            <input type="text" placeholder="NAME OF INTELLECTUAL PROPERTY"/>
                        </div>
                        <div className="certificate_item filled_data_input">
                            <input type="text" placeholder="Date"/>
                        </div>
                    </div>
                    <h5 className="margin_top_8">I CO OWNER of INTELLECTUAL PROPERTY MENTIONED ABOVE: HEREBY GIVE
                        NOTICE:</h5>
                    <div className="margin_top_8">That I am a co-owner of the same property to the ratio</div>
                    <div className="apply_certificate_item margin_top_12">
                        <div className="certificate_item">
                            <label htmlFor="">to myself</label>
                            <input type="text" value="" placeholder=""/>
                        </div>
                        :
                        <div className="certificate_item">
                            <label htmlFor="">.</label>
                            <input type="text" value="" placeholder=""/>
                        </div>
                    </div>
                    <div>
                        That this notice should be registered against the intellectual property name at the Movable
                        Properties Securities
                    </div>
                    <div>Register;</div>
                    <ul className="partnership_list margin_top_24">
                        <li> Amend the certificate/register to include the details of this agreement to enter into
                            partnership. And The governing and issuing authority that issued the certificate.
                        </li>
                    </ul>
                    <div className="hr_line">

                    </div>
                    <h5>Extinction of partnership</h5>
                    <ul className="partnership_list">
                        <li>if partners numbers fall below 2</li>
                        <li>Fix term expires [7.1]</li>
                        <li>Court order</li>
                        <li>[application of Partner / Minister / Court]</li>
                    </ul>
                    <div className="margin_top_12">
                        <span style={{float: "right"}}>[Section 35 of the Partnership Act 2012]</span>
                    </div>
                    <div className="margin_top_12">
                        Confirm: Lodge Notice Now
                    </div>
                    <div className="d_flex align_center margin_top_12">
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="No" value="No" name="radio11"
                            />
                            No
                        </label>
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="yes" value="yes" name="radio11" checked="checked"
                            />
                            Yes
                        </label>
                    </div>
                    <div className="hr_line">

                    </div>
                    <h5>6. COMBINATION OF [IP] OWNERSHIP INTO PARTNERSHIP AND ACCESS:</h5>
                    <div className="d_flex align_center form-group margin_top_12">
                        <input type="checkbox" id="checkbox2" name="checkbox"/>
                        <label htmlFor="checkbox2">The intended partners to this agreement to partner hereby admit and
                            warrant that they have the exclusive rights to consent and authorize the agreement to
                            partner and access to all relevant material to the agreement partner to enable the
                            conclusion of this agreement/partnership.</label>
                    </div>
                    <div className="apply_certificate_item margin_top_12">
                        <div className="certificate_item filled_data_input">
                            <input type="text" placeholder="NAME OF INTELLECTUAL PROPERTY"/>
                        </div>
                        <div className="certificate_item filled_data_input">
                        </div>
                    </div>
                    <div>
                        DOCUMENTATION ATTACHED TO AGREEMENT TO ENTER INTO PARTNERSHIP:
                    </div>
                    <div className="margin_top_24">
                        The documentation and access will include:
                    </div>
                    <h5> The GRANTOR/ PARTNER AND PARTNER A-X have agreed to supply these to the agreement to partner
                        at</h5>
                    <h5 className="margin_top_24">A.</h5>
                    <div className="apply_certificate_item margin_top_12">
                        <div className="certificate_item filled_data_input">
                            <input type="text" placeholder="Later Date"/>
                        </div>
                        <div className="certificate_item filled_data_input">
                        </div>
                    </div>
                    <div>
                        Registration Certificate of property named
                    </div>
                    <div className="apply_certificate_item margin_top_12">
                        <div className="certificate_item filled_data_input">
                            <input type="text" placeholder="NAME OF INTELLECTUAL PROPERTY"/>
                        </div>
                        <div className="certificate_item filled_data_input">
                        </div>
                    </div>
                    <h5 className="margin_top_8">6.1. PARTNER CONTRIBUTION TO AGREEMENT TO ENTER INTO PARTNERSHIP</h5>
                    <div className="margin_top_12">Hereby grants intellectual property named</div>
                    <div className="apply_certificate_item margin_top_12">
                        <div className="certificate_item filled_data_input">
                            <input type="text" placeholder="NAME OF INTELLECTUAL PROPERTY"/>
                        </div>
                        <div className="certificate_item filled_data_input">
                        </div>
                    </div>
                    <div>to be included into this agreement</div>
                    <div className="margin_top_12">
                        <span style={{float: "right"}}>[Section 8 of the MPSR 2017]</span>
                    </div>
                    <p className="margin_top_16">Or NiL [IP]</p>
                    <p className="margin_top_16">Offer contribution [Cash]</p>
                    <div className="apply_certificate_item margin_top_12">
                        <div className="certificate_item">
                            <label htmlFor="">Amount</label>
                            <input type="text" value="" placeholder=""/>
                        </div>
                        <div className="certificate_item">
                        </div>
                    </div>
                    <div className="margin_top_16">
                        <div className="">
                            <textarea name="" id="" cols="" rows=""
                                      style={{
                                          background: "white",
                                          width: "100%"
                                      }}>Decision (max 100 characters)</textarea>
                        </div>
                    </div>
                    <h5 className="margin_top_24">PARTNERSGIP MATERIAL</h5>
                    <div className="d_flex align_center form-group margin_top_12">
                        <input type="checkbox" id="checkbox3" name="checkbox"/>
                        <label htmlFor="checkbox3"> <span>B.</span> Brochures, posters, promotional material.</label>
                    </div>
                    <div className="apply_certificate_item">
                        <div className="certificate_item file_upload" style={{position: "relative"}}>
                            <div className="input_container">
                                <img src={file_upload} alt="file_upload" className="file_upload_img"/>
                                <label htmlFor="files" className="labelbtn">Upload file</label>
                                <input type="file" id="files" placeholder="Passport" onChange={onChangeFileInput}/>
                            </div>
                        </div>
                    </div>
                    <div className="d_flex align_center form-group">
                        <input type="checkbox" id="checkbox4" name="checkbox"/>
                        <label htmlFor="checkbox4"> <span>C.</span> Valuation report and insurance policy</label>
                    </div>
                    <div className="apply_certificate_item">
                        <div className="certificate_item file_upload" style={{position: "relative"}}>
                            <div className="input_container">
                                <img src={file_upload} alt="file_upload" className="file_upload_img"/>
                                <label htmlFor="files" className="labelbtn">Upload file</label>
                                <input type="file" id="files" placeholder="Passport" onChange={onChangeFileInput}/>
                            </div>
                        </div>
                    </div>
                    <div className="d_flex align_center form-group">
                        <input type="checkbox" id="checkbox4" name="checkbox"/>
                        <label htmlFor="checkbox4"> <span>D.</span> Domain name and access to databases and relevant
                            control panel passwords.</label>
                    </div>
                    <div className="d_flex align_center form-group margin_top_24">
                        <input type="checkbox" id="checkbox5" name="checkbox"/>
                        <label htmlFor="checkbox5"> <span>E.</span> Relevant Know-How to accurately hand over ownership
                            as ongoing business or going concern.</label>
                    </div>
                    <div className="margin_top_32">
                        Confirm
                    </div>
                    <div className="d_flex align_center margin_top_12">
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="No" value="No" name="radio12"
                            />
                            No
                        </label>
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="yes" value="yes" name="radio12" checked="checked"
                            />
                            Yes
                        </label>
                    </div>
                    <div className="margin_top_16">
                        <div className="">
                            <textarea name="" id="" cols="" rows=""
                                      style={{
                                          background: "white",
                                          width: "100%"
                                      }}>Partner requests the further information (max 140 characters)</textarea>
                        </div>
                    </div>
                    <div className="hr_line">
                    </div>
                    <h5>7. PARTNERSHIP DURATION AND AIM / GOAL AND CORRESPONDING PARTNER DUTIES:</h5>
                    <div className="margin_top_8">should prompt to continue to the next part of the contract or
                        terminate at this stage.
                    </div>
                    <div className="margin_top_12">
                        Confirm
                    </div>
                    <div className="d_flex align_center margin_top_12">
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="No" value="No" name="radio13"
                            />
                            No
                        </label>
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="yes" value="yes" name="radio13" checked="checked"
                            />
                            Yes
                        </label>
                    </div>
                    <h5 className="margin_top_32">7.1. PARTNERSHIP DURATION</h5>
                    <div className="margin_top_12">
                        <span style={{float: "right"}}>[Section 35(3) Partnership Act 2012:]</span>
                    </div>
                    <div className="margin_top_12">
                        As per the above law. break up of partnership by specified date: On this date the partnership
                        will cease and break up. Each partner gets a notice of how long they have agreed to be in
                        partnership for. At expiry of that time the notification to exit partnership or extend is sent
                        to user dashboard.
                    </div>
                    <h5 className="margin_top_16">GRANTOR PARTNER 1.1</h5>
                    <div className="apply_certificate_item d_flex align_center">
                        <div className="certificate_item calendar_block">
                            <label htmlFor="" style={{color: "transparent"}}>.</label>
                            <img src={calendar_icon} alt="icon" className="calendar_icon"/>
                            <Calendar/>
                        </div>
                    </div>
                    <div className="apply_certificate_item margin_top_12">
                        <div className="certificate_item filled_data_input">
                            <h5>PARTNER A 1.2</h5>
                            <input type="text" placeholder="Date" className="margin_top_16"/>
                        </div>
                        <div className="certificate_item filled_data_input">
                            <h5>PARTNER B 1.3</h5>
                            <input type="text" placeholder="Date" className="margin_top_16"/>
                        </div>
                    </div>
                    <div className="margin_top_8">
                        <span>Distribution of assets at dissolution of partnership.</span>
                        Any assets acquired during the partnership shall be
                        distributed as per:-
                    </div>
                    <div className="margin_top_12">
                        <span style={{float: "right"}}>[Section 35(3) Partnership Act 2012:]</span>
                    </div>
                    <div className="margin_top_12">
                        <span>a. </span>
                        each partner shall pay in amount the partner owes,
                    </div>
                    <div>
                        <span>b. </span>
                        partnership shall pay amounts owed to creditors other than partners,
                    </div>
                    <div>
                        <span>c. </span>
                        pay each partner what is owed to them, excluding the partners contribution towards capital [9.1]
                    </div>
                    <div>
                        <span>d. </span>
                        if assets acquired partners shall share the surplus assets,
                    </div>
                    <div>
                        <span>e. </span>
                        if the partnership is unable to meet its obligations each partner shall contribute to the
                        liability as per their share [Equal / Ratio ] clause 7.3 herein.
                    </div>
                    <div className="margin_top_16">
                        All partners who agree to the above have read and understood the above a-e and shall carry out
                        their duties bearing in mind the above.
                    </div>
                    <div className="margin_top_16">
                        Confirm
                    </div>
                    <div className="d_flex align_center margin_top_12">
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="No" value="No" name="radio14"
                            />
                            No
                        </label>
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="yes" value="yes" name="radio14" checked="checked"
                            />
                            Yes
                        </label>
                    </div>
                    <h5 className="margin_top_32">7.1.1. AIM, GOAL AND PURPOSE OF PARTNERSHIP:</h5>
                    <div className="margin_top_12">
                        This partnership shall terminate on the set goals being achieved. It is mutually agreed by all
                        partners that the aim of this agreement to enter into partnership is in the view of:
                    </div>
                    <div className="margin_top_12">
                        <span style={{float: "right"}}>[Section 35(3) Partnership Act 2012:]</span>
                    </div>
                    <div className="margin_top_12">
                        <span>making a profit, from the combination of [IP] and or resources mentioned in [1A]</span>
                        attached.
                    </div>
                    <div className="margin_top_16">
                        Confirm
                    </div>
                    <div className="d_flex align_center margin_top_12">
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="No" value="No" name="radio15"
                            />
                            No
                        </label>
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="yes" value="yes" name="radio15" checked="checked"
                            />
                            Yes
                        </label>
                    </div>
                    <div className="margin_top_16">
                        <div className="">
                            <textarea name="" id="" cols="" rows=""
                                      style={{
                                          background: "white",
                                          width: "100%"
                                      }}>Type and explain (max 100 characters)</textarea>
                        </div>
                    </div>
                    <h5>7.2. DUTIES OF PARTIES WITHIN PARTNERSHIP:</h5>
                    <div className="margin_top_12">
                        Each party to this agreement to partner shall state their duty during the duration of the
                        partnership.
                    </div>
                    <div className="margin_top_12">But shall be governed by these following duties that are not [7.2.1]
                        to be waived
                        at any moment during the partnership:
                    </div>
                    <h5 className="margin_top_32">
                        7.2.1
                    </h5>
                    <div className="margin_top_12">
                        DUTY OF GOOD FAITH
                    </div>
                    <div className="margin_top_12">
                        <span style={{float: "right"}}>[Section 10 1a-1b Partnership Act 2012]</span>
                    </div>
                    <div className="margin_top_12">
                        DUTY TO KEEP PARTNERS INFORMED
                    </div>
                    <div className="margin_top_12">
                        <span style={{float: "right"}}>[Section 10 2a Partnership Act 2012]</span>
                    </div>
                    <div className="margin_top_12">
                        DUTY TO KEEP ACCOUNT OF PROFIT
                    </div>
                    <div className="margin_top_12">
                        <span style={{float: "right"}}>[Section 10 2b Partnership Act 2012]</span>
                    </div>
                    <div className="margin_top_12">
                        DUTY TO DISCLOSE [3] Above
                    </div>
                    <div className="margin_top_12">
                        <span style={{float: "right"}}>[Section 11 Partnership Act 2012]</span>
                    </div>
                    <div className="margin_top_12">
                        DUTY TO CO-OPERATE
                    </div>
                    <div className="margin_top_12">
                        <span style={{float: "right"}}>[Section 16( 2) Partnership Act 2012]</span>
                    </div>
                    <div className="margin_top_12">
                        DUTY TO PAY CREDITORS
                    </div>
                    <div className="margin_top_12">
                        <span style={{float: "right"}}>[Section 41 Partnership Act 2012]</span>
                    </div>
                    <div className="margin_top_12">
                        DUTY TO RETURN [IP] back to owners at end of the partnership duration.
                    </div>
                    <div className="margin_top_12">
                        DUTY TO WORK in the best interests of the partnership.
                    </div>
                    <div className="margin_top_12">
                        DUTY TO CONTRIBUTE to the outstanding bills accumulated during the partnership.
                    </div>
                    <h5 className="margin_top_32">
                        GRANTOR / PARTNER
                    </h5>
                    <div className="margin_top_16">
                        <div className="">
                            <textarea name="" id="" cols="" rows=""
                                      style={{
                                          background: "white",
                                          width: "100%"
                                      }}>Duties (max 100 words)</textarea>
                        </div>
                    </div>
                    <h5 className="margin_top_24">PARTNER A</h5>
                    <div className="margin_top_16">
                        <div className="">
                            <textarea name="" id="" cols="" rows=""
                                      style={{
                                          background: "#DDEEC9",
                                          width: "100%",
                                          border: "none",
                                          borderBottom: "2px solid #8FC54D"
                                      }}>Duties </textarea>
                        </div>
                    </div>
                    <div className="margin_top_16">
                        Accept duties
                    </div>
                    <div className="d_flex align_center margin_top_12">
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="No" value="No" name="radio16"
                            />
                            No
                        </label>
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="yes" value="yes" name="radio16" checked="checked"
                            />
                            Yes
                        </label>
                    </div>
                    <h5 className="margin_top_32">7.2.2. EXTINCTION OF PARTNERSHIP</h5>
                    <h5 className="margin_top_12">Once the agreement to enter into partnership is registered / attested
                        and begins it shall only
                        be extinguished by:</h5>
                    <h5 className="margin_top_24">Extinction of partnership</h5>
                    <ul style={{listStyle: "square"}} className="partnership_list">
                        <li>if partners numbers fall below 2+</li>
                        <li>Fix Term expires [7.1]</li>
                        <li>Fixed Aim [7.1.1]</li>
                        <li>Court order</li>
                        <li>[application of Partner / Minister / Court]</li>
                    </ul>
                    <div className="margin_top_12">
                        <span style={{float: "right"}}>[Section 35 of the Partnership Act 2012]</span>
                    </div>
                    <h5 className="margin_top_32">7.2.3. VOTE ON ISSUES:</h5>
                    <h5 className="margin_top_12">Every partner shall have the right to</h5>
                    <h5 className="margin_top_24">A. Raise an Issue</h5>
                    <div className="margin_top_16">
                        <div className="">
                            <textarea name="" id="" cols="" rows=""
                                      style={{
                                          background: "white",
                                          width: "100%"
                                      }}>Max 100 words)</textarea>
                        </div>
                    </div>
                    <h5 className="margin_top_12">B. And issue a NOTIFICATION TO VOTE to the other users on the criteria
                        in the issue</h5>
                    <div className="margin_top_24">ALL MEMBERS SHOULD RECIEVE THE NOTIFICATION IN DASHBOARD and agree
                        on:
                    </div>
                    <ul style={{listStyle: "square"}} className="partnership_list">
                        <li>Response to ISSUE:</li>
                        <li>AGREE with ISSUE:</li>
                        <li>DISAGREE with ISSUE:</li>
                    </ul>
                    <div className="margin_top_24" style={{width: "max-content"}}>
                        <div className="filled_data_input">
                            <input type="text" placeholder="Partner name"/>
                        </div>
                        <div className="certificate_item filled_data_input">
                        </div>
                    </div>
                    <div className="margin_top_8">
                        <div className="">
                            <textarea name="" id="" cols="" rows=""
                                      style={{
                                          background: "white",
                                          width: "100%"
                                      }}>Response (max 100 words)</textarea>
                        </div>
                    </div>
                    <h5 className="margin_top_16">7.3. PARTIES / PARTNERS SHARING AND ASSIGNING OF PROFITS:</h5>
                    <div className="margin_top_16">
                        The parties to this agreement to partner hereby agree to share profit and loss by the following:
                    </div>
                    <div className="margin_top_16">
                        The profits shall be shared between the partners or In whichever ratio that the intended
                        partners negotiate and share to:
                    </div>
                    <div className="margin_top_16">
                        PICK:
                    </div>
                    <div className="d_flex align_center  margin_top_12">
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="No" value="No" name="radio17"
                            />
                            Equally [divide] by number of partners to make 100
                        </label>
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="yes" value="yes" name="radio17" checked="checked"
                            />
                            RATIO According to partners
                        </label>
                    </div>
                    <div className="apply_certificate_item margin_top_24">
                        <div className="certificate_item">
                            <label htmlFor="">Grantor / partner</label>
                            <input type="text" value="" placeholder=""/>
                        </div>
                        <div className="certificate_item">
                            <label htmlFor="">Partner A</label>
                            <input type="text" value="" placeholder=""/>
                        </div>
                    </div>
                    <div className="apply_certificate_item">
                        <div className="certificate_item">
                            <label htmlFor="">Partner B</label>
                            <input type="text" value="" placeholder=""/>
                        </div>
                        <div className="certificate_item">
                            <label htmlFor="">Partner C</label>
                            <input type="text" value="" placeholder=""/>
                        </div>
                    </div>
                    <div>Agree with calculation</div>
                    <div className="d_flex align_center  margin_top_12">
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="No" value="No" name="radio18"
                            />
                            No
                        </label>
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="yes" value="yes" name="radio18" checked="checked"
                            />
                            Yes
                        </label>
                    </div>
                    <h5 className="margin_top_32">7.4. ACCEPTANCE OF LIABILITIES:</h5>
                    <div>All partners shall be equally liable for acts or omissions occurring from this agreement to
                        enter into partnership. In the ratio in <span>[7.3]</span> An individual partner may be held
                        liable for acts
                        by the other partners, if the acts are in contravention of the aims of this agreement to enter
                        into partnership.
                    </div>
                    <div className="margin_top_12">Confirm</div>
                    <div className="d_flex align_center  margin_top_12">
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="No" value="No" name="radio19"
                            />
                            No
                        </label>
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="yes" value="yes" name="radio19" checked="checked"
                            />
                            Yes
                        </label>
                    </div>
                    <div className="hr_line">
                    </div>
                    <h5>8. INDEMNIFICATION:</h5>
                    <div className="margin_top_12">
                        Compensation for harm or loss caused by any representation by the each partner Each partner
                        grantor understands that the breach of the following condition will be termed a fundamental
                        breach of conditions to this agreement.
                    </div>
                    <div className="margin_top_12">
                        <span style={{float: "right"}}>[Section 71 (1) and (2) of the Partnership Act 2012]</span>
                    </div>
                    <div className="margin_top_12">
                        liability for false information ,statements is liable.
                    </div>
                    <div className="margin_top_24">
                        <span>Each partner </span> agrees to indemnify, bear
                        the cost of any third party claim of ownership against the agreement to enter into the partner .
                        As breach of implied warranty on transfer of quiet partnership obligations top the aims of the
                        intended partnership and the intellectual property.
                    </div>
                    <div className="margin_top_24">Confirm</div>
                    <div className="d_flex align_center  margin_top_12">
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="No" value="No" name="radio20"
                            />
                            No
                        </label>
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="yes" value="yes" name="radio20" checked="checked"
                            />
                            Yes
                        </label>
                    </div>
                    <div className="margin_top_24">
                        <span>Each partner</span> will rely on the ownership certificate uploaded to the system
                        [ipdepository.com]
                        and attach the governing and issuing body to any suit on only ownership dispute, as the issuing
                        authority or body has a complete and concrete registry of ownership and certification.
                    </div>
                    <div className="margin_top_24">Confirm</div>
                    <div className="d_flex align_center  margin_top_12">
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="No" value="No" name="radio21"
                            />
                            No
                        </label>
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="yes" value="yes" name="radio21" checked="checked"
                            />
                            Yes
                        </label>
                    </div>
                    <h5 className="margin_top_32">8.1. NON COMPETE CLAUSE:</h5>
                    <div className="margin_top_12">
                        <span>EACH PARTNER</span> warrants that they shall not engage, develop or any ancillary business
                        that is
                        similar to the contents, <span>expressions, know-how, and skill and knowledge for the period mentioned
                        above.</span>
                    </div>
                    <div className="margin_top_24">
                        Optional for both parties:
                    </div>
                    <h5 className="margin_top_16">GRANTOR / PARTNER</h5>
                    <div className="d_flex align_center  margin_top_12">
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="6mounts" value="6mounts" name="radio22" checked="checked"
                            />
                            6 months
                        </label>
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="1year" value="1year" name="radio22"
                            />
                            1 year
                        </label>
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="2years" value="2years" name="radio22"
                            />
                            2 years
                        </label>
                    </div>
                    <h5 className="margin_top_24">PARTNER A</h5>
                    <div className="d_flex align_center  margin_top_12">
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="6mounts" value="6mounts" name="radio23" checked="checked"
                            />
                            6 months
                        </label>
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="1year" value="1year" name="radio23"
                            />
                            1 year
                        </label>
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="2years" value="2years" name="radio23"
                            />
                            2 years
                        </label>
                    </div>
                    <div className="hr_line">
                    </div>
                    <h5>9. COST OF PARTNERSHIP AGREEMENT:</h5>
                    <div className="margin_top_12">
                        THE TOTAL COST OF THIS PARTNERSHIP SHALL BE THE TOTALS ADDED UP FROM THE CONTRIBUTIONS IN CASH:
                    </div>
                    <h5 className="margin_top_12">Offer contribution [Cash] (Amount)</h5>
                    <div className="apply_certificate_item margin_top_12">
                        <div className="certificate_item">
                            <label htmlFor="">Amount</label>
                            <input type="text" value="" placeholder=""/>
                        </div>
                        <div className="certificate_item">
                        </div>
                    </div>
                    <div className="apply_certificate_item margin_top_12">
                        <div className="certificate_item filled_data_input">
                            <h5>PARTNER A</h5>
                            <input type="text" placeholder="Amount" className="margin_top_12"/>
                        </div>
                        <div className="certificate_item filled_data_input">
                            <h5>PARTNER B</h5>
                            <input type="text" placeholder="Amount" className="margin_top_12"/>
                        </div>
                    </div>
                    <div>
                        <span style={{float: "right"}}>Grand Total: $250</span>
                    </div>
                    <h5 className="margin_top_32">
                        EACH PARTNER SUMMIT PHONE NUMBER FOR MPESA PAYBILL CODE TO BE SENT:
                    </h5>
                    <div className="apply_certificate_item margin_top_16">
                        <div className="certificate_item">
                            <label htmlFor="">GRANTOR PARTNER</label>
                            <input type="text" value="" placeholder=""/>
                        </div>
                        <div className="certificate_item">
                        </div>
                    </div>
                    <div className="apply_certificate_item margin_top_24">
                        <div className="certificate_item filled_data_input">
                            <h5>PARTNER A</h5>
                            <input type="text" placeholder="Phone number" className="margin_top_12"/>
                        </div>
                        <div className="certificate_item filled_data_input">
                            <h5>PARTNER B</h5>
                            <input type="text" placeholder="Phone number" className="margin_top_12"/>
                        </div>
                    </div>
                    <h5 className="margin_top_8">9.1. PARTNERSHIP CAPITAL CONTRIBUTION:</h5>
                    <h5 className="margin_top_12">Capital contribution</h5>
                    <div className="margin_top_12">
                        <span style={{float: "right"}}>Grand Total: $250</span>
                    </div>
                    <div className="margin_top_12">
                        <span>A. </span>
                        Partner may only contribute capital to this agreement to enter into partnership
                    </div>
                    <div>
                        <span>B. </span>
                        Vary the amount of capital with all other partners agree to vary the amount
                    </div>
                    <div>
                        <span>C. </span>
                        Partner not entitled to interest on the capital contribution
                    </div>
                    <div>
                        <span>D. </span>
                        If capital contribution by partner exceeds partnership amount, the partner is entitled to
                        interest on the amount, 3% per annum.
                    </div>
                    <div>
                        <span>E. </span>
                        Each party is entitled to participate in management of this agreement to partner.
                    </div>
                    <div className="margin_top_12">
                        <span style={{float: "right"}}>[Section 15 of the Partnership Act 2012]</span>
                    </div>
                    <h5 className="margin_top_24">
                        Parties are advised to be factual, and can however state nil capital injection. Kindly keep in
                        mind the aims of the system and future users of your [IP]
                    </h5>
                    <h5 className="margin_top_24">
                        THE PARTIES TO THIS AGREEMENT TO ENTER INTO PARTNERSHIP AGREE THAT THE CAPITAL OF THE
                        PARTNERSHIP SHALL BE:
                    </h5>
                    <h5 className="margin_top_24"> [this is the consideration granted by each party to be part of the
                        agreement to partner] not payable but may be part of objects of partnership institution or
                        resolution.</h5>
                    <div className="apply_certificate_item margin_top_24">
                        <div className="certificate_item">
                            <label htmlFor="">Numerals</label>
                            <input type="text" value="" placeholder=""/>
                        </div>
                        <div className="certificate_item">
                            <label htmlFor="">Words</label>
                            <input type="text" value="" placeholder=""/>
                        </div>
                    </div>
                    <div className="apply_certificate_item">
                        <div className="certificate_item">
                            <label htmlFor="">Currency</label>
                            <input type="text" value="" placeholder=""/>
                        </div>
                        <div className="certificate_item">
                            <label htmlFor="">Other</label>
                            <input type="text" value="" placeholder=""/>
                        </div>
                    </div>
                    <h5>PARTNER A</h5>
                    <div className="apply_certificate_item margin_top_16">
                        <div className="certificate_item filled_data_input">
                            <input type="text" placeholder="Numerals"/>
                        </div>
                        <div className="certificate_item filled_data_input">
                            <input type="text" placeholder="Words"/>
                        </div>
                    </div>
                    <div className="apply_certificate_item">
                        <div className="certificate_item filled_data_input">
                            <input type="text" placeholder="Currency"/>
                        </div>
                        <div className="certificate_item filled_data_input">
                            <input type="text" placeholder="Other"/>
                        </div>
                    </div>
                    <h5 className="margin_top_8">9.2. COMBINATION OF IP FOR PARTNERSHIP NOTICE</h5>
                    <div className="margin_top_12">
                        The grantor/ partner and Partner [A-X] with the execution of this agreement to partner.
                    </div>
                    <div className="apply_certificate_item margin_top_12">
                        <div className="certificate_item filled_data_input">
                            <input type="text" placeholder="Todays date"/>
                        </div>
                    </div>
                    <div>
                        on the above obligations at clause [6.1] the combination shall commence at the completion of
                        payment of partnership price by partners and confirmation of registration of partnership notice.
                    </div>
                    <div className="margin_top_12">
                        <span style={{float: "right"}}>[Movable Properties Securities Rights Act 2017] registry.</span>
                    </div>
                    <div className="margin_top_12">Confirm</div>
                    <div className="d_flex align_center  margin_top_12">
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="No" value="No" name="radio24"
                            />
                            No
                        </label>
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="yes" value="yes" name="radio24" checked="checked"
                            />
                            Yes
                        </label>
                    </div>
                    <div className="hr_line">
                    </div>
                    <h5>10. JURISDICTION:</h5>
                    <div className="margin_top_16">
                        <span style={{float: "right"}}>[Section 81 of the MPSR Act 2017]</span>
                    </div>
                    <div className="margin_top_16">
                        Laws mentioned in the recital [Recital 1] above shall apply accordingly. Except where otherwise
                        provided the law applicable to the creation and effectiveness against third parties is the law
                        of the country in which the grantor/ partner is located.
                    </div>
                    <div className="margin_top_16">
                        Other laws may exist in the
                        jurisdictions of other partners. These laws are subject to treaties on international
                        partnerships.
                    </div>
                    <div className="hr_line">
                    </div>
                    <div className="d_flex align_center title_b space_beetween margin_top_8">
                        <span></span>
                        <h5>AUTOMATED ATTESTATION</h5>
                        <span></span>
                    </div>
                    <h5 className="margin_top_16">SIGNED BY</h5>
                    <div className="margin_top_12">
                        I Grantor / Partner hereby certify that I have granted my intellectual property, mentioned
                        herein in, into this partnership agreement. With the aim of making profit, and within the set
                        duration of time, and profit and loss share as stated in this agreement to enter into
                        partnership.
                    </div>
                    <diV class="margin_top_16">
                        <span>Death / Injury Clause:</span> Incase of the untimely and unfortunate event of injury or
                        death during the subsistence of this partnership I nominate the following:
                    </diV>
                    <h5 className="margin_top_16">Specific Personal Representative for this partnership agreement :</h5>
                    <div className="apply_certificate_item margin_top_16">
                        <div className="certificate_item">
                            <label htmlFor="">Name</label>
                            <input type="text" value="" placeholder=""/>
                        </div>
                        <div className="certificate_item">
                            <label htmlFor="">Email</label>
                            <input type="text" value="" placeholder=""/>
                        </div>
                    </div>
                    <div>
                        Named above to be assigned: My voting power and responsibilities under this partnership, and may
                        choose to exit the said or sell my share of the partnership created in this agreement to an
                        existing partner, for the duration of this partnership herein.
                    </div>
                    <h5 className="margin_top_24">GRANTOR /PARTNER:</h5>
                    <div className="apply_certificate_item margin_top_12">
                        <div className="certificate_item filled_data_input">
                            <input type="text" placeholder="example@gmail.com"/>
                        </div>
                        <div className="certificate_item filled_data_input">
                            <input type="text" placeholder="Passport picture.jpeg"/>
                        </div>
                    </div>
                    <div className="apply_certificate_item">
                        <div className="certificate_item filled_data_input">
                            <input type="text" placeholder="Nationality"/>
                        </div>
                        <div className="certificate_item filled_data_input">
                            <input type="text" placeholder="Location"/>
                        </div>
                    </div>
                    <div className="apply_certificate_item d_flex align_center">
                        <div className="d_flex space_beetween propertation_inputs_b">
                            <div>
                                <label htmlFor="">Time</label>
                                <input type="text" value="" placeholder=""/>
                            </div>
                            :
                            <div>
                                <label htmlFor="">.</label>
                                <input type="text" value="" placeholder=""/>
                            </div>
                        </div>
                        <div className="certificate_item calendar_block">
                            <label htmlFor="">Date</label>
                            <img src={calendar_icon} alt="icon" className="calendar_icon"/>
                            <Calendar/>
                        </div>
                    </div>
                    <div>Digital signature</div>
                    <div>
                        <div className="">
                            <textarea name="" id="" cols="" rows=""
                                      style={{background: "white", width: "100%"}}></textarea>
                        </div>
                    </div>
                    <h5 className="margin_top_24">PARTNER A:</h5>
                    <div className="apply_certificate_item margin_top_12">
                        <div className="certificate_item filled_data_input">
                            <input type="text" placeholder="example@gmail.com"/>
                        </div>
                        <div className="certificate_item filled_data_input">
                            <input type="text" placeholder="Passport picture.jpeg"/>
                        </div>
                    </div>
                    <div className="apply_certificate_item">
                        <div className="certificate_item filled_data_input">
                            <input type="text" placeholder="Nationality"/>
                        </div>
                        <div className="certificate_item filled_data_input">
                            <input type="text" placeholder="Location"/>
                        </div>
                    </div>
                    <div className="apply_certificate_item">
                        <div className="certificate_item filled_data_input">
                            <input type="text" placeholder="Time"/>
                        </div>
                        <div className="certificate_item filled_data_input">
                            <input type="text" placeholder="Date"/>
                        </div>
                    </div>
                    <div>
                        <div className="">
                            <textarea name="" id="" cols="" rows=""
                                      style={{
                                          background: "#DDEEC9",
                                          width: "100%",
                                          border: "none",
                                          borderBottom: "2px solid #8FC54D"
                                      }}>Duties </textarea>
                        </div>
                    </div>
                    <div className="d_flex align_center justify_content_center submit_b margin_top_32">
                        <button className="save_btn" style={{marginLeft: "0px"}}>Continue</button>
                    </div>
                </div>
            </div>
        </>
    )

}