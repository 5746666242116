import React, { useEffect, useState } from "react";
import axiosInstance from "../../../api";
import { useNavigate } from "react-router-dom";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import calendar_icon from "../../../assets/images/icons/calendar.png";
import { CountryList } from "../../../constants/country";
import { Loading } from "../../../components";
import Calendar from "../../../components/calendar";
import moment from "moment";
import Modal from "react-modal";
import cancel_icon from "../../../assets/images/icons/closeBlack.png";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius:"31px",
    maxWidth: "403px",
    width: "100%",
    zIndex: "234564",
  },
};
export function AddIp() {
  let navigate = useNavigate();
  let [loading, setLoading] = useState(false);
  let [data, setData] = useState({
    type_id:1,
    category_id: 1,
    date_of_issue:moment().format("YYYY/MM/DD"),
    publish: 0,
  });
  const [suggestions, setSuggestions] = useState([]);
  let [category, setSetCategory] = useState([]);
  let [agreementType, setAgreementType] = useState([]);
  let [error, setError] = useState("");
  const [proposalInput] = useState([
    {
      label: "Proprietor name",
      placeholder: "Name",
      key: "proprietor_name",
    },
    {
      label: "Certificate Description",
      placeholder: "Description",
      key: "certificate_description",
    },
  ]);
  const [fileName, setFileName] = useState("No File chosen");
  const [file, setFile] = useState("No File chosen");
  const [countryIssuedSearch, setCountryIssuedSearch] = useState(false);

  const handleFileChange = (event) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }
    setFile(event.target.files[0]);
    setFileName(fileObj.name);
    toastr.options = {
      hideDuration: 300,
      timeOut: 60000,
    };
    toastr.clear();
    setTimeout(() => toastr.success(`File uploaded`), 300);
  };

  let changeInput = (e, name) => {
    setError("");
    if (name === "type_id") {
      
      let type_id = agreementType.filter((item) => item.name === e);
      return setData({ ...data, ...{ [name]: type_id[0].id } });
    } else if (name === "category_id") {
      let category_id = category.filter((item) => item.name === e);

      return setData({ ...data, ...{ [name]: category_id[0].id } });
    } else {
      setData({ ...data, ...{ [name]: e } });
    }
  };

  useEffect(() => {
    getCategory();
    getAgreementType();
  }, []);
  let getCategory = async () => {
    try {
      let response = await axiosInstance.get("api/v1/categories");
      setSetCategory(response.data.data);
    } catch (e) {
      console.log(e);
      console.log(e.response);
    }
  };
  let getAgreementType = async () => {
    try {
      let response = await axiosInstance.get("api/v1/certificate-types");
      setAgreementType(response.data.data);
    } catch (e) {
      console.log(e);
      console.log(e.response);
    }
  };

  let find = (country) => {
    let flag = false;
    for (let i = 0; i < CountryList.length; i++) {
      if (CountryList[i].country.toLowerCase() === country.toLowerCase()) {
        flag = true;
        break;
      }
    }
    return flag;
  };
  const [modalIsOpen, setIsOpen] = useState(false);
  let subtitle;
  function openModal() {
    setIsOpen(true);
    document.body.scrollTo = 0;
    document.body.style += "overflow:hidden";
    document.documentElement.scrollTop = 0;
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
    document.body.style += "overflow: inherit";
  }
  let createAgreement = async () => {
    try {
      
      if (
        data.name_of_concept &&
        data.certificate_number &&
        data.date_of_issue &&
        data.proprietor_name &&
        data.country_issued &&
        data.certificate_description &&
        data.category_id &&
        data.certificate_name &&
        file
      ) {
        if (find(data.country_issued)) {
          setLoading(true);
          
          let formData = new FormData();
          formData.append("name_of_concept", data.name_of_concept);
          formData.append("publish", data.publish);
          formData.append("certificate_number", data.certificate_number);
          formData.append("category_id", data.category_id);
          formData.append("date_of_issue", data.date_of_issue);
          formData.append("proprietor_name", data.proprietor_name);
          formData.append("country_issued", data.country_issued);
          formData.append(
            "certificate_description",
            data.certificate_description
          );
          formData.append("certificate_name", data.certificate_name);
          formData.append("type_id", data.type_id);
          formData.append("photo", file);
            
          // try {
            let response = await axiosInstance.post(
              "api/client/create-agreement",
              formData
            );
            setLoading(false);
            openModal(true);
            
          // } catch (error) {
          // console.log('error :', error);

          //   
            
          // }
        } else {
          setError("Please choose corect country");
        }
      } else {
        setError("Please fill in all required fields");
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
      
      if (e.response) {
        let errorData= null;
        if(e.response.data.data) {
           errorData = Object.keys(e.response.data.data).map(
            (key) => e.response.data.data[key][0]
          );

        } else {
          errorData = e.response.message
        }
        
        setError(errorData);
      }
    }
  };

  let findCountry = (e, name) => {
    setCountryIssuedSearch(true);
    setData({ ...data, ...{ [name]: e } });
    const inputValue = e;

    const filteredSuggestions = CountryList.filter((value) =>
      value.country.toLowerCase().includes(inputValue.toLowerCase())
    );
    setSuggestions(filteredSuggestions);
    if (!e) {
      return setCountryIssuedSearch(false);
    }
  };

  return (
    <div>
      <div className="addIpContent">
        <div className="ddIpContent_b1">
          <label className="ddIpContent_b1_item">
            <input
              type="radio"
              id="private"
              value="private"
              name="radio"
              checked={data.publish === 0}
              onChange={(e) => changeInput(0, "publish")}
            />
            Private
          </label>
          <label className="ddIpContent_b1_item">
            <input
              type="radio"
              id="public"
              value="public"
              name="radio"
              checked={data.publish === 1}
              onChange={(e) => changeInput(1, "publish")}
            />
            Public
          </label>
        </div>
        <div className="formGroup">
          <label htmlFor="">
            <p style={{ color: "transparent" }}>a</p>
            <label className="chooseFile">
              <input type="file" onChange={handleFileChange} />
              <span>Choose File</span>
              <p className="fileNameContent">{fileName}</p>
            </label>
          </label>
          <label>
            Agreement Type
            <select onChange={(e) => changeInput(e.target.value, "type_id")}>
              {agreementType.map((item) => {
                return <option>{item.name}</option>;
              })}
            </select>
          </label>
          <label>
            Category
            <select
              onChange={(e) => changeInput(e.target.value, "category_id")}
            >
              {category.map((item, index) => {
                return <option key={index}>{item.name}</option>;
              })}
            </select>
          </label>
          <label>
            Name of certificate
            <input
              type="text"
              placeholder={"Name"}
              onChange={(e) => changeInput(e.target.value, "certificate_name")}
            />
          </label>
          <label>
            Name of concept
            <input
              type="text"
              placeholder={"Name"}
              onChange={(e) => changeInput(e.target.value, "name_of_concept")}
            />
          </label>
          <label>
            Certificate Number
            <input
              type="number"
              placeholder={"Number"}
              onChange={(e) =>
                changeInput(e.target.value, "certificate_number")
              }
            />
          </label>
          <label className="calendar_block">
            Date of Issue
            {/*<input type="text" placeholder={'18 May 2023'}*/}
            {/*       onChange={(e) => changeInput(e.target.value, 'date_of_issue')}/>*/}
            <img
              src={calendar_icon}
              alt="calendar_icon"
              className="calendar_icon"
            />
            <Calendar
              onChange={(e) =>
                changeInput(moment(e).format("YYYY/MM/DD"), "date_of_issue")
              }
            />
          </label>
          <label>
            Proprietor name
            <input
              type="text"
              placeholder={"Name"}
              onChange={(e) => changeInput(e.target.value, "proprietor_name")}
            />
          </label>
          <label>
            Certificate Description
            <input
              type="text"
              placeholder={"Description"}
              onChange={(e) =>
                changeInput(e.target.value, "certificate_description")
              }
            />
          </label>
          <div className="country_issued_b">
            <label>
              Country Issued
              <input
                type="text"
                placeholder={"Name"}
                value={data.country_issued}
                onChange={(e) => {
                  findCountry(e.target.value, "country_issued");
                }}
              />
            </label>
            {countryIssuedSearch ? (
              <div className="country_issued_b_dropdown">
                {suggestions.map((item, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => {
                        setData({
                          ...data,
                          ...{ country_issued: item.country },
                        });
                        setCountryIssuedSearch(false);
                      }}
                    >
                      {item.country}
                    </div>
                  );
                })}
              </div>
            ) : null}
          </div>
        </div>
        <div className="formGroup"></div>
        {error && !Array.isArray(error) ? (
          <p style={{ color: "red" }}>{error}</p>
        ) : (
          error && Array.isArray(error) ? (error.map(item => <p style={{ color: "red" }}>{item}</p>)) : null
        )}
        <button onClick={createAgreement}>Create</button>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Modal"
      >
        <div className="modal_inner">
          <div className="modal_inner_header">
            <p>Success!</p>
            <img src={cancel_icon} alt="" onClick={closeModal} />
          </div>
          <div className="text">Your certificate is successfully updated.</div>
          <div className="cancel_btn_b">
            <button onClick={() => navigate("/agreements")}>
              Go to certificates
            </button>
          </div>
        </div>
      </Modal>
      
    </div>
  );
}
