import { z } from "zod";
import { convertImageFilesToBase64 } from "../../helper/helper";
const ACCEPTED_IMAGE_TYPES = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "image/webp",
];

export const sell_schema = z
  .object({
    part_1: z.string().nonempty("Required"),
    part_2: z.string().nonempty("Required"),
    due_diligence: z
      .boolean()
      .refine(
        (val) => {
          return val === true;
        },
        { message: "You must accept the terms" }
      )
      .optional(),
    confidentiality: z
      .boolean()
      .refine(
        (val) => {
          return val === true;
        },
        { message: "You must accept the terms" }
      )
      .optional(),
    ownership: z.string().nonempty("Required"),
    co_ownership: z.string().nonempty("Required"),
    indemnification: z
      .boolean()
      .refine(
        (val) => {
          return val === true;
        },
        { message: "You must accept the terms" }
      )
      .optional(),
    indemnification_amount: z.number(),
    cost_of_sale_number: z.number(),
    cost_of_sale_figures: z.number(),
    purchase_disclosure: z.string().nonempty("Required"),
    transfer_of_ownership: z.string().nonempty("Required"),
    transfer_of_access_website: z.string().nonempty("Required"),
    transfer_of_access_url: z.string().nonempty("Required"),
    transfer_of_access_literal: z.string().nonempty("Required"),
    transfer_of_access_qr: z
      .any()
      .refine(
        (files) => ACCEPTED_IMAGE_TYPES.includes(files?.[0]?.type),
        "Only .jpg, .jpeg, .png and .webp formats are supported."
      )
      .transform((files) => convertImageFilesToBase64(files)),
    transfer_of_access_number: z.number(),
    transfer_of_access_other: z.string().nonempty("Required"),

    verify_certificate: z.boolean(),
    value: z.boolean(),
    insure: z.boolean(),
  })
  .partial("Required");
