import { Loading } from "../../components";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import calendar_icon from "../../assets/images/icons/calendar.png";
import Calendar from "../../components/calendar";
import file_upload from "../../assets/images/icons/file_upload_black_24dp 1.png";
import axiosInstance from "../../api";
import { zodResolver } from "@hookform/resolvers/zod";
import { partner_schema } from "./schema";
import { Controller, useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
export function CertificatesViewApplyPartner() {
  let navigate = useNavigate();
  const { path, id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [startDate, setStartDate] = useState("");
  let [agreementType, setAgreementType] = useState([]);

  let getAgreementType = async () => {
    try {
      let response = await axiosInstance.get("api/v1/certificate-types");
      setAgreementType(response.data.data);
    } catch (e) {
      console.log(e);
      console.log(e.response);
    }
  };
  useEffect(() => {
    getAgreementType();
  }, []);

    const {
      register,
      handleSubmit,
      formState: { errors },
      reset,
      control,
      clearErrors,
      setValue,
    } = useForm({
      resolver: zodResolver(partner_schema),
    });
    console.log('errors :', errors);

     const handleChangeStart = (dateChange) => {
       setValue("later_data", dateChange.toString(), {
         shouldDirty: true,
       });
       clearErrors("later_data");
       setStartDate(dateChange);
     };
    const submitData = async (data) => {
      
      const type_id = agreementType.filter(
        (item) => item.name.toLowerCase() === path
      )[0];
      const newData = {
          agreement_id: +id,
          type_id: type_id.id,
        certificate: {
            ...data,
        },
      };
      console.log('newData :', newData);
      setErrorMessage("");
      setIsLoading(true);

      // try {
      //   await axiosInstance.post("api/client/create-proposal", newData);

      //   reset();
      //   setIsLoading(false);
      //   navigate(-1);
      // } catch (e) {
      //   console.log(e);
      //   setErrorMessage(e.response.data.message);
      // } finally {
      //   setIsLoading(false);
      // }
    };
  return (
    <>
      <Loading modalIsOpen={isLoading} />
      <div className="CertificatesViewApply_header">
        <h5 className="search_text">Apply for certificate</h5>
      </div>
      <form className="view_certeficate" onSubmit={handleSubmit(submitData)}>
        <div className="body">
          <h5>PARTIES TO THE AGREEMENT</h5>
          <div className="apply_certificate_item margin_top_24">
            <div className="certificate_item">
              <label htmlFor="">Part 1</label>
              {errors?.part_1 ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.part_1.message}
                </p>
              ) : null}
              <input type="text" {...register("part_1")} />
            </div>
            <div className="certificate_item">
              <label htmlFor="">Part 2</label>
              {errors?.part_2 ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.part_2.message}
                </p>
              ) : null}
              <input type="text" {...register("part_2")} />
            </div>
          </div>
          <h5 className="margin_top_24">DUE DILIGENCE</h5>
          {errors?.due_diligence ? (
            <p style={{ color: "red", fontSize: "16px" }}>
              {errors?.due_diligence.message}
            </p>
          ) : null}
          <div className="d_flex align_center form-group">
            <input
              type="checkbox"
              id="checkbox"
              name="checkbox"
              {...register("due_diligence")}
            />
            <label htmlFor="checkbox">
              Parties to this chat template acknowledge the importance of due
              diligence and hereby undertake an inquiry into the status of the
              certificate from the issuing authority. The Authority will
              authenticate the existence of the certificate to the said
              intellectual property.
            </label>
          </div>
          <h5 className="margin_top_48">CONFIDENTIALITY</h5>
          {errors?.confidentiality ? (
            <p style={{ color: "red", fontSize: "16px" }}>
              {errors?.confidentiality.message}
            </p>
          ) : null}
          <div className="d_flex align_center form-group checkbox_item">
            <input
              type="checkbox"
              id="checkbox1"
              name="checkbox"
              className="checkbox"
              {...register("confidentiality")}
            />
            <label htmlFor="checkbox1">
              Parties to this proposal agree to keep the details within this
              proposal confidential.
            </label>
          </div>
          <h5 className="margin_top_48">
            PARTNERSHIP PARTIES DISCLOSURE ACCEPTANCEE
          </h5>
          {errors?.partnership_parties ? (
            <p style={{ color: "red", fontSize: "16px" }}>
              {errors?.partnership_parties.message}
            </p>
          ) : null}
          <div className="d_flex form-group checkbox_item">
            <input
              type="checkbox"
              id="checkbox2"
              name="checkbox"
              className="checkbox"
              {...register("partnership_parties")}
            />
            <label htmlFor="checkbox2">
              The Partner / Grantor and partner [s] hereby confirms that the
              grantor has disclosed the relevant information on a previous
              disclosure of know-how and that the disclosure will in no way
              cause any damage, breach of implied warranty to the agreement to
              enter into partnership mentioned herein.
            </label>
          </div>
          <h5 className="margin_top_48">OWNERSHIP</h5>
          {errors?.ownership ? (
            <p style={{ color: "red", fontSize: "16px" }}>
              {errors?.ownership.message}
            </p>
          ) : null}
          <div className="d_flex align_center form-group checkbox_item">
            <div className="d_flex">
              <input
                type="checkbox"
                id="checkbox3"
                name="checkbox"
                className="checkbox"
                {...register("ownership")}
              />
              <label htmlFor="checkbox3">
                The ownership of the intellectual property [_____NAME OF
                IP_____] [Section 8 of the MPSR Act 2017] is my property and has
                the relevant capacity to enter into partnership and combine
                ownership of the [IP] to that of the Partner [S].{" "}
                <p className="margin_top_24">
                  Any transaction entered into after formation of this agreement
                  to partner will be for the general good of all the partners
                  involved. As presumption of partnership has been activated and
                  executed by signing this agreement, and will be subsisting
                  until the goals or duration of partnership are met.
                </p>
                <p className="margin_top_24">
                  This agreement to partner shall be binding while in its
                  duration and goals on the partners agents and personal
                  representatives.
                </p>
              </label>
            </div>
          </div>
          <h5 className="margin_top_24">
            ARE YOU A CO ONWER IN THE INTELLECTUAL PROPERTY?
          </h5>
          {errors?.co_ownership_intellectual_property ? (
            <p style={{ color: "red", fontSize: "16px" }}>
              {errors?.co_ownership_intellectual_property.message}
            </p>
          ) : null}
          <div className="d_flex radio_btns_b margin_top_24">
            <label className="radio_btn_b d_flex align_center">
              <input
                type="radio"
                id="no"
                value="no"
                name="radio"
                {...register("co_ownership_intellectual_property", {
                  validate: (value) => {
                    const parsedValue = partner_schema.parse({
                      co_ownership_intellectual_property: value,
                    });
                    return (
                      parsedValue.co_ownership_intellectual_property === value
                    );
                  },
                })}
              />
              No
            </label>
            <label className="radio_btn_b d_flex align_center">
              <input
                type="radio"
                id="yes"
                value="yes"
                name="radio"
                {...register("co_ownership_intellectual_property", {
                  validate: (value) => {
                    const parsedValue = partner_schema.parse({
                      co_ownership_intellectual_property: value,
                    });
                    return (
                      parsedValue.co_ownership_intellectual_property === value
                    );
                  },
                })}
              />
              Yes
            </label>
          </div>
          <div className="apply_certificate_item margin_top_24">
            <div className="certificate_item">
              <label htmlFor="">IPD details</label>
              {errors?.ipd_details ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.ipd_details.message}
                </p>
              ) : null}
              <input type="text" placeholder="" {...register("ipd_details")} />
            </div>
            <div className="certificate_item">
              <label htmlFor="">IP name</label>
              {errors?.ipd_name ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.ipd_name.message}
                </p>
              ) : null}
              <input type="text" placeholder="" {...register("ipd_name")} />
            </div>
          </div>
          <div className="apply_certificate_item">
            <div className="certificate_item">
              <label htmlFor="">Email of co owner</label>
              {errors?.email_of_co_owner ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.email_of_co_owner.message}
                </p>
              ) : null}
              <input
                type="email"
                placeholder=""
                {...register("email_of_co_owner")}
              />
            </div>
            <div className="certificate_item">
              <label htmlFor="">IPD address of co owner</label>
              {errors?.ipd_address_of_co_owner ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.ipd_address_of_co_owner.message}
                </p>
              ) : null}
              <input
                type="text"
                placeholder=""
                {...register("ipd_address_of_co_owner")}
              />
            </div>
          </div>
          <div className="apply_certificate_item">
            <div className="certificate_item">
              <label htmlFor="">Phone number of co owner</label>
              {errors?.phone_number_of_co_owner ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.phone_number_of_co_owner.message}
                </p>
              ) : null}
              <input
                type="number"
                placeholder=""
                {...register("phone_number_of_co_owner", {
                  valueAsNumber: true,
                })}
              />
            </div>
            <div className="certificate_item"></div>
          </div>
          <h5 className="margin_top_24">
            COMBINATION OF [IP] OWNERSHIP INTO PARTNERSHIP AND ACCESS
          </h5>
          {errors?.combination_of_ip_ownership ? (
            <p style={{ color: "red", fontSize: "16px" }}>
              {errors?.combination_of_ip_ownership.message}
            </p>
          ) : null}
          <div className="d_flex form-group checkbox_item">
            <input
              type="checkbox"
              id="checkbox4"
              name="checkbox"
              className="checkbox"
              {...register("combination_of_ip_ownership")}
            />
            <label htmlFor="checkbox4">
              The intended partners to this agreement to partner hereby admit
              and warrant that they have the exclusive rights to consent and
              authorize the agreement to partner and access to all relevant
              material to the agreement partner to enable the conclusion of this
              agreement/partnership.
            </label>
          </div>
          <div className="apply_certificate_item margin_top_16">
            {errors?.later_data ? (
              <p style={{ color: "red", fontSize: "16px" }}>
                {errors?.later_data.message}
              </p>
            ) : null}
            <label className="calendar_block">
              Later date
              <img
                src={calendar_icon}
                alt="calendar_icon"
                className="calendar_icon"
              />
              <Controller
                name="duration_start_date"
                control={control}
                defaultValue={startDate}
                render={() => (
                  <DatePicker
                    selected={startDate}
                    onChange={handleChangeStart}
                  />
                )}
              />
            </label>
            <div className="certificate_item"></div>
          </div>
          <h2 className="margin_top_48">PARTNER A</h2>
          <div className="apply_certificate_item">
            <div className="certificate_item">
              <label htmlFor="">Name IPD user</label>
              {errors?.partner_a_name ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.partner_a_name.message}
                </p>
              ) : null}
              <input
                type="text"
                placeholder=""
                {...register("partner_a_name")}
              />
            </div>
            <div className="certificate_item"></div>
          </div>
          <div className="apply_certificate_item">
            <div className="certificate_item">
              <label htmlFor="">Offer contribution amount (cash)</label>
              {errors?.partner_a_offer_amount ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.partner_a_offer_amount.message}
                </p>
              ) : null}
              <input
                type="number"
                placeholder=""
                {...register("partner_a_offer_amount", {
                  valueAsNumber: true,
                })}
              />
            </div>
            <div className="certificate_item">
              <label htmlFor="">Offer contribution - define (expertise)</label>
              {errors?.partner_a_offer_define ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.partner_a_offer_define.message}
                </p>
              ) : null}
              <input
                type="number"
                placeholder=""
                {...register("partner_a_offer_define", {
                  valueAsNumber: true,
                })}
              />
            </div>
          </div>
          <div className="margin_top_24">
            <div className="">
              <label htmlFor="">Further information (max 100 characters)</label>
              {errors?.partner_a_further_info ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.partner_a_further_info.message}
                </p>
              ) : null}
              <textarea
                name=""
                id=""
                cols=""
                rows=""
                style={{ background: "white", width: "100%" }}
                {...register("partner_a_further_info")}
              >
                {" "}
              </textarea>
            </div>
          </div>
          <h5 className="margin_top_48">OPTIONAL PARTNERSHIP MATERIALS</h5>
          {errors?.brochures_posters_promotional_material ? (
            <p style={{ color: "red", fontSize: "16px" }}>
              {errors?.brochures_posters_promotional_material.message}
            </p>
          ) : null}
          <div className="d_flex align_center form-group margin_top_16">
            <input
              type="checkbox"
              id="checkbox5"
              name="checkbox"
              {...register("brochures_posters_promotional_material")}
            />
            <label htmlFor="checkbox5">
              Brochures, posters, promotional material
            </label>
          </div>
          <div className="apply_certificate_item margin_top_24">
            {errors?.brochures_posters_file ? (
              <p style={{ color: "red", fontSize: "16px" }}>
                {errors?.brochures_posters_file.message}
              </p>
            ) : null}
            <div
              className="certificate_item file_upload"
              style={{ position: "relative" }}
            >
              <label htmlFor="upload">Upload file</label>
              <input
                type="file"
                placeholder="Upload QR code"
                id="upload"
                {...register("brochures_posters_file")}
              />
              <img
                src={file_upload}
                alt="file_upload"
                className="file_upload_img"
                style={{ top: "20%" }}
              />
              <span className="input-file-text"></span>
            </div>
            <div className="certificate_item"></div>
          </div>
          <div className="">
            {errors?.valuation_report_insurance_policy ? (
              <p style={{ color: "red", fontSize: "16px" }}>
                {errors?.valuation_report_insurance_policy.message}
              </p>
            ) : null}
            <div className="d_flex align_center form-group">
              <input
                type="checkbox"
                id="checkbox6"
                name="checkbox"
                {...register("valuation_report_insurance_policy")}
              />
              <label htmlFor="checkbox6">
                Valuation report and insurance policy
              </label>
            </div>
          </div>
          <div className="apply_certificate_item margin_top_24">
            {errors?.valuation_report_file ? (
              <p style={{ color: "red", fontSize: "16px" }}>
                {errors?.valuation_report_file.message}
              </p>
            ) : null}
            <div
              className="certificate_item file_upload"
              style={{ position: "relative" }}
            >
              <label htmlFor="upload_val">Upload file</label>
              <input
                type="file"
                placeholder="Upload QR code"
                id="upload_val"
                {...register("valuation_report_file")}
              />
              <img
                src={file_upload}
                alt="file_upload"
                className="file_upload_img"
                style={{ top: "20%" }}
              />
              <span className="input-file-text"></span>
            </div>
            <div className="certificate_item"></div>
          </div>
          <div>
            {errors?.domain_name_access ? (
              <p style={{ color: "red", fontSize: "16px" }}>
                {errors?.domain_name_access.message}
              </p>
            ) : null}
            <div className="d_flex align_center form-group">
              <input
                type="checkbox"
                id="checkbox7"
                name="checkbox"
                {...register("domain_name_access")}
              />
              <label htmlFor="checkbox7">
                Domain name and access to databases and relevant control panel
                passwords
              </label>
            </div>
          </div>
          <div>
            {errors?.relevant_know_how ? (
              <p style={{ color: "red", fontSize: "16px" }}>
                {errors?.relevant_know_how.message}
              </p>
            ) : null}
            <div className="d_flex align_center form-group margin_top_24">
              <input
                type="checkbox"
                id="checkbox8"
                name="checkbox"
                {...register("relevant_know_how")}
              />
              <label htmlFor="checkbox8">
                Relevant Know-How to accurately hand over ownership as ongoing
                business or going concern
              </label>
            </div>
          </div>
          <h5 className="margin_top_48">
            PARTNERSHIP DURATION AND AIM / GOAL AND CORRESPONDING PARTNER DUTIES
          </h5>
          <p style={{ fontSize: "16px" }}>
            As per the above law. break up of partnership by specified date: On
            this date the partnership will cease and break up. Each partner gets
            a notice of how long they have agreed to be in partnership for. At
            expiry of that time the notification to exit partnership or extend
            is sent to user dashboard.
          </p>
          <div className="apply_certificate_item margin_top_16">
            <div className="certificate_item">
              <label htmlFor="">Grantor partner</label>
              {errors?.partnership_duration_grantor_partner ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.partnership_duration_grantor_partner.message}
                </p>
              ) : null}
              <input
                type="text"
                placeholder=""
                {...register("partnership_duration_grantor_partner")}
              />
            </div>
            <div className="certificate_item">
              <label htmlFor="">Partner A</label>
              {errors?.partnership_duration_partner_a ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.partnership_duration_partner_a.message}
                </p>
              ) : null}
              <input
                type="text"
                placeholder=""
                {...register("partnership_duration_partner_a")}
              />
            </div>
          </div>
          <div className="apply_certificate_item">
            <div className="certificate_item">
              <label htmlFor="">Partner B</label>
              {errors?.partnership_duration_partner_b ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.partnership_duration_partner_b.message}
                </p>
              ) : null}
              <input
                type="text"
                placeholder=""
                {...register("partnership_duration_partner_b")}
              />
            </div>
            <div className="certificate_item"></div>
          </div>
          <h2 className="margin_top_24">
            AIM, GOAL AND PURPOSE OF PARTNERSHIP
          </h2>
          {errors?.aim_goal ? (
            <p style={{ color: "red", fontSize: "16px" }}>
              {errors?.aim_goal.message}
            </p>
          ) : null}
          <div className="d_flex align_center form-group">
            <input
              type="checkbox"
              id="checkbox9"
              name="checkbox"
              {...register("aim_goal")}
            />
            <label htmlFor="checkbox9">
              This partnership shall terminate on the set goals being achieved.
              It is mutually agreed by all partners that the aim of this
              agreement to enter into partnership is in the view of:
              [Interpretation Partnership Act 2012] making a profit, from the
              combination of [IP] and or resources mentioned in [1A] attached.
            </label>
          </div>
          <div className="margin_top_16">
            <div className="">
              <label htmlFor="">
                E.G end of event, etc. (max 100 characters)
              </label>
              {errors?.eg_end_of_event ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.eg_end_of_event.message}
                </p>
              ) : null}
              <textarea
                name=""
                id=""
                cols=""
                rows=""
                style={{ background: "white", width: "100%" }}
                {...register("eg_end_of_event")}
              >
                {" "}
              </textarea>
            </div>
          </div>
          <h5 className="margin_top_48">VOTE</h5>
          <h2 className="margin_top_16">
            AIM, GOAL AND PURPOSE OF PARTNERSHIP
          </h2>
          <div className="margin_top_16">
            <div className="">
              {errors?.aim_goal_issue ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.aim_goal_issue.message}
                </p>
              ) : null}
              <label htmlFor="">Issue/Report (max 100 characters)</label>
              <textarea
                name=""
                id=""
                cols=""
                rows=""
                style={{ background: "white", width: "100%" }}
                {...register("aim_goal_issue")}
              >
                {" "}
              </textarea>
            </div>
          </div>
          <div className="margin_top_24">
            <div className="">
              {errors?.aim_goal_suggestion ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.aim_goal_suggestion.message}
                </p>
              ) : null}
              <label htmlFor="">Suggestion/Solution</label>

              <textarea
                name=""
                id=""
                cols=""
                rows=""
                style={{ background: "white", width: "100%" }}
                {...register("aim_goal_suggestion")}
              >
                {" "}
              </textarea>
            </div>
          </div>
          <div className="d_flex space_beetween margin_top_48 checkboxes_group align_center">
            <div className="d_flex align_center form-group">
              <input
                type="checkbox"
                id="checkbox34"
                name="checkbox"
                checked
                className="checkbox"
                {...register("verify_certificate")}
              />
              <label htmlFor="checkbox34">Verify Certificate</label>
            </div>
            <div className="d_flex align_center form-group">
              <input
                type="checkbox"
                id="checkbox35"
                name="checkbox"
                className="checkbox"
                {...register("value")}
              />
              <label htmlFor="checkbox35">Value</label>
            </div>
            <div className="d_flex align_center form-group">
              <input
                type="checkbox"
                id="checkbox36"
                name="checkbox"
                // className="checkbox"
                {...register("insure")}
              />
              <label htmlFor="checkbox36">Insure</label>
            </div>
          </div>
          <div className="d_flex space_beetween  checkboxes_group align_center">
            <div className="d_flex align_center form-group">
              {errors?.verify_certificate ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.verify_certificate.message}
                </p>
              ) : null}
            </div>
            <div className="d_flex align_center form-group">
              {errors?.value ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.value.message}
                </p>
              ) : null}
            </div>
            <div className="d_flex align_center form-group">
              {errors?.insure ? (
                <p style={{ color: "red", fontSize: "16px" }}>
                  {errors?.insure.message}
                </p>
              ) : null}
            </div>
          </div>
          <div className="d_flex align_center submit_b margin_top_48">
            <button
              className="save_btn"
              // onClick={() => navigate("/sign-in")}
              type="submit"
              style={{ marginLeft: "0px" }}
            >
              Submit
            </button>
          </div>
          {errorMessage && (
            <p style={{ color: "red", textAlign: "center", marginTop: "10px" }}>
              {errorMessage}
            </p>
          )}
        </div>
      </form>
    </>
  );
}
