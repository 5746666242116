import React from 'react'
import { Tooltip } from "react-tooltip";
import infoImg from "../assets/images/info.png"
const TooltipItem = ({desc=''}) => {
  return (
    <>
      <a data-tooltip-id="my-tooltip" data-tooltip-content={desc}>
        <img src={infoImg} alt="info" />
      </a>
      <Tooltip id="my-tooltip" />
    </>
  );
}

export default TooltipItem