import "../style.scss";
import React from "react";
import ScrollTop from "../../../components/scrollTop";
import qr_code from "../../../assets/images/QR_code.png";
import calendar_icon from "../../../assets/images/icons/calendar.png";
import Calendar from "../../../components/calendar";


export default function AgreementToLicense3() {
    return (
        <div>
            <ScrollTop/>
            <div className="d_flex align_center margin_top_12">
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="No" value="No" name="radio" checked="checked"
                    />
                    No
                </label>
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="yes" value="yes" name="radio"
                    />
                    Yes
                </label>
            </div>
            <div className="margin_top_8">
                ANY INFRINGEMENT CAUSES IN COURT OR ARBITRATION INVOLVING [IP]
            </div>
            <div className="d_flex align_center margin_top_12">
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="No1" value="No1" name="radio1" checked="checked"
                    />
                    No
                </label>
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="yes1" value="yes1" name="radio1"
                    />
                    Yes
                </label>
            </div>
            <div className="hr_line">

            </div>
            <h5>5. OWNERSHIP</h5>
            <div><span>Grantor / licensor</span>The warrants that the ownership of the intellectual property, and shown
                in search results above.
            </div>
            <div className="d_flex align_center margin_top_8">
                <div className="filled_data_input" style={{marginRight: "24px"}}>
                    <input type="text" placeholder="NAME OF INTELLECTUAL PROPERTY"/>
                </div>
                <h5>[Section 8 of the MPSR Act 2017]</h5>
            </div>
            <div className="margin_top_12">
                This agreement to license shall be binding while in its duration the personal representatives and agents
                of the parties involved and mentioned herein.
            </div>
            <div className="margin_top_24"><span>THAT: </span>
                the said certificate mentioned above is their property and that the Grantor / Licensor has all the
                relevant capacity to authorize the use of the said intellectual property mentioned above for use by the
                Borrower /Licensee.
            </div>
            <div className="margin_top_24">
                Upon the guided permitted use terms herein below.
            </div>
            <div className="d_flex align_center margin_top_12">
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="No2" value="No2" name="radio2" checked="checked"
                    />
                    No
                </label>
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="yes2" value="yes2" name="radio2"
                    />
                    Yes
                </label>
            </div>
            <div className="margin_top_32">
                ARE YOU THE ONLY OWNER?
            </div>
            <div className="d_flex align_center margin_top_12">
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="No3" value="No3" name="radio3"
                    />
                    No
                </label>
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="yes3" value="yes3" name="radio3" checked="checked"
                    />
                    Yes
                </label>
            </div>
            <div className="margin_top_32">
                Is there an existing agreement between you and your co-owner on the management, use or sale of the
                intellectual property:
            </div>
            <div className="d_flex align_center margin_top_12">
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="No4" value="No4" name="radio4"
                    />
                    No
                </label>
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="yes4" value="yes4" name="radio4" checked="checked"
                    />
                    Yes
                </label>
            </div>
            <div className="margin_top_16">
                <div className="">
                            <textarea name="" id="" cols="" rows=""
                                      style={{background: "white", width: "100%"}}>Max 100 characters</textarea>
                </div>
            </div>
            <h5 className="margin_top_24">5.1. CO OWNERSHIP STRUCTURE:</h5>
            <div className="margin_top_12">
                The [co]-owner shall agree to license their share in the intellectual property, if they own the
                intellectual property in co ownership.
            </div>
            <h5 className="margin_top_32">5.1.1.</h5>
            <div className="apply_certificate_item margin_top_8">
                <div className="certificate_item">
                    <label htmlFor="">Percentage that you own in the IP</label>
                    <input type="text" value="" placeholder=""/>
                </div>
                <div className="certificate_item">

                </div>
            </div>
            <h5 className="margin_top_8">GRANTOR/SELLER</h5>
            <div className="apply_certificate_item margin_top_12">
                <div className="certificate_item filled_data_input">
                    <input type="text" placeholder="Name Surname"/>
                </div>
                <div className="certificate_item filled_data_input">

                </div>
            </div>
            <div>
                I have informed <span>the other co owner of my intention to sell</span> my share in the intellectual
                property.
                Send Notice of intention to sell share to Co-Owner:
            </div>
            <div className="apply_certificate_item margin_top_12">
                <div className="certificate_item">
                    <label htmlFor="">Email of external co owner</label>
                    <input type="text" value="" placeholder=""/>
                </div>
                <div className="certificate_item">

                </div>
            </div>
            <div className="margin_top_8">
                AND THIS IS <span>NOTICE OF MY INTENTION TO LICENSE:</span> MY SHARE IN THE PROPERTY TO:
            </div>
            <div className="apply_certificate_item margin_top_12">
                <div className="certificate_item filled_data_input">
                    <input type="text" placeholder="Purchaser IPD info"/>
                </div>
                <div className="certificate_item filled_data_input">
                    <input type="text" placeholder="Email of Co-owner"/>
                </div>
            </div>
            <div className="apply_certificate_item">
                <div className="certificate_item filled_data_input">
                    <input type="text" placeholder="IPD address of co owner"/>
                </div>
                <div className="certificate_item filled_data_input">
                    <input type="text" placeholder="Telephone number of co owner"/>
                </div>
            </div>
            <div className="margin_top_8">
                Due to the lack of a management agreement between me and the co owner. The initial party shall hold the
                licensee fee or the perceived share that I am entitled to, and remenerate [PAY] my share to myself at a
                later date:
            </div>
            <div className="d_flex align_center margin_top_12">
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="No5" value="No5" name="radio5" checked="checked"
                    />
                    No
                </label>
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="yes5" value="yes5" name="radio5"
                    />
                    Yes
                </label>
            </div>
            <div className="d_flex align_center form-group margin_top_24">
                <input type="checkbox" id="checkbox2" name="checkbox2"/>
                <label htmlFor="checkbox2">Confirm receipt.</label>
            </div>
            <h5 className="margin_top_32">5.3. EXTERNAL CO OWNER RESPONSE FROM EMAIL:</h5>
            <div className="apply_certificate_item margin_top_12">
                <div className="certificate_item filled_data_input">
                    <input type="text" placeholder="Email of Co-owner"/>
                </div>
                <div className="certificate_item filled_data_input">
                </div>
            </div>
            <h5 className="margin_top_8">CO-OWNER:</h5>
            <div className="margin_top_12">
                I understand that the co owner named in the this notice is desirous of licensing their share in the
                intellectual property named
            </div>
            <div className="d_flex align_center margin_top_12">
                <div className="filled_data_input" style={{marginRight: "24px"}}>
                    <input type="text" placeholder="NAME OF INTELLECTUAL PROPERTY"/>
                </div>
                <h5>[Section 8 of the MPSR Act 2017]</h5>
            </div>
            <div className="margin_top_32">
                KINDLY EXPLAIN YOUR DEFINED SHARE IN THE CERTIFICATE:
            </div>
            <div className="margin_top_16">
                <div className="">
                            <textarea name="" id="" cols="" rows=""
                                      style={{background: "white", width: "100%"}}>Define</textarea>
                </div>
            </div>
            <div className="margin_top_24">
                WOULD YOU LIKE TO LICENSE YOUR SHARE:
            </div>
            <div className="d_flex align_center margin_top_8">
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="No6" value="No6" name="radio6" checked="checked"
                    />
                    No
                </label>
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="yes6" value="yes6" name="radio6"
                    />
                    Yes
                </label>
            </div>
            <div className="margin_top_8">
                <div className="">
                            <textarea name="" id="" cols="" rows=""
                                      style={{background: "white", width: "100%"}}>Max 100 characters</textarea>
                </div>
            </div>
            <div className="hr_line">
            </div>
            <h5>6. OWNER GRANTS LICENSE UNDER THIS SCHEDULE OF PERMITTED USE SCENARIOS:</h5>
            <div className="margin_top_12">
                The borrower licensee admits and warrants that they shall only use the above subject matter.
                [Intellectual Property for the following use:
            </div>
            <div className="margin_top_12">
                And will at all times when called upon give credit to the grantor / licensor.
            </div>
            <div className="d_flex align_center margin_top_8">
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="No7" value="No7" name="radio7"
                    />
                    No
                </label>
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="yes7" value="yes7" name="radio7" checked="checked"
                    />
                    Yes
                </label>
            </div>
            <h5 className="margin_top_32">PERMITTED USE SCENARIOS:</h5>
            <h5 className="margin_top_24">A. ADVERTISING SCENARIO:</h5>
            <div className="green_div_border margin_top_12">
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                industry's standard dummy text ever since the 1500s.
            </div>
            <div className="margin_top_12">Confirm</div>
            <div className="d_flex align_center margin_top_12">
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="No8" value="No8" name="radio8"
                    />
                    No
                </label>
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="yes8" value="yes8" name="radio8" checked="checked"
                    />
                    Yes
                </label>
            </div>
            <h5 className="margin_top_32">B. RESEARCH SCENARIO:</h5>
            <div className="green_div_border margin_top_12">
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                industry's standard dummy text ever since the 1500s.
            </div>
            <div className="margin_top_12">Confirm</div>
            <div className="d_flex align_center margin_top_12">
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="No9" value="No9" name="radio9"
                    />
                    No
                </label>
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="yes9" value="yes9" name="radio9" checked="checked"
                    />
                    Yes
                </label>
            </div>
            <div className="margin_top_32">
                <span style={{color: "red"}}>NOTE: </span> The relevant Administrative County Authority shall be sought
                for any research that may include
                injury to the animals, environment, humans, and general population & society.
            </div>
            <h5 className="margin_top_32">C. ENTERTAINMENT SHOW:</h5>
            <div className="apply_certificate_item margin_top_12">
                <div className="certificate_item filled_data_input">
                    <input type="text" placeholder="Name of show"/>
                </div>
                <div className="certificate_item filled_data_input">
                    <input type="text" placeholder="Location"/>
                </div>
            </div>
            <div className="apply_certificate_item">
                <div className="certificate_item filled_data_input">
                    <input type="text" placeholder="Venue"/>
                </div>
                <div className="certificate_item filled_data_input">
                    <input type="text" placeholder="Date"/>
                </div>
            </div>
            <div className="apply_certificate_item">
                <div className="certificate_item filled_data_input">
                    <input type="text" placeholder="Host (S)"/>
                </div>
                <div className="certificate_item filled_data_input">
                </div>
            </div>
            <div className="green_div_border">
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                industry's standard dummy text ever since the 1500s.
            </div>
            <div className="margin_top_24">Confirm</div>
            <div className="d_flex align_center margin_top_12">
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="No10" value="No10" name="radio10"
                    />
                    No
                </label>
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="yes10" value="yes10" name="radio10" checked="checked"
                    />
                    Yes
                </label>
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="Skip10" value="Skip10" name="radio10"
                    />
                    Skip
                </label>
            </div>
            <h5 className="margin_top_32">D. CEREMONY USE:</h5>
            <div className="apply_certificate_item margin_top_12">
                <div className="certificate_item filled_data_input">
                    <input type="text" placeholder="Name of ceremony"/>
                </div>
                <div className="certificate_item filled_data_input">
                    <input type="text" placeholder="Date of ceremony"/>
                </div>
            </div>
            <div className="apply_certificate_item">
                <div className="certificate_item filled_data_input">
                    <input type="text" placeholder="Location of ceremony"/>
                </div>
                <div className="certificate_item filled_data_input">
                    <input type="text" placeholder="Venue of ceremony"/>
                </div>
            </div>
            <div className="apply_certificate_item">
                <div className="certificate_item filled_data_input">
                    <input type="text" placeholder="Host (S)"/>
                </div>
                <div className="certificate_item filled_data_input">
                </div>
            </div>
            <div className="green_div_border">
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                industry's standard dummy text ever since the 1500s.
            </div>
            <div className="margin_top_24">Confirm</div>
            <div className="d_flex align_center margin_top_12">
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="No11" value="No11" name="radio11"
                    />
                    No
                </label>
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="yes11" value="yes11" name="radio11" checked="checked"
                    />
                    Yes
                </label>
            </div>
            <h5 className="margin_top_32">E. OTHER PERMITTED USE:</h5>
            <div className="margin_top_16">Definition what the IP is ti be used for:</div>
            <div className="green_div_border">
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                industry's standard dummy text ever since the 1500s.
            </div>
            <div className="margin_top_24">Confirm</div>
            <div className="d_flex align_center margin_top_12">
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="No12" value="No12" name="radio12"
                    />
                    No
                </label>
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="yes12" value="yes12" name="radio12" checked="checked"
                    />
                    Yes
                </label>
            </div>
            <h5 className="margin_top_32">F. RIGHT TO SUB-LICENSE:</h5>
            <div className="margin_top_12">Grantor / Licensor allows sub-license?</div>
            <div className="d_flex align_center margin_top_12">
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="No13" value="No13" name="radio13"
                    />
                    No
                </label>
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="yes13" value="yes13" name="radio13" checked="checked"
                    />
                    Yes
                </label>
            </div>
            <h5 className="margin_top_32">G. TRANSFER OF ACCESS TO [IP]</h5>
            <div className="apply_certificate_item margin_top_12">
                <div className="certificate_item filled_data_input">
                    <input type="text" placeholder="Website"/>
                </div>
                <div className="certificate_item filled_data_input">
                    <input type="text" placeholder="URL"/>
                </div>
            </div>
            <div className="apply_certificate_item">
                <div className="certificate_item filled_data_input">
                    <input type="text" placeholder="Literal material.pdf"/>
                </div>
                <div className="certificate_item filled_data_input">
                    <input type="text" placeholder="File number"/>
                </div>
            </div>
            <div className="apply_certificate_item">
                <div className="certificate_item filled_data_input">
                    <input type="text" placeholder="Other"/>
                </div>
                <div className="certificate_item filled_data_input">
                </div>
            </div>
            <div className="QR_code_b">
                <img src={qr_code} alt="QR" className=""/>
            </div>
            <div className="hr_line">
            </div>
            <h5>7. DURATION (Today: 16 Aug 2023)</h5>
            <h5 className="margin_top_12">LICENSE PERIOD / TIME:</h5>
            <div className="margin_top_8">
                The two parties the Owner / Licensor and the Borrower / Licensee, hereby agree to be bound by the terms
                of this license agreement for the following duration of time.
            </div>
            <h5 className="margin_top_32">7.1. CALLENDER:</h5>
            <div className="apply_certificate_item margin_top_8">
                <div className="certificate_item filled_data_input">
                    <input type="text" placeholder="Start Date"/>
                </div>
                <div className="certificate_item filled_data_input">
                    <input type="text" placeholder="End date"/>
                </div>
            </div>
            <div className="d_flex space_beetween apply_certificate_item">
                <div className="d_flex fd_column certificate_item">
                    <div>Confirm start date picked by Borrower/ Licensee:</div>
                    <div className="d_flex align_center margin_top_12">
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="No14" value="No14" name="radio14"
                            />
                            No
                        </label>
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="yes14" value="yes14" name="radio14" checked="checked"
                            />
                            Yes
                        </label>
                    </div>
                </div>
                <div className="d_flex fd_column certificate_item">
                    <div>Confirm end date picked by Borrower/ Licensee:</div>
                    <div className="d_flex align_center margin_top_12">
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="No15" value="No15" name="radio15"
                            />
                            No
                        </label>
                        <label className="radio_btn_b d_flex align_center">
                            <input type="radio" id="yes15" value="yes15" name="radio15" checked="checked"
                            />
                            Yes
                        </label>
                    </div>
                </div>
            </div>
            <div className="hr_line" style={{marginTop: "0px"}}>
            </div>
            <h5>8. COST OF LICENSE / PRICE USD:</h5>
            <div className="apply_certificate_item margin_top_8">
                <div className="certificate_item filled_data_input">
                    <input type="text" placeholder="Amount in numbers"/>
                </div>
                <div className="certificate_item filled_data_input">
                    <input type="text" placeholder="Amount in figures"/>
                </div>
            </div>
            <h5 className="margin_top_8">8.1. THE AGREED PRICE SHALL BE CHANGED PER:</h5>
            <div className="d_flex margin_top_12  checkboxContent_checkboxes">
                <div className="d_flex align_center form-group">
                    <input type="checkbox" id="checkbox3" name="checkbox3"/>
                    <label htmlFor="checkbox3">Day</label>
                </div>
                <div className="d_flex align_center form-group">
                    <input type="checkbox" id="checkbox4" name="checkbox4"/>
                    <label htmlFor="checkbox4">Week</label>
                </div>
                <div className="d_flex align_center form-group">
                    <input type="checkbox" id="checkbox5" name="checkbox5"/>
                    <label htmlFor="checkbox5">Month</label>
                </div>
            </div>
            <div className="margin_top_32">LICENSEE TO CONFIRM:</div>
            <div className="d_flex align_center margin_top_8">
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="No16" value="No16" name="radio16"
                    />
                    No
                </label>
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="yes16" value="yes16" name="radio16" checked="checked"
                    />
                    Yes
                </label>
            </div>
            <h5 className="margin_top_32">8.2. THE AMOUNT CALCULATED BY NUMBERS ABOVE:</h5>
            <div className="apply_certificate_item margin_top_8">
                <div className="certificate_item filled_data_input">
                    <input type="text" placeholder="Amount in numbers"/>
                </div>
                <div className="certificate_item filled_data_input">
                    <input type="text" placeholder="Amount in figures"/>
                </div>
            </div>
            <h5 className="margin_top_8">8.3. IPD administration fees shall be calculated at one [1%] percent of the
                agreed amount above:</h5>
            <div className="apply_certificate_item margin_top_8">
                <div className="certificate_item filled_data_input">
                    <input type="text" placeholder="VALUE ADDED TAX (%)"/>
                </div>
                <div className="certificate_item filled_data_input">
                    <input type="text" placeholder="CAPITAL GAINS (%)"/>
                </div>
            </div>
            <div className="apply_certificate_item">
                <div className="certificate_item filled_data_input">
                    <input type="text" placeholder="WITHHOLDING TAX (%)"/>
                </div>
                <div className="certificate_item filled_data_input">
                    <input type="text" placeholder="GRAND TOTAL"/>
                </div>
            </div>
            <div className="hr_line" style={{marginTop: "0px"}}>
            </div>
            <h5>9. BREACH OF TERMS</h5>
            <div className="d_flex align_center form-group margin_top_12">
                <input type="checkbox" id="checkbox6" name="checkbox6" checked/>
                <label htmlFor="checkbox6">The Borrower / Licensee hereby admits that they understand that they are
                    only permitted to use the intellectual property owned by the Owner/ Licensor for the permitted
                    use negotiated and agreed upon in <span style={{lineHeight: "0px"}}>Clause 6 A-D or E</span> any
                    unjustified use that is not incidental
                    or related to the use mentioned in this clauses, will amount to a breach of conditions of this
                    agreement, and will enable the Owner / Licensor to terminate the license by issuing a
                    termination notice, [below] and sue for any damages caused by the breach of the permitted use,
                    as they see fit.</label>
            </div>
            <h5 className="margin_top_32">9.1. INDEMNIFICATION:</h5>
            <div className="d_flex align_center form-group margin_top_12">
                <input type="checkbox" id="checkbox7" name="checkbox7" checked/>
                <label htmlFor="checkbox7">Compensation for harm, loss or damage caused by any false representation by
                    the borrower to any third 3rd parties, during the course and subsistence of this license
                    period.
                </label>
            </div>
            <h5 className="margin_top_24">The licensee <span style={{color: "red", lineHeight: "0px"}}>MAY</span> be
                liable for the following: if
                improper use of intellectual property during or after
                license if causation is found to emanate from this license period.</h5>
            <h5 className="margin_top_24">ACTS THIRD PARTY ACTIVITIES AND DAMAGES:</h5>
            <div className="margin_top_24" style={{lineHeight: "normal"}}>
                The licensee/ borrower <span style={{lineHeight: "normal"}}>shall be liable for any loss, or damage occurring
                to third parties by the improper use of the intellectual property licensed to them</span>
            </div>
            <div className="margin_top_24">
                The permitted use guidelines must be adhered to at all times during the subsistence of this license.
            </div>
            <div className="margin_top_24" style={{lineHeight: "normal"}}>
                <span>Torts Law and liability: </span>
                Occupiers liability & risk may shift to the licensee if the intellectual property is premises based,
                during the subsistence of the license.
            </div>
            <div className="margin_top_24" style={{lineHeight: "normal"}}>
                <span>Fitness for purpose: </span>
                the intellectual property must be used per the guidelines stipulated in the registration process, and
                the licensee/ borrower may be liable for any use that is outside the aims of the intellectual property.
                OUTSIDE RESEARCH parameters that cause harm.
            </div>
            <h5 className="margin_top_24">
                RESEARCH PARAMETERS: the licensee / borrower shall seek the relevant County, Administrative or
                Government authorities clearance and permission in your region. Be it Educational, Medical, Sanitation,
                Construction or other related fields.
            </h5>
            <div className="margin_top_24" style={{lineHeight: "normal"}}>
                <span>Negligence:</span>
                The borrower may be liable for any negligent acts or omissions occurring from the licensed use of the
                intellectual property.
            </div>
            <div className="margin_top_24" style={{lineHeight: "normal"}}>
                <span>Contractual:</span>
                The licensee/ borrower may be liable for any contractual obligations arising from the licensed use of
                the intellectual property during the tenure of the license agreement.
            </div>
            <h5 className="margin_top_24">The use of the term may be determined on the facts and through due process by
                court or abitral body.</h5>
            <div className="hr_line">
            </div>
            <h5>10. TERMINATION OF LICENSE:</h5>
            <div className="d_flex align_center form-group margin_top_12">
                <input type="checkbox" id="checkbox8" name="checkbox8" checked/>
                <label htmlFor="checkbox8">The license agreement can be terminated by both parties at any time during
                    the subsistence of the license. Either party wishing to terminate the license shall issue a
                    termination notice to the other party, stating the grounds for termination. And by the termination
                    notice There shall not be a refund of monies paid as service fee or costs to the ipdepository.com
                    system. The contract herein may also be terminated by the breach mentioned above in clause 9 or
                    incidental matters.
                </label>
            </div>
            <div className="margin_top_32">ACTIVATE TERMINATION NOTICE:</div>
            <div className="d_flex align_center margin_top_8">
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="No17" value="No17" name="radio17"
                    />
                    No
                </label>
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="yes17" value="yes17" name="radio17" checked="checked"
                    />
                    Yes
                </label>
            </div>
            <h5 className="margin_top_32">LICENSOR HAS SEEN A VIOLATION FROM THE LICENSEES USE:</h5>
            <h5 className="margin_top_24">PLACE TERMINATION NOTICE:</h5>
            <div className="apply_certificate_item margin_top_12">
                <div className="certificate_item">
                    <label htmlFor="">Date</label>
                    <input type="text" value="" placeholder=""/>
                </div>
                <div className="certificate_item">
                    <label htmlFor="">Time</label>
                    <input type="text" value="" placeholder=""/>
                </div>
            </div>
            <h5 className="margin_top_8">The licensor will issue a violation warning that must be attended to by the
                licensee:</h5>
            <h5 className="margin_top_24">LICENSEE EXPLANATION / Response to violation notification:</h5>
            <div className="margin_top_12">
                            <textarea name="" id="" cols="" rows=""
                                      style={{background: "white", width: "100%"}}>Max 100 charecters</textarea>
            </div>
            <div className="hr_line">
            </div>
            <div className="d_flex align_center title_b space_beetween margin_top_8">
                <span></span>
                <h5>ATTESTATION</h5>
                <span></span>
            </div>
            <div className="margin_top_12">
                Both parties mentioned herein being the grantor / licensor granting permission to the borrower /
                licensee, hereby mutually agree to be bound by the terms of this agreement, that they voluntarily
                entered into, using this automated negotiation template. And hereby attest their signatures to the
                relevant particulars that are their own. Below.
            </div>
            <div className="hr_line">
            </div>
            <div className="d_flex align_center title_b space_beetween margin_top_8">
                <span></span>
                <h5>SIGNED BY</h5>
                <span></span>
            </div>
            <div className="margin_top_12">
                I declare that I licensor have all the relevant capacity to license this intellectual property asset to
                the licensor on the terms stipulated within this license agreement.
            </div>
            <div className="d_flex align_center margin_top_8">
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="No18" value="No18" name="radio18"
                    />
                    No
                </label>
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="yes18" value="yes18" name="radio18" checked="checked"
                    />
                    Yes
                </label>
            </div>
            <h5 className="margin_top_32">GRANTOR/ LICENSOR:</h5>
            <div className="apply_certificate_item margin_top_12">
                <div className="certificate_item filled_data_input">
                    <input type="text" placeholder="example@gmail.com"/>
                </div>
                <div className="certificate_item filled_data_input">
                    <input type="text" placeholder="Passport picture.jpeg"/>
                </div>
            </div>
            <div className="apply_certificate_item">
                <div className="certificate_item filled_data_input">
                    <input type="text" placeholder="Nationality"/>
                </div>
                <div className="certificate_item filled_data_input">

                </div>
            </div>
            <div>Digital signature</div>
            <div>
                <div className="">
                            <textarea name="" id="" cols="" rows=""
                                      style={{background: "white", width: "100%"}}></textarea>
                </div>
            </div>
            <div className="d_flex align_center title_b space_beetween margin_top_32">
                <span></span>
                <h5>SIGNED BY</h5>
                <span></span>
            </div>
            <div className="margin_top_12">
                I declare that I licensor have all the relevant capacity to license this intellectual property asset to
                the licensor on the terms stipulated within this license agreement.
            </div>
            <div className="d_flex align_center margin_top_8">
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="No19" value="No19" name="radio19"
                    />
                    No
                </label>
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="yes19" value="yes19" name="radio19" checked="checked"
                    />
                    Yes
                </label>
            </div>
            <h5 className="margin_top_32">GRANTOR/ LICENSOR:</h5>
            <div className="apply_certificate_item margin_top_12">
                <div className="certificate_item filled_data_input">
                    <input type="text" placeholder="example@gmail.com"/>
                </div>
                <div className="certificate_item filled_data_input">
                    <input type="text" placeholder="Passport picture.jpeg"/>
                </div>
            </div>
            <div className="apply_certificate_item">
                <div className="certificate_item filled_data_input">
                    <input type="text" placeholder="Nationality"/>
                </div>
                <div className="certificate_item filled_data_input">

                </div>
            </div>
            <div>Digital signature</div>
            <div>
                <div className="">
                            <textarea name="" id="" cols="" rows=""
                                      style={{background: "white", width: "100%"}}></textarea>
                </div>
            </div>
            <div className="d_flex align_center justify_content_center submit_b margin_top_32">
                <button className="save_btn" style={{marginLeft: "0px"}}>Continue</button>
            </div>
        </div>
    )
}