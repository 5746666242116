import React, {useState} from "react";
import {logo} from "../../../assets/images";
import {useLocation, useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import * as _ from "lodash";
import axiosInstance from "../../../api";
import {Loading} from "../../../components";

export function ChangePass() {
    let navigate = useNavigate()
    let {state} = useLocation()
    let [loading, setLoading] = useState(false)
    let [error, setError] = useState('')
    let [data, setData] = useState({})

    let changeInput = async (e, name) => {
        setData({..._.assignIn(data, {[name]: e})})
        setError('')
    }

    let changePass = async () => {
        try {
            if (data.password && data.code && data.conf_password) {
                if (data.password.length > 7) {
                    if (data.password === data.conf_password) {
                        const {code,password} = data
                        setLoading(true)
                        await axiosInstance.post("api/client/reset-password", {
                          code,
                          password,
                        });
                        setLoading(false)
                        navigate('/sign-in',)
                    } else {
                        setError('Password and confirm password not match')
                    }
                } else {
                    setError('Length must be at least 8 characters')
                }
            } else {
                setError('Please fill in all required fields')
            }
        } catch (e) {
            setLoading(false)
            if (e.response?.data) {
                setError(e.response?.data.message)
            }
        }
    }


    return (
        <div className='signBanner'>
            <img src={logo} alt="" title="" className='logo'/>
            <div className={'div'}>
                <h1>Reset password</h1>
                <label htmlFor="email">
                    Code
                    <input type="text" id='text' onChange={(e) => changeInput(e.target.value, 'code')}/>
                </label>
                <label htmlFor="email">
                    New password
                    <input type="password" id='password' onChange={(e) => changeInput(e.target.value, 'password')}/>
                </label>
                <label htmlFor="email">
                    Repeat new password
                    <input type="password" id='pass' onChange={(e) => changeInput(e.target.value, 'conf_password')}/>
                </label>
                {error ?
                    <p style={{color: 'red', marginTop: 10}}>{error}</p>
                    :
                    null
                }
                <button className='btn' onClick={changePass}>Change password</button>
            </div>
            <Loading modalIsOpen={loading}/>

        </div>
    );
}