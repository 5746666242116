import React, {useEffect, useRef, useState} from "react";
import {americanExpress, closeBlack, masterCard, visa} from "../../../../assets/images";
import axiosInstance from "../../../../api";

export function Subscription() {
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const [subscription, setSubscription] = useState([])
    const ref = useRef();

    useEffect(() => {
        const checkIfClickedOutside = e => {
            if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
                setIsMenuOpen(false)
                document.body.classList.remove('showModal')
            }
        }
        document.addEventListener("mousedown", checkIfClickedOutside)

        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }

    }, [isMenuOpen])

    const showModal = () => {
        setIsMenuOpen(oldState => !oldState)
        document.body.classList.add('showModal')

    }
    const closeModal = () => {
        setIsMenuOpen(false)
        document.body.classList.remove('showModal')

    }
    const [on, setOnState] = useState(false);
    const toggle = () => setOnState(o => !o);

    useEffect(() => {
        sub()
    }, []);

    let _renderSubscription = () => {
        return subscription.map((data, index) => {
            return(
                <div className="subscrptonItem" key={index}>
                    <h4>{data.name}</h4>
                    <div className="body">
                        <p className='description'>{data.description}</p>
                        <p className='price'>
                            <span>$</span>
                            {data.price}
                            <span style={{color: '#464343'}}>month</span>
                        </p>
                    </div>
                    <button onClick={showModal}>Subscribe Now</button>
                </div>
            )
        })
    }


    let sub = async () => {
        try {
            let response = await axiosInstance.get('api/client/plans')
            setSubscription(response.data);
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <div className='subscrptonContent'>
            <div className="body">
                {_renderSubscription()}

            </div>
            <div className="modal addCardModal">
                <div className="modalBc"></div>
                {isMenuOpen && (<div className="modalContent" ref={ref}>

                    <div className="modalBody">
                        <button onClick={closeModal} className='closeBtn'>
                            <img src={closeBlack} alt="" title=''/>
                        </button>

                        <h5>Add New Card</h5>
                        <div style={{display: "block"}}>
                            <label>
                                Name On Card
                                <input type="text" placeholder=''/>
                            </label>
                        </div>
                        <div className='cardNumberContent'>
                            <label>
                                Card Number
                                <input type="text" placeholder=''/>
                            </label>
                            <div className='cardsContent'>
                                <img src={visa} alt=""/>
                                <img src={masterCard} alt=""/>
                                <img src={americanExpress} alt=""/>
                            </div>
                        </div>
                        <div className='expirationContent'>
                            <label>
                                Expiration Month
                                <select placeholder="Name">
                                    <option placeholder='Name 1'>Name 1</option>
                                    <option placeholder='Name 2'>Name 2</option>
                                    <option placeholder='Name 3'>Name 3</option>
                                </select>
                            </label>
                            <label>
                                Expiration Year
                                <select placeholder="Name">
                                    <option placeholder='Name 1'>Name 1</option>
                                    <option placeholder='Name 2'>Name 2</option>
                                    <option placeholder='Name 3'>Name 3</option>
                                </select>
                            </label>
                            <label>
                                CVV
                                <input type="text" placeholder='CVV'/>
                            </label>
                        </div>
                        <div>
                            <p>Save Card for further billing?</p>
                            <div className="saveCard">
                                <button className={on ? 'on' : 'off'} on={on} onClick={toggle}>
                                    <span className="pin"/>
                                </button>
                                Save Card
                            </div>
                        </div>
                        <div className="btnsContent" style={{margin: "auto", justifyContent: "center"}}>
                            <button>Subscribe</button>
                        </div>
                    </div>
                </div>)}
            </div>
            {/*<div className='btns'>*/}
            {/*    <button className='cancel'>cancel</button>*/}
            {/*    <button>Change Plan</button>*/}
            {/*</div>*/}
        </div>
    );
}
