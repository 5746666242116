import "../style.scss";
import React from "react";
import Calendar from "../../../components/calendar";
import calendar_icon from "../../../assets/images/icons/calendar.png";
import file_upload from "../../../assets/images/icons/file_upload_black_24dp 1.png";
import {useNavigate} from "react-router-dom";
import ScrollTop from "../../../components/scrollTop";


export default function AgreementToSell3() {
    let navigate=useNavigate()

    return (
        <div>
            <ScrollTop/>
            <div className="margin_top_12">Is there a claim?</div>
            <div className="d_flex align_center margin_top_12">
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="No1" value="No1" name="radio4" checked="checked"
                    />
                    No
                </label>
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="yes1" value="yes1" name="radio4"
                    />
                    Yes
                </label>
            </div>
            <div className="margin_top_24">The seller agrees to indemnify, bear the cost of any third party
                claim of ownership claim against the purchaser. As breach of implied warranty on transfer of
                quiet possession of property.
            </div>
            <div className="d_flex align_center margin_top_12">
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="No1" value="No1" name="radio6" checked="checked"
                    />
                    No
                </label>
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="yes1" value="yes1" name="radio6"
                    />
                    Yes
                </label>
            </div>
            <div className="margin_top_24">If there has been a previous disclosure kindly tick
            </div>
            <div className="d_flex align_center margin_top_12">
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="No1" value="No1" name="radio7" checked="checked"
                    />
                    No
                </label>
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="yes1" value="yes1" name="radio7"
                    />
                    Yes
                </label>
            </div>
            <div className="margin_top_24">Does the above disclosure come into direct conflict with the sale and
                transfer of this
            </div>
            <div className="d_flex align_center margin_top_8">
                <div className="filled_data_input" style={{marginRight: "24px"}}>
                    <input type="text" placeholder="NAME OF INTELLECTUAL PROPERTY"/>
                </div>
                <h5>[Section 8 of the MPSR Act 2017]</h5>
            </div>
            <div className="margin_top_24">Is the purchaser aware of this disclosure?</div>
            <div className="d_flex align_center margin_top_12">
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="No1" value="No1" name="radio8" checked="checked"
                    />
                    No
                </label>
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="yes1" value="yes1" name="radio8"
                    />
                    Yes
                </label>
            </div>
            <div className="margin_top_12">
                <span style={{float: "right"}}>[Section 40 of the MPSR Act 2017]</span>
            </div>
            <div className="margin_top_48">Knowledge of a security act in favor of another person on the part of a
                secured creditor does not affect its priority under this act.
            </div>
            <div className="margin_top_12">
                <span style={{float: "right"}}>[Section 14 (c) Sale of Goods Act]</span>
            </div>
            <div className="margin_top_48">Implied warranty that the goods shall be free from any encumbrance, charge in
                favor of a 3rd party, not declared or known to the buyer before or at the time of signing this automated
                negotiation template.
            </div>
            <div className="hr_line">
            </div>
            <h5>4. PURCHASER DISCLOSURE ACCEPTANCE:</h5>
            <div className="margin_top_12">The purchaser hereby confirms that the seller has disclosed the relevant
                information on a previous
                disclosure of know-how and that the disclosure will in no way cause any damage, breach of implied
                warranty to the sale mentioned herein.
            </div>
            <div className="d_flex align_center margin_top_12">
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="No1" value="No1" name="radio9"
                    />
                    No
                </label>
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="yes1" value="yes1" name="radio9" checked="checked"
                    />
                    Yes
                </label>
            </div>
            <h5 className="margin_top_24">4.1. PURCHASER OBLIGATIONS: </h5>
            <div className="margin_top_12">The purchaser hereby confirms by ticking that they shall</div>
            <ul className="obligation_List">
                <li>Upon successful negotiation herein, be bound to pay the purchase price negotiated and accepted
                    between the parties.
                </li>
                <li>And will accept delivery of the goods, by either notification on IPD user dashboard, Movable
                    properties securities register registration, or by use as owner of the intellectual property in the
                    [IP] good/service.
                </li>
            </ul>
            <div className="d_flex align_center margin_top_12">
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="No1" value="No1" name="radio10"
                    />
                    No
                </label>
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="yes1" value="yes1" name="radio10" checked="checked"
                    />
                    Yes
                </label>
            </div>
            <h5 className="margin_top_24">4.2. GRANTOR OBLIGATIONS:</h5>
            <div className="margin_top_12">The seller / grantor confirms by ticking that they shall</div>
            <ul className="obligation_List">
                <li>Upon notice of receipt of negotiated purchase price, transfer ownership in the subject matter [IP]
                    to the purchaser.
                </li>
            </ul>
            <div className="d_flex align_center margin_top_12">
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="No1" value="No1" name="radio11"
                    />
                    No
                </label>
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="yes1" value="yes1" name="radio11" checked="checked"
                    />
                    Yes
                </label>
            </div>
            <div className="hr_line">
            </div>
            <h5 className="margin_top_24">5. OWNERSHIP</h5>
            <div className="margin_top_12">
                <span style={{float: "right"}}>[Section 6 (1) of the MPSR Act]</span>
            </div>
            <div className="margin_top_48">The seller warrants that the ownership of the intellectual property
            </div>
            <div className="d_flex align_center margin_top_8">
                <div className="filled_data_input" style={{marginRight: "24px"}}>
                    <input type="text" placeholder="NAME OF INTELLECTUAL PROPERTY"/>
                </div>
                <h5>[Section 8 of the MPSR Act 2017]</h5>
            </div>
            <div className="margin_top_16">is their property and an implied condition that the seller has the relevant
                capacity to transfer ownership to the purchaser
            </div>
            <div className="">
                <span style={{float: "right"}}>[Section 14 (a) Sale of Goods Act]</span>
            </div>
            <div className="margin_top_48">Any transaction on the same subject matter will be binding on the sellers
                agents and personal representatives of the parties involved and mentioned herein.
            </div>
            <h5 className="margin_top_16">GRANTOR:</h5>
            <div className="d_flex align_center margin_top_12">
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="No1" value="No1" name="radio12" checked="checked"
                    />
                    No
                </label>
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="yes1" value="yes1" name="radio12"
                    />
                    Yes
                </label>
            </div>
            <div className="margin_top_24">Are you a co owner in the intellectual property [IP]?</div>
            <div className="d_flex align_center margin_top_12">
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="No1" value="No1" name="radio13"
                    />
                    No
                </label>
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="yes1" value="yes1" name="radio13" checked="checked"
                    />
                    Yes
                </label>
            </div>
            <h5 className="margin_top_32">GRANTOR / SELLER:</h5>
            <div className="d_flex align_center margin_top_12">
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="No1" value="No1" name="radio14"
                    />
                    No
                </label>
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="yes1" value="yes1" name="radio14" checked="checked"
                    />
                    Yes
                </label>
            </div>
            <div className="margin_top_24">
                ANY THIRD PARTY CLAIMS AGAINST THE [IP] Are there any pending lawsuits or claims against the
                intellectual property?
            </div>
            <div className="d_flex align_center margin_top_12">
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="No1" value="No1" name="radio14"
                    />
                    No
                </label>
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="yes1" value="yes1" name="radio14" checked="checked"
                    />
                    Yes
                </label>
            </div>
            <div className="margin_top_16">
                <div className="">
                            <textarea name="" id="" cols="" rows=""
                                      style={{background: "white", width: "100%"}}>Max 100 characters</textarea>
                </div>
            </div>
            <div className="margin_top_12">ARE YOU THE ONLY OWNER?</div>
            <div className="d_flex align_center margin_top_12">
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="No1" value="No1" name="radio15" checked="checked"
                    />
                    No
                </label>
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="yes1" value="yes1" name="radio15"
                    />
                    Yes
                </label>
            </div>
            <h5 className="margin_top_32">5.1. CO OWNERSHIP STRUCTURE:</h5>
            <div className="margin_top_12">The owner shall agree to sell their share in the intellectual property, if
                they own the intellectual
                property in co ownership. What is
            </div>
            <h5 className="margin_top_24">5.1.1.</h5>
            <div className="apply_certificate_item">
                <div className="certificate_item">
                    <label htmlFor="">Percentage that you own in the IP</label>
                    <input type="text" value="" placeholder=""/>
                </div>
                <div className="certificate_item">
                </div>
            </div>
            <h5>Seller:</h5>
            <div className="d_flex align_center margin_top_12">
                <div className="filled_data_input" style={{marginRight: "24px"}}>
                    <input type="text" placeholder="NAME SURNAME"/>
                </div>
            </div>
            <div className="margin_top_16">I have informed the other co owner of my intention to sell my share in the
                intellectual property. Send
                Notice of intention to sell share to Co-Owner:
            </div>
            <div className="apply_certificate_item margin_top_12">
                <div className="certificate_item">
                    <label htmlFor="">Email of external co owner</label>
                    <input type="text" value="" placeholder=""/>
                </div>
                <div className="certificate_item">
                </div>
            </div>
            <h5>5.2. NOTICE OF INTENTION TO SELL:</h5>
            <div className="apply_certificate_item margin_top_12">
                <div className="certificate_item">
                    <label htmlFor="">Username</label>
                    <input type="text" value="" placeholder=""/>
                </div>
                <div className="certificate_item">
                    <label htmlFor="">Co-own (IP Name)</label>
                    <input type="text" value="" placeholder=""/>
                </div>
            </div>
            <div className="margin_top_8">
                AND THIS IS NOTICE OF MY INTENTION TO SELL AND TRANSFER MY SHARE IN THE PROPERTY TO:
            </div>
            <div className="apply_certificate_item margin_top_12">
                <div className="certificate_item">
                    <label htmlFor="">Purchaser IPD info</label>
                    <input type="text" value="" placeholder=""/>
                </div>
                <div className="certificate_item">
                    <label htmlFor="">Email of Co-owner</label>
                    <input type="text" value="" placeholder=""/>
                </div>
            </div>
            <div className="apply_certificate_item">
                <div className="certificate_item">
                    <label htmlFor="">IPD address of co owner</label>
                    <input type="text" value="" placeholder=""/>
                </div>
                <div className="certificate_item">
                    <label htmlFor="">Telephone number of co owner</label>
                    <input type="text" value="" placeholder=""/>
                </div>
            </div>
            <h5 className="margin_top_8">I understand that the co owner named in the this notice is desirous of selling
                their share in the intellectual property named.</h5>
            <div className="d_flex align_center margin_top_8">
                <div className="filled_data_input" style={{marginRight: "24px"}}>
                    <input type="text" placeholder="NAME OF INTELLECTUAL PROPERTY"/>
                </div>
                <h5>[Section 8 of the MPSR Act 2017]</h5>
            </div>
            <h5 className="margin_top_24">I am a co owner / grantor in the above intellectual property</h5>
            <div className="d_flex align_center margin_top_12">
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="No1" value="No1" name="radio16" checked="checked"
                    />
                    No
                </label>
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="yes1" value="yes1" name="radio16"
                    />
                    Yes
                </label>
            </div>
            <div className="margin_top_24">
                Would you want to sell your share too
            </div>
            <div className="d_flex align_center margin_top_12">
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="No1" value="No1" name="radio17"
                    />
                    No
                </label>
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="yes1" value="yes1" name="radio17" checked="checked"
                    />
                    Yes
                </label>
            </div>
            <div className="margin_top_16">
                <div className="">
                            <textarea name="" id="" cols="" rows=""
                                      style={{background: "white", width: "100%"}}>Max 100 characters</textarea>
                </div>
            </div>
            <div className="d_flex align_center margin_top_8">
                <div className="filled_data_input" style={{marginRight: "24px"}}>
                    <input type="text" placeholder="NAME OF INTELLECTUAL PROPERTY"/>
                </div>
                <div className="filled_data_input" style={{marginRight: "24px", width: "126px"}}>
                    <input type="text" placeholder="10 AUG 2023"/>
                </div>
            </div>
            <h5 className="margin_top_16">I CO OWNER/ GRANTOR of INTELLECTUAL PROPERTY MENTIONED ABOVE: HEREBY GIVE
                NOTICE:</h5>
            <div className="margin_top_12">That I am a co-owner of the same property to the ratio</div>
            <div className="apply_certificate_item margin_top_16 d_flex align_center">
                <div className="certificate_item">
                    <label htmlFor="">Percentage that you own in the IP</label>
                    <input type="text" value="" placeholder=""/>
                </div>
                <div className="d_flex space_beetween propertation_inputs_b">
                    <div>
                        <label htmlFor="">Proportion</label>
                        <input type="text" value="" placeholder=""/>
                    </div>
                    :
                    <div>
                        <label htmlFor="">.</label>
                        <input type="text" value="" placeholder=""/>
                    </div>
                </div>
            </div>
            <div className="">That this notice should be registered against the intellectual property
                name at the Movable Properties Securities
            </div>
            <div>Register;</div>
            <ul>
                <li>
                    Amend the certificate to include the details of this sale and transfer.
                </li>
                <li>
                    Issue new image certificate to new co owner and myself.
                </li>
            </ul>
            <div className="margin_top_32">And 10 The governing and issuing authority that issued the certificate.</div>
            <h5 className="margin_top_16">PURCHASER CONFIRM THE INFORMATION</h5>
            <div className="d_flex align_center margin_top_12">
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="No1" value="No1" name="radio18"
                    />
                    No
                </label>
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="yes1" value="yes1" name="radio18" checked="checked"
                    />
                    Yes
                </label>
            </div>
            <h5 className="margin_top_16">PURCHASER </h5>
            <h5 className="margin_top_24"> Has the right to register a rejection notice at any point before the 10 %
                deposit is paid into escrow.</h5>
            <h5 className="margin_top_24">
                If rejection notice is lodged after the payment of 10% deposit is paid. The system shall initiate a
                service charge for the reversal of the transaction.
            </h5>
            <div className="d_flex align_center margin_top_24">
                <div className="d_flex">
                    <div style={{marginRight: "24px"}}>
                        The purchaser
                    </div>
                    <div className="filled_data_input" style={{marginRight: "24px", width: "126px"}}>
                        <input type="text" placeholder="IPD Name"/>
                    </div>
                </div>
                <div className="d_flex">
                    <div style={{marginRight: "24px"}}>
                        In the transaction mentioned
                    </div>
                    <div className="filled_data_input" style={{marginRight: "24px", width: "83px"}}>
                        <input type="text" placeholder="ABOVE"/>
                    </div>
                </div>
            </div>
            <div className="margin_top_16">Place this rejection notice. And is hereby subject to the relevant authority
                and system fees as
                explained to myself above, and before initiation of this notice.
            </div>
            <div className="apply_certificate_item margin_top_16">
                <div className="certificate_item">
                    <label htmlFor="">Date</label>
                    <input type="text" value="" placeholder=""/>
                </div>
                <div className="certificate_item">

                </div>
            </div>
            <div className="">
                <div className="d_flex align_center form-group">
                    <input type="checkbox" id="checkbox1" name="checkbox1" className="checkbox"/>
                    <label htmlFor="checkbox1">I hereby place this notice.</label>
                </div>
            </div>
            <div className="margin_top_24">Rejection of agreement to sell and transfer in my favor:</div>
            <div className="margin_top_8">
                <div className="">
                            <textarea name="" id="" cols="" rows=""
                                      style={{background: "white", width: "100%"}}>Reason (100 characters)</textarea>
                </div>
            </div>
            <div className="hr_line">
            </div>
            <h5>6.TRANSFER OF OWNERSHIP AND ACCESS:</h5>
            <div className="margin_top_12">
                The seller hereby admits and warrants that they have the exclusive rights to consent and authorize the
                sale and to transfer the ownership and access to all relevant material to the purchaser to enable the
                conclusion of this sale.
            </div>
            <div className="margin_top_12">
                And an implied warranty that the purchaser will enjoy quiet possession of the goods.
            </div>
            <div className="margin_top_12">
                <span style={{float: "right"}}>[Section 14 (b) Sale of Goods Act]</span>
            </div>
            <div className="d_flex align_center margin_top_32">
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="No1" value="No1" name="radio19" checked="checked"
                    />
                    No
                </label>
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="yes1" value="yes1" name="radio19"
                    />
                    Yes
                </label>
            </div>
            <div className="margin_top_12">
                <div className="">
                            <textarea name="" id="" cols="" rows=""
                                      style={{
                                          background: "white",
                                          width: "100%"
                                      }}>Explain ownership (max 50 characters)</textarea>
                </div>
            </div>
            <h5>SELLER DOCUMENTATION ATTACHED TO SELL AND TRANSFER AGREEMENT.</h5>
            <div>The documentation and access will include: The seller has agreed to supply these to the seller at this
                AT:-
            </div>
            <h5 className="margin_top_12">A.</h5>
            <div className="apply_certificate_item margin_top_12">
                <div className="certificate_item calendar_block">
                    <label htmlFor="">Later date</label>
                    <img src={calendar_icon} alt="calendar_icon" className="calendar_icon"/>
                    <Calendar/>
                </div>
                <div className="certificate_item">
                </div>
            </div>
            <div className="margin_top_12">Registration Certificate of property named</div>
            <div className="d_flex align_center margin_top_8">
                <div className="filled_data_input" style={{marginRight: "24px"}}>
                    <input type="text" placeholder="NAME OF INTELLECTUAL PROPERTY"/>
                </div>
            </div>
            <div><span>B.</span>Seller transfers property as-is and has disclosed relevant information to the purchaser.
            </div>
            <h5 className="margin_top_16">OPTIONAL PARTNERSHIP MATERIALS</h5>
            <div className="d_flex align_center form-group margin_top_12">
                <input type="checkbox" id="checkbox2" name="checkbox2" checked/>
                <label htmlFor="checkbox2"><span>C.</span> Brochures, posters, promotional material</label>
            </div>
            <div className="d_flex space_beetween">
                <div className="certificate_item file_upload" style={{position: "relative"}}>
                    <label htmlFor="upload">Upload file</label>
                    <input type="file" value="" placeholder="Upload here" id="upload"/>
                    <img src={file_upload} alt="file_upload" className="file_upload_img"/>
                    <span className="input-file-text"></span>
                </div>
                <div className="certificate_item">

                </div>
            </div>
            <div className="d_flex align_center form-group">
                <input type="checkbox" id="checkbox3" name="checkbox3" checked/>
                <label htmlFor="checkbox3"><span>D.</span> Valuation report and insurance policy</label>
            </div>
            <div className="d_flex space_beetween">
                <div className="certificate_item file_upload" style={{position: "relative"}}>
                    <label htmlFor="upload">Upload file</label>
                    <input type="file" value="" placeholder="Upload here" id="upload"/>
                    <img src={file_upload} alt="file_upload" className="file_upload_img"/>
                    <span className="input-file-text"></span>
                </div>
                <div className="certificate_item">

                </div>
            </div>
            <div className="d_flex align_center form-group">
                <input type="checkbox" id="checkbox4" name="checkbox4"/>
                <label htmlFor="checkbox4"><span>E.</span> Domain name and access to databases and relevant control
                    panel passwords.</label>
            </div>
            <div className="d_flex align_center form-group margin_top_12">
                <input type="checkbox" id="checkbox5" name="checkbox5"/>
                <label htmlFor="checkbox5"><span>F.</span> Relevant Know-How to accurately hand over ownership as
                    ongoing business or going concern.</label>
            </div>
            <div className="margin_top_12">
                <span>G. SELL AS IS:</span>The purchaser has had the chance to inspect the [Intellectual property
                mentioned above as per RIGHT TO INSPECT the collateral [Section 58 of the MPSR Act 2017] and agrees to
                purchase the intellectual property as is/ where is:
            </div>
            <div className="d_flex align_center margin_top_8">
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="No1" value="No1" name="radio20"
                    />
                    No
                </label>
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="yes1" value="yes1" name="radio20" checked="checked"
                    />
                    Yes
                </label>
            </div>
            <div className="margin_top_24"><span>B.</span> Seller transfers property as-is and has disclosed relevant
                information to the purchaser.
            </div>
            <div className="margin_top_12">
                <div className="">
                            <textarea name="" id="" cols="" rows=""
                                      style={{
                                          background: "white",
                                          width: "100%"
                                      }}>Purchaser requests the further information (max 140 characters)</textarea>
                </div>
            </div>
            <div className="hr_line">
            </div>
            <h5>7. INDEMNIFICATION:</h5>
            <div className="d_flex align_center form-group margin_top_12">
                <input type="checkbox" id="checkbox6" name="checkbox6"/>
                <label htmlFor="checkbox6">Compensation for harm or loss caused by any representation by the
                    seller. </label>
            </div>
            <div className="margin_top_24" style={{marginLeft: "4%"}}>The seller understands that the breach of the
                following condition will be termed a
                fundamental breach of conditions to this agreement. The seller confirms that there is no third party
                claim, in terms of copyright, patent claim against the seller and the intellectual property.
            </div>
            <div className="apply_certificate_item margin_top_12">
                <div className="certificate_item">
                    <label htmlFor="">Date</label>
                    <input type="text" value="" placeholder=""/>
                </div>
                <div className="certificate_item d_flex align_center margin_top_8">
                    <label htmlFor="">.</label>
                    <div className="filled_data_input">
                        <input type="text" placeholder="Amount"/>
                    </div>
                </div>
            </div>
            <h5 className="margin_top_8">7.1. INDEMNIFICATION:</h5>
            <div className="margin_top_12">Is there a claim?</div>
            <div className="d_flex align_center margin_top_12">
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="No1" value="No1" name="radio21"
                    />
                    No
                </label>
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="yes1" value="yes1" name="radio21" checked="checked"
                    />
                    Yes
                </label>
            </div>
            <div className="margin_top_24">The seller agrees to indemnify, bear the cost of any third party claim of
                ownership claim against the purchaser. As breach of implied warranty on transfer of quiet possession of
                property.
            </div>
            <div className="d_flex align_center margin_top_12">
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="No1" value="No1" name="radio22" checked="checked"
                    />
                    No
                </label>
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="yes1" value="yes1" name="radio22"
                    />
                    Yes
                </label>
            </div>
            <div className="margin_top_24">If there has been a previous disclosure kindly tick
            </div>
            <div className="d_flex align_center margin_top_12">
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="No1" value="No1" name="radio23" checked="checked"
                    />
                    No
                </label>
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="yes1" value="yes1" name="radio23"
                    />
                    Yes
                </label>
            </div>
            <div className="margin_top_24">Does the above disclosure come into direct conflict with the sale and
                transfer of this:
            </div>
            <div className="d_flex align_center margin_top_8">
                <div className="filled_data_input" style={{marginRight: "24px"}}>
                    <input type="text" placeholder="NAME OF INTELLECTUAL PROPERTY"/>
                </div>
                <h5>[Section 8 of the MPSR Act 2017]</h5>
            </div>
            <div className="margin_top_12">Is the purchaser aware of this disclosure?</div>
            <div className="d_flex align_center margin_top_12">
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="No1" value="No1" name="radio24" checked="checked"
                    />
                    No
                </label>
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="yes1" value="yes1" name="radio24"
                    />
                    Yes
                </label>
            </div>
            <div className="margin_top_12">
                <span style={{float: "right"}}>[Section 40 of the MPSR Act 2017]</span>
            </div>
            <div className="margin_top_48">
                Knowledge of a security act in favor of another person on the part of a secured creditor does not affect
                its priority under this act.
            </div>
            <div className="margin_top_12">
                <span style={{float: "right"}}>[Section 14 (c) Sale of Goods Act]</span>
            </div>
            <div className="margin_top_48">
                Implied warranty that the goods shall be free from any encumbrance, charge in favor of a 3rd party, not
                declared or known to the buyer before or at the time of signing this automated negotiation template.
            </div>
            <h5 className="margin_top_48">7.2. NON COMPETE CLAUSE:</h5>
            <div className="margin_top_8">The <span>GRANTOR /SELLER </span> warrants that they shall not engage, develop
                or any ancillary business that is
                similar to the contents, expressions, know-how, and skill and knowledge for the period mentioned above.
            </div>
            <div className="margin_top_24">Optional for both parties:</div>
            <h5 className="margin_top_16">SELLER</h5>
            <div className="d_flex align_center margin_top_12">
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="6months" value="6months" name="radio25" checked="checked"
                    />
                    6 months
                </label>
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="1year" value="1year" name="radio25"
                    />
                    1 year
                </label>
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="2year" value="2year" name="radio25"
                    />
                    2 year
                </label>
            </div>
            <h5 className="margin_top_24">PURCHASER</h5>
            <div className="d_flex align_center margin_top_12">
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="6months" value="6months" name="radio26" checked="checked"
                    />
                    6 months
                </label>
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="1year" value="1year" name="radio26"
                    />
                    1 year
                </label>
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="2year" value="2year" name="radio26"
                    />
                    2 year
                </label>
            </div>
            <div className="hr_line">
            </div>
            <h5 className="margin_top_24">8. COST OF SALE SHALL BE:</h5>
            <div className="margin_top_8">GRANTOR /SELLER QUOTE:</div>
            <div className="apply_certificate_item">
                <div className="certificate_item filled_data_input">
                    <input type="text" placeholder="Amount in numbers"/>
                </div>
                <div className="certificate_item filled_data_input">
                    <input type="text" placeholder="Amount in words"/>
                </div>
            </div>
            <div>COUNTER OFFER BY PURCHASER:</div>
            <div className="apply_certificate_item">
                <div className="certificate_item filled_data_input">
                    <input type="text" placeholder="Amount in numbers"/>
                </div>
                <div className="certificate_item filled_data_input">
                    <input type="text" placeholder="Amount in words"/>
                </div>
            </div>
            <h5 className="margin_top_24">GOVERNMENT TAX</h5>
            <div className="d_flex space_beetween margin_top_12" style={{maxWidth: "650px"}}>
                <div>
                    <div><span>VAT :</span>16%</div>
                </div>
                <div>
                    <div><span>STAMP DUTY: </span>$250</div>
                </div>
            </div>
            <div className="hr_line">

            </div>
            <h5>9. TRANSFER:</h5>
            <div>The grantor / seller with the execution of this agreement to sell</div>
            <div className="apply_certificate_item">
                <div className="certificate_item filled_data_input">
                    <input type="text" placeholder="Todays date"/>
                </div>
                <div className="certificate_item">
                </div>
            </div>
            <div className="margin_top_16">
                and the transfer shall commence at the completion of payment of purchase price by purchaser and
                confirmation of registration of notice.
            </div>
            <h5 className="margin_top_24"> [Movable Properties Securities Rights Act 2017] registry.</h5>
            <div className="apply_certificate_item margin_top_12">
                <div className="certificate_item filled_data_input">
                    <input type="text" placeholder="NAME OF IP"/>
                </div>
                <div className="certificate_item filled_data_input">
                    <input type="text" placeholder="IMAGE OF CERTIFICATE.pdf"/>
                </div>
            </div>
            <div className="d_flex align_center">
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="No" value="No" name="radio27" checked="checked"
                    />
                    No
                </label>
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="Yes" value="Yes" name="radio27"
                    />
                    Yes
                </label>
            </div>
            <div className="hr_line">

            </div>
            <h5>10. JURISDICTION:</h5>
            <div className="margin_top_12">
                <span style={{float: "right"}}>[Section 81 of the MPSR Act 2017]</span>
            </div>
            <div className="margin_top_48">
                Laws mentioned in the recital: [Recital 1] above shall apply accordingly. Except where otherwise
                provided the law applicable to the creation and effectiveness against third parties is the law of the
                country in which the grantor is located.
            </div>
            <div className="d_flex align_center title_b margin_top_32 space_beetween">
                <span></span>
                <h5>END OF AUTOMATED NEGOTIATION TEMPLATE</h5>
                <span></span>
            </div>
            <div className="margin_top_16">
                Both parties shall be bound by the terms of the above clauses. You are free to review or refresh the
                automated negotiation template.
            </div>
            <div className="d_flex align_center space_beetween" style={{maxWidth: "300px"}}>
                <div className="d_flex align_center form-group margin_top_12">
                    <input type="checkbox" id="checkbox7" name="checkbox7" checked/>
                    <label htmlFor="checkbox7">Review</label>
                </div>
                <div className="d_flex align_center form-group margin_top_12">
                    <input type="checkbox" id="checkbox8" name="checkbox8" checked/>
                    <label htmlFor="checkbox8">Refresh</label>
                </div>
                <div className="d_flex align_center form-group margin_top_12">
                    <input type="checkbox" id="checkbox9" name="checkbox9" checked/>
                    <label htmlFor="checkbox9">Proceed</label>
                </div>
            </div>
            <div className="d_flex align_center title_b margin_top_32 space_beetween">
                <span></span>
                <h5>AUTOMATED ATTESTATION</h5>
                <span></span>
            </div>
            <div className="margin_top_16">
                Upon attesting this agreement you the purchaser are bound to pay the purchase price , and the seller is
                bound to transfer the intellectual property to the purchaser as per the terms negotiated to by the
                parties herein. And both parties are bound by the laws mentioned above and within this agreement to
                sell. Upon signing the condition of ‘’SELL” shall be deemed as satisfied and 16 may now be read as
                “SALE” agreement. Ensuring that each party is contractually bound to the terms of this agreement.
            </div>
            <div className="apply_certificate_item margin_top_12">
                <div className="certificate_item">
                    <label htmlFor="">The automated fees payable to IPD are</label>
                    <input type="text" value="" placeholder=""/>
                </div>
                <div className="certificate_item">
                </div>
            </div>
            <div className="d_flex align_center title_b space_beetween margin_top_8">
                <span></span>
                <h5>SIGNED BY</h5>
                <span></span>
            </div>
            <div className="margin_top_16">
                I seller hereby declare that I intend to sell and transfer ownership in the subject matter of this
                agreement to sell. And shall transfer ownership according to the terms of this sale agreement.
            </div>
            <div className="d_flex align_center margin_top_12">
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="No" value="No" name="radio28"
                    />
                    No
                </label>
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="Yes" value="Yes" name="radio28" checked="checked"
                    />
                    Yes
                </label>
            </div>
            <h5 className="margin_top_32">GRANTOR / SELLER:</h5>
            <div className="apply_certificate_item margin_top_12">
                <div className="certificate_item filled_data_input">
                    <input type="text" placeholder="example@gmail.com"/>
                </div>
                <div className="certificate_item filled_data_input">
                    <input type="text" placeholder="Passport picture.jpeg"/>
                </div>
            </div>
            <div className="apply_certificate_item">
                <div className="certificate_item filled_data_input">
                    <input type="text" placeholder="Nationality"/>
                </div>
                <div className="certificate_item filled_data_input">
                    <input type="text" placeholder="Location"/>
                </div>
            </div>
            <div className="apply_certificate_item">
                <div className="certificate_item filled_data_input">
                    <input type="text" placeholder="ID.pdf | KPA PIN.pdf"/>
                </div>
                <div className="certificate_item filled_data_input">
                    <input type="text" placeholder="Resolution to sell.pdf"/>
                </div>
            </div>
            <div className="apply_certificate_item">
                <div className="certificate_item filled_data_input">
                    <input type="text" placeholder="Passport picture.jpeg"/>
                </div>
                <div className="certificate_item filled_data_input">
                    <input type="text" placeholder="Front page of national identity card.jpeg"/>
                </div>
            </div>
            <div className="apply_certificate_item d_flex align_center">
                <div className="d_flex space_beetween propertation_inputs_b">
                    <div>
                        <label htmlFor="">Time</label>
                        <input type="text" value="" placeholder=""/>
                    </div>
                    :
                    <div>
                        <label htmlFor="">.</label>
                        <input type="text" value="" placeholder=""/>
                    </div>
                </div>
                <div className="certificate_item calendar_block">
                    <label htmlFor="">Date</label>
                    <img src={calendar_icon} alt="icon" className="calendar_icon"/>
                    <Calendar/>
                </div>
            </div>
            <div>Digital signature</div>
            <div>
                <div className="">
                            <textarea name="" id="" cols="" rows=""
                                      style={{background: "white", width: "100%"}}></textarea>
                </div>
            </div>
            <div className="d_flex align_center title_b space_beetween margin_top_32">
                <span></span>
                <h5>SIGNED BY</h5>
                <span></span>
            </div>
            <div className="margin_top_16">
                I purchaser hereby declare that I intend to be bound by the terms of this agreement to sell and shall
                pay the purchase price along the specified terms negotiated between the parties to this agreement.
            </div>
            <div className="d_flex align_center margin_top_12">
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="No" value="No" name="radio29"
                    />
                    No
                </label>
                <label className="radio_btn_b d_flex align_center">
                    <input type="radio" id="Yes" value="Yes" name="radio29" checked="checked"
                    />
                    Yes
                </label>
            </div>
            <h5 className="margin_top_32">GRANTOR / SELLER:</h5>
            <div className="apply_certificate_item margin_top_12">
                <div className="certificate_item filled_data_input">
                    <input type="text" placeholder="example@gmail.com"/>
                </div>
                <div className="certificate_item filled_data_input">
                    <input type="text" placeholder="Passport picture.jpeg"/>
                </div>
            </div>
            <div className="apply_certificate_item">
                <div className="certificate_item filled_data_input">
                    <input type="text" placeholder="Nationality"/>
                </div>
                <div className="certificate_item filled_data_input">
                    <input type="text" placeholder="Location"/>
                </div>
            </div>
            <div className="apply_certificate_item">
                <div className="certificate_item filled_data_input">
                    <input type="text" placeholder="ID.pdf | KPA PIN.pdf"/>
                </div>
                <div className="certificate_item filled_data_input">
                    <input type="text" placeholder="Resolution to sell.pdf"/>
                </div>
            </div>
            <div className="apply_certificate_item">
                <div className="certificate_item filled_data_input">
                    <input type="text" placeholder="Passport picture.jpeg"/>
                </div>
                <div className="certificate_item filled_data_input">
                    <input type="text" placeholder="Front page of national identity card.jpeg"/>
                </div>
            </div>
            <div className="apply_certificate_item d_flex align_center">
                <div className="d_flex space_beetween propertation_inputs_b">
                    <div>
                        <label htmlFor="">Time</label>
                        <input type="text" value="" placeholder=""/>
                    </div>
                    :
                    <div>
                        <label htmlFor="">.</label>
                        <input type="text" value="" placeholder=""/>
                    </div>
                </div>
                <div className="certificate_item calendar_block">
                    <label htmlFor="">Date</label>
                    <img src={calendar_icon} alt="icon" className="calendar_icon"/>
                    <Calendar/>
                </div>
            </div>
            <div>Digital signature</div>
            <div>
                <div className="">
                            <textarea name="" id="" cols="" rows=""
                                      style={{background: "white", width: "100%"}}></textarea>
                </div>
            </div>
            <div className="d_flex align_center justify_content_center submit_b margin_top_48" onClick={()=> navigate("")}>
                <button className="save_btn" style={{marginLeft: "0px"}}>Continue</button>
            </div>

        </div>
    )
}