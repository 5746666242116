import scroll_top from "../assets/images/icons/scroll_top_icon.png";
import React from "react";

export default function ScrollTop(){

    let ScrollToTop =()=>{
        window.scrollTo({
            top:0,
            behavior: 'smooth',
        });
    }
    return (
        <div>
            <div onClick={ScrollToTop}><img src={scroll_top} alt="scrol_top" className="scroll_top_icon"/></div>
        </div>
    )
}