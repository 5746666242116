import { z } from "zod";
import { convertImageFilesToBase64 } from "../../helper/helper";
const ACCEPTED_IMAGE_TYPES = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "image/webp",
];

export const license_schema = z
  .object({
    part_1: z.string().nonempty("Required"),
    part_2: z.string().nonempty("Required"),
    due_diligence: z
      .boolean()
      .refine(
        (val) => {
          return val === true;
        },
        { message: "You must accept the terms" }
      )
      .optional(),
    confidentiality: z
      .boolean()
      .refine(
        (val) => {
          return val === true;
        },
        { message: "You must accept the terms" }
      )
      .optional(),
    licensee_disclosure_acceptance: z
      .boolean()
      .refine(
        (val) => {
          return val === true;
        },
        { message: "You must accept the terms" }
      )
      .optional(),
    ownership: z.string().nonempty("Required"),
    co_ownership: z.string().nonempty("Required"),
    advertising_scenarioe: z
      .boolean()
      .refine(
        (val) => {
          return val === true;
        },
        { message: "You must accept the terms" }
      )
      .optional(),
    define: z.string().nonempty("Required"),
    research_scenario_1: z
      .boolean()
      .refine(
        (val) => {
          return val === true;
        },
        { message: "You must accept the terms" }
      )
      .optional(),
    research_scenario_2: z
      .boolean()
      .refine(
        (val) => {
          return val === true;
        },
        { message: "You must accept the terms" }
      )
      .optional(),
    define_the_aim_of_research: z.string().nonempty("Required"),
    entertainment_show: z
      .boolean()
      .refine(
        (val) => {
          return val === true;
        },
        { message: "You must accept the terms" }
      )
      .optional(),
    entertainment_name: z.string().nonempty("Required"),
    entertainment_venue: z.string().nonempty("Required"),
    entertainment_host: z.string().nonempty("Required"),
    entertainment_location: z.string().nonempty("Required"),
    entertainment_date: z.string().nonempty("Required"),
    entertainment_define_further: z.string().nonempty("Required"),
    ceremony_use: z
      .boolean()
      .refine(
        (val) => {
          return val === true;
        },
        { message: "You must accept the terms" }
      )
      .optional(),
    ceremony_name: z.string().nonempty("Required"),
    ceremony_location: z.string().nonempty("Required"),
    ceremony_host: z.string().nonempty("Required"),
    ceremony_date: z.string().nonempty("Required"),
    ceremony_venue: z.string().nonempty("Required"),
    ceremony_license: z.string().nonempty("Required"),
    other_permitted_use: z
      .boolean()
      .refine(
        (val) => {
          return val === true;
        },
        { message: "You must accept the terms" }
      )
      .optional(),
    other_permitted_accurately: z.string().nonempty("Required"),
    right_to_sub_license: z
      .boolean()
      .refine(
        (val) => {
          return val === true;
        },
        { message: "You must accept the terms" }
      )
      .optional(),
    transfer_of_access_website: z.string().nonempty("Required"),
    transfer_of_access_url: z.string().nonempty("Required"),
    transfer_of_access_literal: z.string().nonempty("Required"),
    transfer_of_access_qr: z
      .any()
      .refine(
        (files) => ACCEPTED_IMAGE_TYPES.includes(files?.[0]?.type),
        "Only .jpg, .jpeg, .png and .webp formats are supported."
      )
      .transform((files) => convertImageFilesToBase64(files)),
    transfer_of_access_number: z.number(),
    transfer_of_access_other: z.string().nonempty("Required"),
    duration: z
      .boolean()
      .refine(
        (val) => {
          return val === true;
        },
        { message: "You must accept the terms" }
      )
      .optional(),
    duration_start_date: z.string().nonempty("Required"),
    duration_end_date: z.string().nonempty("Required"),
    cost_of_license_number: z.number(),
    cost_of_license_figures: z.number(),
    breach_of_terms: z
      .boolean()
      .refine(
        (val) => {
          return val === true;
        },
        { message: "You must accept the terms" }
      )
      .optional(),
    indemnification: z
      .boolean()
      .refine(
        (val) => {
          return val === true;
        },
        { message: "You must accept the terms" }
      )
      .optional(),
    termination_of_license: z
      .boolean()
      .refine(
        (val) => {
          return val === true;
        },
        { message: "You must accept the terms" }
      )
      .optional(),
    verify_certificate: z.boolean(),
    value: z.boolean(),
    insure: z.boolean(),
  })
  .partial("Required");
