import { useEffect, useState } from "react";
import certificate_photo from "../../../../assets/images/certificate_pic.png";
import "./style.css";
import Modal from "react-modal";
import cancel_icon from "../../../../assets/images/icons/closeBlack.png";
import { useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "../../../../api";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

export default function New(props) {
  let navigation = useNavigate();
  let location = useLocation();
  const id = localStorage.getItem("id");

  // console.log(agreementProposal[0]);
  let [agreementProposal, setAgreementProposal] = useState([]);
  console.log("agreementProposal :", agreementProposal);
  let [agreementType, setAgreementType] = useState([]);

  const [actionId, setActionId] = useState(null);
  const [certificateProposal, setCertificateProposal] = useState({});

  const [modalIsOpen, setIsOpen] = useState(false);
  let subtitle;

  useEffect(() => {
    if (location.state.proposals) {
      const agreements = location.state.proposals.map((item) => {
        let certificate = JSON.parse(item.certificate);
        return { ...item, certificate };
      });

      setAgreementProposal(agreements);
    }
  }, [location]);
  useEffect(() => {
    getAgreementType();
  }, []);
  let getAgreementType = async () => {
    try {
      let response = await axiosInstance.get("api/v1/certificate-types");
      setAgreementType(response.data.data);
    } catch (e) {
      console.log(e);
      console.log(e.response);
    }
  };
  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }

  const handleView = (request) => {
    const filterType = agreementType.filter(
      (item) => item.id === request.type_id
    )[0];
    // navigation(type, { state: { proposals: filterDataStatus } });
    navigation(
      `/certificates/${filterType.name.toLowerCase()}/${filterType.name.toLowerCase()}/${
        request.agreement_id
      }`,
      { state: { certificate: request.certificate, view: true } }
    );
  };
  const handleCancel = async (id) => {
    try {
      
      let response = await axiosInstance.put(
        "api/client/change/status/proposal",
        { id, status: 3 }
      );
      navigation('/requests');
      closeModal();
    } catch (e) {
      console.log(e);
      console.log(e.response);
    }
  };
  const handleAccept = async (id,customerId) => {
    try {
      
      let response = await axiosInstance.put(
        "api/client/change/status/proposal",
        { id, status: 1 }
      );
      let responseChat = await axiosInstance.post(
        "api/client/message/create/room",
        { to_id: customerId }
      );
      closeModal();
      navigation("/requests");
    } catch (e) {
      console.log(e);
      console.log(e.response);
    }
  };
  return (
    <div className="agreement_proposal_cont">
      {agreementProposal.map((item, index) => {
        return (
          <div className="agreement_proposal_item">
            <div className="agreement_proposal_item_b1">
              <div className="agreement_photo_b">
                <img src={item?.agreement.qr_code} alt="" />
              </div>
              <span>{item?.agreement?.certificate_name}</span>
            </div>
            <div
              className="agreement_proposal_btns_b"
              style={{ display: "flex", gap: "10px" }}
            >
              {item?.user_id === +id ? (
                <button
                  onClick={() => {
                    openModal();
                    setActionId(item.id);
                  }}
                >
                  Cancel request
                </button>
              ) : null}
              {item?.user_id === +id ? (
                <button
                  onClick={() => {
                    setActionId(item.id);
                    handleAccept(item.id, item.customer_id);
                  }}
                >
                  Accepted
                </button>
              ) : null}

              <button onClick={() => handleView(item)}>View</button>
            </div>
          </div>
        );
      })}
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Modal"
      >
        <div className="modal_inner">
          <div className="modal_inner_header">
            <p>Cancel request</p>
            <img src={cancel_icon} alt="" onClick={closeModal} />
          </div>
          <div className="text">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry.
          </div>
          <div className="cancel_btn_b">
            <button onClick={() => handleCancel(actionId)}>Cancel</button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
