import React, {useEffect, useState} from "react";

import {CertificateBtn} from "../../../components";
import axiosInstance from "../../../api";
import User from "../../auth/signUp/User";

export function Agreements() {
    const [sell, setSell] = useState([])
    const [license, setLicense] = useState([])
    const [charge, setCharge] = useState([])
    const [partner, setPartner] = useState([])
    const [totalCount, setTotalCount] = useState(0)
    let [showAll, setShowAll] = useState(true);
    let [showPublic, setShowPublic] = useState(false);
    let [showPrivate, setShowPrivate] = useState(false);
   let [agreementType, setAgreementType] = useState([]);

    let tabToggler = (active) => {
        
        if (active === "All"){
            setShowAll(true);
            setShowPublic(false);
            setShowPrivate(false);
        }
        if (active === "Public"){
            setShowAll(false);
            setShowPublic(true);
            setShowPrivate(false);

        }
        if (active === "Private"){
            setShowAll(false);
            setShowPublic(false);
            setShowPrivate(true);
        }
    }

    useEffect(() => {
        getAgreements()
       
    }, [])

    let getAgreements =async () => {
        try {
            let response = await axiosInstance.get('api/client/all-agreements')
            console.log('response :', response);
            let responseType = await axiosInstance.get("api/v1/certificate-types");
            setAgreementType(responseType.data.data);
            setTotalCount(response.data.data.total_count)
            setSell(response.data.data.sell)
            setLicense(response.data.data.license)
            setCharge(response.data.data.charge)
            setPartner(response.data.data.partner)
        }catch (e){
            console.log(e);
        }
    }

    return (
      <div>
        <div className="agreementsContainer">
          <div className="d_flex agreementsContainer_tabs">
            <button
              className={showAll ? "tab_active" : "tab"}
              onClick={() => tabToggler("All")}
            >
              All
            </button>
            <button
              className={showPublic ? "tab_active" : "tab"}
              onClick={() => tabToggler("Public")}
            >
              Public
            </button>
            <button
              className={showPrivate ? "tab_active" : "tab"}
              onClick={() => tabToggler("Private")}
            >
              Private
            </button>
          </div>
          <div id="All" className={showAll ? "showTab" : "hideTab"}>
            <div>
              {/*<div className={'CountDiv'}><h2>Total agreements count {totalCount}</h2></div>*/}
              <div>
                <h2>sell</h2>
              </div>
              <div
                className="banner"
                style={sell.length < 4 ? { justifyContent: "start" } : null}
              >
                {sell.map((item, index) => {
                  return (
                    <CertificateBtn
                      data={item}
                      label={"sell"}
                      key={index}
                      agreementType={agreementType}
                    />
                  );
                })}
              </div>
              <div>
                <button className="seeMore">See more</button>
              </div>
            </div>
            <div>
              <div>
                <h2>license</h2>
              </div>
              <div
                className="banner"
                style={license.length < 4 ? { justifyContent: "start" } : null}
              >
                {license.map((item, index) => {
                  return (
                    <CertificateBtn data={item} label={"license"} key={index} />
                  );
                })}
              </div>
              <div>
                <button className="seeMore">See more</button>
              </div>
            </div>
            <div>
              <div>
                <h2>charge</h2>
              </div>
              <div
                className="banner"
                style={charge.length < 4 ? { justifyContent: "start" } : null}
              >
                {charge.map((item, index) => {
                  return (
                    <CertificateBtn data={item} label={"charge"} key={index} />
                  );
                })}
              </div>
              <div>
                <button className="seeMore">See more</button>
              </div>
            </div>
            <div>
              <div>
                <h2>partner</h2>
              </div>
              <div
                className="banner"
                style={partner.length < 4 ? { justifyContent: "start" } : null}
              >
                {partner.map((item, index) => {
                  return (
                    <CertificateBtn data={item} label={"partner"} key={index} />
                  );
                })}
              </div>
              <div>
                <button className="seeMore">See more</button>
              </div>
            </div>
          </div>
          <div id="Public" className={showPublic ? "showTab" : "hideTab"}>
            <div>
              {/*<div className={'CountDiv'}><h2>Total agreements count {totalCount}</h2></div>*/}
              <div>
                <h2>sell</h2>
              </div>
              <div
                className="banner"
                style={sell.length < 4 ? { justifyContent: "start" } : null}
              >
                {sell.map((item, index) => {
                  if (item.publish === 1) {
                    return (
                      <CertificateBtn data={item} label={"sell"} key={index} />
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
              <div>
                <button className="seeMore">See more</button>
              </div>
            </div>
            <div>
              <div>
                <h2>license</h2>
              </div>
              <div
                className="banner"
                style={license.length < 4 ? { justifyContent: "start" } : null}
              >
                {license.map((item, index) => {
                  if (item.publish === 1) {
                    return (
                      <CertificateBtn
                        data={item}
                        label={"license"}
                        key={index}
                      />
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
              <div>
                <button className="seeMore">See more</button>
              </div>
            </div>
            <div>
              <div>
                <h2>charge</h2>
              </div>
              <div
                className="banner"
                style={charge.length < 4 ? { justifyContent: "start" } : null}
              >
                {charge.map((item, index) => {
                  if (item.publish === 1) {
                    return (
                      <CertificateBtn
                        data={item}
                        label={"charge"}
                        key={index}
                      />
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
              <div>
                <button className="seeMore">See more</button>
              </div>
            </div>
            <div>
              <div>
                <h2>partner</h2>
              </div>
              <div
                className="banner"
                style={partner.length < 4 ? { justifyContent: "start" } : null}
              >
                {partner.map((item, index) => {
                  if (item.publish === 1) {
                    return (
                      <CertificateBtn
                        data={item}
                        label={"partner"}
                        key={index}
                      />
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
              <div>
                <button className="seeMore">See more</button>
              </div>
            </div>
          </div>
          <div id="Private" className={showPrivate ? "showTab" : "hideTab"}>
            <div>
              {/*<div className={'CountDiv'}><h2>Total agreements count {totalCount}</h2></div>*/}
              <div>
                <h2>sell</h2>
              </div>
              <div
                className="banner"
                style={sell.length < 4 ? { justifyContent: "start" } : null}
              >
                {sell.map((item, index) => {
                  if (item.publish === 0) {
                    return (
                      <CertificateBtn data={item} label={"sell"} key={index} />
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
              <div>
                <button className="seeMore">See more</button>
              </div>
            </div>
            <div>
              <div>
                <h2>license</h2>
              </div>
              <div
                className="banner"
                style={license.length < 4 ? { justifyContent: "start" } : null}
              >
                {license.map((item, index) => {
                  if (item.publish === 0) {
                    return (
                      <CertificateBtn
                        data={item}
                        label={"license"}
                        key={index}
                      />
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
              <div>
                <button className="seeMore">See more</button>
              </div>
            </div>
            <div>
              <div>
                <h2>charge</h2>
              </div>
              <div
                className="banner"
                style={charge.length < 4 ? { justifyContent: "start" } : null}
              >
                {charge.map((item, index) => {
                  if (item.publish === 0) {
                    return (
                      <CertificateBtn
                        data={item}
                        label={"charge"}
                        key={index}
                      />
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
              <div>
                <button className="seeMore">See more</button>
              </div>
            </div>
            <div>
              <div>
                <h2>partner</h2>
              </div>
              <div
                className="banner"
                style={partner.length < 4 ? { justifyContent: "start" } : null}
              >
                {partner.map((item, index) => {
                  if (item.publish === 0) {
                    return (
                      <CertificateBtn
                        data={item}
                        label={"partner"}
                        key={index}
                      />
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
              <div>
                <button className="seeMore">See more</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}
