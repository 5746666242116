import React, {useState} from "react";
import {logo} from "../../../assets/images";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import * as _ from "lodash";
import axiosInstance from "../../../api";
import {Loading} from "../../../components";

export function ForgotPass({handleLogin}) {
    let navigate = useNavigate()
    let [loading, setLoading] = useState(false)
    let [error, setError] = useState('')
    let [data, setData] = useState({})

    let changeInput = async (e, name) => {
        setData({..._.assignIn(data, {[name]: e})})
        setError('')
    }

    const EmailValidation = () => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        re.test(String(data.email).toLowerCase());
        return re.test(String(data.email).toLowerCase());
    }

    let sendEmail = async () => {
        try {
            if (data.email) {
                if (EmailValidation()) {
                    setLoading(true)
                    await axiosInstance.post('api/client/forgot-password', data)
                    setLoading(false)
                    navigate('change', {state: data})
                } else {
                    setError('Please enter valid email address')
                }
            } else {
                setError('Please fill in all required fields')
            }
        } catch (e) {
            setLoading(false)
            if (e.response?.data) {
                setError(e.response?.data.message)
            }
        }
    }


    return (
        <div className='signBanner'>
            <img src={logo} alt="" title="" className='logo'/>
            <div className={'div'}>
                <h1>Forgot password</h1>
                <p className="text_center enter_email_text">Enter your email to get a password reset link.</p>
                <label htmlFor="email">
                    Email address
                    <input type="email" id='email' onChange={(e) => changeInput(e.target.value, 'email')}/>
                </label>
                {error ?
                    <p style={{color: 'red', marginTop: 10}}>{error}</p>
                    :
                    null
                }
                <button className='btn' onClick={sendEmail}>Send email</button>
            </div>
            <Loading modalIsOpen={loading}/>
        </div>
    );
}